import { useRef } from 'react';

import {
  VariableTypeEnum,
  elementText,
  SourceTypeEnum,
  type VariableMap,
  type GlobalVariable,
  type Variable,
  type DatasourceMetadata,
  type Rule,
  type SelectVariable,
} from 'types-shared';
import { Select as MultiChoiceSelect } from 'ui-kit';

import {
  VariableInput,
  type VariableInputRef,
} from '../VariableTypes/VariableInput';
import { Select } from '../Select';
import {
  openAddVariableModal,
  openPreviewVariableModal,
} from '../../../../utils/helper';

interface Props {
  rule: Rule;
  onChange: (variable: Variable) => void;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  variablesMap: Record<string, Variable>;
  datasourceMetadata: DatasourceMetadata | null;
  sourceType?: SourceTypeEnum;
}

export default function ConditionalInput({
  rule,
  onChange,
  datasourceMetadata,
  variablesMap,
  globalVariablesMap,
  sourceType,
}: Props) {
  const inputRef = useRef<VariableInputRef>();

  const rawVar = rule.output[0];
  const variable =
    'variableId' in rawVar ? variablesMap[rawVar.variableId] : undefined;

  const onAddNewVariable = () => {
    openAddVariableModal({ isCondition: true });
  };

  const onPreviewVariable = (variableId: string) => {
    openPreviewVariableModal({ isCondition: true, variableId });
  };

  if (!variable) return null;

  if (variable.type === VariableTypeEnum.Template) {
    return (
      <VariableInput
        ref={inputRef}
        allowAddVariable={
          Boolean(datasourceMetadata) ||
          sourceType === SourceTypeEnum.API ||
          sourceType === SourceTypeEnum.EmailTrigger
        }
        className="my-4"
        value={variable.data}
        label={variable.name ?? ''}
        onClickAddNew={(indexForVariableInsert) => {
          openAddVariableModal({
            insertVariable(newVariable: Variable) {
              inputRef.current?.addVariable(
                newVariable,
                indexForVariableInsert,
              );
            },
            isCondition: true,
          });
        }}
        onChange={(data) => {
          onChange({
            ...variable,
            data,
          });
        }}
        onClickVariableChip={(variableId) => {
          onPreviewVariable(variableId);
        }}
        placeholder="Enter a value"
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
      />
    );
  }

  if (variable.type === VariableTypeEnum.Select) {
    return (
      <Select
        allowAddVariable={
          Boolean(datasourceMetadata) ||
          sourceType === SourceTypeEnum.API ||
          sourceType === SourceTypeEnum.EmailTrigger
        }
        className="my-4"
        data={variable.data}
        label={variable.name ?? ''}
        onAddNew={onAddNewVariable}
        onChange={(data) => {
          onChange({
            ...variable,
            data,
          });
        }}
        onPreview={(variableId) => {
          onPreviewVariable(variableId);
        }}
        options={(variable as SelectVariable).selectOptions}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
      />
    );
  }

  if (variable.type === VariableTypeEnum.MultiChoice) {
    const options = variable.multiChoiceOptions
      .map(
        (queryPath, i) =>
          elementText(queryPath) ?? `Option ${(i + 1).toString()}`,
      )
      .filter((v) => Boolean(v));

    const { selectedChoiceIx } = variable;
    const value =
      selectedChoiceIx !== null
        ? elementText(variable.multiChoiceOptions[selectedChoiceIx]) ?? ''
        : '';

    return (
      <MultiChoiceSelect
        className="w-full my-4"
        getLabel={(opt: string) => opt}
        getValue={(opt: string) => opt}
        label="Options"
        onChange={(p) => {
          const option = p.target.value;

          const selectedIndex = variable.multiChoiceOptions.findIndex(
            (v, i) =>
              (elementText(v) ?? `Option ${(i + 1).toString()}`) === option,
          );

          onChange({
            ...variable,
            selectedChoiceIx: selectedIndex,
            data: [selectedIndex.toString()],
          });
        }}
        options={options}
        placeholder="Select the option"
        value={value}
      />
    );
  }

  return null;
}
