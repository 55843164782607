import React, { type HTMLAttributes } from 'react';
import { clsx } from 'clsx';

interface LabelProps extends HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
  className?: string;
}
export function Label({ children, className, ...props }: LabelProps) {
  return (
    <span
      {...props}
      className={clsx(
        'text-cyan-900 text-sm font-medium leading-tight tracking-tight block',
        className,
      )}
    >
      {children}
    </span>
  );
}

interface SubLabelProps extends HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
  className?: string;
}
export function SubLabel({ children, className, ...props }: SubLabelProps) {
  return (
    <span
      {...props}
      className={clsx(
        'text-slate-500 text-sm font-normal leading-tight tracking-tight block',
        className,
      )}
    >
      {children}
    </span>
  );
}
