import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { clsx } from 'clsx';

type Props = Pick<
  TooltipProps,
  'title' | 'children' | 'placement' | 'hidden' | 'arrow'
> & {
  containerClassName?: string;
  tooltipClassName?: string;
};

export default function CustomTooltip({
  children,
  tooltipClassName,
  containerClassName,
  ...props
}: Props) {
  return props.hidden ? (
    children
  ) : (
    <Tooltip
      placement={props.placement ?? 'top'}
      {...props}
      classes={{
        tooltip: clsx('!bg-black !text-white !mb-0', tooltipClassName),
        arrow: '!text-black',
      }}
    >
      <span className={clsx('flex', containerClassName)}>{children}</span>
    </Tooltip>
  );
}
