import { Box, FormControl, Typography } from '@mui/material';
import { WorkflowConversionFormatEnum } from 'api-types-shared';
import { useState } from 'react';
import { apiEndpoints, Button, Flex, Select, useEnvironment } from 'ui-kit';
import { useAPI } from '../../../hooks/useApi';

interface Props {
  workflowId: string;
  onCancel: () => void;
}

export function ConfirmWorkflowProcessing({ workflowId, onCancel }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [temporalWorkflowId, setTemporalWorkflowId] = useState<string | null>(
    null,
  );

  const [inputFormat, setInputFormat] = useState(
    WorkflowConversionFormatEnum.LegacyWorkflow,
  );
  const [outputFormat, setOutputFormat] = useState(
    WorkflowConversionFormatEnum.LegacyWorkflow,
  );

  const { workflowSDK } = useAPI();
  const { selectedEnv } = useEnvironment();

  return (
    <Box>
      <Typography className="!font-medium" variant="h5">
        Process Workflow
      </Typography>
      <Typography className="text-info-dark max-w-md !mt-4" variant="body2">
        Select input/output format for the workflow you want to process.
      </Typography>
      <Box className="mt-7 bg-color-light-blue p-5 rounded-md">
        <Flex className="gap-2">
          <FormControl sx={{ margin: 0 }}>
            <div className="relative">
              <Select
                classes={{ select: '!w-56' }}
                getLabel={(opt: string) => opt}
                getValue={(opt: string) => opt}
                label="Select the workflow's input format"
                labelId="select-input-format"
                onChange={(event) => {
                  setInputFormat(
                    event.target.value as WorkflowConversionFormatEnum,
                  );
                }}
                options={Object.values(WorkflowConversionFormatEnum)}
                value={inputFormat}
              />
            </div>
          </FormControl>
        </Flex>
        <Flex className="gap-2">
          <FormControl sx={{ margin: 0 }}>
            <div className="relative">
              <Select
                classes={{ select: '!w-56' }}
                getLabel={(opt: string) => opt}
                getValue={(opt: string) => opt}
                label="Select the workflow's output format"
                labelId="select-output-format"
                onChange={(event) => {
                  setOutputFormat(
                    event.target.value as WorkflowConversionFormatEnum,
                  );
                }}
                options={Object.values(WorkflowConversionFormatEnum)}
                value={outputFormat}
              />
            </div>
          </FormControl>
        </Flex>
      </Box>
      <Flex className="gap-2 mt-9">
        <Button
          color="secondary"
          loading={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const processingId = await workflowSDK.startWorkflowProcessing(
              workflowId,
              inputFormat,
              outputFormat,
            );
            setTemporalWorkflowId(processingId);
            setIsLoading(false);
          }}
          variant="contained"
        >
          Start Processing
        </Button>
        <Button color="secondary" onClick={onCancel} variant="text">
          Cancel
        </Button>
      </Flex>
      {temporalWorkflowId ? (
        <Flex className="mt-4">
          <a
            className="px-2 py-1 border border-gray-800 rounded"
            target="_blank"
            rel="noreferrer"
            href={`${apiEndpoints[selectedEnv].temporalBaseUrl}/${temporalWorkflowId}`}
          >
            View on temporal
          </a>
        </Flex>
      ) : null}
    </Box>
  );
}
