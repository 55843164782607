import {
  type ExecutionVariables,
  parseVariable,
  ParseVariableMode,
  RequestMethodEnum,
  type VariableMap,
  WorkflowRequestNode,
  type TemplateVariable,
} from 'types-shared';
import { useMemo } from 'react';
import { Label } from 'ui-kit';
import { extractOutputsData, getRequestNodeBody } from '../../../utils';
import { handleException } from 'sentry-browser-shared';

interface Props {
  node: WorkflowRequestNode;
  executionVariables: ExecutionVariables;
}

export function RequestOutputs({
  node,
  executionVariables,
  // currentStepNodeId,
}: Props) {
  const responseOutputs = useMemo(() => {
    // This is for backwards compatibility for executions
    if (!WorkflowRequestNode.safeParse(node).success) {
      handleException(new Error('Request node is not valid'), {
        name: 'Request node is not valid',
        source: 'RequestOutputs',
        extra: { node },
      });
      return [];
    }
    // TODO: @neil show scrape data
    const allOutputs = extractOutputsData(executionVariables);
    const response = allOutputs.find(
      (o) => o.id === node.data.responseVariableId,
    );
    const { url, method, body } = node.data;
    const parsedUrl = parseVariable({
      variable: executionVariables[url.variableId] as TemplateVariable,
      variableMap: executionVariables as VariableMap,
      mode: ParseVariableMode.Execution,
      handleException,
    });

    const items = [
      { id: 'event', title: 'Method', description: method },
      { id: 'url', title: 'URL', description: parsedUrl },
      {
        id: 'response',
        title: 'Response',
        description: response?.description ?? 'No response content',
      },
    ];
    if (method === RequestMethodEnum.Get) {
      return items;
    }

    const bodyItem = {
      id: 'body',
      title: 'Body',
      description: getRequestNodeBody(body, executionVariables as VariableMap),
    };

    items.splice(2, 0, bodyItem);

    return items;
  }, [executionVariables, node]);

  return (
    <div className="flex flex-col gap-4 py-4 px-6 overflow-auto">
      {responseOutputs.map(({ id, title, description }) => (
        <div
          className="rounded-lg border border-slate-300 px-4 py-4 flex flex-col space-y-6"
          key={id}
        >
          <Label>{title}</Label>

          {description ? (
            <div
              className="text-slate-500 text-sm [&>a]:text-blue-700 [&>a]:underline break-all"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : null}
        </div>
      ))}
    </div>
  );
}
