export function CustomMarker() {
  const markerDim = 48;
  return (
    <svg fill="none">
      {' '}
      {/* Tailwind class for z-index: 50 and translate 50% up */}
      <defs>
        <marker
          id="triangle"
          markerHeight={markerDim}
          markerUnits="userSpaceOnUse"
          markerWidth={markerDim}
          orient="auto"
          overflow="visible"
          refX="14"
          refY="12"
          viewBox="0 0 24 24"
        >
          <path
            d="M10 8L14 12L10 16"
            stroke="black"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </marker>
      </defs>
    </svg>
  );
}
