import { useMemo } from 'react';
import {
  NodeTypesEnum,
  RequestIntegrationTypeEnum,
  type SourceVariable,
  WorkflowImageNode,
  WorkflowNode,
  type WorkflowSourceNode,
} from 'types-shared/workflowTypes';
import { getMetadataFromImageUrl, type ScreenshotUrl } from '../utils';
import {
  type ExecutionBase,
  type ExecutionProgress,
} from 'types-shared/executionTypes';
import {
  ConditionalIcon,
  Logo,
  RequestBlockIcon,
  MailIcon,
  RetryIcon,
  FlagIcon,
  ConditionalErrorIcon,
} from 'ui-kit';
import { SourceTypeEnum } from 'types-shared/sourceTypes';
import { clsx } from 'clsx';
import isNil from 'lodash/isNil';
import { handleException } from 'sentry-browser-shared';
import { isAdmin } from '../../../utils/env';

export function RetryNodeIcon({ className }: { className?: string }) {
  return <RetryIcon className={clsx('retry-node-contrast', className)} />;
}

export const iconNodeTypeMapping = {
  [NodeTypesEnum.Conditional]: ConditionalIcon,
  [NodeTypesEnum.Request]: RequestBlockIcon,
  [NodeTypesEnum.Source]: MailIcon,
  [NodeTypesEnum.Freeform]: Logo,
  [NodeTypesEnum.Retry]: RetryNodeIcon,
  [NodeTypesEnum.Stop]: FlagIcon,
};

const useWorkflowScreenshotUrls = ({
  nodes,
  sourceNode,
  sourceVariable,
  completedSteps,
  showAllNodeLogs,
  currentStep,
  imageArray = [],
}: {
  nodes: WorkflowNode[];
  sourceNode: WorkflowSourceNode | undefined;
  sourceVariable: SourceVariable | undefined;
  completedSteps: ExecutionProgress;
  showAllNodeLogs: boolean;
  currentStep: ExecutionBase['currentStep'];
  imageArray: [string, string][];
}): ScreenshotUrl[] => {
  const screenshotUrls: ScreenshotUrl[] = useMemo(() => {
    const screenshotIntermediate: ScreenshotUrl[] = imageArray
      .filter(([name]) => {
        const { nodeId: screenshotNodeId } = getMetadataFromImageUrl(name);
        const node = nodes.find((n) => n.id === screenshotNodeId);
        return isAdmin || !node?.hideFromUser;
      })
      .map(([name, url]) => {
        const {
          nodeId: screenshotNodeId,
          timestamp,
          stepId,
        } = getMetadataFromImageUrl(name);
        const node = nodes.find((n) => n.id === screenshotNodeId);
        const nodeType = node?.type ?? NodeTypesEnum.Image;
        const isVideo = name.includes('.mp4');
        return {
          type: isVideo ? 'video' : 'blob',
          nodeType,
          src: url,
          sortData: {
            timestamp,
            nodeId: screenshotNodeId,
            stepId,
            name,
          },
        };
      });

    const stepsFromCompleted: ScreenshotUrl[] = completedSteps
      .filter((step) => {
        const stepNode = nodes.find((n) => n.id === step.nodeId);
        return (
          stepNode &&
          !WorkflowImageNode.safeParse(stepNode).success &&
          (isAdmin || !stepNode.hideFromUser)
        );
      })
      .map((step) => {
        const rawStepNode = nodes.find((n) => n.id === step.nodeId);
        if (!rawStepNode) {
          handleException(new Error('Step node not found'), {
            name: 'Step node not found',
            source: 'useWorkflowScreenshotUrls',
            extra: { step, nodes },
          });
          throw new Error('Step node not found');
        }

        const parsedStepNode = WorkflowNode.safeParse(rawStepNode);
        if (!parsedStepNode.success) {
          handleException(new Error(parsedStepNode.error.message), {
            name: 'Step node in execution details is not valid',
            source: 'useWorkflowScreenshotUrls',
            extra: { step, nodes, rawStepNode, parsedStepNode },
          });
        }
        const stepNode = parsedStepNode.success
          ? parsedStepNode.data
          : rawStepNode;

        const isSalesforce =
          stepNode.type === NodeTypesEnum.Request &&
          stepNode.data.integrationType ===
            RequestIntegrationTypeEnum.Salesforce;

        if (isSalesforce) {
          return {
            type: 'image-icon',
            nodeType: stepNode.type,
            src: <img alt="salesforce" src="/salesforce.png" />,
            sortData: {
              timestamp: step.timestamp,
              nodeId: step.nodeId,
              stepId: undefined,
              name: undefined,
            },
          };
        }

        const icon =
          stepNode.type === NodeTypesEnum.Conditional && stepNode.errorOverlay
            ? ConditionalErrorIcon
            : iconNodeTypeMapping[
                stepNode.type as keyof typeof iconNodeTypeMapping
              ];
        return {
          type: 'icon',
          nodeType: stepNode.type,
          src: icon,
          sortData: {
            timestamp: step.timestamp,
            nodeId: step.nodeId,
            stepId: undefined,
            name: undefined,
          },
        };
      });

    const stepsUnsorted = showAllNodeLogs
      ? [...stepsFromCompleted, ...screenshotIntermediate]
      : screenshotIntermediate;

    const stepsSorted = stepsUnsorted.sort((a, b) => {
      const aTimestamp = a.sortData.timestamp;
      const bTimestamp = b.sortData.timestamp;

      // This is to handle backwards compatibility for steps that don't have a timestamp
      if (aTimestamp === undefined || bTimestamp === undefined) {
        const indexA = completedSteps.findIndex(
          (s) => s.nodeId === a.sortData.nodeId,
        );
        const indexB = completedSteps.findIndex(
          (s) => s.nodeId === b.sortData.nodeId,
        );
        return indexA - indexB;
      }

      return aTimestamp.localeCompare(bTimestamp);
    });

    if (
      sourceNode &&
      sourceVariable?.data.sourceType === SourceTypeEnum.EmailTrigger
    ) {
      stepsSorted.unshift({
        type: 'icon',
        nodeType: NodeTypesEnum.Source,
        src: iconNodeTypeMapping[NodeTypesEnum.Source],
        sortData: {
          nodeId: sourceNode.id,
          timestamp: undefined,
          stepId: undefined,
          name: undefined,
        },
      });
    }

    const lastCompletedStep = completedSteps[completedSteps.length - 1];
    const currentStepNode = nodes.find((n) => n.id === currentStep?.nodeId);
    if (
      isNil(lastCompletedStep) ||
      (lastCompletedStep.nodeId !== currentStep?.nodeId &&
        (isAdmin || !currentStepNode?.hideFromUser))
    ) {
      const currentNode = nodes.find((node) => node.id === currentStep?.nodeId);
      if (
        currentNode &&
        !WorkflowImageNode.safeParse(currentNode).success &&
        currentNode.type in iconNodeTypeMapping
      ) {
        stepsSorted.push({
          type: 'icon',
          nodeType: currentNode.type,
          src: iconNodeTypeMapping[
            currentNode.type as keyof typeof iconNodeTypeMapping
          ],
          sortData: {
            nodeId: currentNode.id,
            stepId: currentStep?.stepId,
          },
        });
      }
    }

    return stepsSorted;
  }, [
    imageArray,
    completedSteps,
    showAllNodeLogs,
    sourceNode,
    sourceVariable?.data.sourceType,
    currentStep,
    nodes,
  ]);

  return screenshotUrls;
};

export default useWorkflowScreenshotUrls;
