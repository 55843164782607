import { useState } from 'react';
import { Input, Modal, ModalContent } from 'ui-kit';

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (apiKey: string) => void;
}

export default function AddKeyModal({ open, onClose, onSave }: Props) {
  const [apiKey, setApiKey] = useState<string>('');

  const handleClose = () => {
    onClose();
    setApiKey('');
  };

  return (
    <Modal className="w-full max-w-screen-md" onClose={handleClose} open={open}>
      <ModalContent
        title="Set API Key"
        descriptions={[
          'Add a previously created API Key to test the curl request.',
        ]}
        actions={[
          {
            text: 'Set API Key',
            color: 'secondary',
            disabled: !apiKey,
            onClick: () => {
              onSave(apiKey);
              handleClose();
            },
          },
          {
            text: 'Cancel',
            variant: 'text',
            onClick: handleClose,
          },
        ]}
      >
        <Input
          classes={{ wrapper: '!mt-8 !mb-2' }}
          floatingLabel
          label="API Key"
          onChange={setApiKey}
          placeholder="37880e9a-f21b-4be0-a51e-cc85f657311f"
          value={apiKey}
        />
      </ModalContent>
    </Modal>
  );
}
