"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParagonUserTokenSchema = void 0;
var types_shared_1 = require("types-shared");
exports.getParagonUserTokenSchema = {
    request: types_shared_1.z.object({}),
    response: types_shared_1.z.object({
        token: types_shared_1.z.string(),
    }),
};
