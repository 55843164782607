"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.listFileMetasSchema = void 0;
var types_shared_1 = require("types-shared");
var file_schema_1 = require("./file.schema");
exports.listFileMetasSchema = {
    request: types_shared_1.z.object({
        query: types_shared_1.z
            .object({
            workflowId: types_shared_1.z.string().optional(),
            teamId: types_shared_1.z.string().optional(),
        })
            .optional(),
    }),
    response: types_shared_1.z.object({
        filesMetadata: types_shared_1.z.array(file_schema_1.fileMetadataSchema),
    }),
};
