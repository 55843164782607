import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { useAuth0 } from '@auth0/auth0-react';
import PersonIcon from '../../assets/PersonIcon';
import { clsx } from 'clsx';
import debounce from 'lodash/debounce';
import type { TeamNameAndRole } from 'hasura-gql';

export default function Profile({
  teamDetails,
  isExpanded,
}: {
  teamDetails?: TeamNameAndRole;
  isExpanded: boolean;
}) {
  const { user, logout } = useAuth0();
  const { teamName, roleName } = teamDetails ?? {};
  const profileButtonRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const logOutUser = () => {
    void logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseOver = useCallback(
    ({ target, clientX, clientY }: MouseEvent) => {
      const node = target as Node;
      const {
        top = 0,
        left = 0,
        right = 0,
        bottom = 0,
      } = profileButtonRef.current?.getBoundingClientRect() ?? {};
      const isHoveringOverPopup =
        Boolean(popupRef.current?.contains(node)) ||
        Boolean(profileButtonRef.current?.contains(node)) ||
        (clientX >= left &&
          clientX <= right &&
          clientY >= top &&
          clientY <= bottom);

      if (!isHoveringOverPopup) {
        handleClose();
      }
    },
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleMouseOver = useCallback(
    debounce(handleMouseOver, 150),
    [],
  );

  useEffect(() => {
    window.addEventListener('mousemove', debouncedHandleMouseOver);

    return () => {
      window.removeEventListener('mousemove', debouncedHandleMouseOver);
    };
  }, [debouncedHandleMouseOver]);

  return (
    <>
      <div
        className={clsx(
          'mt-auto flex space-x-3 items-center px-3 py-4 rounded w-full duration-200',
          { 'bg-sola-primary bg-opacity-10': Boolean(anchorEl) },
        )}
        onMouseEnter={handleClick}
        ref={profileButtonRef}
      >
        <AccountCircleOutlinedIcon
          className={clsx('text-color-grey !h-5 !w-5', {
            '!mx-auto': !isExpanded,
          })}
        />
        {isExpanded ? (
          <div className="text-sm font-normal text-left">
            <p className="text-color-grey font-medium">Profile</p>
            <p className="text-black">{user?.name}</p>
            {roleName ? (
              <p className="text-color-grey text-xs mt-0.5">{roleName}</p>
            ) : null}
          </div>
        ) : null}
      </div>

      <MuiMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        hideBackdrop
        onClose={handleClose}
        open={Boolean(anchorEl)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '8px',
            paddingTop: 0,
          },
          '& .MuiMenu-list': {
            background:
              'linear-gradient(50.33deg, rgba(184, 220, 252, 0) 68.39%, rgba(184, 220, 252, 0.4) 100%)',
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className="z-[1500] zoom-adjusted-container" ref={popupRef}>
          <div
            className={clsx(
              'flex flex-row items-start w-full pb-12 px-4 pt-5 border-b border-border',
              { '!pb-5': teamDetails },
            )}
          >
            <div className="!bg-primarySelected py-1.5 px-2 rounded">
              <PersonIcon className="!fill-none" />
            </div>
            <div className="pl-5 text-sm font-medium text-left">
              <p className="text-infoDark">
                {teamName ? teamName : user?.name}
              </p>
              <p className="text-secondaryText">{user?.email}</p>

              {roleName ? (
                <p className="text-infoDark text-xs mt-4">{roleName}</p>
              ) : null}
            </div>
          </div>
          <MuiMenuItem className="group min-w-72 h-12" onClick={logOutUser}>
            <span className="font-normal text-base text-error leading-6 pl-0.5 py-1">
              Logout
            </span>
          </MuiMenuItem>
        </div>
      </MuiMenu>
    </>
  );
}
