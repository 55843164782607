import { createEventBus, type EventMap } from 'ui-kit';
import { useEffect, useState } from 'react';

interface EditNodeEvents extends EventMap {
  open: (nodeId: string | undefined) => void;
  close: () => void;
}

const editNodeEventsEventBus = createEventBus<EditNodeEvents>();

export const useEditingNodeId = () => {
  const [editingNodeId, setEditingNodeId] = useState<string | undefined>();

  useEffect(() => {
    const unsubscribe = editNodeEventsEventBus.on('open', (id) => {
      setEditingNodeId(undefined);
      setTimeout(() => {
        setEditingNodeId(id);
      }, 0);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    editingNodeId,
    setEditingNodeId: (id: string | undefined) => {
      editNodeEventsEventBus.emit('open', id);
    },
  };
};
