import Skeleton from '@mui/material/Skeleton';
import { useGetWorkflowListMetrics } from '../hooks/useMetrics';

export default function WorkflowListMetrics() {
  const { data, isLoading } = useGetWorkflowListMetrics();

  return (
    <div className="flex space-x-4 w-full mt-7">
      <div className="flex flex-col space-y-3 flex-1 px-6 py-4 rounded-lg border">
        <p className="text-base font-medium">Total workflows</p>
        {isLoading ? (
          <Skeleton
            variant="text"
            sx={{ fontSize: '2rem' }}
            className="w-full"
            height={36}
          />
        ) : (
          <p className="text-3xl font-medium">{data?.workflowsCount}</p>
        )}
      </div>
      <div className="flex flex-1 justify-between items-center px-6 py-4 rounded-lg border">
        <div className="flex flex-col space-y-3">
          <p className="text-base font-medium">
            Executions
            <span className="text-gray-500 ml-1">this month</span>
          </p>
          {isLoading ? (
            <Skeleton
              variant="text"
              sx={{ fontSize: '2rem' }}
              className="w-full"
              height={36}
            />
          ) : (
            <p className="text-3xl font-medium">{data?.executionsCount}</p>
          )}
        </div>
      </div>
    </div>
  );
}
