"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRequiredExtensionVersionSchema = exports.getExtensionSchema = void 0;
var types_shared_1 = require("types-shared");
exports.getExtensionSchema = {
    request: types_shared_1.z.object({}),
    response: types_shared_1.z.object({
        version: types_shared_1.z.string(),
        url: types_shared_1.z.string(),
    }),
};
exports.getRequiredExtensionVersionSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({}).nullable(),
        query: types_shared_1.z.object({
            currentVersion: types_shared_1.z.string(),
        }),
    }),
    response: types_shared_1.z.object({
        requiredVersion: types_shared_1.z.string(),
        needsUpdate: types_shared_1.z.boolean(),
    }),
};
