import React, { useCallback, useState, useEffect } from 'react';
import {
  type DocumentVariable,
  type VariableMap,
  type WorkflowAction,
  type WorkflowImageNode,
} from 'types-shared/workflowTypes';
import { useGetFileBlob } from '../../../hooks';
import { type RenderPdfProps } from './AnnotatedPDF';
import { EditorStore } from '../../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import omit from 'lodash/omit';
import DocumentVariablesWithFile from './HasFile';
import DocumentVariablesWithNoFile from './HasNoFile';
import { useFeatureFlag } from '../../../../../utils/helper';
import { FeatureFlag } from '../../../../../utils/constants';
import { VariableChip } from '../../../../../components/VariableChip';

interface DocumentVariablesProps {
  variable: DocumentVariable;
  variablesMap: VariableMap;
  globalVariablesMap: VariableMap;
  onPreviewVariable: () => void;
  workflowId: string;
  action: WorkflowAction;
  node: WorkflowImageNode;
  setAnnotatedPDFData: React.Dispatch<
    React.SetStateAction<RenderPdfProps | undefined>
  >;
}

export default function DocumentVariables({
  variable,
  variablesMap,
  globalVariablesMap,
  onPreviewVariable,
  workflowId,
  action,
  node,
  setAnnotatedPDFData,
}: DocumentVariablesProps) {
  const documentProcessingEnabled = useFeatureFlag(
    FeatureFlag.DocumentProcessing,
  );
  const { updateVariable } = EditorStore(
    useShallow((s) => ({
      updateVariable: s.updateVariable,
    })),
  );

  const [fileBlob, setFileBlob] = useState<Blob | undefined>(undefined);

  const { mutateAsync: getBlob, status } = useGetFileBlob();

  const isFetching = status === 'pending';

  const handleGetBlob = useCallback(
    async (fileId: string) => {
      const blob = await getBlob({ fileId });
      setFileBlob(blob);
    },
    [getBlob],
  );

  const destroyOldCanvas = () => {
    const containerParent = document.getElementById(
      'annotated-canvas-container',
    );
    const container = document.getElementById('annotated-canvas');
    if (container && containerParent) {
      container.remove();
      const newCanvas = document.createElement('canvas');
      newCanvas.className = 'border border-gray-300';
      newCanvas.id = 'annotated-canvas';
      containerParent.insertBefore(newCanvas, containerParent.firstChild);
    }
  };

  const onSwapFile = () => {
    const clonedData = omit(variable.data, ['s3Ref']);
    updateVariable({
      ...variable,
      data: clonedData,
    });
    setAnnotatedPDFData(undefined);
    setFileBlob(undefined);
    destroyOldCanvas();
  };

  useEffect(() => {
    if (variable.data.s3Ref && !fileBlob) {
      void handleGetBlob(variable.data.s3Ref.fileId);
    }
  }, [variable, handleGetBlob, fileBlob]);

  return (
    <div>
      <span className="font-medium text-[#103D61]">Downloaded document</span>
      <div className="pt-2">
        <VariableChip
          onClick={onPreviewVariable}
          variableId={variable.id}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
        />
      </div>

      {documentProcessingEnabled ? (
        <>
          {variable.data.s3Ref && fileBlob ? (
            <DocumentVariablesWithFile
              fileBlob={fileBlob}
              variable={variable}
              variablesMap={variablesMap}
              fileId={variable.data.s3Ref.fileId}
              onSwapFile={onSwapFile}
              setAnnotatedPDFData={setAnnotatedPDFData}
              updateVariable={updateVariable}
            />
          ) : (
            <DocumentVariablesWithNoFile
              variable={variable}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              onPreviewVariable={onPreviewVariable}
              workflowId={workflowId}
              action={action}
              node={node}
              fetchingFile={isFetching}
            />
          )}
        </>
      ) : null}
    </div>
  );
}
