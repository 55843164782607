import type { BlockerFunction } from 'react-router-dom';
import { useBlocker } from 'react-router-dom';
import { useEffect } from 'react';
import { modalEventChannel } from 'ui-kit';

interface Props {
  when: boolean | BlockerFunction;
  message: string;
  onLeave?: (blocker: { proceed: () => void }) => void;
  onSave?: () => Promise<void>;
}

export default function usePrompt({ when, message, onLeave, onSave }: Props) {
  const blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      modalEventChannel.emit('open', {
        title: 'Save your workflow changes to cloud',
        descriptions: [message],
        actions: [
          {
            text: onSave ? 'SAVE CHANGES IN CLOUD' : 'Leave',
            variant: 'contained',
            onClick: async () => {
              modalEventChannel.emit('close');
              if (onSave) {
                await onSave();
              }
              if (onLeave) {
                onLeave(blocker);
              } else {
                blocker.proceed();
              }
            },
          },
          {
            text: 'Leave',
            variant: 'outlined',
            onClick: () => {
              blocker.reset();
              modalEventChannel.emit('close');
            },
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker, message]);
}
