import { clsx } from 'clsx';
import React, { type ReactNode } from 'react';

import Button from '../Button/index';
import Typography from '@mui/material/Typography';

export interface Props {
  title?: string;
  descriptions?: string[];
  children?: ReactNode;

  contentClassName?: string;
  titleClassName?: string;

  actions?: {
    text: string;
    onClick: () => void;
    variant?: 'text' | 'outlined' | 'contained'; // default: contained
    color?:
      | 'inherit'
      | 'primary'
      | 'secondary'
      | 'success'
      | 'error'
      | 'info'
      | 'warning'; // default: secondary
    disabled?: boolean;
    loading?: boolean;
  }[];

  alignActions?: 'left' | 'right'; // default: left
}

function ModalContent({
  title,
  descriptions,
  children,
  contentClassName,
  titleClassName,
  actions,
  alignActions = 'left',
}: Props) {
  return (
    <div className={clsx('!max-w-2xl pr-6', contentClassName)}>
      {title ? (
        <Typography className={clsx('font-bold', titleClassName)} variant="h5">
          {title}
        </Typography>
      ) : null}

      {descriptions ? (
        <div className="mt-8">
          {descriptions.map((description, index) => (
            <Typography
              key={description}
              className={clsx('!text-info-dark', index !== 0 && '!mt-4')}
            >
              {description}
            </Typography>
          ))}
        </div>
      ) : null}

      {children}
      {actions ? (
        <div
          className={clsx({
            'flex items-center space-x-2 !mt-8': true,
            'justify-start': alignActions === 'left',
            'justify-end': alignActions === 'right',
          })}
        >
          {actions.map((action) => (
            <Button
              color={action.color ?? 'secondary'}
              disabled={action.disabled}
              key={action.text}
              loading={action.loading}
              onClick={action.onClick}
              variant={action.variant ?? 'contained'}
            >
              {action.text}
            </Button>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default ModalContent;
