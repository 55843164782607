import { useMemo } from 'react';
import {
  type DocumentVariable,
  QueryVariable,
  type Variable,
} from 'types-shared';

interface Props {
  documentVariables: DocumentVariable[];
  variablesMap: Record<string, Variable>;
}

export const useDocumentQueryVariables = ({
  documentVariables,
  variablesMap,
}: Props): QueryVariable[] => {
  return useMemo(() => {
    return Object.values(variablesMap).filter((v) => {
      const parseRes = QueryVariable.safeParse(v);
      if (!parseRes.success) return false;

      return parseRes.data.data.sourceIds.some((sourceId) =>
        documentVariables.some((docVar) => docVar.id === sourceId),
      );
    }) as QueryVariable[];
  }, [documentVariables, variablesMap]);
};
