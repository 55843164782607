import type { ButtonProps } from '@mui/material/Button';
import MuiButton from '@mui/material/Button';
import { clsx } from 'clsx';
import type { ForwardedRef } from 'react';
import React, { forwardRef } from 'react';

import Spinner from '../Spinner';

type Props = ButtonProps & {
  loading?: boolean;
};

function Button(
  { className, variant, disabled, loading, children, ...props }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const classes = clsx('!shadow-none', className);
  const spinnerClasses = clsx('ml-2', {
    '!hidden': !loading,
  });

  return (
    <MuiButton
      className={classes}
      disabled={Boolean(disabled) || Boolean(loading)}
      ref={ref}
      variant={variant}
      {...props}
    >
      {children}
      <Spinner className={spinnerClasses} />
    </MuiButton>
  );
}

export default forwardRef(Button);
