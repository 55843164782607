"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkApolloQueryResult = checkApolloQueryResult;
exports.checkApolloMutationResult = checkApolloMutationResult;
function checkApolloQueryResult(result, queryPrefix) {
    if (result.errors && result.errors.length > 0) {
        throw new Error("Failed to query: ".concat(queryPrefix, ": ").concat(result.errors.map(function (e) { return e.message; }).join(', ')));
    }
    if (!result.data) {
        throw new Error("".concat(queryPrefix, ": No data returned"));
    }
    return result.data;
}
function checkApolloMutationResult(result, mutationPrefix) {
    if (result.errors && result.errors.length > 0) {
        throw new Error("Failed to mutate: ".concat(mutationPrefix, ": ").concat(result.errors.map(function (e) { return e.message; }).join(', ')));
    }
    if (!result.data) {
        throw new Error("".concat(mutationPrefix, ": No data returned"));
    }
    return result.data;
}
