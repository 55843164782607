import { useRef, useCallback } from 'react';

function useThrottle<T extends (...args: never[]) => void>(
  callback: T,
  delay: number,
): T {
  const lastCall = useRef<number | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const throttledFunction = useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now();

      if (lastCall.current === null || now - lastCall.current >= delay) {
        if (timeout.current) {
          clearTimeout(timeout.current);
          timeout.current = null;
        }

        lastCall.current = now;
        callback(...args);
      } else if (!timeout.current) {
        timeout.current = setTimeout(
          () => {
            lastCall.current = Date.now();
            timeout.current = null;
            callback(...args);
          },
          delay - (now - lastCall.current),
        );
      }
    },
    [callback, delay],
  );

  return throttledFunction as T;
}

export default useThrottle;
