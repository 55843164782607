import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { hasuraGraphQLEndpoint, isAdmin } from '../utils/env';

export const createApolloClient = (
  getAccessTokenSilently: () => Promise<string>,
) => {
  const graphQLEndpoint = `${hasuraGraphQLEndpoint}/v1/graphql`;
  const httpLink = createHttpLink({
    uri: graphQLEndpoint,
  });

  const authLink = setContext(
    async (_, { headers }: { headers?: Record<string, string> }) => {
      // get the authentication token from local storage if it exists
      const token = await getAccessTokenSilently();
      const headerName = isAdmin ? 'x-hasura-admin-secret' : 'authorization';
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          [headerName]: isAdmin ? token : `Bearer ${token}`,
        },
      };
    },
  );

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        // NOTE: We do not currently read the cache for query results
        fetchPolicy: 'network-only',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
};
