"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiKey = exports.ApiKeyFull = void 0;
var zod_1 = require("zod");
exports.ApiKeyFull = zod_1.z.object({
    keyId: zod_1.z.string(),
    name: zod_1.z.string(),
    key: zod_1.z.string(),
    userId: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
    lastUsedAt: zod_1.z.string(),
    deleted: zod_1.z.boolean(),
});
exports.ApiKey = exports.ApiKeyFull.omit({
    key: true,
});
