import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  useFetchWorkflowExecutionDetails,
  useFetchWorkflowMetadata,
  useUpdateWorkflowName,
  useWorkflowDetailCols,
  useBatchCancelExecutions,
} from '../hooks';
import { useFeatureFlag } from '../../../utils/helper';
import { isUUID } from '../../Editor/utils/helper';
import {
  ContentContainer,
  Flex,
  ScrollableContainer,
  SortOrder,
  CustomTypography,
  Button,
  InfoOutlined,
  WarningAmberOutlined,
} from 'ui-kit';
import { AdminVersionEnum, WorkflowStatusEnum } from 'api-types-shared';
import { type ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { isAdmin } from '../../../utils/env';

import { Toolbar } from '../../../components/Toolbar';
import {
  WorkflowDetailSkeleton,
  WorkflowDetailSkeletonHeader,
} from '../components/WorkflowDetailSkeleton';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { NoDataFound } from '../../../components/NoDataFound';
import CloneModal from '../components/CloneModal';
import {
  EXECUTIONS_LIST_REFETCH_INTERVAL,
  FeatureFlag,
  workflowProcessingStatuses,
} from '../../../utils/constants';
import { useUpdateExecution } from '../../Execution/hooks';
import type { CompactWorkflowMetadata } from '../types';
import WorkflowDetailHeader from '../components/WorkflowDetailHeader';
import ExecutionListFilter from '../components/ExecutionListFilter';
import {
  executionListFiltersSchema,
  defaultFilters,
  mapStatuses,
  hasFilters,
} from '../utils/helper';
import { type GridSortModel } from '@mui/x-data-grid/models';
import usePersistedURLState from '../../../hooks/usePersistedURLState';
import WorkflowExecutionMetrics from '../components/WorkflowExecutionMetrics';
import CircularProgress from '@mui/material/CircularProgress';
import isEqual from 'lodash/isEqual';

import { clsx } from 'clsx';
import WorkflowDetailsMenu, {
  type WorkflowDetailsMenuProps,
} from '../components/WorkflowDetailMenu';

const sortOrder = [SortOrder.DESC, SortOrder.ASC];

export default function WorkflowDetail() {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('WorkflowId not found!');
  }

  if (!isUUID(workflowId)) {
    navigate('/');
  }

  const inputRef = useRef<HTMLInputElement>(null);

  const [filters, setFilters] = usePersistedURLState(
    executionListFiltersSchema,
    defaultFilters,
    `executions-list-filter-${workflowId}`,
  );
  const [orderBy, setOrderBy] = useState<GridSortModel>([
    { field: 'createdAt', sort: SortOrder.DESC },
  ]);
  const [cancellingExecutions, setCancellingExecutions] = useState<string[]>(
    [],
  );
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [isEditingName, setIsEditingName] = useState<boolean>(false);
  const [timeToRefresh, setTimeToRefresh] = useState<number>(
    EXECUTIONS_LIST_REFETCH_INTERVAL,
  );
  const [workflowName, setWorkflowName] = useState<string>('');

  const [cloneWorkflowData, setCloneWorkflowData] =
    useState<CompactWorkflowMetadata | null>(null);
  const [menuProps, setMenuProps] = useState<WorkflowDetailsMenuProps | null>();

  const showFilters = useFeatureFlag(FeatureFlag.ExecutionsFilters);
  const cancelExecutionsEnabled = useFeatureFlag(FeatureFlag.CancelExecutions);

  const {
    data: workflowMetadata,
    isLoading: fetchingWorkflowMetadata,
    refetch: refetchWorkflowMetadata,
  } = useFetchWorkflowMetadata(workflowId);
  const statusValue = useMemo(() => {
    const status = filters.status[0];
    return !status || (filters.status.length === 1 && status === 'All')
      ? undefined
      : mapStatuses(filters.status);
  }, [filters.status]);

  const workflowProcessing = useMemo(
    () =>
      Boolean(
        workflowMetadata?.status &&
          workflowProcessingStatuses.allProcessing.includes(
            workflowMetadata.status,
          ),
      ),
    [workflowMetadata?.status],
  );

  const workflowVersionId = workflowMetadata?.currentVersionId;

  const {
    isLoading: executionsListLoading,
    refetch: refetchExecutions,
    data: executionsData,
    isRefetching: executionsListFetching,
    dataUpdatedAt: executionsListDataUpdatedAt,
  } = useFetchWorkflowExecutionDetails(
    {
      workflowId,
      adminRun:
        typeof filters.hideAdminRuns === 'boolean'
          ? filters.hideAdminRuns
          : undefined,
      status: statusValue,
      executionId: filters.id ? filters.id : undefined,
    },
    {
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      orderBy,
      doNotRefetch: workflowProcessing && !isAdmin,
    },
  );
  const {
    executions = [],
    filteredExecutionsCount = 0,
    totalExecutionsCount = 0,
  } = executionsData ?? {};
  const isLoading = executionsListLoading || executionsListFetching;
  // const { mutateAsync, status } = useDownloadExecutionData(); - TODO: re-enable after M&M sandbox
  // const { mutateAsync: downloadExecutionData } = useDownloadExecutionData();
  // const hideRunId = useFeatureFlag(FeatureFlag.HideRunId, false);
  const { status: executionUpdateStatus, mutate: updateExecution } =
    useUpdateExecution();
  const editWorkflowEnabled = useFeatureFlag(FeatureFlag.EditWorkflow);
  const executionDetailsEnabled = Boolean(
    useFeatureFlag(FeatureFlag.ExecutionDetails),
  );
  const hitlLiveViewEnabled = Boolean(useFeatureFlag(FeatureFlag.HitlLiveView));
  const { mutateAsync: updateWorkflowMetadata } = useUpdateWorkflowName();

  const { mutateAsync: batchCancelExecutions } = useBatchCancelExecutions();

  const handleCancelExecutions = async () => {
    if (menuProps?.executionIds) {
      setCancellingExecutions((prevCancelIds) => [
        ...prevCancelIds,
        ...menuProps.executionIds,
      ]);
      await batchCancelExecutions({ executionIds: menuProps.executionIds })
        .then(() => {
          setCancellingExecutions((prevCancelIds) =>
            prevCancelIds.filter((i) => !menuProps.executionIds.includes(i)),
          );
          setMenuProps(null);
        })
        .catch(() => {
          setCancellingExecutions((prevCancelIds) =>
            prevCancelIds.filter((i) => !menuProps.executionIds.includes(i)),
          );
          setMenuProps(null);
        });

      await refetchExecutions();
    }
  };

  const hasErrorPush = useMemo(() => {
    return workflowMetadata?.currentVersionCommitUsers?.includes(
      AdminVersionEnum.ForcePush,
    );
  }, [workflowMetadata?.currentVersionCommitUsers]);

  const hasErrorForcePush = useMemo(() => {
    return workflowMetadata?.currentVersionCommitUsers?.includes(
      AdminVersionEnum.ErrorForcePush,
    );
  }, [workflowMetadata?.currentVersionCommitUsers]);

  const columns = useWorkflowDetailCols({
    updateExecution,
    executionUpdateStatus,
    executionDetailsEnabled,
    hitlLiveViewEnabled,
    setMenuProps,
    cancelExecutionsEnabled: Boolean(cancelExecutionsEnabled),
  });

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkflowName(event.target.value);
  };

  const updateWorkflowName = async () => {
    setIsEditingName(false);
    if (inputRef.current) {
      inputRef.current.scrollLeft = 0;
    }
    if (workflowMetadata?.workflowName !== workflowName) {
      await updateWorkflowMetadata({ workflowId, workflowName });
    }
  };

  const onResetPage = () => {
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
  };

  useEffect(() => {
    if (!hasFilters(filters)) {
      setSearchParams({});
    }
  }, [filters, setSearchParams]);

  const noFiltersYet = useMemo(() => {
    return isEqual(filters, defaultFilters);
  }, [filters]);

  const workflowProcessingLabel = useMemo(() => {
    if (!workflowMetadata?.status) return '';

    if (workflowMetadata.status === WorkflowStatusEnum.ProcessingImport) {
      return "Your workflow is actively processing. This could take a few minutes. We’ll notify you once it's ready!";
    }
    if (
      workflowMetadata.status ===
      WorkflowStatusEnum.ProcessingImportErrorAuthentication
    ) {
      return 'Your workflow is currently missing the necessary credentials. Please re-record the login steps and import them here to continue processing.';
    }
    if (workflowMetadata.status === WorkflowStatusEnum.ProcessingImportError) {
      return 'An error has occurred with your workflow. Please re-import it and avoid closing or refreshing this tab during the process. If the issue persists, you may need to re-record the workflow for it to function correctly.';
    }

    return '';
  }, [workflowMetadata?.status]);

  useEffect(() => {
    if (workflowMetadata?.workflowName) {
      setWorkflowName(workflowMetadata.workflowName);
    }
  }, [workflowMetadata]);

  useEffect(() => {
    void refetchExecutions();
  }, [orderBy, paginationModel, refetchExecutions]);

  useEffect(() => {
    setTimeToRefresh(EXECUTIONS_LIST_REFETCH_INTERVAL);
  }, [executionsListDataUpdatedAt]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToRefresh((prev) => {
        if (prev === 0) {
          return prev;
        }
        return prev - 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isLoading && menuProps) {
      setMenuProps(null);
    }
  }, [isLoading, menuProps]);

  return (
    <ScrollableContainer>
      <Toolbar
        onBack={() => {
          navigate('/workflows', { replace: true });
        }}
        pageInfo={`Workflow ID: ${workflowId}`}
      />
      <ContentContainer withToolbar>
        {fetchingWorkflowMetadata || !workflowMetadata ? (
          <WorkflowDetailSkeletonHeader />
        ) : (
          <WorkflowDetailHeader
            setCloneWorkflowData={setCloneWorkflowData}
            setIsEditingName={setIsEditingName}
            isEditingName={isEditingName}
            editWorkflowEnabled={Boolean(editWorkflowEnabled)}
            workflowName={workflowName}
            inputRef={inputRef}
            workflowMetadata={workflowMetadata}
            workflowId={workflowId}
            updateWorkflowName={updateWorkflowName}
            onNameChange={onNameChange}
            hasErrorForcePush={Boolean(hasErrorForcePush)}
            hasErrorPush={Boolean(hasErrorPush)}
            workflowProcessing={workflowProcessing}
            workflowVersionId={workflowVersionId ?? ''}
          />
        )}

        {!isAdmin && workflowMetadata?.status && workflowProcessing ? (
          <div
            className={clsx({
              'my-6 flex space-x-2 px-4 py-3 rounded': true,
              'bg-primary-blue-extralight':
                workflowMetadata.status === WorkflowStatusEnum.ProcessingImport,
              'bg-[#FFF4E5]':
                workflowMetadata.status ===
                WorkflowStatusEnum.ProcessingImportErrorAuthentication,
              'bg-[#FDEDED]':
                workflowMetadata.status ===
                WorkflowStatusEnum.ProcessingImportError,
            })}
          >
            {workflowProcessingStatuses.processingAndInaccessible.includes(
              workflowMetadata.status,
            ) ? (
              <InfoOutlined
                className={clsx({
                  '!w-5 !h-5': true,
                  '!text-[#0288D1]':
                    workflowMetadata.status ===
                    WorkflowStatusEnum.ProcessingImport,
                  'text-error':
                    workflowMetadata.status ===
                    WorkflowStatusEnum.ProcessingImportError,
                })}
              />
            ) : (
              <WarningAmberOutlined className="!w-5 !h-5 !text-warning !mt-0.5" />
            )}
            <span
              className={clsx({
                'text-sm font-medium': true,
                'text-primary-blue-dark':
                  workflowMetadata.status ===
                  WorkflowStatusEnum.ProcessingImport,
                'text-[#663C00]':
                  workflowMetadata.status ===
                  WorkflowStatusEnum.ProcessingImportErrorAuthentication,
                'text-red-900':
                  workflowMetadata.status ===
                  WorkflowStatusEnum.ProcessingImportError,
              })}
            >
              {workflowProcessingLabel}
            </span>
          </div>
        ) : null}

        <WorkflowExecutionMetrics
          hasMinExecutionForCharts={totalExecutionsCount >= 5}
          workflowId={workflowId}
        />

        <div className="flex items-end justify-between mt-8">
          <div className="flex flex-col space-y-1">
            <Flex className="gap-2" flexDirection="column">
              <CustomTypography color="text.primary" variant="h5">
                Workflow Executions
              </CustomTypography>
            </Flex>
          </div>

          <div className="flex items-center">
            <div className="flex items-center min-w-max">
              {!isAdmin && workflowProcessing ? (
                <Button
                  className="h-9"
                  color="secondary"
                  onClick={() => {
                    void refetchExecutions();
                  }}
                  variant="outlined"
                >
                  REFRESH
                </Button>
              ) : (
                <div className="flex flex-row items-center gap-1">
                  <span className="text-sm text-gray-500 min-w-max mr-4">
                    Auto-updating
                  </span>
                  <CircularProgress
                    color="secondary"
                    size={20}
                    thickness={5}
                    sx={{ scale: '-1 1' }}
                    variant="determinate"
                    value={((20 - timeToRefresh) / 20) * 100}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {(isAdmin || showFilters) && totalExecutionsCount > 0 ? (
          <ExecutionListFilter
            filters={filters}
            setFilters={setFilters}
            onResetPage={onResetPage}
            onRefresh={() => {
              void refetchExecutions();
              void refetchWorkflowMetadata();
            }}
          />
        ) : null}

        <div>
          {isLoading ? (
            <WorkflowDetailSkeleton columns={columns} />
          ) : (
            <>
              {executions.length > 0 ? (
                <>
                  <DataGrid
                    columns={columns}
                    getRowId={(row) => row.executionId}
                    rows={executions}
                    sortingOrder={sortOrder}
                    rowCount={filteredExecutionsCount}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    paginationMode="server"
                    sortModel={orderBy}
                    onSortModelChange={(val) => {
                      onResetPage();
                      setOrderBy(val);
                    }}
                  />
                  {menuProps ? (
                    <WorkflowDetailsMenu
                      menuProps={menuProps}
                      cancellingExecutionIds={cancellingExecutions}
                      closeMenu={() => {
                        setMenuProps(null);
                      }}
                      handleCancelExecutions={handleCancelExecutions}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  {noFiltersYet ? (
                    <div className="bg-[#f3f4f6] rounded-lg w-full h-[20rem] flex items-center justify-center my-5">
                      <span>There are no executions yet</span>
                    </div>
                  ) : (
                    <NoDataFound
                      className="mt-4"
                      heading="Execution details"
                      subHeading="No execution matches those filters."
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </ContentContainer>
      {cloneWorkflowData ? (
        <CloneModal
          onCancel={() => {
            setCloneWorkflowData(null);
          }}
          workflow={cloneWorkflowData}
        />
      ) : null}
    </ScrollableContainer>
  );
}
