"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBodyAsBuffer = exports.S3Shim = exports.S3Operation = void 0;
var client_s3_1 = require("@aws-sdk/client-s3");
var client_s3_2 = require("@aws-sdk/client-s3");
var s3_request_presigner_1 = require("@aws-sdk/s3-request-presigner");
var lib_storage_1 = require("@aws-sdk/lib-storage");
var defaults_1 = require("./defaults");
/**
 * Enum for S3 operations
 */
var S3Operation;
(function (S3Operation) {
    S3Operation[S3Operation["PutObject"] = 0] = "PutObject";
    S3Operation[S3Operation["GetObject"] = 1] = "GetObject";
})(S3Operation || (exports.S3Operation = S3Operation = {}));
/**
 * Function to transform URL for localstack
 * @param url - The URL to be transformed
 * @returns - The transformed URL
 */
function transformUrlForLocalstack(url) {
    var urlObj = new URL(url);
    urlObj.hostname = 'localhost';
    return urlObj.toString();
}
/**
 * Class for S3 operations
 */
var S3Shim = /** @class */ (function () {
    /**
     * Constructor for S3Shim class
     * @param configs - The configuration for the S3 client
     */
    function S3Shim(cfg) {
        var _this = this;
        /**
         * Method to get presigned URL for S3 operations.
         * @param bucket - The name of the S3 bucket.
         * @param key - The object key in the S3 bucket.
         * @param operation - The S3 operation for which the URL is generated (e.g., PutObject, GetObject).
         * @param contentMD5 - The MD5 hash of the object's content (optional).
         * @param expiresIn - The duration in seconds for which the presigned URL is valid (default is 60 seconds).
         * @param responseContentDisposition - The content disposition of the response (optional).
         * @param responseContentType - The content type of the response (optional).
         * @param versionId - Version of the S3 object for GET operation (optional).
         * @returns - The presigned URL as a string.
         */
        this.getPresignedUrl = function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
            var command, url;
            var _c;
            var bucket = _b.bucket, key = _b.key, operation = _b.operation, contentMD5 = _b.contentMD5, _d = _b.expiresIn, expiresIn = _d === void 0 ? 60 : _d, putObjectContentType = _b.putObjectContentType, responseContentDisposition = _b.responseContentDisposition, responseContentType = _b.responseContentType, versionId = _b.versionId, tagSet = _b.tagSet;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        command = operation === S3Operation.PutObject
                            ? new client_s3_2.PutObjectCommand({
                                Bucket: bucket,
                                Key: key,
                                ContentMD5: contentMD5,
                                ContentType: putObjectContentType,
                                Tagging: tagSet,
                            })
                            : new client_s3_2.GetObjectCommand({
                                Bucket: bucket,
                                Key: key,
                                ResponseContentDisposition: responseContentDisposition,
                                ResponseContentType: responseContentType,
                                VersionId: versionId,
                            });
                        if (!(operation === S3Operation.GetObject)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fileExists(bucket, key)];
                    case 1:
                        if (!(_e.sent())) {
                            throw new Error("".concat(key, " does not exist in bucket: ").concat(bucket));
                        }
                        _e.label = 2;
                    case 2: return [4 /*yield*/, (0, s3_request_presigner_1.getSignedUrl)(this.s3, command, { expiresIn: expiresIn })];
                    case 3:
                        url = _e.sent();
                        if ((_c = process.env.AWS_ENDPOINT_URL) === null || _c === void 0 ? void 0 : _c.includes(':4566')) {
                            return [2 /*return*/, transformUrlForLocalstack(url)];
                        }
                        return [2 /*return*/, url];
                }
            });
        }); };
        /**
         * Method to list objects in a bucket
         * @param bucket - The name of the bucket
         * @param prefix - The prefix of the objects to be listed
         * @returns - The list of object keys
         */
        this.listObjects = function (bucket, prefix) { return __awaiter(_this, void 0, void 0, function () {
            var params, command, data;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        params = {
                            Bucket: bucket,
                            Prefix: prefix,
                        };
                        command = new client_s3_2.ListObjectsCommand(params);
                        return [4 /*yield*/, this.s3.send(command)];
                    case 1:
                        data = _c.sent();
                        if (data.IsTruncated) {
                            throw new Error('The result is truncated. Not all objects are listed.');
                        }
                        return [2 /*return*/, (_b = (_a = data.Contents) === null || _a === void 0 ? void 0 : _a.map(function (content) { var _a; return (_a = content.Key) !== null && _a !== void 0 ? _a : ''; })) !== null && _b !== void 0 ? _b : []];
                }
            });
        }); };
        /**
         * Method to list objects in a bucket with pagination
         * @param bucket - The name of the bucket
         * @param prefix - The prefix of the objects to be listed
         * @param maxKeys - The maximum number of keys to return per page (optional, default is 1000)
         * @returns - The list of object keys
         */
        this.listObjectsPaginated = function (bucket_1, prefix_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([bucket_1, prefix_1], args_1, true), void 0, function (bucket, prefix, maxKeys) {
                var params, allKeys, _a, _b, _c, page, pageKeys, e_1_1;
                var _d, e_1, _e, _f;
                var _g, _h;
                if (maxKeys === void 0) { maxKeys = 1000; }
                return __generator(this, function (_j) {
                    switch (_j.label) {
                        case 0:
                            params = {
                                Bucket: bucket,
                                Prefix: prefix,
                                MaxKeys: maxKeys,
                            };
                            allKeys = [];
                            _j.label = 1;
                        case 1:
                            _j.trys.push([1, 6, 7, 12]);
                            _a = true, _b = __asyncValues((0, client_s3_1.paginateListObjectsV2)({ client: this.s3 }, params));
                            _j.label = 2;
                        case 2: return [4 /*yield*/, _b.next()];
                        case 3:
                            if (!(_c = _j.sent(), _d = _c.done, !_d)) return [3 /*break*/, 5];
                            _f = _c.value;
                            _a = false;
                            page = _f;
                            pageKeys = (_h = (_g = page.Contents) === null || _g === void 0 ? void 0 : _g.map(function (content) { var _a; return (_a = content.Key) !== null && _a !== void 0 ? _a : ''; })) !== null && _h !== void 0 ? _h : [];
                            allKeys.push.apply(allKeys, pageKeys);
                            _j.label = 4;
                        case 4:
                            _a = true;
                            return [3 /*break*/, 2];
                        case 5: return [3 /*break*/, 12];
                        case 6:
                            e_1_1 = _j.sent();
                            e_1 = { error: e_1_1 };
                            return [3 /*break*/, 12];
                        case 7:
                            _j.trys.push([7, , 10, 11]);
                            if (!(!_a && !_d && (_e = _b.return))) return [3 /*break*/, 9];
                            return [4 /*yield*/, _e.call(_b)];
                        case 8:
                            _j.sent();
                            _j.label = 9;
                        case 9: return [3 /*break*/, 11];
                        case 10:
                            if (e_1) throw e_1.error;
                            return [7 /*endfinally*/];
                        case 11: return [7 /*endfinally*/];
                        case 12: return [2 /*return*/, allKeys];
                    }
                });
            });
        };
        /**
         * Method to list objects in a bucket
         * @param bucket - The name of the bucket
         * @param prefix - The prefix of the objects to be listed
         * @returns - The list of object keys
         */
        this.listObjectsMetadata = function (bucket, prefix) { return __awaiter(_this, void 0, void 0, function () {
            var params, command, data;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = {
                            Bucket: bucket,
                            Prefix: prefix,
                        };
                        command = new client_s3_2.ListObjectsCommand(params);
                        return [4 /*yield*/, this.s3.send(command)];
                    case 1:
                        data = _b.sent();
                        if (data.IsTruncated) {
                            throw new Error('The result is truncated. Not all objects are listed.');
                        }
                        return [2 /*return*/, (_a = data.Contents) !== null && _a !== void 0 ? _a : []];
                }
            });
        }); };
        /**
         * Method to get an object from a bucket
         * @param bucket - The name of the bucket
         * @param key - The key of the object
         * @returns - The output of the GetObjectCommand
         */
        this.getObject = function (bucket, key, versionId) { return __awaiter(_this, void 0, void 0, function () {
            var params, command, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            Bucket: bucket,
                            Key: key,
                            VersionId: versionId,
                        };
                        command = new client_s3_2.GetObjectCommand(params);
                        return [4 /*yield*/, this.s3.send(command)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        /**
         * Method to retrieve metadata about an object stored in an Amazon S3 bucket without downloading the object itself.
         *
         * @param bucket - The name of the S3 bucket containing the object.
         * @param key - The key (path) of the object within the S3 bucket.
         * @returns - A promise that resolves with the metadata of the object if it exists.
         * @throws Throws a `NoSuchKey` error if the specified object does not exist in the S3 bucket.
         * @throws Throws other errors that may occur during the API call.
         */
        this.headObject = function (bucket, key) { return __awaiter(_this, void 0, void 0, function () {
            var params, command, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            Bucket: bucket,
                            Key: key,
                        };
                        command = new client_s3_2.HeadObjectCommand(params);
                        return [4 /*yield*/, this.s3.send(command)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        /**
         * Method to put an object into a bucket
         * @param bucket - The name of the bucket
         * @param key - The key of the object
         * @param body - The body of the object
         * @returns - The output of the PutObjectCommand
         */
        this.putObject = function (bucket, key, body) { return __awaiter(_this, void 0, void 0, function () {
            var params, command, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            Bucket: bucket,
                            Key: key,
                            Body: body,
                        };
                        command = new client_s3_2.PutObjectCommand(params);
                        return [4 /*yield*/, this.s3.send(command)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        /**
         * Method to delete an object from a bucket
         * @param bucket - The name of the bucket
         * @param key - The key of the object
         * @returns The output of the DeleteObjectCommand
         */
        this.deleteObject = function (bucket, key, versionId) { return __awaiter(_this, void 0, void 0, function () {
            var params, command, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            Bucket: bucket,
                            Key: key,
                            VersionId: versionId,
                        };
                        command = new client_s3_2.DeleteObjectCommand(params);
                        return [4 /*yield*/, this.s3.send(command)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        /**
         * Method to upload an object to a bucket, better for large files and more configurable than putObject
         * @param bucket - The name of the bucket
         * @param key - The key of the object
         * @param body - The body of the object, can be a string, Uint8Array, Buffer, or Readable stream
         * @returns - An instance of the Upload class
         */
        this.uploadObject = function (bucket, key, body) {
            return new lib_storage_1.Upload({
                client: _this.s3,
                params: {
                    Bucket: bucket,
                    Key: key,
                    Body: body,
                },
            });
        };
        /**
         * Copies an object from one bucket to another.
         * @param sourceBucket - The name of the source bucket.
         * @param sourceKey - The key of the source object.
         * @param destinationBucket - The name of the destination bucket.
         * @param destinationKey - The key of the destination object.
         * @returns The output of the CopyObjectCommand.
         */
        this.copyObject = function (sourceBucket, sourceKey, destinationBucket, destinationKey) { return __awaiter(_this, void 0, void 0, function () {
            var params, command, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            Bucket: destinationBucket,
                            CopySource: "".concat(sourceBucket, "/").concat(sourceKey),
                            Key: destinationKey,
                        };
                        command = new client_s3_2.CopyObjectCommand(params);
                        return [4 /*yield*/, this.s3.send(command)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        }); };
        /**
         * Copies multiple objects from one bucket to another, optionally overwriting existing objects.
         * @param sourceBucket - The name of the source bucket.
         * @param destinationBucket - The name of the destination bucket.
         * @param sourcePrefix - The prefix of the source objects.
         * @param destinationPrefix - The prefix of the destination objects.
         * @param overwrite - Flag to determine if existing objects should be overwritten.
         * @returns A promise that resolves when all objects have been copied.
         */
        this.copyObjects = function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
            var sourceKeyPrefix, destinationKeyPrefix, sourceKeys, keysToCopy, destinationKeys_1, copyPromises;
            var _this = this;
            var sourceBucket = _b.sourceBucket, sourcePrefix = _b.sourcePrefix, destinationBucket = _b.destinationBucket, destinationPrefix = _b.destinationPrefix, _c = _b.overwrite, overwrite = _c === void 0 ? true : _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        sourceKeyPrefix = sourcePrefix.endsWith('/')
                            ? sourcePrefix
                            : "".concat(sourcePrefix, "/");
                        destinationKeyPrefix = destinationPrefix.endsWith('/')
                            ? destinationPrefix
                            : "".concat(destinationPrefix, "/");
                        return [4 /*yield*/, this.listObjects(sourceBucket, sourceKeyPrefix)];
                    case 1:
                        sourceKeys = _d.sent();
                        keysToCopy = sourceKeys;
                        if (!overwrite) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.listObjects(destinationBucket, destinationKeyPrefix)];
                    case 2:
                        destinationKeys_1 = _d.sent();
                        keysToCopy = sourceKeys.filter(function (key) { return !destinationKeys_1.includes(key); });
                        _d.label = 3;
                    case 3:
                        copyPromises = keysToCopy.map(function (key) {
                            return _this.copyObject(sourceBucket, key, destinationBucket, key.replace(sourceKeyPrefix, destinationKeyPrefix));
                        });
                        return [4 /*yield*/, Promise.all(copyPromises)];
                    case 4:
                        _d.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * Lists all versions of an object within a specified bucket.
         *
         * @param bucket - The name of the S3 bucket.
         * @param key - The key (path) of the object within the S3 bucket.
         * @returns A promise that resolves to the output of the ListObjectVersions command.
         */
        this.listObjectVersions = function (bucket, key) { return __awaiter(_this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                params = {
                    Bucket: bucket,
                    Prefix: key,
                };
                return [2 /*return*/, this.s3.send(new client_s3_2.ListObjectVersionsCommand(params))];
            });
        }); };
        /**
         * Method to retrieve the latest version ID of an object stored in an Amazon S3 bucket.
         *
         * @param bucket - The name of the S3 bucket containing the object.
         * @param key - The key (path) of the object within the S3 bucket.
         * @returns - A promise that resolves with the latest version ID of the object.
         */
        this.getLatestObjectVersion = function (bucket, key) { return __awaiter(_this, void 0, void 0, function () {
            var params, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            Bucket: bucket,
                            Key: key,
                        };
                        return [4 /*yield*/, this.s3.send(new client_s3_2.HeadObjectCommand(params))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.VersionId];
                }
            });
        }); };
        /**
         * Reverts an S3 object to a specified version by creating a new version with the content of the specified version.
         *
         * @param bucketName - The name of the S3 bucket.
         * @param key - The key (path) of the object within the S3 bucket.
         * @param versionId - The version ID of the object to revert to.
         * @returns A promise that resolves to an object containing the new version ID and the output of the CopyObjectCommand.
         */
        this.revertToVersion = function (bucketName, key, versionId) { return __awaiter(_this, void 0, void 0, function () {
            var copyParams, copyResult, headParams, headResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        copyParams = {
                            Bucket: bucketName,
                            CopySource: "".concat(bucketName, "/").concat(key, "?versionId=").concat(versionId),
                            Key: key,
                        };
                        return [4 /*yield*/, this.s3.send(new client_s3_2.CopyObjectCommand(copyParams))];
                    case 1:
                        copyResult = _a.sent();
                        headParams = {
                            Bucket: bucketName,
                            Key: key,
                        };
                        return [4 /*yield*/, this.s3.send(new client_s3_2.HeadObjectCommand(headParams))];
                    case 2:
                        headResult = _a.sent();
                        return [2 /*return*/, {
                                newVersionId: headResult.VersionId,
                                copyResult: copyResult,
                            }];
                }
            });
        }); };
        /**
         * Method to check if a file exists in a bucket
         * @param bucket - The name of the bucket
         * @param key - The key of the object
         * @returns - True if the file exists, false otherwise
         */
        this.fileExists = function (bucket, key) { return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.headObject(bucket, key)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2:
                        error_1 = _a.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        if (cfg) {
            this.s3 = new client_s3_2.S3Client(cfg);
        }
        else {
            this.s3 =
                process.env.DEPLOYED_STAGE === 'local'
                    ? new client_s3_2.S3Client((0, defaults_1.defaultClientConfigsFromEnv)())
                    : new client_s3_2.S3Client();
        }
    }
    S3Shim.loadCredentialsFromEnv = function () {
        return new S3Shim((0, defaults_1.defaultClientConfigsFromEnv)());
    };
    S3Shim.prototype.getPresignedUrlsForMultipartUpload = function (_a) {
        return __awaiter(this, arguments, void 0, function (_b) {
            var createCommand, UploadId, uploadPartCommands, urls;
            var _this = this;
            var _c;
            var bucket = _b.bucket, key = _b.key, numParts = _b.numParts, _d = _b.expiresIn, expiresIn = _d === void 0 ? 600 : _d, contentType = _b.contentType;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        createCommand = new client_s3_2.CreateMultipartUploadCommand({
                            Bucket: bucket,
                            Key: key,
                            ContentType: contentType,
                        });
                        return [4 /*yield*/, this.s3.send(createCommand)];
                    case 1:
                        UploadId = (_e.sent()).UploadId;
                        if (!UploadId) {
                            throw new Error('Failed to create uploadId for multipart upload');
                        }
                        uploadPartCommands = new Array(numParts).fill(null).map(function (_, part) {
                            return new client_s3_2.UploadPartCommand({
                                Bucket: bucket,
                                Key: key,
                                UploadId: UploadId,
                                PartNumber: part + 1, // Part numbers are 1-indexed
                            });
                        });
                        return [4 /*yield*/, Promise.all(uploadPartCommands.map(function (command) {
                                return (0, s3_request_presigner_1.getSignedUrl)(_this.s3, command, { expiresIn: expiresIn });
                            }))];
                    case 2:
                        urls = _e.sent();
                        if ((_c = process.env.AWS_ENDPOINT_URL) === null || _c === void 0 ? void 0 : _c.includes(':4566')) {
                            return [2 /*return*/, {
                                    uploadId: UploadId,
                                    urls: urls.map(function (url) { return transformUrlForLocalstack(url); }),
                                }];
                        }
                        return [2 /*return*/, {
                                uploadId: UploadId,
                                urls: urls,
                            }];
                }
            });
        });
    };
    return S3Shim;
}());
exports.S3Shim = S3Shim;
var getBody = function (response) {
    return response.Body ? response.Body : null;
};
var getBodyAsBuffer = function (response) { return __awaiter(void 0, void 0, void 0, function () {
    var stream, chunks_1;
    return __generator(this, function (_a) {
        stream = getBody(response);
        if (stream) {
            chunks_1 = [];
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    stream.on('data', function (chunk) {
                        chunks_1.push(chunk);
                    });
                    stream.on('end', function () {
                        resolve(Buffer.concat(chunks_1));
                    });
                    stream.on('error', function (err) {
                        reject(err);
                    });
                })];
        }
        return [2 /*return*/, Promise.resolve(null)];
    });
}); };
exports.getBodyAsBuffer = getBodyAsBuffer;
