import {
  useQuery,
  useMutation,
  type ApolloError,
  type TypedDocumentNode,
} from '@apollo/client';
import { GlobalVariable, graphql } from 'hasura-gql';
import { useMemo } from 'react';

interface Variable {
  id: string;
  name: string;
  ownerId: string;
  value: string;
  updatedAt: string | Date;
}

const GET_ALL_GLOBAL_VARIABLES = graphql(`
  query GET_ALL_GLOBAL_VARIABLES {
    global_variables {
      id
      name
      ownerId
      value
      updatedAt
    }
  }
`) as TypedDocumentNode<{ global_variables: Variable[] }>;

export const useGetGlobalVariables = (
  userIdForAuthorization?: string,
): {
  data?: GlobalVariable[];
  loading: boolean;
  error?: ApolloError;
} => {
  const options = userIdForAuthorization
    ? { context: { userIdForAuthorization } }
    : undefined;

  // FIXME:Do we need to do this memoization, fix the issue in Editor/index.tsx
  const {
    data: initialData,
    loading,
    error,
  } = useQuery(GET_ALL_GLOBAL_VARIABLES, options);

  const finalData = useMemo(() => {
    return initialData?.global_variables.map((variable) =>
      GlobalVariable.parse(variable),
    );
  }, [initialData]);

  return {
    data: finalData,
    loading,
    error,
  };
};

const SAVE_GLOBAL_VARIABLE = graphql(`
  mutation SAVE_GLOBAL_VARIABLE(
    $name: String!
    $ownerId: String!
    $value: String!
  ) {
    insert_global_variables(
      objects: { name: $name, ownerId: $ownerId, value: $value }
    ) {
      returning {
        id
        name
        ownerId
        value
      }
    }
  }
`) as TypedDocumentNode<
  { insert_global_variables?: { returning: Variable[] } },
  { name: string; ownerId: string; value: string }
>;

export const useSaveGlobalVariable = (): {
  saveGlobalVariable: (variables: {
    name: string;
    ownerId: string;
    value: string;
  }) => Promise<unknown>;
  data?: GlobalVariable;
  loading: boolean;
  error?: ApolloError;
} => {
  const [mutate, { data, loading, error }] = useMutation(SAVE_GLOBAL_VARIABLE);

  const saveGlobalVariable = (variables: {
    name: string;
    ownerId: string;
    value: string;
  }) => {
    return mutate({
      variables,
      refetchQueries: [{ query: GET_ALL_GLOBAL_VARIABLES }],
    });
  };

  const finalData = data?.insert_global_variables
    ?.returning[0] as GlobalVariable;

  return { saveGlobalVariable, data: finalData, loading, error };
};

const UPDATE_GLOBAL_VARIABLE = graphql(`
  mutation UPDATE_GLOBAL_VARIABLE($id: uuid!, $name: String, $value: String) {
    update_global_variables_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, value: $value }
    ) {
      id
      name
      value
      ownerId
    }
  }
`) as TypedDocumentNode<
  { update_global_variables_by_pk?: Variable },
  { id: string; name: string; value: string }
>;

export const useUpdateGlobalVariable = (): {
  updateGlobalVariable: (variables: {
    name: string;
    id: string;
    value: string;
  }) => Promise<unknown>;
  data?: GlobalVariable;
  loading: boolean;
  error?: ApolloError;
} => {
  const [mutate, { data, loading, error }] = useMutation(
    UPDATE_GLOBAL_VARIABLE,
  );

  const updateGlobalVariable = (variables: {
    name: string;
    id: string;
    value: string;
  }) => {
    return mutate({
      variables,
      refetchQueries: [{ query: GET_ALL_GLOBAL_VARIABLES }],
    });
  };

  const finalData = data?.update_global_variables_by_pk as GlobalVariable;

  return { updateGlobalVariable, data: finalData, loading, error };
};
