import type { GridColDef } from '@mui/x-data-grid/models';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import Skeleton from '@mui/material/Skeleton';
import { v4 as uuid } from 'uuid';
import type { ApiKey } from 'types-shared';

interface Props {
  columns: GridColDef<ApiKey>[];
}

const fakeData = Array.from({ length: 10 }, () => ({
  keyId: uuid(),
}));

export function ApiKeysManagerSkeleton({ columns }: Props) {
  return (
    <DataGrid
      columns={columns.map((column) => ({
        ...column,
        renderCell: () => (
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem' }}
            className="w-4/12"
            height={40}
          />
        ),
      }))}
      hideFooter
      getRowId={(row) => row.keyId}
      rows={fakeData as never}
    />
  );
}
