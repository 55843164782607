import { useMemo } from 'react';
import {
  type GlobalVariable,
  type Variable,
  type VariableMap,
  TemplateVariable,
  type WorkflowFreeformNode,
} from 'types-shared';

import { VariableChip } from '../../../../../components/VariableChip';
import HITLPrompt from './HITLModal/components/HITLPrompt';

interface FreeformInputsProps {
  currentNode: WorkflowFreeformNode;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  variablesMap: Record<string, Variable>;
  showHITLPrompt: boolean;
  openHITLModal: () => void;
  freeformTitle?: string;
  signalLoading: boolean;
}

export default function FreeformInputs({
  currentNode,
  showHITLPrompt,
  variablesMap,
  globalVariablesMap,
  openHITLModal,
  freeformTitle,
  signalLoading,
}: FreeformInputsProps) {
  const instructions = useMemo(() => {
    const instructionVariable =
      variablesMap[currentNode.data.instructions.variableId];

    const check = TemplateVariable.safeParse(instructionVariable);

    if (check.success) {
      const data = check.data.data;
      const content = data.map((val) => {
        if (typeof val === 'object') {
          return (
            <VariableChip
              key={val.id}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              variableId={val.id}
            />
          );
        }
        return val;
      });

      return content;
    }

    return [];
  }, [currentNode, variablesMap, globalVariablesMap]);

  return (
    <>
      {showHITLPrompt ? (
        <div className="px-6 -mb-8">
          <HITLPrompt
            defaultTitle={freeformTitle}
            openHITLModal={openHITLModal}
            actionIndex={0}
            signalLoading={signalLoading}
          />
        </div>
      ) : null}
      <div className="flex flex-col gap-2 py-4 px-6 overflow-auto rounded-lg border border-indigo-light m-6">
        <p className="text-info-dark text-sm font-medium">Instructions:</p>

        <p className="text-slate-500 text-sm font-normal">{instructions}</p>
      </div>
    </>
  );
}
