import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function LockIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50065 7.5H6.00081C5.06739 7.5 4.60033 7.5 4.24382 7.68166C3.93021 7.84145 3.67543 8.09623 3.51564 8.40983C3.33398 8.76635 3.33398 9.23341 3.33398 10.1668V14.8335C3.33398 15.7669 3.33398 16.2334 3.51564 16.5899C3.67543 16.9035 3.93021 17.1587 4.24382 17.3185C4.59999 17.5 5.06649 17.5 5.9981 17.5L14.0032 17.5C14.9348 17.5 15.4006 17.5 15.7568 17.3185C16.0704 17.1587 16.3261 16.9035 16.4858 16.5899C16.6673 16.2337 16.6673 15.7679 16.6673 14.8363V10.1641C16.6673 9.23249 16.6673 8.766 16.4858 8.40983C16.3261 8.09623 16.0704 7.84144 15.7568 7.68166C15.4003 7.5 14.9342 7.5 14.0008 7.5H7.50065ZM7.50065 7.5V5.1001C7.50065 3.66416 8.584 2.5 9.92038 2.5C10.607 2.5 11.2263 2.80732 11.6667 3.30094"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
