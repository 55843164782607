import {
  type Condition,
  type Group,
  isGroup,
  OperatorEnum,
  StringComparatorEnum,
  type Variable,
  VariableTypeEnum,
} from 'types-shared';
import { v4 as uuid } from 'uuid';
import filter from 'lodash/filter';
import { createTemplateVariable } from '../EditNodePanel/request.helpers';

export const initialGroup = (addVariable: (newVar: Variable) => void) => {
  return {
    id: uuid(),
    operator: OperatorEnum.Or,
    elements: [
      {
        field: { variableId: createTemplateVariable(addVariable).id },
        value: { variableId: createTemplateVariable(addVariable).id },
        id: uuid(),
        comparator: StringComparatorEnum.Is,
      },
    ],
  };
};

export const copyVariable = (
  oldVariable: Variable,
  addVariable: (newVar: Variable) => void,
) => {
  const newVariable = {
    ...oldVariable,
    id: uuid(),
  };

  addVariable(newVariable);

  return newVariable;
};

export const addConditionHelper = (
  prevGroup: Group,
  groupId: string,
  operator: OperatorEnum,
  addVariable: (newVar: Variable) => void,
  andConditionId?: string,
): Group => {
  const newConditionToAdd: Condition = {
    id: uuid(),
    comparator: StringComparatorEnum.Is,
    field: {
      variableId: createTemplateVariable(addVariable).id,
    },
    value: {
      variableId: createTemplateVariable(addVariable).id,
    },
  };

  switch (operator) {
    case OperatorEnum.And: {
      const addAndCondition: (g: Group) => Group = (g: Group) => {
        const newElements = g.elements.map((element) => {
          if (element.id === andConditionId) {
            return {
              id: uuid(),
              operator: OperatorEnum.And,
              elements: [element, newConditionToAdd],
            };
          }

          if (isGroup(element)) {
            return addAndCondition(element);
          }
          return element;
        });

        return {
          ...g,
          elements: newElements,
        };
      };

      return addAndCondition(prevGroup);
    }

    case OperatorEnum.Or: {
      const addOrCondition: (g: Group) => Group = (g: Group) => {
        if (g.id === groupId) {
          if (g.operator === OperatorEnum.And) {
            return {
              id: uuid(),
              operator: OperatorEnum.Or,
              elements: [g, newConditionToAdd],
            };
          }

          return {
            ...g,
            elements: [...g.elements, newConditionToAdd],
          };
        }

        const newElements = g.elements.map((element) => {
          if (isGroup(element)) {
            return addOrCondition(element);
          }
          return element;
        });

        return {
          ...g,
          elements: newElements,
        };
      };

      return addOrCondition(prevGroup);
    }
    default: {
      return prevGroup;
    }
  }
};

export const deleteConditionHelper = (
  prevGroup: Group,
  conditionId: string,
): Group => {
  const _deleteCondition: (g: Group) => Group | null = (g) => {
    const _newElements: (Group | Condition | null)[] = g.elements.map(
      (groupOrCondition) => {
        if (isGroup(groupOrCondition)) {
          return _deleteCondition(groupOrCondition);
        }
        const found = groupOrCondition.id === conditionId;
        return found ? null : groupOrCondition;
      },
    );

    // Filter out null elements (using type guard for type correction)
    const newElements: (Group | Condition)[] = filter(
      _newElements,
      (data: unknown): data is Group | Condition => {
        return data !== null;
      },
    ).map((element: Group | Condition) => {
      // clean up any un-necessary nesting
      if (isGroup(element) && element.elements.length === 1) {
        return element.elements[0];
      }

      return element;
    });

    if (newElements.length === 0) {
      return null;
    }

    return {
      ...g,
      operator: newElements.length === 1 ? OperatorEnum.Or : g.operator,
      elements: newElements,
    };
  };

  return _deleteCondition(prevGroup) ?? prevGroup;
};

export const updateConditionHelper = (
  prevGroup: Group,
  conditionId: string,
  condition: Partial<Condition>,
): Group => {
  const _updateCondition: (
    g: Group,
    _conditionId: string,
    data: Partial<Condition>,
  ) => Group = (g: Group, _conditionId: string, data: Partial<Condition>) => {
    const newElements = g.elements.map((element) => {
      if (isGroup(element)) {
        return _updateCondition(element, _conditionId, data);
      }

      if (element.id === _conditionId) {
        return {
          ...element,
          ...data,
        };
      }

      return element;
    });

    return {
      ...g,
      elements: newElements,
    };
  };

  return _updateCondition(prevGroup, conditionId, condition);
};

export const extractLabel = (variable: Variable) => {
  if (
    variable.type === VariableTypeEnum.MultiChoice ||
    variable.type === VariableTypeEnum.Select
  ) {
    return 'Select this option';
  }

  return 'Enter this value';
};

export const extractInputLabel = (variable: Variable) => {
  if (variable.type === VariableTypeEnum.MultiChoice) {
    return 'Multiple Choice';
  }

  if (variable.type === VariableTypeEnum.Template) {
    return 'Input';
  }
  if (variable.type === VariableTypeEnum.Select) {
    return 'Select';
  }
  return '';
};
