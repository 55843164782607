import { Menu, MenuItem, DoNotDisturbIcon, Tooltip } from 'ui-kit';
import CircularProgress from '@mui/material/CircularProgress';
import { clsx } from 'clsx';

export interface WorkflowDetailsMenuProps {
  el: HTMLButtonElement | null;
  executionIds: string[];
  cantCancel: boolean;
}

interface CancellingExecutionProps {
  menuProps: WorkflowDetailsMenuProps;
  cancellingExecutionIds: string[];
  closeMenu: () => void;
  handleCancelExecutions: () => void;
}

function WorkflowDetailsMenu({
  menuProps: { el, executionIds, cantCancel },
  cancellingExecutionIds,
  closeMenu,
  handleCancelExecutions,
}: CancellingExecutionProps) {
  const alreadyCancelled = cantCancel;
  const cancellingNow = executionIds.every((id) =>
    cancellingExecutionIds.includes(id),
  );
  const cannotCancel = alreadyCancelled || cancellingNow;

  return (
    <Menu
      anchorEl={el}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={() => {
        closeMenu();
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
      open={Boolean(el)}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem
        className="min-w-64 h-8 !px-4"
        onClick={
          cannotCancel
            ? undefined
            : () => {
                handleCancelExecutions();
              }
        }
      >
        <Tooltip
          hidden={!cannotCancel}
          arrow
          placement="right"
          title={
            cancellingNow
              ? 'This execution is already attempting to cancel.'
              : "This execution already finished and can't be cancelled."
          }
          containerClassName="w-full"
        >
          <div className="w-full flex flex-row items-center justify-between">
            <span
              className={clsx({
                'font-normal mr-4': true,
                'text-gray-400': cannotCancel,
              })}
            >
              Cancel execution
            </span>

            {cancellingNow ? (
              <CircularProgress
                // color="error"
                className="text-gray-400"
                size={20}
                thickness={5}
                sx={{ scale: '-1 1' }}
                variant="indeterminate"
              />
            ) : (
              <DoNotDisturbIcon
                className={clsx({
                  'text-gray-400': cannotCancel,
                })}
              />
            )}
          </div>
        </Tooltip>
      </MenuItem>
    </Menu>
  );
}

export default WorkflowDetailsMenu;
