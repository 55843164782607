"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateExecutionSchema = void 0;
var types_shared_1 = require("types-shared");
exports.updateExecutionSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
        query: types_shared_1.z.object({}).nullable(),
        body: types_shared_1.z.object({
            status: types_shared_1.ExecutionStatus.optional(),
            statusDescr: types_shared_1.z.string().optional(),
            adminRun: types_shared_1.z.boolean().optional(),
        }),
    }),
    response: types_shared_1.z.object({
        lastUpdatedAt: types_shared_1.z.string(),
    }),
};
