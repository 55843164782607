import { type ExecutionDocument, SignalTypeEnum } from 'api-types-shared';
import { useState, useEffect, useMemo } from 'react';
import { ExecutionStatusEnum, type ExecutionBase } from 'types-shared';
import {
  FullscreenModal,
  Button,
  Logo,
  PersonIconAlt,
  notify,
  AlertVariant,
  HelpOutlineOutlined,
  IconButton,
  CloseIcon,
  GrayedOutInput,
} from 'ui-kit';

import {
  FeatureFlag,
  notAllowedExecutionSignalStatuses,
  terminalStatuses,
} from '../../../../../../utils/constants';

import { useAuth, useFeatureFlag } from '../../../../../../utils/helper';
import { LiveView } from '../../../LiveView';
import Box from '@mui/material/Box';
import { clsx } from 'clsx';
import { type StepActions } from '../../../../hooks/useWorkflowCurrentStepActions';
import { getActionTitle } from '../../../../utils';
import SignalModal from './components/SignalModal';
import DisclaimerModal from './components/DisclaimerModal';

interface HITLModalProps {
  open: boolean;
  setOpen: (newOpen: boolean) => void;
  executionArtifacts: ExecutionDocument[];
  executionMetadata: ExecutionBase;
  currentStepActions: StepActions;
  onSendSignal?: (signal: SignalTypeEnum, payload?: object | undefined) => void;
  onUpdateStatusDesc: (statusDesc: string) => void;
  updateDescStatus: 'error' | 'idle' | 'pending' | 'success';
}

const hideModalKey = 'hideInitialModal';

function HumanInLoopTag() {
  return (
    <Box className="bg-[#1C3E66] text-white rounded-lg px-3 py-1 flex items-center space-x-2 cursor-pointer text-sm">
      <PersonIconAlt className="text-white" fontSize="inherit" />
      <span>Human in loop</span>
    </Box>
  );
}

export default function HITLModal({
  open,
  setOpen,
  executionMetadata,
  currentStepActions,
  onSendSignal,
  onUpdateStatusDesc,
}: HITLModalProps) {
  const { user: authUser } = useAuth();
  const [hitlOpen, setHitlOpen] = useState(false);
  const [hideInitialModal, setHideInitialModal] = useState(false);
  const [hideInitialModalChkd, setHideInitialModalChkd] = useState(false);
  const [showHelp, setShowHelp] = useState(true);
  const [signal, setSignal] = useState<SignalTypeEnum>();

  const hitlViewEnabled = useFeatureFlag(FeatureFlag.HitlLiveView);
  const executionDetailsEnabled = useFeatureFlag(FeatureFlag.ExecutionDetails);
  const { currentStep, currentStepIndex, action } = useMemo(() => {
    const currentStepId = executionMetadata.currentStep?.stepId;

    const step = currentStepActions.find((s) => s.action.id === currentStepId);
    const actionFound = step?.action;
    return {
      currentStep: step,
      currentStepIndex: step ? currentStepActions.indexOf(step) : undefined,
      action: actionFound,
    };
  }, [currentStepActions, executionMetadata]);

  const runId = useMemo(() => {
    const { setId } = executionMetadata;

    return setId;
  }, [executionMetadata]);

  const hideInitialModalNextTime = (newChecked: boolean) => {
    if (newChecked) {
      localStorage.setItem(hideModalKey, 'hideInitialModal');
    } else {
      localStorage.removeItem(hideModalKey);
    }
    setHideInitialModalChkd(newChecked);
  };

  const openHitl = () => {
    setHitlOpen(true);
    setHideInitialModal(true);
  };

  const onResumeRun = () => {
    setSignal(SignalTypeEnum.Resume);
  };
  const onTerminateRun = () => {
    setSignal(SignalTypeEnum.Terminate);
  };

  const executionInFinalState = useMemo(
    () => notAllowedExecutionSignalStatuses.includes(executionMetadata.status),
    [executionMetadata],
  );

  const updateStatusMessage = () => {
    if (authUser?.email) {
      const terminationMsg = `Execution terminated by ${authUser.email}`;
      onUpdateStatusDesc(terminationMsg);
    }
  };

  const handleSendSignal = (newSignal: SignalTypeEnum) => {
    if (executionInFinalState) {
      notify({
        variant: AlertVariant.WARNING,
        message:
          "Can't send signals to execution in Queue, Failed, Terminated, or Success",
      });
      return;
    }

    onSendSignal?.(newSignal, {});
    if (
      newSignal === SignalTypeEnum.Terminate &&
      executionMetadata.status === ExecutionStatusEnum.PendingUser
    ) {
      updateStatusMessage();
    }
    setSignal(undefined);
    setOpen(false);
  };

  useEffect(() => {
    const hideInitialModalFromUser = localStorage.getItem(hideModalKey);

    if (hideInitialModalFromUser) {
      setHideInitialModal(true);
      setHideInitialModalChkd(true);
    }
  }, []);

  if (open && !hitlOpen && !hideInitialModal) {
    return (
      <DisclaimerModal
        setOpen={setOpen}
        open={open}
        hideInitialModalNextTime={hideInitialModalNextTime}
        hideInitialModalChkd={hideInitialModalChkd}
        openHitl={openHitl}
      />
    );
  }

  return (
    <FullscreenModal
      open={open}
      setOpen={(newOpen) => {
        setOpen(newOpen);
      }}
      toolbarChildren={
        <div className="flex flex-row justify-between items-center w-full ">
          <div className="flex flex-row gap-4 items-center ">
            <Logo className="!w-7 !h-7" />

            <span>
              <span className="text-sm text-gray-500">Execution {runId}</span>
              <span className="text-sm text-gray-500">
                &nbsp;/&nbsp;Execution details
              </span>
              <span className="text-sm text-info-dark">
                &nbsp;/&nbsp;Running execution
              </span>
            </span>

            <HumanInLoopTag />
          </div>

          <div className="flex flex-row gap-4 items-center pl-2">
            <Button color="error" onClick={onTerminateRun} variant="outlined">
              TERMINATE RUN
            </Button>
            <Button color="secondary" onClick={onResumeRun} variant="contained">
              RESUME RUN
            </Button>
          </div>
        </div>
      }
    >
      {currentStep && currentStepIndex ? (
        <div className="px-4 pb-4">
          <div className="mx-[auto] max-w-[80vw] text-center">
            <span className="font-medium text-primary-blue">
              Action {currentStepIndex + 1} -{' '}
              {getActionTitle(currentStep.action)}
              {currentStep.variableName ? (
                <span className="text-gray-600">
                  &nbsp;({currentStep.variableName})
                </span>
              ) : null}
            </span>
          </div>
        </div>
      ) : null}

      <div
        className="flex flex-row h-full border-t border-t-[1px] relative"
        style={{ borderColor: '#bcc8d3' }}
      >
        <div
          className={clsx({
            'h-full w-full min-w-[65rem] min-h-[80vh] bg-[#eaf1f3] px-4 py-4 flex items-center justify-center no-zoom':
              true,
            'border-r border-r-[1px]': showHelp,
          })}
          style={{ borderColor: '#bcc8d3' }}
        >
          <div className="w-[65rem] max-w-[95vw] mx-[auto] my-[auto] border-2 rounded-lg overflow-hidden border-[#3074f9] ">
            {(Boolean(hitlViewEnabled) || Boolean(executionDetailsEnabled)) &&
            executionMetadata.sessionId &&
            !terminalStatuses.includes(executionMetadata.status) ? (
              <div className="!h-[45rem] w-full max-w-[95vw]">
                <LiveView
                  sessionId={executionMetadata.sessionId}
                  userId={executionMetadata.userId}
                />
              </div>
            ) : null}
          </div>
        </div>

        {showHelp ? (
          <div className="bg-[#ffffff] w-[30rem] flex flex-col">
            <div
              className="flex flex-row justify-between items-center pl-4 py-2 border-b border-b-[1px] flex-shrink-0"
              style={{ borderColor: '#bcc8d3' }}
            >
              <div className="flex flex-row gap-3 items-center !text-info-dark">
                <p className="m-0 text-sm font-medium">Instructions</p>
              </div>
              <IconButton
                className="!text-info cursor-pointer"
                onClick={() => {
                  setShowHelp(false);
                }}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </div>

            <div className="px-6 py-4 flex-grow overflow-y-auto max-h-[60vh]">
              <p className="text-info-dark pt-4 pb-8">
                Interact directly with the website following instructions below.
              </p>
              <ul className="text-info-dark pb-8 list-outside list-disc pl-4 ">
                <li>
                  If you need to perform several actions in succession to
                  complete the instructions, execute them all before resuming
                  the process by clicking the button above. Your execution will
                  continue smoothly from there!
                </li>
              </ul>

              <div className="pb-5">
                <GrayedOutInput
                  label="Instructions"
                  value={action?.description ?? ''}
                />
              </div>
            </div>

            <div className="flex-shrink-0 px-4 py-5 ">
              <div className="pb-4">
                <div className="mt-2 w-full bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded">
                  <HelpOutlineOutlined
                    className="text-info ml-2"
                    fontSize="small"
                  />
                  <span className="text-primary-blue-dark">
                    Be aware that any action you take from here will directly
                    affect the run. If you encounter an error that you can't
                    recover from, the run may fail.
                  </span>
                </div>
              </div>

              <Button
                className="w-full"
                color="secondary"
                onClick={() => {
                  setShowHelp(false);
                }}
                variant="outlined"
              >
                CLOSE PANEL
              </Button>
            </div>
          </div>
        ) : null}

        {!showHelp ? (
          <div
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            role="presentation"
            className="bg-white px-3 py-3 rounded-md text-sm flex flex-row items-center justify-center absolute bottom-5 right-5 shadow cursor-pointer"
            onClick={() => {
              setShowHelp(true);
            }}
          >
            Instructions
            <HelpOutlineOutlined className="text-info ml-2" fontSize="small" />
          </div>
        ) : null}
      </div>

      <SignalModal
        signal={signal}
        clearSignal={() => {
          setSignal(undefined);
        }}
        onSendSignal={handleSendSignal}
      />
    </FullscreenModal>
  );
}
