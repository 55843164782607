import { SignalTypeEnum } from 'api-types-shared';
import { Modal, Button } from 'ui-kit';

export default function SignalModal({
  clearSignal,
  signal,
  onSendSignal,
}: {
  signal?: SignalTypeEnum;
  clearSignal: () => void;
  onSendSignal: (newSignal: SignalTypeEnum) => void;
}) {
  return (
    <Modal
      className="flex item-center justify-items-center max-w-[40rem]"
      onClose={() => {
        clearSignal();
      }}
      open={Boolean(signal)}
      enableEventBubbling
    >
      <div className="text-sm flex">
        <div className="flex-1 flex flex-col">
          <h2 className="font-medium text-xl mb-7 text-black">
            Are you sure you want to{' '}
            {signal === SignalTypeEnum.Terminate ? 'terminate' : 'resume'} the
            run?
          </h2>

          <p className="text-gray-600 mb-5">
            {signal === SignalTypeEnum.Terminate
              ? 'If this run is terminated, the remaining actions in the workflow will not be executed and the run will be marked as terminated.'
              : 'The workflow will resume on the next step. Any actions you took during human-in-the-loop mode will have a direct effect on the run.'}
          </p>

          <div className="flex flex-row gap-7 mt-5">
            {signal ? (
              <Button
                color={
                  signal === SignalTypeEnum.Terminate ? 'error' : 'secondary'
                }
                onClick={() => {
                  onSendSignal(signal);
                }}
                variant="contained"
              >
                {signal === SignalTypeEnum.Terminate
                  ? 'TERMINATE RUN'
                  : 'RESUME RUN'}
              </Button>
            ) : null}
            <Button
              className="h-9"
              color="secondary"
              onClick={() => {
                clearSignal();
              }}
              variant="outlined"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
