import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { Button, Flex, Modal, CustomTypography } from 'ui-kit';
import { useUpdateWorkflowName } from '../hooks';
import { type CompactWorkflowMetadata } from '../types';
import { useCallback, useState } from 'react';
import { handleException } from 'sentry-browser-shared';

interface Props {
  onCancel: () => void;
  workflow: CompactWorkflowMetadata;
}

export default function RenameWorkflowModal({ onCancel, workflow }: Props) {
  const [workflowName, setWorkflowName] = useState<string>(
    workflow.workflowName,
  );
  const { mutateAsync, status } = useUpdateWorkflowName();

  const onSubmit = useCallback(async () => {
    try {
      await mutateAsync({ workflowId: workflow.workflowId, workflowName });
      onCancel();
    } catch (error) {
      handleException(error, {
        name: 'Rename workflow',
        source: 'RenameWorkflowModal',
        extra: {
          workflowId: workflow.workflowId,
          workflowName,
        },
      });
    }
    onCancel();
  }, [mutateAsync, workflow.workflowId, workflowName, onCancel]);

  return (
    <Modal className="w-full !max-w-3xl" onClose={onCancel} open>
      <CustomTypography variant="h5">Rename workflow</CustomTypography>

      <div className="mt-7">
        <InputLabel className="!text-primary">Workflow name</InputLabel>
        <TextField
          color="secondary"
          focused
          fullWidth
          onChange={(e) => {
            setWorkflowName(e.target.value);
          }}
          size="medium"
          value={workflowName}
          variant="outlined"
        />
      </div>

      <Flex className="gap-4 mt-10">
        <Button
          color="secondary"
          loading={status === 'pending'}
          onClick={onSubmit}
          variant="contained"
        >
          Rename workflow
        </Button>
        <Button color="secondary" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
}
