import { clsx } from 'clsx';
import React, { useMemo, useRef, useState } from 'react';
import {
  isVariableAllowedToAddInInput,
  type SelectOption,
  type TemplateData,
  type Variable,
  type VariableMap,
  type GlobalVariable,
} from 'types-shared';
import Menu from './Menu';
import { ArrowDropDown } from 'ui-kit';
import values from 'lodash/values';
import { VariableChip } from '../../../../components/VariableChip';

interface Props {
  label: string;
  data: TemplateData;
  disabled?: boolean;
  className?: string;
  options: SelectOption[];
  onChange: (val: TemplateData) => void;
  allowAddVariable?: boolean;
  onAddNew?: () => void;
  onPreview?: (variableId: string) => void;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  filterVariables?: boolean;
}

export function Select({
  className,
  data,
  label,
  disabled,
  onChange,
  onAddNew,
  variablesMap,
  globalVariablesMap,
  options,
  onPreview,
  allowAddVariable = true,
  filterVariables = true,
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showVariableMenu, setShowVariableMenu] = useState<boolean>(false);
  const selectedItem = data[0];

  const handleItemSelect = (value: Variable | string) => {
    onChange([value]);
  };

  const handleAddNew = () => {
    setShowVariableMenu(false);
    onAddNew?.();
  };

  const handleCloseMenu = () => {
    setShowVariableMenu(false);
  };

  const allowedVariables = useMemo(() => {
    if (!filterVariables) {
      return values(variablesMap);
    }
    return values(variablesMap).filter((variable: Variable) => {
      return isVariableAllowedToAddInInput(variable);
    });
  }, [filterVariables, variablesMap]);

  const allowedGlobalVariables: GlobalVariable[] = useMemo(
    () => values(globalVariablesMap) as GlobalVariable[],
    [globalVariablesMap],
  );

  return (
    <div
      className={clsx(
        'relative flex border px-3 !pr-6 py-2.5 max-w-full rounded',
        className,
      )}
      ref={containerRef}
    >
      <span className="absolute -top-2 left-3 text-xs text-gray-500 bg-white px-1">
        {label}
      </span>
      <button
        className={clsx('absolute top-1/2 right-2 -translate-y-1/2', {
          'rotate-180': showVariableMenu,
          '!cursor-not-allowed': disabled,
        })}
        onClick={() => {
          setShowVariableMenu((val) => !val);
        }}
        type="button"
      >
        <ArrowDropDown
          className={clsx(disabled ? 'text-gray-400' : 'text-gray-600')}
        />
      </button>
      <div
        className="flex-1 cursor-pointer flex items-center"
        onClick={() => {
          setShowVariableMenu((val) => !val);
        }}
        role="presentation"
      >
        {typeof selectedItem === 'string' ? <span>{selectedItem}</span> : null}
        {typeof selectedItem === 'object' && selectedItem.id ? (
          <VariableChip
            variableId={selectedItem.id}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onPreview?.(selectedItem.id);
            }}
          />
        ) : null}
      </div>
      {showVariableMenu ? (
        <Menu
          allowAddVariable={allowAddVariable}
          anchorEl={containerRef.current}
          onAddNew={handleAddNew}
          onClose={handleCloseMenu}
          onSelect={handleItemSelect}
          open={showVariableMenu}
          options={options}
          variables={allowedVariables}
          globalVariablesMap={globalVariablesMap}
          globalVariables={allowedGlobalVariables}
          variablesMap={variablesMap}
        />
      ) : null}
    </div>
  );
}
