import React, { type ReactNode, type HTMLAttributes, useMemo } from 'react';
import { clsx } from 'clsx';

export enum TypographyVariants {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  subtitle1 = 'subtitle1',
  subtitle2 = 'subtitle2',
  body1 = 'body1',
  body2 = 'body2',
  inherit = 'inherit',
}

export enum TypographyColors {
  'text.primary' = 'rgba(0, 0, 0, 0.87)',
  'text.secondary' = 'rgba(0, 0, 0, 0.60)',
  'text.tertiary' = 'rgb(122 133 156 / 90%)',
  'text.info' = '#103D61',
}

interface Props extends HTMLAttributes<HTMLElement> {
  variant?: keyof typeof TypographyVariants;
  children?: ReactNode | string;
  color?: keyof typeof TypographyColors;
  className?: string;
}

export default function CustomTypography({
  children,
  variant = 'body1',
  color = 'text.primary',
  className,
  style = {},
  ...rest
}: Props) {
  const defaultProps = useMemo(
    () => ({
      style: { color: TypographyColors[color], ...style },
      className: clsx(
        className,
        `custom-typography-${variant}`,
        variant !== TypographyVariants.inherit &&
          `custom-typography-${variant}`,
      ),
    }),
    [variant, className, color, style],
  );

  switch (variant) {
    case TypographyVariants.h1:
      return (
        <h1 {...defaultProps} {...rest}>
          {children}
        </h1>
      );
    case TypographyVariants.h2:
      return (
        <h2 {...defaultProps} {...rest}>
          {children}
        </h2>
      );
    case TypographyVariants.h3:
      return (
        <h3 {...defaultProps} {...rest}>
          {children}
        </h3>
      );
    case TypographyVariants.h4:
      return (
        <h4 {...defaultProps} {...rest}>
          {children}
        </h4>
      );
    case TypographyVariants.h5:
      return (
        <h5 {...defaultProps} {...rest}>
          {children}
        </h5>
      );
    case TypographyVariants.h6:
      return (
        <h6 {...defaultProps} {...rest}>
          {children}
        </h6>
      );
    case TypographyVariants.subtitle1:
      return (
        <h6 {...defaultProps} {...rest}>
          {children}
        </h6>
      );
    case TypographyVariants.subtitle2:
      return (
        <h6 {...defaultProps} {...rest}>
          {children}
        </h6>
      );
    case TypographyVariants.body1:
      return (
        <p {...defaultProps} {...rest}>
          {children}
        </p>
      );
    case TypographyVariants.body2:
      return (
        <p {...defaultProps} {...rest}>
          {children}
        </p>
      );
    case TypographyVariants.inherit:
      return (
        <p {...defaultProps} {...rest}>
          {children}
        </p>
      );

    default:
      return (
        <p {...defaultProps} {...rest}>
          {children}
        </p>
      );
  }
}
