import {
  type DocumentVariablePreviewProps,
  VariableEventsEnum,
} from '../../utils/variableModal';
import { Button, Input, GrayedOutInput } from 'ui-kit';
import { useState, useMemo } from 'react';
import { EditorStore } from '../../pages/Editor/store/EditorState';
import { useFeatureFlag } from '../../utils/helper';
import { FeatureFlag } from '../../utils/constants';
import { isAdmin } from '../../utils/env';
import { type Variable } from 'types-shared';

export default function DocumentVariableModalContent({
  variable,
  onCancel,
  modalAction,
  changeModalAction,
}: DocumentVariablePreviewProps) {
  const { updateVariable } = EditorStore();

  const isEditing = modalAction === VariableEventsEnum.EDIT_VARIABLE;
  const [variableName, setVariableName] = useState(variable.name);
  const [hasError, setHasError] = useState(false);
  const downloadEditEnabled =
    useFeatureFlag(FeatureFlag.EditDownloads) ?? false;

  const toggleEditing = () => {
    const payload = isEditing
      ? VariableEventsEnum.VARIABLE_PREVIEW
      : VariableEventsEnum.EDIT_VARIABLE;
    changeModalAction(payload);
  };

  const saveChanges = () => {
    if (!variableName) {
      setHasError(true);
      return;
    }

    changeModalAction(VariableEventsEnum.VARIABLE_PREVIEW);
    const payload = {
      ...variable,
      name: variableName,
    };

    updateVariable(payload as Variable);
    onCancel();
  };

  const disableSaveOrEdit = useMemo(() => {
    if (isEditing) {
      return hasError;
    }

    return isAdmin ? false : !downloadEditEnabled;
  }, [isEditing, downloadEditEnabled, hasError]);

  return (
    <div className="flex-1 mt-10" key={modalAction}>
      <div
        className="flex-1"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {isEditing ? (
          <div className="mb-5 text-sm">
            {' '}
            <Input
              floatingLabel
              label="File name"
              onChange={(value) => {
                setVariableName(value);
                if (hasError && value) {
                  setHasError(false);
                }
              }}
              value={variableName}
              error={hasError}
              errorText="You must name the file"
            />
            {hasError ? (
              <span className="text-red-500 text-xs mt-2 ml-2">
                You must name the file
              </span>
            ) : null}
          </div>
        ) : (
          <GrayedOutInput
            className="mb-5"
            label="File name"
            value={variable.name ?? 'Document-1'}
          />
        )}

        <GrayedOutInput
          className="mb-5"
          label="Source"
          value="Document downloaded during the workflow"
        />

        <div className="flex flex-row gap-7 mt-6">
          <Button
            color="secondary"
            onClick={isEditing ? saveChanges : toggleEditing}
            variant="contained"
            disabled={disableSaveOrEdit}
          >
            {isEditing ? 'SAVE CHANGES' : 'EDIT NAME'}
          </Button>
          <Button
            color="secondary"
            onClick={isEditing ? toggleEditing : onCancel}
            variant="outlined"
          >
            CANCEL
          </Button>
        </div>
      </div>
    </div>
  );
}
