import { useMemo } from 'react';
import { type WorkflowAction } from 'types-shared/workflowTypes';
import { InfoOutlined, Button, Tooltip, GrayedOutInput } from 'ui-kit';
import { FeatureFlag } from '../../../../../../../utils/constants';
import { useFeatureFlag } from '../../../../../../../utils/helper';
import { getActionTitle } from '../../../../../utils';

interface HITLPromptProps {
  variableName?: string;
  action?: WorkflowAction;
  openHITLModal: () => void;
  actionIndex: number;
  defaultTitle?: string;
  signalLoading: boolean;
}

function HITLPrompt({
  variableName,
  action,
  openHITLModal,
  actionIndex,
  defaultTitle,
  signalLoading,
}: HITLPromptProps) {
  const hitlLiveViewEnabled = useFeatureFlag(FeatureFlag.HitlLiveView);

  const title = useMemo(() => {
    if (action) {
      return getActionTitle(action);
    }
    return defaultTitle;
  }, [action, defaultTitle]);

  const description = useMemo(() => {
    if (!action?.description) return '';

    if (action.description.length > 200) {
      return `${action.description.slice(0, 200)}...`;
    }

    return action.description;
  }, [action?.description]);

  return (
    <div className="mt-10 w-full bg-primary-blue-extralight flex flex-col space-y-2 px-4 py-4 rounded mb-6 text-sm">
      <div className="flex flex-row gap-2 py-2 items-center">
        <span className="text-xs text-white bg-primary-blue-dark rounded-full h-6 w-6 flex justify-center items-center inline-block">
          {actionIndex + 1}
        </span>

        <span className="font-medactionIndexium text-primary-blue-dark">
          Action {actionIndex + 1} - {title}
          {variableName ? (
            <span className="text-gray-600">&nbsp;({variableName})</span>
          ) : null}
        </span>
      </div>

      <div className="flex flex-row gap-2 py-2">
        <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
        <span className="font-normal text-primary-blue-dark">
          Handle this action manually to continue with the workflow.
        </span>
      </div>

      {action?.description ? (
        <GrayedOutInput
          className="mb-5"
          label="Instructions"
          value={description}
        />
      ) : null}

      <div className="py-2">
        <Tooltip
          hidden={hitlLiveViewEnabled}
          title="Take charge and process this action manually. Contact sales to learn more!"
          arrow
          placement="right"
        >
          <Button
            color="secondary"
            onClick={openHITLModal}
            variant="contained"
            loading={signalLoading}
            disabled={!hitlLiveViewEnabled}
          >
            <span className={!hitlLiveViewEnabled ? 'text-gray-400' : ''}>
              HANDLE ACTION MANUALLY
            </span>
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

export default HITLPrompt;
