import React, { useRef } from 'react';
import { Button, Input } from 'ui-kit';
import {
  type GlobalVariable,
  type VariableMap,
  type TemplateData,
  type Variable,
  SourceTypeEnum,
  type DatasourceMetadata,
  isVariableAllowedToAddInInput,
} from 'types-shared';
import {
  VariableInput,
  type VariableInputRef,
} from '../VariableTypes/VariableInput';
import {
  openAddVariableModal,
  openPreviewVariableModal,
} from '../../../../utils/helper';

interface Props {
  onCancel: () => void;
  onUpdateInstruction: () => void;
  edgeName: string;
  setEdgeName: (name: string) => void;
  instruction: TemplateData;
  setInstruction: (instruction: TemplateData) => void;
  variablesMap: VariableMap;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  sourceType?: SourceTypeEnum;
  datasourceMetadata: DatasourceMetadata | null;
}
export default function BranchInstruction({
  edgeName,
  setEdgeName,
  onCancel,
  onUpdateInstruction,
  instruction,
  setInstruction,
  globalVariablesMap,
  variablesMap,
  sourceType,
  datasourceMetadata,
}: Props) {
  const inputRef = useRef<VariableInputRef>();

  const hasDatasource = Boolean(datasourceMetadata);

  const updateBranchData = (variableToAdd: Variable) => {
    if (!isVariableAllowedToAddInInput(variableToAdd)) {
      return;
    }
    const newItems: TemplateData = [{ id: variableToAdd.id }];
    setInstruction(newItems);
  };

  return (
    <div>
      <div className="flex-1 flex flex-col mt-6 px-8 mb-8">
        <p className="font-medium text-base">Conditions determined by AI</p>
        <p className="font-normal text-sm mt-2 text-gray-400">
          For more complex conditions, set a prompt to determine when the bot
          should follow this branch.
        </p>
        <p className="font-bold text-sm mt-6">Branch Name</p>
        <div className="relative mt-6">
          <Input
            label={
              <label
                className="text-xs absolute bg-white z-10 top-0 ml-3 px-1 -mt-1 text-gray-400"
                htmlFor="name"
              >
                Name
              </label>
            }
            onChange={setEdgeName}
            placeholder="Name"
            value={edgeName}
          />
        </div>
        <p className="font-bold text-sm mt-6">Take this branch when</p>
        <div className="relative mt-6">
          <VariableInput
            ref={inputRef}
            multiline
            label="Instructions"
            onChange={(val) => {
              if (val.length === 0) {
                setInstruction(['']);
              } else {
                setInstruction(val);
              }
            }}
            allowAddVariable={
              hasDatasource ||
              sourceType === SourceTypeEnum.API ||
              sourceType === SourceTypeEnum.EmailTrigger
            }
            onClickAddNew={(indexForVariableInsert) => {
              if (inputRef.current) {
                openAddVariableModal({
                  insertVariable(newVariable: Variable) {
                    inputRef.current?.addVariable(
                      newVariable,
                      indexForVariableInsert,
                    );
                  },
                  updateBranchData,
                  isCondition: true,
                });
              }
            }}
            onClickVariableChip={(id) => {
              openPreviewVariableModal({
                variableId: id,
                isCondition: true,
                updateBranchData,
              });
            }}
            value={instruction}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            placeholder="Write open-ended instructions for the condition"
            className="!min-h-[174px]"
          />
        </div>
      </div>

      <div className="flex w-full gap-9 px-8 py-6  absolute bottom-0">
        <Button
          className="!flex-1"
          color="secondary"
          disabled={!edgeName || !instruction}
          fullWidth
          onClick={() => {
            onUpdateInstruction();
          }}
          variant="contained"
        >
          Save changes
        </Button>
        <Button
          className="!flex-1"
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          cancel
        </Button>
      </div>
    </div>
  );
}
