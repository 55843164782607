import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function MessageIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.73307 13.2815L4.74905 12.4687L4.75652 12.463C4.96833 12.2936 5.07521 12.2081 5.19445 12.1471C5.30144 12.0925 5.41552 12.0527 5.5332 12.0285C5.66585 12.0013 5.80402 12.0013 6.08138 12.0013H11.8687C12.614 12.0013 12.9871 12.0013 13.272 11.8561C13.5229 11.7283 13.727 11.5241 13.8548 11.2732C14 10.9883 14 10.6156 14 9.87035V4.79924C14 4.05396 14 3.68077 13.8548 3.39583C13.727 3.14495 13.5225 2.94112 13.2716 2.81329C12.9864 2.66797 12.6135 2.66797 11.8668 2.66797H4.13346C3.38673 2.66797 3.01308 2.66797 2.72786 2.81329C2.47698 2.94112 2.27316 3.14495 2.14532 3.39583C2 3.68105 2 4.0547 2 4.80143V12.4488C2 13.1592 2 13.5144 2.14564 13.6968C2.2723 13.8555 2.46429 13.9478 2.66732 13.9476C2.90077 13.9474 3.17829 13.7254 3.73307 13.2815Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
