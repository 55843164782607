"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageEvent = exports.ExtensionEvent = exports.sourceSchema = exports.ExtensionData = exports.RecordingConfig = exports.ExtensionScreenshotArray = exports.ExtensionDataActions = exports.RecordingAction = exports.extensionDataType = exports.ExtensionScreenshot = exports.BrowserAction = exports.BrowserTabAction = exports.BrowserTabUpdateAction = exports.BrowserNewTabAction = exports.BrowserInitialTabAction = exports.BrowserSwitchTabAction = exports.BaseBrowserTabAction = exports.BrowserDownloadAction = exports.DownloadData = exports.BrowserActionType = exports.BrowserActionTypeEnum = void 0;
var zod_1 = require("zod");
var action_types_1 = require("./action-types");
var chrome_types_1 = require("./chrome-types");
var BrowserActionTypeEnum;
(function (BrowserActionTypeEnum) {
    BrowserActionTypeEnum["Download"] = "Download";
    BrowserActionTypeEnum["InitialTab"] = "InitialTab";
    BrowserActionTypeEnum["NewTab"] = "NewTab";
    BrowserActionTypeEnum["TabUpdate"] = "TabUpdate";
    BrowserActionTypeEnum["SwitchTab"] = "SwitchTab";
})(BrowserActionTypeEnum || (exports.BrowserActionTypeEnum = BrowserActionTypeEnum = {}));
exports.BrowserActionType = zod_1.z.nativeEnum(BrowserActionTypeEnum);
exports.DownloadData = zod_1.z.object({
    url: zod_1.z.string().optional(),
    filename: zod_1.z.string().optional(),
    mimeType: zod_1.z.string().optional(),
    finalUrl: zod_1.z.string().optional(),
});
exports.BrowserDownloadAction = zod_1.z.object({
    type: zod_1.z.literal(BrowserActionTypeEnum.Download),
    data: exports.DownloadData,
    timestamp: zod_1.z.string(),
});
exports.BaseBrowserTabAction = zod_1.z.object({
    data: chrome_types_1.ChromeTab,
    timestamp: zod_1.z.string(),
});
exports.BrowserSwitchTabAction = exports.BaseBrowserTabAction.extend({
    type: zod_1.z.literal(BrowserActionTypeEnum.SwitchTab),
});
exports.BrowserInitialTabAction = exports.BaseBrowserTabAction.extend({
    type: zod_1.z.literal(BrowserActionTypeEnum.InitialTab),
});
exports.BrowserNewTabAction = exports.BaseBrowserTabAction.extend({
    type: zod_1.z.literal(BrowserActionTypeEnum.NewTab),
});
exports.BrowserTabUpdateAction = exports.BaseBrowserTabAction.extend({
    type: zod_1.z.literal(BrowserActionTypeEnum.TabUpdate),
});
exports.BrowserTabAction = zod_1.z.union([
    exports.BrowserInitialTabAction,
    exports.BrowserNewTabAction,
    exports.BrowserSwitchTabAction,
    exports.BrowserTabUpdateAction,
]);
exports.BrowserAction = zod_1.z.union([exports.BrowserDownloadAction, exports.BrowserTabAction]);
exports.ExtensionScreenshot = zod_1.z.object({
    dataUrl: zod_1.z.string(),
    width: zod_1.z.number(),
    height: zod_1.z.number(),
    imageId: zod_1.z.string(),
});
exports.extensionDataType = 'Sola-Extension-Upload';
exports.RecordingAction = zod_1.z.union([action_types_1.ExtensionAction, exports.BrowserAction]);
exports.ExtensionDataActions = zod_1.z.array(zod_1.z.array(exports.RecordingAction));
exports.ExtensionScreenshotArray = zod_1.z.array(zod_1.z.array(exports.ExtensionScreenshot.omit({ dataUrl: true })));
exports.RecordingConfig = zod_1.z.object({
    replaceNodeId: zod_1.z.string().optional(),
});
exports.ExtensionData = zod_1.z.object({
    actions: exports.ExtensionDataActions,
    scans: exports.ExtensionScreenshotArray,
    capturedUrls: zod_1.z.array(zod_1.z.string()),
    config: exports.RecordingConfig.optional(),
});
exports.sourceSchema = zod_1.z.custom(function (val) { return val instanceof Window && typeof val === typeof globalThis; });
exports.ExtensionEvent = zod_1.z.object({
    data: exports.ExtensionData,
    source: exports.sourceSchema,
});
exports.MessageEvent = zod_1.z.object({
    data: zod_1.z
        .object({
        type: zod_1.z.string().optional(),
    })
        .optional(),
    source: exports.sourceSchema.optional(),
});
