import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function StarsIcon(
  props: SvgIconProps & { starColor?: string },
) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.30344 2.17753C5.32596 2.22258 5.36249 2.25911 5.40755 2.28164L6.86167 3.0087C7.03325 3.09449 7.03325 3.33935 6.86167 3.42514L5.40755 4.1522C5.36249 4.17472 5.32596 4.21125 5.30344 4.25631L4.57638 5.71042C4.49059 5.88201 4.24573 5.88201 4.15994 5.71043L3.43288 4.25631C3.41035 4.21125 3.37382 4.17472 3.32877 4.1522L1.87465 3.42514C1.70307 3.33935 1.70307 3.09449 1.87465 3.0087L3.32877 2.28164C3.37382 2.25911 3.41035 2.22258 3.43288 2.17753L4.15994 0.723413C4.24573 0.551831 4.49059 0.551831 4.57638 0.723413L5.30344 2.17753Z"
        fill={props.starColor ?? 'white'}
      />
      <path
        d="M3.55747 9.74338C3.58 9.78844 3.61653 9.82497 3.66158 9.84749L5.1157 10.5746C5.28728 10.6603 5.28728 10.9052 5.1157 10.991L3.66158 11.718C3.61653 11.7406 3.58 11.7771 3.55747 11.8222L2.83041 13.2763C2.74462 13.4479 2.49976 13.4479 2.41397 13.2763L1.68691 11.8222C1.66439 11.7771 1.62786 11.7406 1.5828 11.718L0.128686 10.991C-0.0428956 10.9052 -0.0428953 10.6603 0.128686 10.5746L1.5828 9.84749C1.62786 9.82497 1.66439 9.78844 1.68691 9.74338L2.41397 8.28927C2.49976 8.11768 2.74462 8.11768 2.83041 8.28927L3.55747 9.74338Z"
        fill={props.starColor ?? 'white'}
      />
      <path
        d="M5.91736 7.66741C5.75302 7.75505 5.75302 7.99061 5.91736 8.07824L8.37353 9.38795C8.41791 9.41162 8.45343 9.44902 8.47478 9.49457L9.68626 12.0791C9.77001 12.2577 10.0241 12.2577 10.1078 12.0791L11.3193 9.49457C11.3407 9.44902 11.3762 9.41162 11.4206 9.38795L13.8767 8.07824C14.0411 7.99061 14.0411 7.75505 13.8767 7.66741L11.4206 6.3577C11.3762 6.33404 11.3407 6.29663 11.3193 6.25109L10.1078 3.6666C10.0241 3.48794 9.77001 3.48795 9.68626 3.6666L8.47478 6.25109C8.45343 6.29663 8.41791 6.33404 8.37353 6.3577L5.91736 7.66741Z"
        fill={props.starColor ?? 'white'}
      />
    </SvgIcon>
  );
}
