import {
  Button,
  Chip,
  ContentContainer,
  ScrollableContainer,
  CustomTypography,
  AccessTimeIcon,
  PeopleIcon,
} from 'ui-kit';
import ReactECharts from 'echarts-for-react';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { type GridColDef } from '@mui/x-data-grid/models';
import { NavigationPanel } from '../components/NavigationPanel';
import { ChartColors } from '../utils/constants';

const pieChartOptions = {
  tooltip: {
    trigger: 'item',
  },
  color: [ChartColors.SUCCESS, ChartColors.FAILURE],
  legend: {
    bottom: '5%',
    left: 'center',
    itemGap: 40,
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '65%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
      },
      top: '-30',
      data: [
        { value: 92, name: 'Success' },
        { value: 8, name: 'Failure' },
      ],
    },
  ],
};

const rawData = [
  [100, 150, 177, 113, 148, 189],
  [15, 20, 10, 23, 12, 19],
  [9, 11, 6, 16, 8, 12],
];

const series = ['Success', 'Running & Queued', 'Failure'].map((name, i) => {
  return {
    name,
    type: 'bar',
    stack: 'total',
    barWidth: '70%',
    data: rawData[i],
    label: {
      show: false,
    },
    ...(i === 1
      ? {}
      : {
          itemStyle: {
            normal: {
              barBorderRadius: i === 0 ? [0, 0, 3, 3] : [3, 3, 0, 0],
            },
          },
        }),
  };
});

const executionsBarChartOptions = {
  tooltip: {
    trigger: 'item',
  },
  legend: {
    selectedMode: false,
    bottom: '5%',
    left: 'center',
    itemGap: 40,
  },
  color: [ChartColors.SUCCESS, ChartColors.RUNNING, ChartColors.FAILURE],
  xAxis: {
    type: 'category',
    data: ['12 PM', '4 AM', '8 AM', '12 AM', '4 PM', '8 PM'],
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: 'value',
    show: false,
  },
  series,
};

const executionsPerMemberOptions = {
  tooltip: {
    trigger: 'item',
  },
  xAxis: {
    max: 'dataMax',
    show: false,
  },
  color: ['#3174fa99'],
  yAxis: {
    type: 'category',
    data: ['John', 'Gabe', 'Michael', 'Jessica'],
    inverse: true,
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  series: [
    {
      name: 'Executions',
      type: 'bar',
      data: [14892, 5402, 2918, 239],
      label: {
        show: false,
      },
      barWidth: '70%',
      itemStyle: {
        normal: {
          barBorderRadius: [3, 3, 3, 3],
        },
      },
    },
  ],
  legend: {
    show: false,
  },
};

interface Member {
  email: string;
  workflows: number;
  executions: number;
  status: string;
  role?: string;
}

const members: Member[] = [
  { email: 'john@sola.ai', workflows: 10, executions: 14892, status: 'Active' },
  {
    email: 'gabe@sola.ai',
    workflows: 3,
    executions: 5402,
    status: 'Active',
    role: 'Owner',
  },
  {
    email: 'michael@sola.ai',
    workflows: 7,
    executions: 2918,
    status: 'Active',
  },
  {
    email: 'jessica@sola.ai',
    workflows: 11,
    executions: 239,
    status: 'Active',
    role: 'Admin',
  },
];

const columns: GridColDef<Member>[] = [
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <div>
        {row.email} {row.role ? <b>({row.role})</b> : null}
      </div>
    ),
  },
  { field: 'workflows', headerName: 'Workflows', flex: 1, sortable: false },
  { field: 'executions', headerName: 'Executions', flex: 1, sortable: false },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: ({ row }) => <Chip label={row.status} />,
    sortable: false,
  },
];

export default function Insights() {
  return (
    <ScrollableContainer>
      <div className="w-full h-full flex">
        <NavigationPanel />

        <ContentContainer className="flex flex-col space-y-8">
          <CustomTypography variant="h4">Insights</CustomTypography>
          <div className="flex w-full space-x-4 items-stretch">
            <div className="flex flex-col space-y-1 flex-1 px-6 py-4 rounded-lg border">
              <p className="text-base font-medium">Total workflows</p>
              <p className="text-3xl !mt-3 font-medium">33</p>
              <p className="text-sm text-gray-500">4 added this month</p>
            </div>
            <div className="flex flex-col space-y-1 flex-1 px-5 py-4 rounded-lg border">
              <div className="flex w-full justify-between items-center">
                <p className="text-base font-medium">
                  Total workflows
                  <span className="text-gray-500 ml-1">last month</span>
                </p>
                <AccessTimeIcon className="!w-5 !h-5 text-gray-500" />
              </div>
              <p className="text-3xl !mt-3 font-medium">
                382
                <span className="text-sm text-gray-500 ml-1">HS</span>
              </p>
              <p className="text-sm text-gray-500">+24% from last month</p>
            </div>
            <div className="flex flex-col space-y-1 flex-1 px-6 py-4 rounded-lg border">
              <div className="flex w-full justify-between items-center">
                <p className="text-base font-medium">Active Bots</p>
                <Button
                  className="!font-medium !text-sm !p-0"
                  color="secondary"
                  variant="text"
                >
                  Upgrade
                </Button>
              </div>
              <p className="text-3xl !mt-3 font-medium">8</p>
            </div>
            <div className="flex flex-col space-y-1 flex-1 px-6 py-4 rounded-lg border">
              <div className="flex w-full justify-between items-center">
                <p className="text-base font-medium">Members</p>
                <PeopleIcon className="!w-5 !h-5 text-gray-500" />
              </div>
              <p className="text-3xl !mt-3 font-medium">4</p>
            </div>
          </div>
          <div className="flex w-full space-x-4 items-stretch">
            <div className="flex flex-col flex-1 px-6 py-4 rounded-lg border">
              <div className="flex w-full justify-between items-center">
                <p className="text-base font-medium">Total executions: 23451</p>
                <span className="text-sm text-gray-500">Overall</span>
              </div>
              <ReactECharts option={pieChartOptions} />
            </div>
            <div className="flex flex-col space-y-1 flex-1 px-6 py-4 rounded-lg border">
              <p className="text-base font-medium">
                Busy Hours
                <span className="text-gray-500 ml-1">last month</span>
              </p>
              <ReactECharts option={executionsBarChartOptions} />
            </div>
            <div className="flex-1" />
          </div>
          <div className="flex w-full space-x-4 items-stretch">
            <div className="flex flex-col flex-1 px-6 py-4 rounded-lg border">
              <p className="text-base font-medium">
                Total executions per member
              </p>
              <ReactECharts option={executionsPerMemberOptions} />
            </div>
            <div className="flex flex-col space-y-1 flex-1 px-6 py-4 rounded-lg border">
              <p className="text-base font-medium">Member insights</p>
              <DataGrid<Member>
                columns={columns}
                getRowId={(row) => row.email}
                hideFooter
                rows={members}
              />
            </div>
          </div>
        </ContentContainer>
      </div>
    </ScrollableContainer>
  );
}
