import { createBrowserRouter, Navigate } from 'react-router-dom';
import { AuthenticationGuard } from 'ui-kit';

import AddDatasource from './pages/Datasource/pages/AddDatasource';
import Editor from './pages/Editor';
import WorkflowDetail from './pages/Workflows/pages/WorkflowDetail';
import WorkflowsList from './pages/Workflows/pages/WorkflowsList';
import Integrations from './pages/Integrations';
import Extension from './pages/Extension';
import DatasourcePreview from './pages/Datasource/pages/DatasourcePreview';
import Execution from './pages/Execution';
import APITriggerSettings from './pages/APITriggerSettings';
import ListApiKeys from './pages/ApiKeysManager/pages/ListApiKeys';
import CreateEditApiKey from './pages/ApiKeysManager/pages/CreateEditApiKey';
import ParagonRedirect from './pages/ParagonRedirect';
import GlobalVariables from './pages/GlobalVariables';
import Members from './pages/Members';
import Insights from './pages/Insights';
import ExecutionsList from './pages/ExecutionsList';
import { isAdmin } from './utils/env';
import { ErrorBoundaryLayout } from './components/ErrorBoundary/ErrorBoundaryLayout';
import WorkflowSettings from './pages/WorkflowSettings';

export const router = createBrowserRouter([
  {
    element: <ErrorBoundaryLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthenticationGuard component={WorkflowsList} bypassAuth={isAdmin} />
        ),
      },
      {
        path: 'editor/:workflowId',
        element: (
          <AuthenticationGuard component={Editor} bypassAuth={isAdmin} />
        ),
      },
      {
        path: 'datasource/:workflowId',
        element: (
          <AuthenticationGuard component={AddDatasource} bypassAuth={isAdmin} />
        ),
      },
      {
        path: 'workflows/:workflowId',
        element: (
          <AuthenticationGuard
            component={WorkflowDetail}
            bypassAuth={isAdmin}
          />
        ),
      },
      {
        path: 'workflows/:workflowId/settings',
        element: (
          <AuthenticationGuard
            component={WorkflowSettings}
            bypassAuth={isAdmin}
          />
        ),
      },
      {
        path: 'datasource/:workflowId/preview',
        element: (
          <AuthenticationGuard
            component={DatasourcePreview}
            bypassAuth={isAdmin}
          />
        ),
      },
      {
        path: 'execution/:executionId',
        element: (
          <AuthenticationGuard component={Execution} bypassAuth={isAdmin} />
        ),
      },
      {
        path: 'global-variables',
        element: (
          <AuthenticationGuard
            component={GlobalVariables}
            bypassAuth={isAdmin}
          />
        ),
      },
      {
        path: 'api-trigger-settings/:workflowId',
        element: (
          <AuthenticationGuard
            component={APITriggerSettings}
            bypassAuth={isAdmin}
          />
        ),
      },
      ...(isAdmin
        ? [
            {
              path: 'executions-list',
              element: (
                <AuthenticationGuard
                  component={ExecutionsList}
                  bypassAuth={isAdmin}
                />
              ),
            },
          ]
        : [
            {
              path: 'integrations',
              element: <AuthenticationGuard component={Integrations} />,
            },
            {
              path: 'api-keys-manager',
              element: <AuthenticationGuard component={ListApiKeys} />,
            },
            {
              path: 'api-keys-manager/create',
              element: <AuthenticationGuard component={CreateEditApiKey} />,
            },
            {
              path: 'api-keys-manager/edit/:id',
              element: <AuthenticationGuard component={CreateEditApiKey} />,
            },
            {
              path: 'extension',
              element: <AuthenticationGuard component={Extension} />,
            },
            {
              path: 'members',
              element: <AuthenticationGuard component={Members} />,
            },
            {
              path: 'insights',
              element: <AuthenticationGuard component={Insights} />,
            },
            {
              path: 'paragon',
              element: <ParagonRedirect />,
            },
          ]),
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
]);
