import {
  type TemplateData,
  type WebhookHeader,
  type WorkflowWebhook,
} from 'types-shared/workflowTypes';
import { v4 as uuid } from 'uuid';

export interface FormValues {
  id: string;
  eventName: string;
  event: string;
  action: string;
  url: TemplateData;
  headers: ({ id: string } & WebhookHeader)[];
}

export enum Event {
  ExecutionFinished = 'Execution finished',
  ExecutionStatusChange = 'Execution status change',
}

export enum Action {
  SendWebhook = 'Send webhook',
  SendNotification = 'Send notification',
}

export const events = [Event.ExecutionFinished, Event.ExecutionStatusChange];
export const actions = [Action.SendWebhook, Action.SendNotification];

export const defaultForm: FormValues = {
  id: uuid(),
  eventName: '',
  event: Event.ExecutionFinished,
  action: Action.SendWebhook,
  url: [],
  headers: [],
};

export const convertWebhookToFormValues = (
  webhook: WorkflowWebhook,
): FormValues => ({
  id: webhook.id,
  eventName: webhook.name,
  event: Event.ExecutionFinished,
  action: Action.SendWebhook,
  url: webhook.webhookUrl,
  headers: webhook.headers.map(({ key, value }) => ({
    id: uuid(),
    key,
    value,
  })),
});

export const convertFormValuesToWebhook = (
  formValues: FormValues,
): WorkflowWebhook => ({
  id: formValues.id,
  name: formValues.eventName,
  webhookUrl: formValues.url,
  headers: formValues.headers.map((header) => ({
    key: header.key,
    value: header.value,
  })),
});
