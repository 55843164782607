import { useEffect } from 'react';

export default function useReactFlowZoomFix() {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.zoom = '1';
    }

    return () => {
      if (root) {
        root.style.zoom = '0.8';
      }
    };
  }, []);
}
