import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query';
import type {
  GetDatasourceResponse,
  UploadGoogleSheetsResponse,
} from 'api-types-shared';
import { axios, UploadUrlContentTypeEnum } from 'api-types-shared';
import type {
  DatasourceMetadata,
  DatasourceTable,
  GoogleDriveFileMetadata,
} from 'types-shared';
import { handleException } from 'sentry-browser-shared';
import { useAPI } from '../../hooks/useApi';
import { useAuth } from '../../utils/helper';

export const useFetchDatasourcesList = (): UseQueryResult<
  DatasourceMetadata[]
> => {
  const { datasourceSDK: sdk } = useAPI();
  return useQuery<DatasourceMetadata[]>({
    queryKey: ['datasources'],
    queryFn: async () => {
      const resp = await sdk
        .fetchDatasourcesList({
          query: {},
        })
        .catch((err: unknown) => {
          handleException(err, {
            name: 'Datasource meta fetch failed',
            source: 'hooks/useFetchDatasourcesList',
          });
          return { datasourceMetas: [] };
        });

      return resp.datasourceMetas;
    },
  });
};

export const useGetDatasource = (
  datasourceId: string,
): UseQueryResult<GetDatasourceResponse> => {
  const { datasourceSDK: sdk } = useAPI();
  return useQuery<GetDatasourceResponse>({
    queryKey: ['datasources', datasourceId],
    queryFn: () => {
      // const user = await getAuthUser();
      return sdk.getDatasource({
        params: { datasourceId },
        query: {
          uploadUrlContentType: UploadUrlContentTypeEnum.CSV,
        },
      });
    },
  });
};

export const useGetDatasourceForWorkflow = (
  workflowId: string,
): UseQueryResult<DatasourceMetadata[]> => {
  const { datasourceSDK: sdk } = useAPI();
  return useQuery<DatasourceMetadata[]>({
    queryKey: ['datasources', workflowId],
    queryFn: async () => {
      const { datasourceMetas } = await sdk
        .fetchDatasourcesList({
          query: { workflowId },
        })
        .catch((err: unknown) => {
          handleException(err, {
            name: 'Datasource meta fetch failed',
            source: 'hooks/useGetDatasourceForWorkflow',
          });
          return { datasourceMetas: [] };
        });
      return datasourceMetas;
    },
  });
};

export const useFetchDatasourceTable = (
  datasourceId: string | undefined,
  enabled: boolean,
): UseQueryResult<DatasourceTable | null> => {
  const { datasourceSDK: sdk } = useAPI();

  return useQuery<DatasourceTable | null>({
    queryKey: ['datasources', datasourceId],
    queryFn: async () => {
      if (!datasourceId) {
        return null;
      }
      const response = await sdk.getDatasource({
        params: { datasourceId },
        query: {
          uploadUrlContentType: UploadUrlContentTypeEnum.JSON,
        },
      });

      const { url } = response;
      return url ? sdk.getDatasourceTable(url) : null;
    },
    enabled: Boolean(datasourceId) && enabled,
    retry: (failureCount) => {
      return failureCount > 5;
    },
    refetchInterval: (query) => {
      if (query.state.data ?? query.state.dataUpdateCount > 5) {
        return false;
      }

      return 2000;
    },
  });
};

export const useUploadCsvFile = () => {
  const { datasourceSDK: sdk } = useAPI();
  const queryClient = useQueryClient();

  return useMutation<
    DatasourceMetadata,
    Error,
    { file: File; name: string; description: string; workflowId: string }
  >({
    mutationFn: async ({ file, name, description, workflowId }) => {
      const { url, meta } = await sdk.createUploadUrl({
        body: {
          filename: file.name,
          contentType: UploadUrlContentTypeEnum.CSV,
          description,
          name,
          workflowId,
        },
      });

      await axios
        .put(url, file, {
          headers: {
            'Content-Type': UploadUrlContentTypeEnum.CSV,
          },
        })
        .catch((err: unknown) => {
          handleException(err, {
            name: 'CSV file upload failed',
            source: 'hooks/useUploadCsvFile',
            extra: {
              name,
              description,
              workflowId,
            },
          });
        });

      await queryClient.invalidateQueries({
        queryKey: ['datasources'],
      });

      return meta;
    },
  });
};

export const useFetchGoogleSheets = (): UseQueryResult<
  GoogleDriveFileMetadata[]
> => {
  const { user } = useAuth();
  const { datasourceSDK: sdk } = useAPI();

  return useQuery<GoogleDriveFileMetadata[]>({
    queryKey: ['googleSheets', user?.email],
    queryFn: async () => {
      try {
        const resp = await sdk.fetchDatasourcesList({
          query: { sheetsReq: true },
        });
        return resp.googleSheetMetas;
      } catch (e) {
        handleException(e, {
          name: 'Google Sheets fetch failed',
          source: 'hooks/useFetchGoogleSheets',
        });
      }
      return [];
    },
    enabled: Boolean(user),
  });
};

export const useUploadGoogleSheet = () => {
  const { datasourceSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<
    UploadGoogleSheetsResponse,
    Error,
    { sheetId: string; workflowId: string; name: string; description: string }
  >({
    mutationFn: async ({ sheetId, workflowId, name, description }) => {
      const meta = sdk.uploadGoogleSheet({
        body: {
          sheetId,
          description,
          name,
          workflowId,
        },
      });

      await queryClient.invalidateQueries({
        queryKey: ['datasources'],
      });

      return meta;
    },
  });
};
