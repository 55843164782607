import { CustomTypography, Flex } from 'ui-kit';

export default function ComingSoonIntegrationTile() {
  return (
    <Flex className="min-w-80 max-w-80 h-52 flex-col rounded-lg border-2 border-color-light-blue bg-color-light-blue p-7">
      <CustomTypography color="text.info" variant="body1">
        <span className="text-sm">
          <b>More integrations...</b>
          <br />
          Not seeing the integration you need? Some features may be limited
          based on your plan. Contact us to explore enabling additional
          integrations or to request another tool!
        </span>
      </CustomTypography>
    </Flex>
  );
}
