import { Add, DeleteOutlineIcon, Button, Flex, Input } from 'ui-kit';
import React, { useState } from 'react';
import { ActionsEnum } from 'types-shared';
import { v4 as uuid } from 'uuid';

interface Props {
  actionType: ActionsEnum;
  onSubmit: (options: string[]) => void;
  onCancel: () => void;
}

export function CreateActionButtons({ actionType, onSubmit, onCancel }: Props) {
  const [options, setOptions] = useState<{ label: string; id: string }[]>([
    { id: uuid(), label: '' },
  ]);

  return (
    <div className="flex-1 flex flex-col gap-4">
      {actionType === ActionsEnum.Select ||
      actionType === ActionsEnum.MultiChoice ? (
        <>
          {options.map(({ label, id }, index) => {
            return (
              <div className="flex gap-2 items-center" key={id}>
                <Input
                  classes={{ wrapper: 'flex-1' }}
                  onChange={(value) => {
                    setOptions((prevOptions) => {
                      const newOptions = [...prevOptions];
                      newOptions[index] = { id, label: value };
                      return newOptions;
                    });
                  }}
                  placeholder={`Option ${(index + 1).toString()}`}
                  value={label}
                />
                <DeleteOutlineIcon
                  className="hover:text-red-500 cursor-pointer"
                  onClick={() => {
                    setOptions((prevOptions) => {
                      const newOptions = [...prevOptions];
                      newOptions.splice(index, 1);
                      return newOptions;
                    });
                  }}
                />
              </div>
            );
          })}
          <AddButton
            onClick={() => {
              setOptions((prevOptions) => [
                ...prevOptions,
                { id: uuid(), label: '' },
              ]);
            }}
          />
        </>
      ) : null}

      <Flex className="gap-4 w-full">
        <Button
          color="secondary"
          disabled={!actionType}
          fullWidth
          onClick={() => {
            onSubmit(options.map((option) => option.label));
          }}
          variant="contained"
        >
          Create Action
        </Button>
        <Button
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          Cancel
        </Button>
      </Flex>
    </div>
  );
}

function AddButton({ onClick }: { onClick: () => void }) {
  return (
    <Button
      className="!max-w-min"
      color="secondary"
      onClick={onClick}
      variant="outlined"
    >
      <Add />
    </Button>
  );
}
