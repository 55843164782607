export const extractServerErrorMessage = async (
  errLike: unknown,
): Promise<string | null> => {
  // if there is a mesage in server response, return that message instead
  const serverErr = errLike as {
    response: { json: () => Promise<Record<string, string>> };
  };
  if (
    'response' in serverErr &&
    'json' in serverErr.response &&
    typeof serverErr.response.json === 'function'
  ) {
    const serverMessage = await serverErr.response.json();

    if ('message' in serverMessage && serverMessage.message) {
      return serverMessage.message;
    }
  }
  return null;
};
