import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import { type GetParagonUserTokenResponse } from 'api-types-shared';
import { useAPI } from '../../hooks/useApi';
import { handleException } from 'sentry-browser-shared';
import { useCallback, useEffect, useState } from 'react';
import {
  type AuthenticatedConnectUser,
  paragon,
  SDK_EVENT,
} from '@useparagon/connect';
import { paragonProjectId } from '../../utils/env';

export const useGetParagonUserToken =
  (): UseQueryResult<GetParagonUserTokenResponse | null> => {
    const { miscSDK: sdk } = useAPI();

    return useQuery<GetParagonUserTokenResponse | null>({
      queryKey: ['paragonUserToken'],
      queryFn: async () => {
        const response = await sdk
          .getParagonUserToken()
          .catch((err: unknown) => {
            handleException(err, {
              name: 'Paragon user token fetch failed',
              source: `Integrations/useGetParagonUserToken`,
              extra: {
                endpoint: sdk.endpoint,
              },
            });
            return null;
          });
        return response;
      },
    });
  };

export const useParagonAuth = (
  paragonUserToken?: string,
): {
  user?: AuthenticatedConnectUser;
  error?: Error;
} => {
  const [user, setUser] = useState<AuthenticatedConnectUser | undefined>();
  const [error, setError] = useState<Error | undefined>();

  const authenticateUser = useCallback(async () => {
    try {
      await paragon.authenticate(paragonProjectId, paragonUserToken ?? '');
      setUser(paragon.getUser() as AuthenticatedConnectUser);
    } catch (errorToSet) {
      setError(errorToSet as Error);
    }
  }, [paragonUserToken]);

  useEffect(() => {
    const listener = () => {
      const authedUser = paragon.getUser();
      if (authedUser.authenticated) {
        setUser({ ...authedUser });
      }
    };
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, listener);
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, listener);
    return () => {
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, listener);
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, listener);
    };
  }, []);

  useEffect(() => {
    if (paragonUserToken && !error) {
      void authenticateUser();
    }
  }, [paragonUserToken, error, authenticateUser]);

  return { user, error };
};
