import { useParams, useSearchParams } from 'react-router-dom';
import { ChevronLeft, ChevronRight, IconButton } from 'ui-kit';
import React, { useCallback, useMemo, useState } from 'react';
import type { NodeData, WorkflowEdge, WorkflowNode } from 'types-shared';
import { WorkflowImageNode, WorkflowSourceNode } from 'types-shared';
import type { WorkflowStatusEnum } from 'api-types-shared';
import { handleException } from 'sentry-browser-shared';
import { isAdmin } from '../../../utils/env';
import AnnotatedPDF, {
  type RenderPdfProps,
  AnnotatedPdfControls,
} from './ActionsManager/DocumentVariables/AnnotatedPDF';

interface Props {
  selectedNodeId?: string | null;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  setSelectedNode: (nodeId: string | null) => void;
  SelectedImageNodeContent: React.FunctionComponent<{
    nodeData: NodeData;
    className?: string;
  }>;

  ActionsManager: React.FunctionComponent<{
    isReadonlyView?: boolean;
    workflowStatus?: WorkflowStatusEnum;
    setHasSelectedAction: (status: boolean) => void;
    setAnnotatedPDFData: React.Dispatch<
      React.SetStateAction<RenderPdfProps | undefined>
    >;
  }>;
  workflowStatus?: WorkflowStatusEnum;
  isReadonlyView?: boolean;
  annotatedPDFData?: RenderPdfProps;
  setAnnotatedPDFData: React.Dispatch<
    React.SetStateAction<RenderPdfProps | undefined>
  >;
}

export function ActionViewCore({
  selectedNodeId,
  nodes,
  edges,
  setSelectedNode,
  SelectedImageNodeContent,
  ActionsManager,
  workflowStatus,
  isReadonlyView = false,
  annotatedPDFData,
  setAnnotatedPDFData,
}: Props) {
  const [zoomLevel, setZoomLevel] = useState(1.2); // 100% base zoom
  const [maxPages, setMaxPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const { workflowId } = useParams();
  const [, setSearchParams] = useSearchParams();
  if (!workflowId) {
    throw new Error('Workflow ID not provided');
  }

  const [hasSelectedAction, setHasSelectedAction] = useState<boolean>(false);

  const handleZoom = useCallback((increase: boolean) => {
    setZoomLevel((prev) => {
      const step = 0.12; // 10% step relative to base zoom of 1.2
      const newZoom = increase ? prev + step : prev - step;
      // Allow zoom from 50% to 200% relative to base zoom of 1.2
      if (newZoom >= 0.6 && newZoom <= 2.4) {
        return newZoom;
      }
      return prev;
    });
  }, []);

  const currentZoomPercentage = Math.round((zoomLevel / 1.2) * 100);

  const { filteredNodes, datasourceNodeId } = useMemo(() => {
    let dataNodeId = '';
    const nonDatasourceNodes = nodes.filter((n) => {
      if (WorkflowSourceNode.safeParse(n).success) {
        dataNodeId = n.id;
        return false;
      }
      return true;
    });
    return {
      datasourceNodeId: dataNodeId,
      filteredNodes: nonDatasourceNodes,
    };
  }, [nodes]); // skip the datasource node
  const filteredEdges = useMemo(
    () =>
      edges.filter(
        (e) => e.source !== datasourceNodeId && e.target !== datasourceNodeId,
      ),
    [datasourceNodeId, edges],
  );
  const selectedNode = useMemo(() => {
    const _selectedNode = selectedNodeId
      ? filteredNodes.find((n) => n.id === selectedNodeId)
      : null;
    const parsedSelectedNode = WorkflowImageNode.safeParse(_selectedNode);
    if (!parsedSelectedNode.success) {
      setSelectedNode(null);
      setSearchParams({});
      handleException(new Error(), {
        name: 'Invalid image node selected',
        source: 'Editor/ActionViewCore',
        extra: {
          workflowId,
          selectedNodeId,
          filteredNodes,
          parsedSelectedNode,
        },
      });
      return null;
    }
    return parsedSelectedNode.data;
  }, [
    selectedNodeId,
    filteredNodes,
    setSelectedNode,
    setSearchParams,
    workflowId,
  ]);

  const validateSetSelectedNode = useCallback(
    (nodeId: string) => {
      const node = nodes.find((n) => n.id === nodeId);
      if (WorkflowImageNode.safeParse(node).success) {
        setSelectedNode(nodeId);
      } else {
        setSearchParams({});
        setSelectedNode(null);
      }
    },
    [nodes, setSearchParams, setSelectedNode],
  );

  const { isFirst, isLast, nextNodeId, prevNodeId } = useMemo(() => {
    const forwardEdge = filteredEdges.find((e) => e.source === selectedNodeId);
    const backwardEdge = filteredEdges.find((e) => e.target === selectedNodeId);
    return {
      isFirst: !backwardEdge,
      isLast: !forwardEdge,
      nextNodeId: forwardEdge?.target,
      prevNodeId: backwardEdge?.source,
    };
  }, [filteredEdges, selectedNodeId]);

  if (!selectedNode) return null;

  return (
    <div className="zoom-adjusted-container fixed inset-0">
      <div className="relative w-full h-full flex">
        <ActionsManager
          isReadonlyView={isReadonlyView}
          setHasSelectedAction={setHasSelectedAction}
          workflowStatus={workflowStatus}
          setAnnotatedPDFData={setAnnotatedPDFData}
        />
        <div
          id="selected-image-node-content-container"
          className="w-full flex justify-center items-center dotted-bg relative"
        >
          {/* AnnotatedPDF stays rendered but hidden when no data */}
          <div style={{ display: annotatedPDFData ? 'block' : 'none' }}>
            <AnnotatedPdfControls
              handleZoom={handleZoom}
              zoomLevel={zoomLevel}
              currentZoomPercentage={currentZoomPercentage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              maxPages={maxPages}
              disableControls={annotatedPDFData?.isProcessing || false}
            />
            <AnnotatedPDF
              {...(annotatedPDFData || {})}
              maxPages={maxPages}
              setMaxPages={setMaxPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              zoomLevel={zoomLevel}
              setZoomLevel={setZoomLevel}
            />
          </div>

          {/* The fallback content stays rendered but hidden when data exists */}
          <div
            className="flex-1"
            style={{
              display: annotatedPDFData ? 'none' : 'block',
            }}
          >
            <div
              id="selected-image-node-content-container"
              className="w-full flex justify-center items-center dotted-bg relative"
            >
              {!isFirst && !hasSelectedAction ? (
                <IconButton
                  className="!bg-white !mr-6"
                  onClick={() => {
                    if (prevNodeId) {
                      validateSetSelectedNode(prevNodeId);
                      setSearchParams({ focusNode: prevNodeId });
                    }
                  }}
                >
                  <ChevronLeft />
                </IconButton>
              ) : (
                <div className="w-[40px] mr-6" role="none" />
              )}
              <div className="relative !w-4/5">
                {isAdmin ? (
                  <span className="absolute left-1/2 -top-12 -translate-x-1/2 text-sm font-semibold bg-white px-3 py-1 rounded-lg min-w-max">
                    {selectedNode.id}
                  </span>
                ) : null}
                <SelectedImageNodeContent
                  className="!w-full max-h-[80vh] h-auto"
                  nodeData={selectedNode.data}
                />
              </div>
              {!isLast && !hasSelectedAction ? (
                <IconButton
                  className="!bg-white !ml-6"
                  onClick={() => {
                    if (nextNodeId) {
                      validateSetSelectedNode(nextNodeId);
                      setSearchParams({ focusNode: nextNodeId });
                    }
                  }}
                >
                  <ChevronRight />
                </IconButton>
              ) : (
                <div className="w-[40px] ml-6" role="none" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
