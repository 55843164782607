import {
  Button,
  Select,
  Input,
  Modal,
  Label,
  SubLabel,
  notify,
  AlertVariant,
  TrashIcon,
} from 'ui-kit';

import React, { useEffect, useState, useRef } from 'react';
import {
  type FormValues,
  type ContactModalOpenPayload,
  contactModalEventChannel,
  defaultFormValue,
  problemTypes,
} from '../utils/contactModal';
import { CONTACT_SLACK_CHANNEL_ID } from '../utils/constants';
import { useSendSlackMessageWithFile } from '../hooks/useSendSlackMessageWithFile';

export default function ContactModal() {
  const uploaderRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>(defaultFormValue);
  const [inputKey, setInputKey] = useState('initial');
  const [workflowData, setWorkflowData] = useState<ContactModalOpenPayload>(
    {} as ContactModalOpenPayload,
  );

  const { workflowId, workflowName } = workflowData;

  const { mutateAsync: sendMessage, status: sendMessageStatus } =
    useSendSlackMessageWithFile();

  const setFormValue = (key: string, value: string | boolean) => {
    setFormValues((form) => ({
      ...form,
      [key]: value,
    }));
  };
  // const { channelId, text, workflowId, fileBase64, fileName } = req.body;

  const onSubmit = async () => {
    if (workflowId) {
      await sendMessage({
        workflowId,
        channelId: CONTACT_SLACK_CHANNEL_ID,
        text: `Type: ${formValues.problemType} | Description: ${formValues.description}`,
        fileBase64: formValues.file,
        fileName: formValues.fileName,
      });
      setFormValues(defaultFormValue);
      setOpen(false);
      contactModalEventChannel.emit('submit');
    }
  };

  const openUpload = () => uploaderRef.current?.click();

  const onDeleteFile = () => {
    setFormValues((form) => ({
      ...form,
      file: '',
      fileName: '',
    }));

    // Re-render the input component to allow new files to be uploaded
    setInputKey(new Date().toISOString());
  };

  const initiateUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const maxFileSize = 5 * 1024 * 1024; // 5 MB

      if (file.size > maxFileSize) {
        notify({
          message: 'Image size exceeds 5 MB limit.',
          variant: AlertVariant.WARNING,
        });
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        setFormValues((form) => ({
          ...form,
          file: base64,
          fileName: file.name,
        }));
      };
      reader.onerror = () => {
        notify({
          message: 'Error while uploading file.',
          variant: AlertVariant.WARNING,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const unsubscribe = contactModalEventChannel.on(
      'open',
      ({ problemType = 'General Issue', ...data }) => {
        setOpen(true);
        setFormValue('problemType', problemType);
        setWorkflowData(data);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const isCustomProblem = !problemTypes.includes(formValues.problemType);

  return (
    <Modal
      className="flex item-center justify-items-center max-w-[62rem]"
      onClose={() => {
        setOpen(false);
        setFormValues(defaultFormValue);
      }}
      open={open}
      enableEventBubbling
    >
      <div className="text-sm flex flex-row gap-20">
        <div className="flex-1 flex flex-col">
          <p className="font-medium mb-7 text-black">Contact support</p>
          <p className="font-medium mb-2.5 text-lg text-black">
            Contact support
          </p>
          <p className="text-gray-600">
            Send us a message if you are experiencing issues with actions or
            looking for any additional functionality! We will be in touch within
            24 hours.
          </p>
        </div>
        <div className="flex-1 max-w-md">
          <div className="flex flex-col gap-2 mt-14">
            {workflowName ? (
              <>
                <Label>Workflow</Label>
                <SubLabel>{workflowName}</SubLabel>
              </>
            ) : (
              <>
                <Label>Workflow</Label>
                <SubLabel>{workflowId}</SubLabel>
              </>
            )}
          </div>

          {!isCustomProblem ? (
            <div className="flex flex-col mt-8">
              <Select
                classes={{ select: '!py-4' }}
                getLabel={(opt: string) => opt}
                getValue={(opt: string) => opt}
                label="Problem type"
                labelId="template-select-variable-source"
                onChange={(e) => {
                  setFormValue('problemType', e.target.value);
                }}
                options={problemTypes}
                value={formValues.problemType}
              />
            </div>
          ) : (
            <div className="flex flex-col gap-2 mt-8">
              <Label>Reason</Label>
              <SubLabel>{formValues.problemType}</SubLabel>
            </div>
          )}

          {!isCustomProblem ? (
            <div className="relative flex flex-col mt-11">
              <p className="mb-5 text-black">
                Upload a screenshot/image of your issue
              </p>
              {formValues.file ? (
                <Button
                  endIcon={<TrashIcon className="!ml-auto text-red-500" />}
                  className="h-9"
                  color="error"
                  variant="outlined"
                  onClick={onDeleteFile}
                >
                  <span className="truncate w-md">
                    Delete {formValues.fileName}
                  </span>
                </Button>
              ) : (
                <Button
                  className="w-40 h-9"
                  color="secondary"
                  variant="outlined"
                  onClick={openUpload}
                >
                  UPLOAD IMAGE
                </Button>
              )}
            </div>
          ) : null}

          <Input
            classes={{ wrapper: 'flex flex-col mt-10' }}
            floatingLabel
            label="Description"
            multiline
            onChange={(description: string) => {
              setFormValue('description', description);
            }}
            placeholder="Describe your issue in detail"
            rows={5}
            value={formValues.description}
          />

          <div className="flex flex-row gap-7 mt-10">
            <Button
              className="w-40 h-9"
              color="secondary"
              loading={sendMessageStatus === 'pending'}
              onClick={onSubmit}
              variant="contained"
            >
              SUBMIT
            </Button>
            <Button
              className="h-9"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
              variant="text"
            >
              CANCEL
            </Button>
          </div>
          <input
            className="hidden"
            key={inputKey}
            onChange={initiateUpload}
            ref={uploaderRef}
            type="file"
            accept="image/jpeg, image/png, image/gif, image/svg+xml, image/webp"
          />
        </div>
      </div>
    </Modal>
  );
}
