import type { StateCreator } from 'zustand';
import { produce } from 'immer';

export interface TriggerStateData {
  apiSettings: {
    retryInterval: number | null;
    maxAttempts: number | null;
  };
}

const defaultApiSettings = {
  retryInterval: null,
  maxAttempts: null,
};

export const initialTriggerState: TriggerStateData = {
  apiSettings: defaultApiSettings,
};

export interface TriggerStateActions {
  setRetryInterval: (retryInterval: number | null) => void;
  setMaxAttempts: (maxAttempts: number | null) => void;
  resetApiSettings: () => void;
}

export const TriggerState: StateCreator<
  TriggerStateData & TriggerStateActions
> = (set) => ({
  ...initialTriggerState,
  setRetryInterval: (retryInterval: number | null) => {
    set((state) =>
      produce(state, (draft) => {
        draft.apiSettings.retryInterval = retryInterval;
      }),
    );
  },
  setMaxAttempts: (maxAttempts: number | null) => {
    set((state) =>
      produce(state, (draft) => {
        draft.apiSettings.maxAttempts = maxAttempts;
      }),
    );
  },
  resetApiSettings: () => {
    set({ apiSettings: defaultApiSettings });
  },
});
