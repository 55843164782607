import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function ArrowDownLeftIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      className="!fill-none"
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2501 10.417L7.08341 14.5837M7.08341 14.5837L2.91675 10.417M7.08341 14.5837V8.08382C7.08341 7.1504 7.08341 6.68334 7.26507 6.32682C7.42486 6.01322 7.67964 5.75844 7.99324 5.59865C8.34976 5.41699 8.81682 5.41699 9.75024 5.41699H17.9169"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
