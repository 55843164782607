import { clsx } from 'clsx';
import { useMemo } from 'react';
import { Button, FileIconAlt } from 'ui-kit';

const descriptionBreakLength = 700;

const truncateDesc = (desc: string) => {
  return desc.length > descriptionBreakLength
    ? `${desc.slice(0, descriptionBreakLength - 3)}...`
    : desc;
};

export enum OutputItemActions {
  DOWNLOAD = 'download',
  COPY = 'copy',
}

export interface OutputItem {
  id: string;
  title: string;
  description?: string | object | null;
  onDownloadLinkData?: (url: string) => void;
  action: OutputItemActions;
  uri?: string;
  type: string;
}

interface Props {
  isOutput: boolean;
  outputItem: OutputItem;
  setPreviewString: (value: string) => void;
  setPreviewTitle: (value: string) => void;
  isJson: boolean;
}

export default function Description({
  isOutput,
  outputItem,
  setPreviewString,
  setPreviewTitle,
  isJson,
}: Props) {
  const { action, description, title } = outputItem;
  const canPreview = useMemo(
    () =>
      (isJson && typeof outputItem.description === 'string') ||
      (outputItem.action === OutputItemActions.COPY &&
        typeof outputItem.description === 'string' &&
        outputItem.description.length > descriptionBreakLength),
    [outputItem.action, outputItem.description, isJson],
  );

  const hasNoDescription =
    typeof description === 'undefined' ||
    (typeof description === 'string' && !description.length);

  const truncatedDescription = useMemo(() => {
    if (typeof description !== 'string') {
      return null;
    }
    return truncateDesc(description);
  }, [description]);

  return (
    <div className="py-4">
      <div
        className={clsx({
          'flex flex-row items-center text-info-dark': true,
          'mb-2': canPreview,
        })}
      >
        {action === OutputItemActions.DOWNLOAD ? (
          <FileIconAlt style={{ width: 30 }} />
        ) : null}{' '}
        <span
          className={
            hasNoDescription ? 'text-gray-500 italic' : 'break-word-span'
          }
        >
          {truncatedDescription}

          {hasNoDescription ? 'No value provided' : null}
        </span>
      </div>
      {canPreview && typeof description === 'string' ? (
        <Button
          className="my-2"
          color="secondary"
          variant="outlined"
          onClick={() => {
            setPreviewString(description);
            setPreviewTitle(title);
          }}
        >
          PREVIEW {isOutput ? 'OUTPUT' : 'INPUT'}
        </Button>
      ) : null}
    </div>
  );
}
