import { sendToBackgroundViaRelay } from '@plasmohq/messaging';
import { axios, type AxiosResponse } from 'api-types-shared';
import type { ExtensionData } from 'types-shared';

export const getExtensionData = (): Promise<ExtensionData | null> => {
  return new Promise((resolve) => {
    let resolveFn: CallableFunction | null = resolve;

    setTimeout(() => {
      if (resolveFn) {
        resolveFn(null);
        resolveFn = null;
      }
    }, 4000);
    void (sendToBackgroundViaRelay as typeof sendToBackgroundViaRelayType)({
      name: 'onRecordingImport.handler',
    }).then((res) => {
      if (resolveFn) {
        resolveFn(res as ExtensionData | null);
        resolveFn = null;
      }
    });
  });
};

export const uploadBlobToS3 = async (
  blob: Blob,
  url: string,
): Promise<AxiosResponse> => {
  return axios.put(url, blob, {
    headers: {
      'Content-Type': blob.type,
      'Content-Length': blob.size.toString(),
    },
  });
};
