import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function Logo(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      width="96"
      height="96"
      viewBox="0 0 96 96"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9059 8.10122C30.6928 0.730601 39.7349 0.464483 47.9171 5.60445C56.0992 10.7444 61.6582 23.1104 53.4795 29.3602C45.3009 35.6101 30.5038 36.2726 23.5895 31.1734C16.6752 26.0741 19.1191 15.4718 24.9059 8.10122Z"
        fill="url(#paint0_linear_3_30)"
      />
      <path
        d="M25.6668 62.3518C22.632 49.359 31.2207 40.739 39.5915 39.7882C47.4112 38.8999 60.9168 41.5788 59.5995 58.5952C57.9938 79.3377 50.5362 95.6681 43.7687 95.6738C37.0012 95.6794 30.1326 81.4709 25.6668 62.3518Z"
        fill="url(#paint1_linear_3_30)"
      />
      <path
        d="M60.227 35.9866C61.092 34.55 61.5001 31.8726 66.106 31.8153C77.3389 31.6756 96.7006 61.822 92.853 65.687C89.0054 69.5519 59.3621 37.4231 60.227 35.9866Z"
        fill="url(#paint2_linear_3_30)"
      />
      <path
        d="M20.2863 42.9646C18.6448 42.0857 16.7751 39.7965 12.1932 42.2336C1.01907 48.1772 -1.59599 86.9081 4.32246 88.4505C10.2409 89.9928 21.9278 43.8436 20.2863 42.9646Z"
        fill="url(#paint3_linear_3_30)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_30"
          x1="22.8539"
          y1="3.53119"
          x2="53.3861"
          y2="42.9903"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.125" stopColor="#9D7277" />
          <stop offset="1" stopColor="#6F717A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3_30"
          x1="29.5"
          y1="45"
          x2="61.8718"
          y2="87.2966"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.125" stopColor="#9D7277" />
          <stop offset="1" stopColor="#6F717A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3_30"
          x1="59"
          y1="34.5"
          x2="91.8856"
          y2="66.0778"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.125" stopColor="#9D7277" />
          <stop offset="1" stopColor="#6F717A" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3_30"
          x1="16"
          y1="40"
          x2="23.948"
          y2="88.0912"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.125" stopColor="#9D7277" />
          <stop offset="1" stopColor="#6F717A" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
