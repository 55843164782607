import { useEffect, useState, useRef, useMemo } from 'react';
import { VncScreen } from 'react-vnc';

import type { SessionInfo } from 'selenium-gql';
import { SeleniumGQLClient } from 'selenium-gql';
import { useGetSeleniumAddress } from '../hooks';
import { handleException } from 'sentry-browser-shared';

interface LiveViewProps {
  sessionId: string;
  userId: string;
}

function DefaultView({ text }: { text: string }) {
  return (
    <div className="!min-h-[30rem] flex items-center justify-center">
      {text}
    </div>
  );
}

export function LiveView({ sessionId, userId }: LiveViewProps) {
  const canvasRef = useRef(null);
  const [vncUrl, setVncUrl] = useState<string>();

  const [fetchingSession, setFetchingSession] = useState<boolean>(true);
  const [sessionInfo, setSessionInfo] = useState<SessionInfo>();

  const { data: seleniumAddress, loading } = useGetSeleniumAddress(userId);

  const gqlClient = useMemo(() => {
    if (loading) {
      return null;
    } else if (!seleniumAddress) {
      handleException(new Error(), {
        name: 'Selenium Address not found in LiveView',
        source: 'Execution/LiveView',
        extra: {
          userId,
          seleniumAddress,
          sessionId,
        },
      });
      return null;
    }
    return new SeleniumGQLClient(`${seleniumAddress}/graphql`);
  }, [seleniumAddress, loading, userId, sessionId]);

  useEffect(() => {
    if (!gqlClient) {
      return;
    }
    try {
      setFetchingSession(true);
      const fetchSessionInfo = async () => {
        const resp = await gqlClient.querySessionInfo(sessionId);
        setSessionInfo(resp.session);
      };
      void fetchSessionInfo();
    } catch (e) {
      handleException(e, {
        name: 'Session info fetch failed in LiveView',
        source: 'Execution/LiveView',
        extra: {
          sessionId,
        },
      });
    } finally {
      setFetchingSession(false);
    }
  }, [gqlClient, sessionId]);

  useEffect(() => {
    if (!sessionInfo) {
      return;
    }
    const capabilities = JSON.parse(sessionInfo.capabilities) as {
      'se:vnc': string;
    };
    const vnc = capabilities['se:vnc'];
    if (!vnc) {
      throw new Error('Vnc not found');
    }
    if (!seleniumAddress) {
      handleException(new Error(), {
        name: 'Selenium Address not found in LiveView',
        source: 'Execution/LiveView',
        extra: {
          sessionId,
          seleniumAddress,
          userId,
          sessionInfo,
          loading,
        },
      });
      return;
    }
    const url = new URL(seleniumAddress);
    const newVncUrl = new URL(vnc);
    url.pathname = newVncUrl.pathname;
    url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:';
    setVncUrl(url.href);
  }, [seleniumAddress, sessionId, sessionInfo, userId, loading]);

  if (fetchingSession) {
    return <DefaultView text="Loading..." />;
  }

  if (!sessionInfo) {
    return <DefaultView text="No active Session found" />;
  }

  return vncUrl ? (
    <VncScreen
      background="#000000"
      ref={canvasRef}
      scaleViewport
      style={{
        width: '100%',
        height: '100%',
      }}
      url={vncUrl}
    />
  ) : (
    <DefaultView text="Loading..." />
  );
}
