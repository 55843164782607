import { clsx } from 'clsx';
import Skeleton from '@mui/material/Skeleton';

export function LoadingManualRun() {
  return (
    <div className={clsx('flex flex-col mt-8')}>
      <Skeleton variant="text" className="w-4/12 mb-10" />

      <div>
        <Skeleton height={56} className="w-10/12" />
        <Skeleton height={56} className="w-8/12" />
        <Skeleton height={56} className="w-6/12" />
      </div>
    </div>
  );
}
