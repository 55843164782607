import * as React from 'react';
import { useState, useRef, useCallback } from 'react';
import {
  type DocumentVariable,
  type Variable,
  type VariableMap,
  type WorkflowAction,
  type WorkflowImageNode,
} from 'types-shared/workflowTypes';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, notify, AlertVariant } from 'ui-kit';
import { useUploadFiles } from '../../../hooks';
import { EditorStore } from '../../../store/EditorState';

interface DocumentVariablesProps {
  fileUrl?: string;
  variable: Variable;
  variablesMap: VariableMap;
  globalVariablesMap: VariableMap;
  onPreviewVariable: () => void;
  workflowId: string;
  action: WorkflowAction;
  node: WorkflowImageNode;
  fetchingFile: boolean;
}

export default function DocumentVariablesNoFile({
  variable,
  workflowId,
  fetchingFile,
}: DocumentVariablesProps) {
  const uploaderRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [inputKey, setInputKey] = useState('initial');
  const isLoading = fetchingFile || loading;

  const {
    // variables: variablesMap,
    // globalVariables: globalVariablesMap = {},
    updateVariable,
    // updateNode,
  } = EditorStore();

  const resetUpload = () => {
    // Re-render the input component to allow new files to be uploaded
    setInputKey(new Date().toISOString());
  };

  const { mutateAsync: uploadFiles } = useUploadFiles();

  const onUploadFile = useCallback(
    async (file: File) => {
      try {
        setLoading(true);

        const fileIds = await uploadFiles({
          files: [file],
          workflowId,
        });
        notify({
          message: 'File upload successful',
          variant: AlertVariant.SUCCESS,
        });
        setLoading(false);

        resetUpload();
        return { fileId: fileIds[0] };
      } catch (error) {
        setLoading(false);
        const actualErr = error as Error;
        notify({
          message: actualErr.message,
          variant: AlertVariant.ERROR,
        });
      }
    },
    [uploadFiles, workflowId],
  );

  const openUpload = () => uploaderRef.current?.click();

  const initiateUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        const file = e.target.files[0];
        const maxFileSize = 5 * 1024 * 1024; // 5 MB

        if (file.size > maxFileSize) {
          notify({
            message: 'PDF size exceeds 5 MB limit.',
            variant: AlertVariant.WARNING,
          });
          return;
        }

        const uploadResult = await onUploadFile(file);

        if (uploadResult?.fileId) {
          updateVariable({
            ...variable,
            data: {
              ...variable.data,
              s3Ref: {
                fileId: uploadResult.fileId,
                fileName: file.name,
              },
            },
          } as DocumentVariable);
        }
      }
    } catch (error) {
      const actualErr = error as Error;
      notify({
        message: actualErr.message,
        variant: AlertVariant.ERROR,
      });
    }
  };

  return (
    <div>
      <div className="flex flex-col mt-7 border border-[#BDCAD5] rounded-lg">
        <div className="p-6 pb-8 flex flex-col">
          <span className="text-sm font-medium text-[#103D61] pb-5">
            Upload an example document to extract data
          </span>
          <span className="text-sm text-[#7A859C]">
            By uploading an example document, you’ll be able to extract data and
            create variables from the content to be used later in the workflow
            or as outputs.
          </span>
          <div className="pt-5 pb-3">
            <Button
              variant="contained"
              startIcon={isLoading ? undefined : <UploadFileIcon />}
              className="h-9 w-[13rem]"
              color="secondary"
              onClick={openUpload}
              loading={isLoading}
            >
              {isLoading ? '' : 'UPLOAD DOCUMENT'}
            </Button>
            <input
              className="hidden"
              key={inputKey}
              onChange={initiateUpload}
              ref={uploaderRef}
              type="file"
              accept="application/pdf, image/png, image/jpeg"
            />
          </div>
          <span className="text-xs text-[#7A859C]">
            Supported formats: PDF, PNG, JPEG, DOCX
          </span>
        </div>
      </div>
    </div>
  );
}
