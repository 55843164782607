import { useEffect, useMemo, useRef, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useGetOriginalImageData } from '../../hooks';
import type { EditorStoreProps } from '../../store/EditorState';
import { EditorStore } from '../../store/EditorState';
import { getNodeActions } from 'types-shared';
import type { NodeData } from 'types-shared/workflowTypes';
import type { EventMap } from 'ui-kit';
import { createEventBus, Spinner } from 'ui-kit';
import { clsx } from 'clsx';
import { isAdmin } from '../../../../utils/env';
import useThrottle from '../../../../hooks/useThrottle';

interface ImageNodeEvents extends EventMap {
  onActionHover: (targetId: string | null) => void;
  onTargetHover: (targetId: string | null) => void;
}

export const imageNodeEventChannel = createEventBus<ImageNodeEvents>();

interface Props {
  nodeData: NodeData;
  className?: string;
}

export default function SelectedImageNodeContent({
  nodeData,
  className,
}: Props) {
  const { imageData, actionData, actionOrder } = nodeData;
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const [resizingId, setResizingId] = useState<string | null>(null);
  const [offset, setOffset] = useState<{ x: number; y: number } | null>(null);
  const [initialResize, setInitialResize] = useState<{
    mouseX: number;
    mouseY: number;
    width?: number;
    height?: number;
  } | null>(null);

  const imgRef = useRef<HTMLImageElement>(null);
  const actions = useMemo(
    () =>
      getNodeActions({
        actionOrder,
        actionData,
        isAdmin,
      }),
    [actionData, actionOrder],
  );

  const { selectedNode, targets, workflowId, setNodeImage, updateTarget } =
    EditorStore(
      useShallow((state: EditorStoreProps) => ({
        selectedNode: state.selectedNode,
        targets: state.targets,
        workflowId: state.workflowId,
        setNodeImage: state.setImage,
        updateTarget: state.updateTarget,
      })),
    );
  const targetsArr = useMemo(() => Object.values(targets), [targets]);
  const [activeTarget, setActiveTarget] = useState<string | null>(null);

  const { data: fullImageData, status } = useGetOriginalImageData(
    workflowId,
    imageData.imageId,
    true,
  );

  const loading = status === 'pending';

  useEffect(() => {
    const unsubscribeActionHover = imageNodeEventChannel.on(
      'onActionHover',
      setActiveTarget,
    );

    return () => {
      unsubscribeActionHover();
    };
  }, []);

  useEffect(() => {
    if (!selectedNode) return;

    if (fullImageData) {
      setNodeImage(nodeData.imageData.imageId, fullImageData);
    }
  }, [selectedNode, setNodeImage, fullImageData, nodeData.imageData.imageId]);

  const handleMouseUp = () => {
    setDraggingId(null);
    setOffset(null);
    setResizingId(null);
    setInitialResize(null);
  };

  const onTargetLeave = () => {
    imageNodeEventChannel.emit('onTargetHover', null);
  };

  const onTargetHover = (targetId: string | null) => {
    imageNodeEventChannel.emit('onTargetHover', targetId);
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const handleMouseMove = useThrottle((e: React.MouseEvent) => {
    if (!isAdmin) return;

    if (resizingId !== null && initialResize && imgRef.current) {
      const target = targetsArr.find((t) => t.id === resizingId);
      const imageRect = imgRef.current.getBoundingClientRect();

      if (!target) return;

      const expandedOffsetX = e.clientX - initialResize.mouseX;
      const expandedOffsetY = e.clientY - initialResize.mouseY;

      const newWidth = (initialResize.width ?? 0) + expandedOffsetX;
      const newHeight = (initialResize.height ?? 0) + expandedOffsetY;

      updateTarget({
        ...target,
        ref: {
          ...target.ref,
          coordinates: {
            ...target.ref.coordinates,
            width: newWidth,
            height: newHeight,
          },
        },
        coordinates: {
          ...target.coordinates,
          widthPercent: (newWidth / imageRect.width) * 100,
          heightPercent: (newHeight / imageRect.height) * 100,
        },
      });
    }

    if (draggingId !== null && offset && imgRef.current) {
      const imageRect = imgRef.current.getBoundingClientRect();
      const newLeft = e.clientX - offset.x;
      const newTop = e.clientY - offset.y;

      const xPercent = (newLeft / imageRect.width) * 100;
      const yPercent = (newTop / imageRect.height) * 100;

      const target = targetsArr.find((t) => t.id === draggingId);

      if (!target) return;
      updateTarget({
        ...target,
        ref: {
          ...target.ref,
          coordinates: {
            ...target.ref.coordinates,
            x: newLeft,
            y: newTop,
          },
        },
        coordinates: {
          ...target.coordinates,
          xPercent,
          yPercent,
        },
      });
    }
  }, 100);

  return (
    <div
      className={clsx(
        'w-64 h-64 rounded-lg show-scrollbar overflow-y-scroll',
        className,
        { 'bg-flow-view relative': loading },
        { 'border border-indigo-light': !loading },
      )}
    >
      <div
        className="relative h-fit w-full"
        onMouseMove={handleMouseMove}
        role="presentation"
      >
        {loading ? (
          <div className="w-full h-64 z-[6] flex items-center justify-center">
            <Spinner size={36} />
          </div>
        ) : (
          <img
            alt=""
            className={clsx('min-h-fit w-full', {
              'h-64 z-[6]': !fullImageData,
            })}
            onError={(e) => {
              e.currentTarget.classList.add(
                'h-64',
                'z-[6]',
                'absolute',
                'left-0',
                'right-0',
                'top-0',
                'bottom-0',
              );
            }}
            ref={imgRef}
            src={fullImageData ?? undefined}
          />
        )}
        {fullImageData
          ? actions.map(({ targetId = '', options }, i) => {
              if (options?.hidden === true || options?.terminal) return null;

              const target = targets[targetId];

              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if (!target) {
                return null;
              }

              const coordinates = target.coordinates;
              return (
                <div
                  className={clsx(
                    'absolute bg-[rgba(0,0,0,0.25)] border-2 border-gray-500 hover:!border-2 hover:!border-info hover:before:bg-info hover:bg-[rgba(36,107,250,0.25)]',
                    'before:content-[attr(data-before)] before:text-white before:text-[0.6rem] before:bg-gray-700 before:rounded-full',
                    'before:absolute rounded before:-top-2 before:-translate-x-1/3 before:-translate-y-1/3 before:-right-5 before:w-6 before:h-6 before:leading-none before:flex before:justify-center before:items-center',
                    {
                      '!border-2 !border-info before:bg-info bg-[rgba(36,107,250,0.25)]':
                        activeTarget === targetId,
                      'cursor-move': isAdmin,
                    },
                  )}
                  data-before={i + 1}
                  key={targetId}
                  onBlur={onTargetLeave}
                  onFocus={() => {
                    onTargetHover(targetId);
                  }}
                  onMouseDown={(e) => {
                    if (!isAdmin) return;

                    e.preventDefault();
                    e.stopPropagation();

                    if (imgRef.current) {
                      const imageRect = imgRef.current.getBoundingClientRect();
                      const targetRect =
                        e.currentTarget.getBoundingClientRect();

                      setDraggingId(targetId);
                      setOffset({
                        x: e.clientX - targetRect.left + imageRect.left,
                        y: e.clientY - targetRect.top + imageRect.top,
                      });
                    }
                  }}
                  onMouseLeave={onTargetLeave}
                  onMouseOver={() => {
                    onTargetHover(targetId);
                  }}
                  role="presentation"
                  style={{
                    left: `${coordinates.xPercent.toString()}%`,
                    top: `${coordinates.yPercent.toString()}%`,
                    width: `${coordinates.widthPercent.toString()}%`,
                    height: `${coordinates.heightPercent.toString()}%`,
                  }}
                >
                  {isAdmin ? (
                    <div
                      className="absolute bottom-0 right-0 w-2 h-2 bg-blue-500 cursor-[nwse-resize] transform translate-x-[-50%] translate-y-[50%] z-10"
                      onMouseDown={(event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        if (resizingId !== null) return;
                        setResizingId(target.id);
                        setInitialResize({
                          mouseX: event.clientX,
                          mouseY: event.clientY,
                          width: event.currentTarget.parentElement?.clientWidth,
                          height:
                            event.currentTarget.parentElement?.clientHeight,
                        });
                      }}
                      role="presentation"
                    />
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}
