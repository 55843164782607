export const primaryBlue = '#2C71F0';
export const primaryExtraLight = '#D8D8D8';
export const primaryLight = '#7F7F7F';
export const primary = '#191919';
export const primaryDark = '#000000';
export const primarySelected = 'rgba(33, 150, 243, 0.08)';
export const secondaryLight = 'rgba(49, 116, 250, 0.6)';
export const secondary = '#3174FA';
export const secondaryDark = '#0F4CD2';
export const error = '#E34545';
export const border = '#EEF1F4';
export const infoDark = '#0f3d61';
export const secondaryText = 'rgba(0, 0, 0, 0.60)';
export const cardStyles =
  'bg-white shadow rounded border-none overflow-x-hidden';
export const pageStyles =
  'w-full px-3 pb-12 overflow-scroll md:px-7 mx-auto max-w-7xl sm:px-4 md:px-8 scrollbar-hide';
