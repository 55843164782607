"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.recordingImportSchema = void 0;
var types_shared_1 = require("types-shared");
exports.recordingImportSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z.object({}),
    }),
    response: types_shared_1.ExtensionData.partial(),
};
