import { type WorkflowNodeProps } from 'types-shared';
import { DocumentIcon } from 'ui-kit';
import NodeElement from './index';
import { useEditingNodeId } from '../../hooks/useEditingNodeId';

export default function DocumentNode(workflowData: WorkflowNodeProps) {
  const { setEditingNodeId } = useEditingNodeId();
  return (
    <NodeElement
      allowBulkCheck
      showAddButton
      showEditButton
      workflowData={workflowData}
      onClick={() => {
        setEditingNodeId(workflowData.id);
      }}
    >
      <div className="flex-1 rounded-lg border border-indigo-light bg-gray-100 flex justify-center items-center">
        <DocumentIcon className="!h-12 !w-12 text-cyan-900" />
      </div>
      <span className="text-sm font-bold truncate">Documents step</span>
    </NodeElement>
  );
}
