import {
  Button,
  Flex,
  EditIconAlt as EditIcon,
  WarningAmberOutlined,
  Select,
  Tooltip,
} from 'ui-kit';
import React, { type ChangeEvent, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import {
  type WorkflowMetadataType,
  type WorkflowStatusEnum,
} from 'api-types-shared';
import type {
  CompactWorkflowMetadata,
  WorkflowStatusSelectItem,
} from '../types';
import { isAdmin } from '../../../utils/env';
import {
  workflowListStatusToTitleMap,
  workflowProcessingStatuses,
  workflowStatusSelectOptions,
} from '../../../utils/constants';
import { useUpdateWorkflowStatus } from '../hooks';
import WorkflowChip from './Chips/WorkflowChip';
import StatusLabel from './StatusLabel';
import { NodeStatusEnum, NodeTypesEnum } from 'types-shared';
import { useGetWorkflowData } from '../../Editor/hooks';

export default function WorkflowDetailHeader({
  isEditingName,
  workflowName,
  inputRef,
  setIsEditingName,
  editWorkflowEnabled,
  workflowMetadata,
  workflowId,
  updateWorkflowName,
  onNameChange,
  setCloneWorkflowData,
  workflowProcessing,
  workflowVersionId,
  hasErrorPush,
  hasErrorForcePush,
}: {
  isEditingName: boolean;
  hasErrorPush: boolean;
  hasErrorForcePush: boolean;
  workflowName: string;
  editWorkflowEnabled: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  setIsEditingName: React.Dispatch<React.SetStateAction<boolean>>;
  workflowMetadata: WorkflowMetadataType;
  workflowId: string;
  updateWorkflowName: () => Promise<void>;
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setCloneWorkflowData: React.Dispatch<
    React.SetStateAction<CompactWorkflowMetadata | null>
  >;
  workflowProcessing: boolean;
  workflowVersionId: string;
}) {
  const { data: workflowData, refetch: fetchWorkflowData } = useGetWorkflowData(
    workflowId,
    false,
    true,
    undefined,
    'detail-header',
  );

  useEffect(() => {
    if (workflowId && workflowVersionId && !workflowData) {
      void fetchWorkflowData();
    }
  }, [workflowId, workflowVersionId, workflowData, fetchWorkflowData]);

  const navigate = useNavigate();
  const { mutate: updateWorkflowMetadata, status: workflowUpdateStatus } =
    useUpdateWorkflowStatus();

  const workflowProcessingAndInaccessible = useMemo(() => {
    if (!workflowMetadata.status) return false;

    return (
      !isAdmin &&
      workflowProcessingStatuses.processingAndInaccessible.includes(
        workflowMetadata.status,
      )
    );
  }, [workflowMetadata.status]);

  const finishedDetails = useMemo(() => {
    const payload: {
      finishedSteps: number;
      totalSteps: number;
      statusTitle?: WorkflowStatusEnum;
    } = {
      finishedSteps: 0,
      totalSteps: 0,
    };

    if (workflowData) {
      const workflowNodes = workflowData.nodes.filter(
        (n) => n.type !== NodeTypesEnum.Source,
      );

      payload.finishedSteps = workflowNodes.filter(
        (node) => node.data.nodeStatus === NodeStatusEnum.Checked,
      ).length;
      payload.totalSteps = workflowNodes.length;

      if (workflowMetadata.status) {
        const workflowTitle = isAdmin
          ? workflowMetadata.status
          : workflowListStatusToTitleMap[workflowMetadata.status] ??
            workflowMetadata.status;
        payload.statusTitle = workflowTitle;
      }
    }
    return payload;
  }, [workflowData, workflowMetadata.status]);

  return (
    <>
      <div>
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Flex alignItems="flex-end" className="flex-1">
            <EditIcon
              className="text-indigo-light hover:text-color-grey mb-2 mr-1 cursor-pointer !fill-none"
              onClick={() => {
                setIsEditingName((e) => !e);
              }}
            />
            <input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={isEditingName}
              style={{ width: `${workflowName.length.toString()}ch` }}
              className={clsx(
                'border-b-2 border-transparent placeholder:text-indigo-light text-4xl font-bold focus:outline-0 !text-ellipsis',
                'max-w-[700px] min-w-[250px]',
                {
                  'text-blue-500 !border-primary-blue': isEditingName,
                },
              )}
              onBlur={updateWorkflowName}
              onChange={onNameChange}
              onClick={() => {
                setIsEditingName(true);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  void updateWorkflowName();
                }
              }}
              placeholder={workflowMetadata.workflowName}
              readOnly={!isEditingName}
              ref={inputRef}
              value={workflowName}
            />
          </Flex>
          <div className="flex space-x-2">
            {isAdmin ? (
              <Select<WorkflowStatusSelectItem>
                classes={{
                  root: '!min-h-10',
                  select: '!py-0 !-mb-2',
                }}
                disabled={workflowUpdateStatus === 'pending'}
                getLabel={(option) => option.label}
                getValue={(option) => option.value}
                onChange={(event) => {
                  updateWorkflowMetadata({
                    ...workflowMetadata,
                    status: event.target.value as WorkflowStatusEnum,
                  });
                }}
                options={workflowStatusSelectOptions}
                value={
                  workflowMetadata.status as unknown as WorkflowStatusSelectItem
                }
              />
            ) : null}
            {isAdmin ? (
              <Button
                color="secondary"
                onClick={() => {
                  setCloneWorkflowData({
                    workflowId: workflowMetadata.workflowId,
                    workflowName: workflowMetadata.workflowName,
                  });
                }}
                variant="outlined"
              >
                Clone workflow
              </Button>
            ) : null}
            {editWorkflowEnabled ? (
              <Tooltip
                hidden={!workflowProcessingAndInaccessible}
                title="You will be able to view the workflow once it finishes processing"
                arrow
                placement="right"
              >
                <div>
                  <Button
                    color="secondary"
                    onClick={() => {
                      navigate(`/editor/${workflowId}`);
                    }}
                    variant="contained"
                    disabled={workflowProcessingAndInaccessible}
                  >
                    Edit workflow
                  </Button>
                </div>
              </Tooltip>
            ) : null}
          </div>
        </Flex>
        {isAdmin && workflowMetadata.email ? (
          <div>Email: {workflowMetadata.email}</div>
        ) : null}
      </div>

      {(hasErrorPush || hasErrorForcePush) && !isAdmin ? (
        <div className="mt-4 bg-[#FFF4E5] flex space-x-2 px-6 py-4 rounded">
          <WarningAmberOutlined className="!w-5 !h-5 !mt-0.5 text-warning" />
          <div className="flex flex-col space-y-1">
            <span className="text-[#663C00] text-base font-medium">
              {hasErrorForcePush
                ? 'Automated enhancements were added to workflow'
                : 'Prevent failing executions by applying improvements to your workflow.  '}
            </span>
            <span className="text-sm text-[#663C00]">
              {hasErrorForcePush ? (
                <>
                  Our system detected issues in this workflow that are leading
                  to failures and has successfully applied enhancements to
                  prevent them.
                  <br /> Explore the updates that were implemented to proceed
                  seamlessly.
                </>
              ) : (
                <>
                  Our system has detected issues in this workflow that are
                  leading to failures and has found some improvements that could
                  prevent failures. <br /> We recommend applying these
                  enhancements. Review, then accept or reject the changes
                  recommended by Sola to continue.
                </>
              )}
            </span>
          </div>
          <Button
            className="!ml-auto !my-auto !max-h-max"
            color="secondary"
            onClick={() => {
              if (workflowId) {
                navigate(`/editor/${workflowId}`);
              }
            }}
            variant="contained"
          >
            Review improvements
          </Button>
        </div>
      ) : null}

      <div className="mt-5 mb-10">
        {workflowMetadata.status && finishedDetails.statusTitle ? (
          <WorkflowChip
            className={clsx('!h-6 !overflow-x-hidden', {
              '!bg-info-dark': workflowProcessing && !isAdmin,
            })}
            classes={{
              label: '!overflow-hidden !max-w-full',
            }}
            status={workflowMetadata.status}
            label={
              <StatusLabel
                showExtraInfo
                showProcessingState={!isAdmin}
                processedCount={`${(finishedDetails.totalSteps - finishedDetails.finishedSteps).toString()} of ${finishedDetails.totalSteps.toString()} steps `}
                workflowStatus={finishedDetails.statusTitle}
                onlyOneStepLeft={
                  finishedDetails.totalSteps - finishedDetails.finishedSteps ===
                  1
                }
              />
            }
            variant="filled"
          />
        ) : null}
      </div>
    </>
  );
}
