import type { AxiosResponse } from 'api-types-shared';
import { axios } from 'api-types-shared';

export const uploadBlobToS3 = async (
  blob: Blob,
  url: string,
): Promise<AxiosResponse> => {
  return axios.put(url, blob, {
    headers: {
      'Content-Type': blob.type,
      'Content-Length': blob.size.toString(),
    },
  });
};

export const getUrlFromBlob = (blob: Blob): string => URL.createObjectURL(blob);

export const getBlobFromS3 = async (url: string): Promise<Blob> => {
  const response = await axios.get(url, {
    responseType: 'blob',
  });
  if (response.status !== 200) {
    throw new Error(`Failed to get blob from S3: ${response.statusText}`);
  }
  return new Blob([response.data]);
};
