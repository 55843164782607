import AnnotatedPDF, {
  AnnotatedPdfControls,
  type RenderPdfProps,
} from '../../../../../../Editor/components/ActionsManager/DocumentVariables/AnnotatedPDF';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { values } from 'lodash';
import {
  QueryVariable,
  type VariableMap,
  type DocumentVariable,
} from 'types-shared';
import { VariableChip } from '../../../../../../../components/VariableChip';
import {
  Button,
  GrayedOutInput,
  InfoOutlined,
  CloseIcon,
  IconButton,
  Logo,
} from 'ui-kit';
import { contactModalEventChannel } from '../../../../../../../utils/contactModal';
import { useUnzipExecutionFile } from '../../../../../hooks';

interface DocumentVariableFullScreenProps {
  onClose: () => void;
  variable: DocumentVariable;
  variableMap: VariableMap;
  workflowId: string;
  executionId: string;
  executionName?: string;
  artifacts?: {
    variableId: string | null;
    mediaType: string;
    s3Key: string;
    uri: string;
  }[];
}

function DocumentVariableFullScreen({
  onClose,
  variable,
  variableMap,
  workflowId,
  executionId,
  executionName,
  artifacts,
}: DocumentVariableFullScreenProps) {
  const [annotatedPDFData, setAnnotatedPDFData] = useState<RenderPdfProps>();
  const [zoomLevel, setZoomLevel] = useState(1.1);
  const [maxPages, setMaxPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVariable, setSelectedVariable] = useState<QueryVariable>();
  const lastFileUrl = useRef<string>();
  const [loadingPdf, setLoadingPdf] = useState(false);

  const { mutateAsync: unzipExecutionFile } = useUnzipExecutionFile();
  const handleZoom = useCallback((increase: boolean) => {
    setZoomLevel((prev) => {
      const step = 0.11;
      const newZoom = increase ? prev + step : prev - step;
      return newZoom >= 0.55 && newZoom <= 2.2 ? newZoom : prev;
    });
  }, []);

  const currentZoomPercentage = Math.round((zoomLevel / 1.1) * 100);

  const handleUnzipExecutionFile = useCallback(
    async (url: string) => {
      setLoadingPdf(true);
      try {
        const { fileName, fileBlob } = await unzipExecutionFile({ url });

        setAnnotatedPDFData({
          fileBlob,
          fileName,
          annotations: [],
          isProcessing: false,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error unzipping execution file:', error);
      } finally {
        setLoadingPdf(false);
      }
    },
    [unzipExecutionFile],
  );

  useEffect(() => {
    if (lastFileUrl.current) return;
    if (!artifacts?.length) return;

    const fileUri = artifacts.find(
      (artifact) => artifact.variableId === variable.id,
    )?.uri;
    lastFileUrl.current = fileUri;
    if (fileUri) {
      void handleUnzipExecutionFile(fileUri);
    }
  }, [variable, handleUnzipExecutionFile, artifacts]);

  const queryVariables = useMemo(
    () =>
      values(variableMap).filter(
        (_variable) =>
          QueryVariable.safeParse(_variable).success &&
          (_variable as QueryVariable).data.sourceIds.includes(variable.id),
      ) as QueryVariable[],
    [variableMap, variable],
  );

  const queryVariablesMap = useMemo(
    () =>
      queryVariables.reduce((acc: VariableMap, v) => {
        acc[v.id] = v;
        return acc;
      }, {}),
    [queryVariables],
  );

  useEffect(() => {
    if (queryVariables.length && !selectedVariable) {
      setSelectedVariable(queryVariables[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVariables]);

  const variableInstructions = useMemo(() => {
    const initialQuery = selectedVariable?.data.query[0] as string;
    const executionQuery = selectedVariable?.dashboardData?.transformInputs
      ?.query[0] as string;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return executionQuery ?? initialQuery ?? '';
  }, [selectedVariable]);

  const transformationResult = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (selectedVariable?.executionData?.transformedValue as string) ?? 'N/A',
    [selectedVariable],
  );

  return (
    <div className="fixed inset-0 bg-white border">
      <div className="h-16 w-full bg-white border-b border-gray-200 flex items-center justify-between px-6 z-50 relative">
        <div className="flex flex-row items-center gap-2">
          <Logo className="!w-7 !h-7" />
          <h2 className="ml-2 text-sm text-gray-500">
            {executionName ? `${executionName} / ` : ''}
            Execution details /{' '}
            <span className="text-info-dark">Document extract preview</span>
          </h2>
        </div>
        <IconButton onClick={onClose}>
          <CloseIcon className="text-info" />
        </IconButton>
      </div>

      <div className="flex flex-row " style={{ height: 'calc(100% - 4rem)' }}>
        <div className="w-125 overflow-y-auto flex flex-col justify-between bg-white relative border-r border-gray-200 h-max-[calc(100vh-4rem)]">
          <div className="overflow-y-auto">
            <div className="space-y-6 p-8 border-b border-gray-200 flex-1">
              <p className="text-sm font-medium text-info-dark">
                Document extract preview
              </p>
              <span className="text-sm text-gray-500">
                Preview the document and check the extracted values
              </span>
              <p className="text-xs font-medium text-info-dark">
                Values extracted
              </p>
              <div className="mb-2">
                {queryVariables.map((queryVar) => {
                  const isSelected = selectedVariable?.id === queryVar.id;
                  return (
                    <VariableChip
                      key={queryVar.id}
                      className={`!bg-secondary-purple my-1 mr-1 ${isSelected ? '!border-3 !border-secondary-purple-300' : ''}`}
                      style={{
                        border: isSelected
                          ? '4px solid #c880d4'
                          : '4px solid #ffffff',
                      }}
                      leftIconClassName="!hidden"
                      variableId={queryVar.id}
                      variablesMap={queryVariablesMap}
                      globalVariablesMap={{}}
                      onClick={() => {
                        setSelectedVariable(queryVar);
                      }}
                    />
                  );
                })}
              </div>
            </div>

            <div className="space-y-6 p-8">
              <p className="text-xs font-medium text-info-dark">
                Variable Preview
              </p>
              <GrayedOutInput
                value={selectedVariable?.name}
                label="Variable name"
                className="mb-2"
              />
              <GrayedOutInput
                value={variableInstructions}
                label="Instructions"
                className="mb-2"
              />
              <GrayedOutInput
                value={transformationResult}
                label="Example variable result"
              />
            </div>
          </div>

          <div className="p-8">
            <div className="mt-8 w-full bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded">
              <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
              <div>
                <p className="text-primary-blue-dark text-sm font-medium">
                  Something looks incorrect?
                </p>
                <p className="text-primary-blue-dark text-sm mb-5">
                  Help the model learn by notifying it when the extracted value
                  is not what you expect.
                </p>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="mt-5"
                  onClick={() => {
                    contactModalEventChannel.emit('open', {
                      problemType: `General Issue | Execution ID: ${executionId}`,
                      workflowId,
                    });
                  }}
                >
                  NOTIFY ON INCONSISTENCY
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center items-center dotted-bg relative border-gray-200">
          <AnnotatedPdfControls
            handleZoom={handleZoom}
            zoomLevel={zoomLevel}
            currentZoomPercentage={currentZoomPercentage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            maxPages={maxPages}
            disableControls={annotatedPDFData?.isProcessing ?? loadingPdf}
            fileName={
              annotatedPDFData?.fileName ?? variable.data.s3Ref?.fileName
            }
          />

          <AnnotatedPDF
            {...(annotatedPDFData ?? {})}
            maxPages={maxPages}
            setMaxPages={setMaxPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            zoomLevel={zoomLevel}
            setZoomLevel={setZoomLevel}
            loadingPdf={loadingPdf}
          />
        </div>
      </div>
    </div>
  );
}

export default DocumentVariableFullScreen;
