import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function ArrowUpLeftIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      className="!fill-none"
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4166 9.58366L6.24992 5.41699M6.24992 5.41699L2.08325 9.58366M6.24992 5.41699V11.917C6.24992 12.8504 6.24992 13.3168 6.43158 13.6733C6.59136 13.9869 6.84615 14.2424 7.15975 14.4022C7.51592 14.5837 7.98241 14.5837 8.91401 14.5837H17.0834"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
