"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultClientConfigsFromEnv = void 0;
var defaultClientConfigsFromEnv = function () {
    var _a;
    return {
        region: (_a = process.env.AWS_REGION) !== null && _a !== void 0 ? _a : 'us-east-1',
        endpoint: process.env.AWS_ENDPOINT_URL,
        credentials: {
            accessKeyId: String(process.env.AWS_ACCESS_KEY_ID),
            secretAccessKey: String(process.env.AWS_SECRET_ACCESS_KEY),
            sessionToken: process.env.AWS_SESSION_TOKEN,
        },
    };
};
exports.defaultClientConfigsFromEnv = defaultClientConfigsFromEnv;
