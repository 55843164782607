import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function CopyIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 6.5V4.16683C6.5 3.23341 6.5 2.76635 6.68166 2.40983C6.84144 2.09623 7.09623 1.84144 7.40983 1.68166C7.76635 1.5 8.23341 1.5 9.16683 1.5H13.8335C14.7669 1.5 15.2334 1.5 15.5899 1.68166C15.9035 1.84144 16.1587 2.09623 16.3185 2.40983C16.5002 2.76635 16.5002 3.23306 16.5002 4.16648V8.83317C16.5002 9.76659 16.5002 10.2333 16.3185 10.5898C16.1587 10.9034 15.9033 11.1587 15.5897 11.3185C15.2335 11.5 14.7675 11.5 13.8359 11.5H11.5M6.5 6.5H4.16683C3.23341 6.5 2.76635 6.5 2.40983 6.68166C2.09623 6.84144 1.84144 7.09623 1.68166 7.40983C1.5 7.76635 1.5 8.23341 1.5 9.16683V13.8335C1.5 14.7669 1.5 15.2334 1.68166 15.5899C1.84144 15.9035 2.09623 16.1587 2.40983 16.3185C2.766 16.5 3.23249 16.5 4.16409 16.5H8.83629C9.76789 16.5 10.2337 16.5 10.5899 16.3185C10.9035 16.1587 11.1587 15.9033 11.3185 15.5897C11.5 15.2335 11.5 14.7675 11.5 13.8359V11.5M6.5 6.5H8.8335C9.76692 6.5 10.2334 6.5 10.5899 6.68166C10.9035 6.84144 11.1587 7.09623 11.3185 7.40983C11.5 7.766 11.5 8.2325 11.5 9.16412L11.5 11.5"
        stroke="#103D61"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
