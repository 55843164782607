import type { LlmTransformResponse, ZodFetcher } from 'api-types-shared';
import { llmTransformSchema } from 'api-types-shared';
import type { KyInstance, Options } from 'ky';
import type { NodeEnv } from 'ui-kit';
import { apiEndpoints } from 'ui-kit';
import { createZodKyFetcher } from '../fetcher';
import { handleException } from 'sentry-browser-shared';

export class TransformSDK {
  readonly endpoint: string;
  private _kyFetcher: ZodFetcher<KyInstance>;

  constructor(env: NodeEnv, kyOpts?: Options) {
    this.endpoint = apiEndpoints[env].transformApiV1;
    this._kyFetcher = createZodKyFetcher(kyOpts);
  }

  async transform({
    data,
    prompt,
    example,
    cache = true,
  }: {
    data: string;
    prompt: string;
    example?: { input: string; output: string };
    cache?: boolean;
  }): Promise<LlmTransformResponse | null> {
    const request = llmTransformSchema.request.parse({
      params: {},
      query: {},
      body: {
        data,
        prompt,
        cache,
        example,
      },
    });
    const response = await this._kyFetcher(
      llmTransformSchema.response,
      `${this.endpoint}/llm-transform`,
      {
        method: 'post',
        json: request.body,
      },
    ).catch((err: unknown) => {
      handleException(err, {
        name: 'Transform data fetch failed',
        source: 'TransformSDK.transform',
        extra: { request },
      });
      // Throw all errors, so we can handle them in the UI
      if (err) {
        throw new Error(
          'Transformation is still processing, please try again later.',
        );
      }
      return null;
    });
    return response;
  }
}
