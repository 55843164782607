"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateApiKeySchema = void 0;
var types_shared_1 = require("types-shared");
exports.updateApiKeySchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.ApiKey.pick({
            keyId: true,
        }),
        body: types_shared_1.ApiKey.pick({
            name: true,
        }),
    }),
    response: types_shared_1.z.object({
        apiKey: types_shared_1.ApiKey,
    }),
};
