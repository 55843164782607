import MuiModal from '@mui/material/Modal';
import React, { type ReactNode } from 'react';
import { clsx } from 'clsx';
import Box from '@mui/material/Box';
import IconButton from '../Button/IconButton';
import Close from '@mui/icons-material/Close';
import noop from 'lodash/noop';

interface Props {
  children?: ReactNode;
  open: boolean;
  onClose: () => void;
  className?: string;
  containerClassName?: string;
  showCloseIcon?: boolean;
  enableEventBubbling?: boolean;
  borderOnCloseIcon?: boolean;
}

function Modal({
  children,
  open,
  onClose,
  className,
  showCloseIcon = true,
  containerClassName,
  enableEventBubbling = false,
  borderOnCloseIcon = true,
}: Props) {
  return (
    <MuiModal
      className="p-4 flex justify-center items-center"
      onClick={
        enableEventBubbling
          ? noop
          : (e) => {
              e.preventDefault();
              e.stopPropagation();
            }
      }
      onClose={onClose}
      open={open}
    >
      <div
        className={clsx(
          'flex bg-white rounded-2xl p-4 overflow-auto w-auto relative',
          className,
        )}
      >
        {showCloseIcon ? (
          <Box
            className={clsx({
              'absolute right-4 top-4': true,
              'border border-info rounded-lg': borderOnCloseIcon,
            })}
          >
            <IconButton color="secondary" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        ) : null}
        <Box className={clsx('p-8 w-full !pb-8', containerClassName)}>
          {children}
        </Box>
      </div>
    </MuiModal>
  );
}

export default Modal;
