import { clsx } from 'clsx';
import { CheckedCheckmarkIcon, Flex, GoogleIcon } from 'ui-kit';

interface Props {
  onClick?: () => void;
  platform: string;
  isConnected?: boolean;
  iconUrl?: string;
}
export default function IntegrationTile({
  onClick,
  platform,
  isConnected = false,
  iconUrl,
}: Props) {
  const shouldDisable = [
    'Microsoft Outlook',
    'outlook',
    'Google Drive',
    'googledrive',
  ].includes(platform);
  return platform === 'Salesforce' || platform === 'salesforce' ? null : (
    <Flex
      className={clsx({
        'min-w-80 p-7 relative transition max-w-80 h-52 justify-center items-center rounded-lg border-2 border-color-light-blue':
          true,
        'hover:border-primary-blue cursor-pointer': !shouldDisable,
        'opacity-50 cursor-not-allowed': shouldDisable,
      })}
      onClick={shouldDisable ? undefined : onClick}
    >
      {platform === 'google' && <GoogleIcon className="!w-full" />}
      {iconUrl ? (
        <img alt={platform} className="!max-h-full" src={iconUrl} />
      ) : null}
      {isConnected ? (
        <CheckedCheckmarkIcon
          className="absolute top-4 right-4"
          color="success"
        />
      ) : null}
    </Flex>
  );
}
