import { useState, useEffect, useMemo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Button,
  Input,
  notify,
  AlertVariant,
} from 'ui-kit';

interface Settings {
  retryInterval: number | string | null;
  maxAttempts: number | string | null;
}

interface Props {
  settings: Settings;
  setSettings: (data: Settings) => void;
  hasRetryNode?: boolean;
  setMaxAttempts: (maxAttempts: number | null) => void;
  setRetryInterval: (retryInterval: number | null) => void;
  apiSettings: {
    retryInterval: number | null;
    maxAttempts: number | null;
  };
  extractDaysHoursMinutes: (minutes: number) => {
    days: number;
    hours: number;
    minutes: number;
  };
  getTotalMinutes: ({
    days,
    hours,
    minutes,
  }: {
    days: number;
    hours: number;
    minutes: number;
  }) => number;
}

const defaultRetryConfig = {
  retryInterval: 1440,
  maxAttempts: 3,
};

export function ApiCallSettings({
  settings,
  setSettings,
  apiSettings,
  setMaxAttempts,
  setRetryInterval,
  extractDaysHoursMinutes,
  getTotalMinutes,
}: Props) {
  const [hasUpdated, setHasUpdated] = useState<boolean>(false);

  const [duration, setDuration] = useState<{
    days: number | string;
    hours: number | string;
    minutes: number | string;
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  const { retryInterval, maxAttempts } = settings;

  useEffect(() => {
    if (retryInterval !== null) {
      const { days, hours, minutes } = extractDaysHoursMinutes(
        Number(retryInterval) || 5,
      );
      setDuration({ days, hours, minutes });
    }
  }, [extractDaysHoursMinutes, retryInterval]);

  const onCancel = () => {
    setSettings(apiSettings);
    setHasUpdated(false);
  };

  const onSave = () => {
    if (retryInterval !== null) {
      const totalMinutes: number = getTotalMinutes({
        days: Number(duration.days),
        hours: Number(duration.hours),
        minutes: Number(duration.minutes),
      });
      setRetryInterval(totalMinutes);
    } else {
      setRetryInterval(null);
    }
    setMaxAttempts(maxAttempts === null ? null : Number(maxAttempts));
    setHasUpdated(false);
    notify({
      message: 'API settings changes were saved successfully.',
      variant: AlertVariant.SUCCESS,
    });
  };

  const toggleCheckbox = (key: 'retryInterval' | 'maxAttempts') => () => {
    const defaultVal = defaultRetryConfig[key];
    const isNotChecked = settings[key] === null;
    setSettings({
      ...settings,
      [key]: isNotChecked ? defaultVal : null,
    });
    setHasUpdated(true);
    if (key === 'retryInterval' && isNotChecked) {
      setDuration(extractDaysHoursMinutes(defaultVal));
    }
  };

  const updateSettings =
    (key: 'retryInterval' | 'maxAttempts') => (value: string) => {
      if (
        (value && Number(value) <= 0) ||
        ['e', 'E', '-', '.'].some((char) => value.includes(char))
      ) {
        return;
      }
      setSettings({ ...settings, [key]: value });
      setHasUpdated(true);
    };

  const updateDuration =
    (key: 'days' | 'hours' | 'minutes') => (value: string) => {
      if (
        Number(value) < 0 ||
        (key === 'minutes' && Number(value) > 59) ||
        (key === 'hours' && Number(value) > 23) ||
        ['e', 'E', '-', '.'].some((char) => value.includes(char))
      ) {
        return;
      }
      setDuration({ ...duration, [key]: value });
      setHasUpdated(true);
    };

  const disableSaveButton = useMemo(() => {
    return !hasUpdated || (maxAttempts !== null && Number(maxAttempts) < 1);
  }, [hasUpdated, maxAttempts]);

  return (
    <div className="mt-8 p-8 border border-indigo-light rounded-lg">
      <p className="text-info-dark text-lg font-medium">
        Orchestration settings
      </p>
      <div className="mt-8">
        <FormControlLabel
          control={
            <Checkbox
              checked={retryInterval !== null}
              color="secondary"
              onChange={toggleCheckbox('retryInterval')}
            />
          }
          label={
            <span className="text-info-dark text-sm font-medium">
              Automatic retry interval
            </span>
          }
        />
        <div className="flex flex-row">
          <Input
            InputProps={{
              className: 'w-24 mr-4',
              inputMode: 'numeric',
              inputProps: { max: 1000 },
            }}
            disabled={retryInterval === null}
            floatingLabel
            id="retry-days"
            label="Days"
            onChange={updateDuration('days')}
            type="number"
            value={duration.days}
          />
          <Input
            InputProps={{
              className: 'w-24 mr-4',
              inputMode: 'numeric',
              inputProps: { max: 1000 },
            }}
            disabled={retryInterval === null}
            floatingLabel
            id="retry-hours"
            label="Hours"
            onChange={updateDuration('hours')}
            type="number"
            value={duration.hours}
          />
          <Input
            InputProps={{
              className: 'w-24',
              inputMode: 'numeric',
              inputProps: { max: 1000 },
            }}
            disabled={retryInterval === null}
            floatingLabel
            id="retry-minutes"
            label="Minutes"
            onChange={updateDuration('minutes')}
            type="number"
            value={duration.minutes}
          />
        </div>
      </div>
      <div className="mt-8">
        <FormControlLabel
          control={
            <Checkbox
              checked={maxAttempts !== null}
              color="secondary"
              onChange={toggleCheckbox('maxAttempts')}
            />
          }
          label={
            <span className="text-info-dark text-sm font-medium">
              Terminate when consecutive job execution fail count reaches
            </span>
          }
        />
        <Input
          InputProps={{
            className: 'w-28',
            inputMode: 'numeric',
            inputProps: { max: 1000 },
          }}
          disabled={maxAttempts === null}
          error={maxAttempts !== null && Number(maxAttempts) === 0}
          floatingLabel
          id="retry-count"
          label="Fail times"
          onChange={updateSettings('maxAttempts')}
          type="number"
          value={maxAttempts}
        />
      </div>
      {maxAttempts !== null && Number(maxAttempts) === 0 ? (
        <p className="text-red-500 text-sm font-normal mt-3">
          Number of failures to trigger termination must be at least 1
        </p>
      ) : null}
      <div className="flex-row justify-between gap-4 mt-14">
        <Button
          className="!mr-4"
          color="secondary"
          disabled={disableSaveButton}
          onClick={onSave}
          variant="contained"
        >
          SAVE CHANGES
        </Button>
        <Button
          color="secondary"
          disabled={!hasUpdated}
          onClick={onCancel}
          variant="outlined"
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
}
