import {
  Button,
  DataLoader,
  Flex,
  IconButton,
  ContentCopyOutlined,
  DescriptionOutlined,
  CustomDownloadIcon,
} from 'ui-kit';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import type { GetExecutionResponse } from 'api-types-shared';
import { useParams } from 'react-router-dom';
import { clsx } from 'clsx';
import { copyToClipboard } from '../../../../../utils/helper';
import OutputsTable from '../../OutputsTable';
import { type OutputItem } from '../../OutputsTable/Description';
import { getOutputItemsFromExecutionArtifacts } from '../../../utils';
import type {
  QueryObserverResult,
  RefetchOptions,
} from '@tanstack/react-query';

interface Props {
  downloadZippedOutput: (
    executionId: string,
    outputs: OutputItem[],
  ) => Promise<void>;
  downloadLinkData: (url: string) => void;
  downloadZippedOutputStatus: 'error' | 'success' | 'pending' | 'idle';
  artifactsLoading: boolean;
  fetchExecutionArtifacts: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<GetExecutionResponse>>;
  executionDetail: GetExecutionResponse;
  outputDetailsExportButtonEnabled: boolean;
}

export function RecordOutputs({
  executionDetail,
  downloadZippedOutput,
  downloadZippedOutputStatus,
  downloadLinkData,
  fetchExecutionArtifacts,
  artifactsLoading,
  outputDetailsExportButtonEnabled,
}: Props) {
  const { executionId } = useParams();

  if (!executionId) {
    throw Error('executionId not found!');
  }

  const { artifacts = [] } = executionDetail;

  const isDownloadingZippedOutput = downloadZippedOutputStatus === 'pending';

  const onDownloadLinkData = useCallback(
    (uri: string) => {
      downloadLinkData(uri);
    },
    [downloadLinkData],
  );

  const outputItems: OutputItem[] = useMemo(() => {
    return getOutputItemsFromExecutionArtifacts(
      executionDetail,
      onDownloadLinkData,
    );
  }, [executionDetail, onDownloadLinkData]);

  const handleDownloadZippedOutput = useCallback(async () => {
    const { data: refreshedExecutionDetail } = await fetchExecutionArtifacts();
    if (refreshedExecutionDetail) {
      const newOutputItems = getOutputItemsFromExecutionArtifacts(
        refreshedExecutionDetail,
        onDownloadLinkData,
      );
      void downloadZippedOutput(executionId, newOutputItems);
    }
  }, [
    fetchExecutionArtifacts,
    onDownloadLinkData,
    downloadZippedOutput,
    executionId,
  ]);

  useEffect(() => {
    void fetchExecutionArtifacts();
  }, [fetchExecutionArtifacts]);

  return artifactsLoading ? (
    <DataLoader />
  ) : (
    <div>
      <Flex alignItems="center" className="my-8" justifyContent="space-between">
        <div>
          <h2 className="text-cyan-900 text-2xl font-medium leading-9 tracking-tight">
            Output details
          </h2>
          <span className="text-slate-500 text-sm leading-normal">
            View and manage workflow outputs. Copy or download data as needed.
          </span>
        </div>

        <div>
          <Button
            className="!border-none"
            color="secondary"
            loading={isDownloadingZippedOutput}
            disabled={
              artifacts.length === 0 || !outputDetailsExportButtonEnabled
            }
            onClick={handleDownloadZippedOutput}
            variant="outlined"
          >
            {!isDownloadingZippedOutput ? (
              <CustomDownloadIcon
                fontSize="small"
                className={clsx({
                  'text-gray-350 mr-1':
                    artifacts.length === 0 || !outputDetailsExportButtonEnabled,
                  'text-info mr-1': !(
                    artifacts.length === 0 || !outputDetailsExportButtonEnabled
                  ),
                })}
              />
            ) : null}
            Export all documents
          </Button>
        </div>
      </Flex>

      <OutputsTable outputItems={outputItems} loading={artifactsLoading} />
    </div>
  );
}

interface OutputProps {
  title: string;
  description: string | object;
  onDownloadLinkData?: (url: string) => void;
  action: 'download' | 'copy';
  uri?: string;
  className?: string;
}
export function Output({
  title,
  description,
  action = 'copy',
  uri,
  onDownloadLinkData,
  className,
}: OutputProps) {
  const copy = useCallback(() => {
    const text =
      typeof description === 'string'
        ? description
        : JSON.stringify(description, null, 2);
    copyToClipboard(text);
  }, [description]);

  const downloadFile = useCallback(() => {
    if (uri && onDownloadLinkData) {
      onDownloadLinkData(uri);
    }
  }, [onDownloadLinkData, uri]);

  const descriptionText = useMemo(() => {
    if (typeof description === 'string') {
      const parts = description.split('\n');
      if (parts.length === 1) {
        return parts[0];
      }
      return parts.map((line: string) => (
        <Fragment key={line}>
          {line}
          <br />
        </Fragment>
      ));
    }
    return JSON.stringify(description, null, 2);
  }, [description]);

  const cookiesTitle =
    descriptionText === 'cookies.json'
      ? 'Workflow Execution Cookies'
      : undefined;

  return (
    <div
      className={clsx(
        'px-4 py-2 bg-neutral-50 rounded-lg border border-slate-300 flex items-start gap-2 w-fit',
        className,
      )}
    >
      {action === 'download' ? (
        <DescriptionOutlined color="action" fontSize="small" />
      ) : null}
      <div className="flex flex-col mr-2">
        <div className="text-gray-500 text-xs leading-3 tracking-tight break-all">
          {cookiesTitle ?? title}
        </div>
        <div className="text-cyan-900 text-base leading-normal tracking-tight break-all whitespace-pre-wrap font-mono">
          {descriptionText}
        </div>
      </div>
      <IconButton
        className="mt-2"
        onClick={action === 'download' ? downloadFile : copy}
      >
        {action === 'download' ? (
          <CustomDownloadIcon color="secondary" fontSize="small" />
        ) : (
          <ContentCopyOutlined color="secondary" fontSize="small" />
        )}
      </IconButton>
    </div>
  );
}
