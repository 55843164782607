import dayjs, { extend, type Dayjs } from 'dayjs';
import localizedPlugin from 'dayjs/plugin/localizedFormat';
import relativePlugin from 'dayjs/plugin/relativeTime';
import utcPlugin from 'dayjs/plugin/utc';

extend(utcPlugin);
extend(relativePlugin);
extend(localizedPlugin);

export default dayjs;
export type { Dayjs };
