import {
  addApiKeySchema,
  deleteApiKeySchema,
  getApiKeySchema,
  listApiKeysSchema,
  updateApiKeySchema,
} from 'api-types-shared';
import type {
  AddApiKeyRequest,
  ZodFetcher,
  DeleteApiKeyRequest,
  GetApiKeyRequest,
  UpdateApiKeyRequest,
} from 'api-types-shared';
import type { KyInstance, Options } from 'ky';
import { createZodKyFetcher } from '../fetcher';
import { apiEndpoints, type NodeEnv } from 'ui-kit';
import { handleException } from 'sentry-browser-shared';
import { extractServerErrorMessage } from '../utils/error';

export class UserSDK {
  readonly endpoint: string;
  private _kyFetcher: ZodFetcher<KyInstance>;

  constructor(env: NodeEnv, kyOpts?: Options) {
    this.endpoint = apiEndpoints[env].userApiV1;
    this._kyFetcher = createZodKyFetcher(kyOpts);
  }

  addApiKey = async (req: AddApiKeyRequest) => {
    const response = await this._kyFetcher(
      addApiKeySchema.response,
      `${this.endpoint}/add`,
      {
        method: 'POST',
        body: JSON.stringify(req.body),
      },
    ).catch(async (error: unknown) => {
      const msg = await extractServerErrorMessage(error);
      if (msg) {
        throw new Error(msg);
      } else {
        handleException(error, {
          name: 'Add API key error',
          source: 'UserSDK.addApiKey',
          extra: { request: req },
        });
        throw error;
      }
    });

    return addApiKeySchema.response.parse(response);
  };

  updateApiKey = async (req: UpdateApiKeyRequest) => {
    const response = await this._kyFetcher(
      updateApiKeySchema.response,
      `${this.endpoint}/keys/${req.params.keyId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(req.body),
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Update API key error',
        source: 'UserSDK.updateApiKey',
        extra: { request: req },
      });
      throw error;
    });

    return updateApiKeySchema.response.parse(response);
  };

  getApiKey = async (req: GetApiKeyRequest) => {
    const response = await this._kyFetcher(
      getApiKeySchema.response,
      `${this.endpoint}/keys/${req.params.keyId}`,
      {
        method: 'GET',
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Get API key error',
        source: 'UserSDK.getApiKey',
        extra: { request: req },
      });
      throw error;
    });

    return getApiKeySchema.response.parse(response);
  };

  deleteApiKey = async (req: DeleteApiKeyRequest) => {
    const response = await this._kyFetcher(
      deleteApiKeySchema.response,
      `${this.endpoint}/keys/${req.params.keyId}`,
      {
        method: 'DELETE',
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Delete API key error',
        source: 'UserSDK.deleteApiKey',
        extra: { request: req },
      });
      throw error;
    });

    return deleteApiKeySchema.response.parse(response);
  };

  listApiKeys = async () => {
    const response = await this._kyFetcher(
      listApiKeysSchema.response,
      `${this.endpoint}/list`,
      {
        method: 'GET',
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'List API keys error',
        source: 'UserSDK.listApiKeys',
      });
      throw error;
    });

    return listApiKeysSchema.response.parse(response);
  };
}
