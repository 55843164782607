"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HasuraExecutionStatusEnum = exports.HasuraExecution = exports.ClusterData = exports.TemporalAuthenticationEnum = exports.GlobalVariable = exports.HasuraDate = exports.TeamNameAndRole = exports.MemberRole = void 0;
var types_shared_1 = require("types-shared");
var graphql_1 = require("./generated/graphql");
Object.defineProperty(exports, "HasuraExecutionStatusEnum", { enumerable: true, get: function () { return graphql_1.Execution_Status_Enum; } });
exports.MemberRole = types_shared_1.z.object({
    roleIdentifier: types_shared_1.z.string(),
    teamId: types_shared_1.z.string(),
});
exports.TeamNameAndRole = types_shared_1.z.object({
    teamId: types_shared_1.z.string(),
    teamName: types_shared_1.z.string(),
    roleName: types_shared_1.z.string(),
    isAdmin: types_shared_1.z.boolean(),
});
exports.HasuraDate = types_shared_1.z.union([types_shared_1.z.string(), types_shared_1.z.date()]).transform(function (val) {
    return new Date(val);
});
exports.GlobalVariable = types_shared_1.z.object({
    id: types_shared_1.z.string(),
    name: types_shared_1.z.string(),
    ownerId: types_shared_1.z.string(),
    value: types_shared_1.z.string(),
    updatedAt: exports.HasuraDate,
});
var TemporalAuthenticationEnum;
(function (TemporalAuthenticationEnum) {
    TemporalAuthenticationEnum["None"] = "none";
    TemporalAuthenticationEnum["ApiKey"] = "api_key";
    TemporalAuthenticationEnum["Mtls"] = "mtls";
})(TemporalAuthenticationEnum || (exports.TemporalAuthenticationEnum = TemporalAuthenticationEnum = {}));
exports.ClusterData = types_shared_1.z.object({
    id: types_shared_1.z.string(),
    datahubAddress: types_shared_1.z.string(),
    seleniumAddress: types_shared_1.z.string(),
    temporalAddress: types_shared_1.z.string(),
    temporalNamespace: types_shared_1.z.string(),
    temporalAuthentication: types_shared_1.z.nativeEnum(TemporalAuthenticationEnum),
});
var TeamMember = types_shared_1.z.object({
    id: types_shared_1.z.string(),
    email: types_shared_1.z.string(),
    role: types_shared_1.z.string(),
    workflowCount: types_shared_1.z.number(),
});
exports.HasuraExecution = types_shared_1.z.object({
    id: types_shared_1.z.string(),
    adminRun: types_shared_1.z.boolean(),
    status: types_shared_1.ExecutionStatus, // z.enum([ExecutionStatusEnum.Running, ExecutionStatusEnum.Terminated])
    trigger: types_shared_1.ExecutionTrigger,
    workflowVersionId: types_shared_1.z.string(),
    attempt: types_shared_1.z.number(),
    proxyMode: types_shared_1.z.string().nullable(),
    // FIXME: We should amend HasuraDate to support serialization to the database by converting to a string in ISO8601 format
    // See https://hasura.io/blog/working-with-dates-time-timezones-graphql-postgresql#timestamp-with-time-zone
    createdAt: exports.HasuraDate,
    updatedAt: exports.HasuraDate,
    // TODO: standardize "ownerId" across execution types
    // Currently "ownerId" is used on Hasura, and "userId" in ddb
    ownerId: types_shared_1.z.string(),
    workflowId: types_shared_1.z.string(),
});
