"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HasuraShim = void 0;
var client_1 = require("@apollo/client");
var dev_1 = require("@apollo/client/dev");
var types_shared_1 = require("types-shared");
var graphql_1 = require("./generated/graphql");
var types_1 = require("./types");
var handle_error_1 = require("./handle-error");
if (process.env.NODE_ENV !== 'production' ||
    process.env.DEPLOYED_STAGE === 'development') {
    (0, dev_1.loadDevMessages)();
    (0, dev_1.loadErrorMessages)();
}
var HasuraShim = /** @class */ (function () {
    function HasuraShim(_a) {
        var userId = _a.userId, jwt = _a.jwt, endpoint = _a.endpoint;
        var hasuraEndpoint = endpoint !== null && endpoint !== void 0 ? endpoint : process.env.HASURA_GRAPHQL_ENDPOINT;
        if (!hasuraEndpoint) {
            throw new Error('HASURA_GRAPHQL_ENDPOINT is not set');
        }
        var graphQLEndpoint = "".concat(hasuraEndpoint, "/v1/graphql");
        var headers = {};
        if (userId && jwt) {
            throw new Error('Should not use JWT and userId together. JWT takes precedence.');
        }
        // If a JWT is provided, this must be a User Hasura client
        if (jwt) {
            headers.Authorization = "Bearer ".concat(jwt);
            headers['x-hasura-role'] = 'user';
        }
        else if (userId) {
            // We are authenticating using the admin secret...
            headers['x-hasura-admin-secret'] = String(process.env.HASURA_GRAPHQL_ADMIN_SECRET);
            // ...but authorized as the user
            headers['x-hasura-user-id'] = userId;
            headers['x-hasura-role'] = 'user';
        }
        else {
            // We are authenticating and authorized as admin
            headers['x-hasura-admin-secret'] = String(process.env.HASURA_GRAPHQL_ADMIN_SECRET);
        }
        // MARK: Creating Apollo Client instance
        this.client = new client_1.ApolloClient({
            uri: graphQLEndpoint,
            headers: headers,
            // FIXME: Do not give apollo a cache or fix caching errors
            // ex: https://www.apollographql.com/docs/react/errors#%7B%22version%22%3A%223.11.2%22%2C%22message%22%3A12%2C%22args%22%3A%5B%22workflowId%22%2C%22%7B%7D%22%5D%7D
            cache: new client_1.InMemoryCache(),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                mutate: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            },
        });
    }
    HasuraShim.asAdmin = function (endpoint) {
        return new HasuraShim({ endpoint: endpoint });
    };
    HasuraShim.asUser = function (jwt) {
        return new HasuraShim({ jwt: jwt });
    };
    /**
     * Use with caution. Prefer JWT authentication when possible.
     */
    HasuraShim.asUserWithoutJwt = function (userId) {
        return new HasuraShim({ userId: userId });
    };
    HasuraShim.serverlessClient = function (_a) {
        // This function assumes that the actual authentication is done by the serverless middleware.
        var jwt = _a.jwt, isAdmin = _a.isAdmin, userId = _a.userId;
        // If the userId is provided, we are authenticating as the user, without a JWT
        // This is used for user-initiated API Key calls
        if (userId) {
            return HasuraShim.asUserWithoutJwt(userId);
        }
        else if (jwt) {
            // Otherwise, we are authenticating as the user, via their JWT, likely via a website request
            return HasuraShim.asUser(jwt);
        }
        throw new Error("Either userId or jwt must be provided. Instead got: isAdmin: ".concat(String(isAdmin), ", userId: ").concat(String(userId), ", jwt: ").concat(String(jwt)));
    };
    HasuraShim.prototype.checkedQuery = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.query(options)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, (0, handle_error_1.checkApolloQueryResult)(result, 'checkedQuery')];
                }
            });
        });
    };
    HasuraShim.prototype.listWorkflowIDs = function () {
        return __awaiter(this, void 0, void 0, function () {
            var result, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.query({ query: graphql_1.ListAllWorkflowsDocument })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'listWorkflowIDs');
                        return [2 /*return*/, data.workflows.map(function (workflow) { return workflow.id; })];
                }
            });
        });
    };
    HasuraShim.prototype.getAllowedWorkflowIDs = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetAllowedWorkflowIDsDocument,
                            variables: { ids: ids },
                        })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'getAllowedWorkflowIDs');
                        return [2 /*return*/, data.workflows.map(function (workflow) { return workflow.id; })];
                }
            });
        });
    };
    HasuraShim.prototype.createWorkflow = function (id, ownerId, currentVersionId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, createdWorkflowID;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.mutate({
                            mutation: graphql_1.CreateWorkflowDocument,
                            variables: {
                                id: id,
                                ownerId: ownerId,
                                currentVersionId: currentVersionId !== null && currentVersionId !== void 0 ? currentVersionId : null,
                            },
                        })];
                    case 1:
                        result = _b.sent();
                        data = (0, handle_error_1.checkApolloMutationResult)(result, 'createWorkflow');
                        createdWorkflowID = (_a = data.insert_workflows) === null || _a === void 0 ? void 0 : _a.returning[0].id;
                        if (!createdWorkflowID) {
                            throw new Error("Failed to create workflow: No ID returned: ".concat(JSON.stringify(data)));
                        }
                        return [2 /*return*/, createdWorkflowID];
                }
            });
        });
    };
    /**
     * NOTE: This is a soft delete that sets `deletedAt` to the provided value
     */
    HasuraShim.prototype.deleteWorkflow = function (id, deletedAt) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, deletedWorkflowID;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.mutate({
                            mutation: graphql_1.MarkWorkflowAsDeletedDocument,
                            variables: { id: id, deletedAt: deletedAt.toISOString() },
                        })];
                    case 1:
                        result = _b.sent();
                        data = (0, handle_error_1.checkApolloMutationResult)(result, 'deleteWorkflow');
                        deletedWorkflowID = (_a = data.update_workflows_by_pk) === null || _a === void 0 ? void 0 : _a.id;
                        if (!deletedWorkflowID) {
                            throw new Error("Failed to delete workflow: No ID returned: ".concat(JSON.stringify(data)));
                        }
                        return [2 /*return*/, deletedWorkflowID];
                }
            });
        });
    };
    HasuraShim.prototype.updateWorkflow = function (id, currentVersionId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, updatedWorkflowID;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.mutate({
                            mutation: graphql_1.UpdateWorkflowDocument,
                            variables: {
                                id: id,
                                currentVersionId: currentVersionId,
                            },
                        })];
                    case 1:
                        result = _b.sent();
                        data = (0, handle_error_1.checkApolloMutationResult)(result, 'updateWorkflow');
                        updatedWorkflowID = (_a = data.update_workflows_by_pk) === null || _a === void 0 ? void 0 : _a.id;
                        if (!updatedWorkflowID) {
                            throw new Error("Workflow not found: ".concat(JSON.stringify(data)));
                        }
                        return [2 /*return*/, updatedWorkflowID];
                }
            });
        });
    };
    HasuraShim.prototype.getUserEmails = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, emails;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetUsersDocument,
                            variables: { ids: ids },
                        })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserEmails');
                        emails = {};
                        data.users.forEach(function (user) {
                            if (user.id && user.email) {
                                emails[user.id] = user.email;
                            }
                        });
                        return [2 /*return*/, emails];
                }
            });
        });
    };
    HasuraShim.prototype.getUserEmail = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetUserDocument,
                            variables: { id: id },
                        })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserEmail');
                        user = data.users.at(0);
                        if (!user) {
                            throw new Error("No user found for ".concat(id));
                        }
                        return [2 /*return*/, user.email];
                }
            });
        });
    };
    HasuraShim.prototype.attemptToGetOwnerId = function (workflowId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetOwnerDocument,
                            variables: { workflowId: workflowId },
                        })];
                    case 1:
                        result = _b.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'attemptToGetOwnerId');
                        return [2 /*return*/, (_a = data.workflows_by_pk) === null || _a === void 0 ? void 0 : _a.ownerId];
                }
            });
        });
    };
    HasuraShim.prototype.getAssumableRoleForAction = function (userId, action, workflowId) {
        return __awaiter(this, void 0, void 0, function () {
            var ownerTeamsResult, ownerTeamsData, teamIds, teamRolesPromises, teamRolesResponses, assumableRoles;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetOwnerTeamsDocument,
                            variables: { workflowId: workflowId },
                        })];
                    case 1:
                        ownerTeamsResult = _b.sent();
                        ownerTeamsData = (0, handle_error_1.checkApolloQueryResult)(ownerTeamsResult, 'getAssumableRoleForAction');
                        teamIds = (_a = ownerTeamsData.workflows_by_pk) === null || _a === void 0 ? void 0 : _a.owner.memberships.map(function (membership) { return membership.teamId; });
                        if (!teamIds) {
                            throw new Error("Failed to get team IDs: ".concat(JSON.stringify(ownerTeamsData)));
                        }
                        teamRolesPromises = teamIds.map(function (teamId) {
                            return _this.client.query({
                                query: graphql_1.GetTeamMemberRolesDocument,
                                variables: {
                                    teamId: teamId,
                                    userId: userId,
                                    resourceType: graphql_1.Access_Control_Enum_Resource_Enum.Workflow,
                                    actionType: action,
                                },
                            });
                        });
                        return [4 /*yield*/, Promise.all(teamRolesPromises)];
                    case 2:
                        teamRolesResponses = _b.sent();
                        teamRolesResponses.forEach(function (response) {
                            if (response.errors) {
                                throw new Error("Failed to get team member roles: ".concat(response.errors.map(function (e) { return e.message; }).join(', ')));
                            }
                        });
                        assumableRoles = teamRolesResponses.flatMap(function (teamRoles) {
                            return teamRoles.data.organizations_team_member_roles.map(function (memberRole) { return ({
                                roleIdentifier: memberRole.role.identifier,
                                teamId: memberRole.membership.teamId,
                            }); });
                        });
                        if (assumableRoles.length === 0) {
                            throw new Error("No assumable roles found for teams: ".concat(teamIds.join(', ')));
                        }
                        return [2 /*return*/, types_1.MemberRole.parse(assumableRoles[0])];
                }
            });
        });
    };
    HasuraShim.prototype.getUserClusterData = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, memberships, clustersData;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetUserClusterDataDocument,
                            variables: { userId: userId },
                        })];
                    case 1:
                        result = _b.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserClusterData');
                        memberships = (_a = data.users[0]) === null || _a === void 0 ? void 0 : _a.memberships;
                        clustersData = memberships
                            .map(function (membership) { var _a; return (_a = membership.team.clusterConfiguration) === null || _a === void 0 ? void 0 : _a.cluster; })
                            .filter(Boolean);
                        if (!clustersData[0]) {
                            return [2 /*return*/, null];
                        }
                        return [2 /*return*/, types_1.ClusterData.parse(clustersData[0])];
                }
            });
        });
    };
    HasuraShim.prototype.getAllowedExecutionIDs = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetAllowedExecutionIDsDocument,
                            variables: { ids: ids },
                        })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'getAllowedExecutionIDs');
                        return [2 /*return*/, data.executions.map(function (execution) { return execution.id; })];
                }
            });
        });
    };
    HasuraShim.prototype.createExecution = function (execution) {
        return __awaiter(this, void 0, void 0, function () {
            var hasuraExecution, result, data, createdExecution;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        hasuraExecution = __assign(__assign({}, execution), { status: this._toHasuraExecutionStatus(execution.status) });
                        return [4 /*yield*/, this.client.mutate({
                                mutation: graphql_1.CreateExecutionDocument,
                                variables: { execution: hasuraExecution },
                            })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloMutationResult)(result, 'createExecution');
                        createdExecution = data.insert_executions_one;
                        if (!createdExecution) {
                            throw new Error("Failed to create execution: No execution returned: ".concat(JSON.stringify(data)));
                        }
                        return [2 /*return*/, createdExecution];
                }
            });
        });
    };
    HasuraShim.prototype.getUserTeamIds = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetUserTeamsDocument,
                            variables: { userId: userId },
                        })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserTeamIds');
                        return [2 /*return*/, data.organizations_team_memberships.map(function (membership) { return membership.team.id; })];
                }
            });
        });
    };
    HasuraShim.prototype.getUserTeamMaxConcurrency = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, maxConcurrentExecutionsArray;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetUserTeamsDocument,
                            variables: { userId: userId },
                        })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserTeamMaxConcurrency');
                        maxConcurrentExecutionsArray = data.organizations_team_memberships.map(function (membership) {
                            var maxConcurrent = membership.team.maxConcurrentExecutions;
                            // NOTE: Undefined maxConcurrentExecutions means that this function doesn't have access to the team's maxConcurrentExecutions
                            if (maxConcurrent === undefined) {
                                throw new Error('maxConcurrentExecutions is undefined');
                            }
                            return maxConcurrent;
                        });
                        // Null maxConcurrentExecutions (which is the default value) means that the team has no limit
                        if (maxConcurrentExecutionsArray.includes(null)) {
                            return [2 /*return*/, null];
                        }
                        // Otherwise, return the maximum limit across all teams the user is a member of
                        // TODO: patch up single user, multi-team cases
                        return [2 /*return*/, Math.max.apply(Math, maxConcurrentExecutionsArray)];
                }
            });
        });
    };
    HasuraShim.prototype.checkGlobalVariableExists = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetGlobalVariableNameDocument,
                            variables: { id: id },
                        })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'checkGlobalVariableExists');
                        return [2 /*return*/, Boolean(data.global_variables_by_pk)];
                }
            });
        });
    };
    HasuraShim.prototype.getUserTeamExecutionsCount = function (userId, 
    // eslint-disable-next-line camelcase -- We're using the generated type here
    filter) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetUserTeamExecutionCountDocument,
                            variables: {
                                userId: userId,
                                filter: filter,
                            },
                        })];
                    case 1:
                        result = _c.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserTeamExecutionsCount');
                        return [2 /*return*/, (_b = (_a = data.executions_aggregate.aggregate) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0];
                }
            });
        });
    };
    HasuraShim.prototype.updateExecutionStatus = function (id, status) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, updatedExecution;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.mutate({
                            mutation: graphql_1.UpdateExecutionStatusDocument,
                            variables: {
                                id: id,
                                status: this._toHasuraExecutionStatus(status),
                            },
                        })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloMutationResult)(result, 'updateExecutionStatus');
                        updatedExecution = data.update_executions_by_pk;
                        if (!updatedExecution) {
                            throw new Error("Failed to update execution: No execution returned: ".concat(JSON.stringify(data)));
                        }
                        return [2 /*return*/, updatedExecution.status];
                }
            });
        });
    };
    HasuraShim.prototype.updateExecutionAdminRun = function (id, adminRun) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, updatedExecution;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.mutate({
                            mutation: graphql_1.UpdateExecutionAdminRunDocument,
                            variables: {
                                id: id,
                                adminRun: adminRun,
                            },
                        })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloMutationResult)(result, 'updateExecutionAdminRun');
                        updatedExecution = data.update_executions_by_pk;
                        if (!updatedExecution) {
                            throw new Error("Failed to update execution: No execution returned: ".concat(JSON.stringify(data)));
                        }
                        return [2 /*return*/, updatedExecution.adminRun];
                }
            });
        });
    };
    HasuraShim.prototype.updateExecutionOwnerId = function (id, ownerId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, updatedExecution;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.mutate({
                            mutation: graphql_1.UpdateExecutionOwnerIdDocument,
                            variables: {
                                id: id,
                                ownerId: ownerId,
                            },
                        })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloMutationResult)(result, 'updateExecutionOwnerId');
                        updatedExecution = data.update_executions_by_pk;
                        if (!updatedExecution) {
                            throw new Error("Failed to update execution: No execution returned: ".concat(JSON.stringify(data)));
                        }
                        return [2 /*return*/, updatedExecution.ownerId];
                }
            });
        });
    };
    HasuraShim.prototype.getUserEmailAndChannelId = function (userId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetUserEmailAndChannelIdDocument,
                            variables: { userId: userId },
                        })];
                    case 1:
                        result = _b.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUserEmailAndChannelId');
                        return [2 /*return*/, {
                                userEmail: data.users[0].email,
                                teamChannelId: (_a = data.users[0].memberships[0].team.slackChannelId) !== null && _a !== void 0 ? _a : null,
                            }];
                }
            });
        });
    };
    HasuraShim.prototype.getUsersClusterConfigurations = function (userIds) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetUsersClusterConfigurationsDocument,
                            variables: { userIds: userIds },
                        })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'getUsersClusterConfigurations');
                        return [2 /*return*/, data.users.reduce(function (acc, user) {
                                var _a;
                                // NOTE: Assumes that the user is a member of exactly one team
                                var cluster = (_a = user.memberships[0].team.clusterConfiguration) === null || _a === void 0 ? void 0 : _a.cluster;
                                if (cluster) {
                                    acc[user.id] = types_1.ClusterData.omit({
                                        datahubAddress: true,
                                        seleniumAddress: true,
                                    }).parse(cluster);
                                }
                                return acc;
                            }, {})];
                }
            });
        });
    };
    HasuraShim.prototype.listAllExecutionsFiltered = function (limit_1) {
        return __awaiter(this, arguments, void 0, function (limit, options) {
            var fromTime, toTime, nextExecutionCursor, workflowId, statuses, whereConditions, variables, result, data;
            var _this = this;
            if (options === void 0) { options = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fromTime = options.fromTime, toTime = options.toTime, nextExecutionCursor = options.nextExecutionCursor, workflowId = options.workflowId, statuses = options.statuses;
                        whereConditions = [];
                        if (fromTime) {
                            whereConditions.push({ createdAt: { _gte: fromTime } });
                        }
                        if (toTime) {
                            whereConditions.push({ createdAt: { _lte: toTime } });
                        }
                        // Only add cursor conditions if we have a cursor
                        if (nextExecutionCursor) {
                            whereConditions.push({
                                _or: [
                                    { createdAt: { _lt: nextExecutionCursor.createdAt } },
                                    {
                                        _and: [
                                            { createdAt: { _eq: nextExecutionCursor.createdAt } },
                                            { id: { _lt: nextExecutionCursor.id } },
                                        ],
                                    },
                                ],
                            });
                        }
                        if (workflowId) {
                            whereConditions.push({ workflowId: { _eq: workflowId } });
                        }
                        if (statuses) {
                            whereConditions.push({
                                status: {
                                    _in: statuses.map(function (status) { return _this._toHasuraExecutionStatus(status); }),
                                },
                            });
                        }
                        else {
                            whereConditions.push({
                                status: {
                                    _in: [
                                        graphql_1.Execution_Status_Enum.Queued,
                                        graphql_1.Execution_Status_Enum.Running,
                                        graphql_1.Execution_Status_Enum.Failed,
                                        graphql_1.Execution_Status_Enum.Success,
                                    ],
                                },
                            });
                        }
                        variables = {
                            limit: limit,
                            whereConditions: whereConditions,
                        };
                        return [4 /*yield*/, this.client.query({
                                query: graphql_1.ListAllExecutionsFilteredDocument,
                                variables: variables,
                            })];
                    case 1:
                        result = _a.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'listAllExecutionsFiltered');
                        return [2 /*return*/, data.executions.map(function (execution) { return (__assign(__assign({}, execution), { status: types_shared_1.ExecutionStatus.parse(execution.status) })); })];
                }
            });
        });
    };
    HasuraShim.prototype.createRecording = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data, createdRecordingId;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.mutate({
                            mutation: graphql_1.CreateRecordingDocument,
                            variables: {
                                id: id,
                            },
                        })];
                    case 1:
                        result = _b.sent();
                        data = (0, handle_error_1.checkApolloMutationResult)(result, 'createRecording');
                        createdRecordingId = (_a = data.insert_recordings_one) === null || _a === void 0 ? void 0 : _a.id;
                        if (!createdRecordingId) {
                            throw new Error("Failed to create recording: No ID returned: ".concat(JSON.stringify(data)));
                        }
                        return [2 /*return*/, createdRecordingId];
                }
            });
        });
    };
    HasuraShim.prototype.getRecordingById = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var result, data;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.client.query({
                            query: graphql_1.GetRecordingByIdDocument,
                            variables: { id: id },
                        })];
                    case 1:
                        result = _b.sent();
                        data = (0, handle_error_1.checkApolloQueryResult)(result, 'getRecordingById');
                        return [2 /*return*/, (_a = data.recordings_by_pk) !== null && _a !== void 0 ? _a : undefined];
                }
            });
        });
    };
    HasuraShim.prototype._toHasuraExecutionStatus = function (status) {
        if (Object.values(graphql_1.Execution_Status_Enum).includes(status)) {
            return status;
        }
        throw new Error("Invalid execution status: ".concat(status));
    };
    return HasuraShim;
}());
exports.HasuraShim = HasuraShim;
