import { useQuery } from '@tanstack/react-query';
import { useAPI } from '../../../hooks/useApi';
import { EXECUTION_METRICS_REFETCH_INTERVAL } from '../../../utils/constants';

export function useGetWorkflowListMetrics() {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<{
    workflowsCount: number;
    executionsCount: number;
  }>({
    queryKey: ['workflowsListMetrics'],
    queryFn: () => {
      return sdk.fetchWorkflowMetrics();
    },
  });
}

export function useGetWorkflowExecutionMetrics(
  workflowId: string,
  enabled: boolean,
) {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<{
    totalExecutions: number;
    executionsCount: Record<string, number>;
    monthlyExecutions: Record<string, number>[];
    hourlyExecutions: Record<string, number>[];
  }>({
    refetchInterval: EXECUTION_METRICS_REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    retry() {
      return document.hasFocus();
    },
    queryKey: ['workflowExecutionMetrics', workflowId],
    queryFn: () => {
      return sdk.fetchWorkflowExecutionMetrics(workflowId);
    },
    staleTime: 0,
    enabled,
  });
}
