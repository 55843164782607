import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

export default function MessageIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="43"
      height="55"
      viewBox="0 0 43 55"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.0697 44.8582L25.3337 40.1222M24.3582 2.00249C24.0853 2 23.7782 2 23.4278 2H10.6434C7.44312 2 5.84177 2 4.61942 2.62282C3.54421 3.17067 2.67067 4.04421 2.12282 5.11942C1.5 6.34177 1.5 7.94312 1.5 11.1434V44.2863C1.5 47.4866 1.5 49.0859 2.12282 50.3083C2.67067 51.3835 3.54421 52.2585 4.61942 52.8064C5.84059 53.4286 7.44001 53.4286 10.6341 53.4286L32.366 53.4286C35.56 53.4286 37.1571 53.4286 38.3783 52.8064C39.4535 52.2585 40.3299 51.3835 40.8778 50.3082C41.5 49.0871 41.5 47.49 41.5 44.2959V20.0734C41.5 19.7229 41.4997 19.4158 41.4972 19.1429M24.3582 2.00249C25.1738 2.00993 25.6878 2.04019 26.1805 2.15849C26.7636 2.29847 27.3224 2.52933 27.8337 2.84263C28.4102 3.19591 28.9052 3.6909 29.8929 4.67857L38.8228 13.6085C39.8111 14.5968 40.3025 15.0896 40.6559 15.6663C40.9692 16.1775 41.201 16.735 41.341 17.3181C41.4593 17.8108 41.4898 18.3273 41.4972 19.1429M24.3582 2.00249L24.3571 10.0006C24.3571 13.2009 24.3571 14.8004 24.98 16.0228C25.5278 17.098 26.4013 17.9728 27.4766 18.5206C28.6977 19.1429 30.2972 19.1429 33.4912 19.1429H41.4972M20.0714 42C16.1265 42 12.9286 38.802 12.9286 34.8571C12.9286 30.9123 16.1265 27.7143 20.0714 27.7143C24.0163 27.7143 27.2143 30.9123 27.2143 34.8571C27.2143 38.802 24.0163 42 20.0714 42Z"
        stroke="#7A859C"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="#ffffff"
      />
    </SvgIcon>
  );
}
