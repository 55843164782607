import {
  type QueryVariable,
  type Variable,
  type GlobalVariable,
  type VariableMap,
} from 'types-shared';
import React from 'react';
import { Add, Button, Tooltip } from 'ui-kit';
import { VariableChip } from '../../../../../components/VariableChip';

interface Props {
  queryVariables: QueryVariable[];
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  onClick?: (variable: QueryVariable) => void;
  onClickAdd?: () => void;
  tooltipText?: string;
}

export function TriggerQueryVariables({
  queryVariables,
  variablesMap,
  globalVariablesMap,
  onClick,
  onClickAdd,
  tooltipText = 'Extract data to a variable',
}: Props) {
  return (
    <div className="flex flex-wrap gap-2 mt-2 items-center">
      {queryVariables.map((v) => {
        return (
          <VariableChip
            key={v.id}
            variableId={v.id}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            alwaysClickable
            onClick={() => {
              if (onClick) {
                onClick(v);
              }
            }}
          />
        );
      })}

      <Tooltip title={tooltipText} placement="right">
        <div>
          <Button
            classes={{ root: '!min-w-[unset]' }}
            color="secondary"
            onClick={onClickAdd}
            variant="outlined"
            size="small"
          >
            <Add />
          </Button>
        </div>
      </Tooltip>
    </div>
  );
}
