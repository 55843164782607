import type { GridColDef } from '@mui/x-data-grid/models';
import type { WorkflowMetadataType } from 'api-types-shared';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import Skeleton from '@mui/material/Skeleton';
import { v4 as uuid } from 'uuid';

interface Props {
  columns: GridColDef<WorkflowMetadataType>[];
}

const fakeData = Array.from({ length: 10 }, () => ({
  workflowId: uuid(),
}));

export default function ListSkeleton({ columns }: Props) {
  return (
    <DataGrid
      columns={columns.map((column) => ({
        ...column,
        renderCell: () => (
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem' }}
            className="w-9/12"
            height={40}
          />
        ),
      }))}
      getRowId={(row) => row.workflowId}
      rows={fakeData as never}
    />
  );
}
