import React, { useEffect, useRef, useMemo } from 'react';
import { getDocument } from 'pdfjs-dist';
import { IconButton, StarsIcon } from 'ui-kit';
import CircularProgress from '@mui/material/CircularProgress';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export interface Annotation {
  page: number;
  rect: [number, number, number, number]; // [x1, y1, x2, y2]
  annotationNumber: string;
}

export interface RenderPdfProps {
  fileUrl?: string;
  fileBlob?: Blob;
  fileName?: string;
  annotations?: Annotation[];
  isProcessing?: boolean;
  loadingPdf?: boolean;
  processingQuery?: boolean;
}

const addAnnotationsToCanvas = (
  context: CanvasRenderingContext2D | null,
  annotations: Annotation[],
  canvasHeight: number,
  currentPage: number,
): void => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!annotations || !context) return;

  // Filter annotations for the current page
  const filteredAnnotations = annotations.filter(
    (annotation) => annotation.page === currentPage,
  );

  filteredAnnotations.forEach(({ rect, annotationNumber }) => {
    const [x, y, x2, y2] = rect;

    const radius = 5; // Corner radius

    // Adjust Y-coordinates for PDF.js canvas (inverted Y-axis)
    const adjustedY = canvasHeight - y;
    const adjustedY2 = canvasHeight - y2;

    // Colors
    const borderColor = 'rgba(255, 35, 189, 1)';
    const fillColor = 'rgba(255, 35, 189, 0.3)';
    const textColor = 'white';

    // Draw rounded rectangle
    context.beginPath();
    context.moveTo(x + radius, adjustedY2); // Top-left corner
    context.lineTo(x2 - radius, adjustedY2); // Top-right edge
    context.arcTo(x2, adjustedY2, x2, adjustedY2 + radius, radius); // Top-right corner
    context.lineTo(x2, adjustedY - radius); // Right edge
    context.arcTo(x2, adjustedY, x2 - radius, adjustedY, radius); // Bottom-right corner
    context.lineTo(x + radius, adjustedY); // Bottom edge
    context.arcTo(x, adjustedY, x, adjustedY - radius, radius); // Bottom-left corner
    context.lineTo(x, adjustedY2 + radius); // Left edge
    context.arcTo(x, adjustedY2, x + radius, adjustedY2, radius); // Top-left corner
    context.closePath();

    // Fill rectangle
    context.fillStyle = fillColor;
    context.fill();

    // Stroke border
    context.strokeStyle = borderColor;
    context.lineWidth = 2;
    context.stroke();

    // Draw circle at the top-right corner
    const circleRadius = 10;
    const circleCenterX = x2; // Top-right corner X-coordinate
    const circleCenterY = adjustedY2; // Top-right corner Y-coordinate

    context.beginPath();
    context.arc(circleCenterX, circleCenterY, circleRadius, 0, 2 * Math.PI);
    context.fillStyle = borderColor;
    context.fill();

    // Draw annotation number inside the circle
    context.fillStyle = textColor;
    context.font = '10px Arial';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(annotationNumber, circleCenterX, circleCenterY);
  });
};

const renderPdfWithAnnotations = async (
  fileBlob: Blob,
  currentPage: number,
  annotations: Annotation[],
  canvas: HTMLCanvasElement,
  setMaxPages: (maxPages: number) => void,
  zoomLevel: number,
  preferredUrl?: string,
): Promise<void> => {
  let pdfData;
  if (preferredUrl?.startsWith('blob:')) {
    // For blob URLs, fetch the data first
    const response = await fetch(preferredUrl);
    pdfData = await response.arrayBuffer();
  } else {
    pdfData = preferredUrl ?? URL.createObjectURL(fileBlob);
  }

  const pdf = await getDocument(pdfData).promise;
  const page = await pdf.getPage(currentPage);
  setMaxPages(pdf.numPages);
  const viewport = page.getViewport({ scale: 1 });
  const targetHeight = Math.max(
    window.innerHeight * 0.8,
    window.innerHeight * 0.85,
  );
  const scale = (targetHeight / viewport.height) * zoomLevel;
  const scaledViewport = page.getViewport({ scale });

  // Get the device pixel ratio
  const pixelRatio = window.devicePixelRatio || 1;

  // Set physical canvas size scaled by pixel ratio
  canvas.width = Math.floor(scaledViewport.width * pixelRatio);
  canvas.height = Math.floor(scaledViewport.height * pixelRatio);

  // Set CSS size
  canvas.style.width = `${Math.floor(scaledViewport.width).toString()}px`;
  canvas.style.height = `${Math.floor(scaledViewport.height).toString()}px`;

  const context = canvas.getContext('2d');
  if (!context) throw new Error('Failed to get canvas context');

  // Scale context by pixel ratio
  context.scale(pixelRatio, pixelRatio);

  // Render PDF page
  const renderContext = {
    canvasContext: context,
    viewport: scaledViewport,
  };
  await page.render(renderContext).promise;

  // Add annotations
  addAnnotationsToCanvas(
    context,
    annotations,
    scaledViewport.height,
    currentPage,
  );
};

interface AnnotatedPDFProps extends RenderPdfProps {
  maxPages: number;
  setMaxPages: (maxPages: number) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  zoomLevel: number;
  setZoomLevel: React.Dispatch<React.SetStateAction<number>>;
}

export default function AnnotatedPDF({
  fileBlob,
  annotations,
  isProcessing,
  setMaxPages,
  currentPage,
  zoomLevel,
  loadingPdf,
  fileUrl: preferredUrl,
  processingQuery,
}: AnnotatedPDFProps) {
  const canvasRefContainer = useRef<HTMLDivElement | null>(null);

  const showLoading = isProcessing || loadingPdf || processingQuery;

  const loaderText = useMemo(() => {
    if (processingQuery) {
      return 'Analyzing query...';
    }
    if (isProcessing || loadingPdf) {
      return 'Processing document...';
    }
    return 'Please wait while we process your PDF...';
  }, [isProcessing, loadingPdf, processingQuery]);

  useEffect(() => {
    (() => {
      const canvas = canvasRefContainer.current?.querySelector('canvas');
      if (!canvas || !fileBlob) return;

      void renderPdfWithAnnotations(
        fileBlob,
        currentPage,
        annotations ?? [],
        canvas,
        setMaxPages,
        zoomLevel,
        preferredUrl,
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileBlob, currentPage, annotations, zoomLevel, preferredUrl]);

  return (
    <div
      className="relative w-full h-full"
      id="pdf-canvas"
      style={{ minHeight: '80vh' }}
    >
      <div className="flex flex-row items-center justify-center h-full">
        <div
          className="relative"
          ref={canvasRefContainer}
          id="annotated-canvas-container"
        >
          <canvas className="border border-gray-300" id="annotated-canvas" />
          {showLoading ? (
            <div className="absolute inset-0">
              <div className="absolute inset-0 bg-blue-900 opacity-40 !p-10 !h-min-[20rem] !w-min-[20rem]" />
              <div className="absolute inset-0 flex justify-center items-center z-10">
                <div className="flex flex-col items-center gap-3 bg-white text-lg font-bold rounded-lg p-8">
                  <div className="relative">
                    <CircularProgress
                      variant="indeterminate"
                      color="secondary"
                      sx={{ backgroundColor: 'transparent' }}
                    />
                    <div className="absolute top-[45%] left-[52%] -translate-x-1/2 -translate-y-1/2 z-10 scale-[0.7]">
                      <StarsIcon
                        className="w-3 h-3 text-info"
                        starColor="#246bfa"
                      />
                    </div>
                  </div>

                  <span className="text-sm text-info-dark font-medium">
                    {loaderText}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

interface AnnotatedPdfControlsProps {
  handleZoom: (increase: boolean) => void;
  zoomLevel: number;
  currentZoomPercentage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  maxPages: number;
  disableControls: boolean;
  fileName?: string;
}

export function AnnotatedPdfControls({
  handleZoom,
  zoomLevel,
  currentZoomPercentage,
  setCurrentPage,
  currentPage,
  maxPages,
  disableControls,
  fileName,
}: AnnotatedPdfControlsProps) {
  return (
    <div
      className="absolute top-0 left-0 right-0 bg-white z-10 py-10 flex flex-row items-center justify-between px-6 shadow-[0_4px_6px_-1px_rgba(0,0,0,0.03)]"
      style={{ height: '4.5rem' }}
    >
      <span className="text-gray-700 font-medium">
        {fileName ? (
          <span className="text-sm font-medium text-gray-500">
            Original source:{' '}
            <span className="text-sm font-medium text-info-dark">
              {fileName}
            </span>
          </span>
        ) : (
          'PDF Preview'
        )}
      </span>

      <div className="flex flex-row items-center gap-4">
        <IconButton
          onClick={() => {
            setCurrentPage((prev) => Math.max(1, prev - 1));
          }}
          disabled={currentPage === 1 || disableControls}
          className="!bg-white rounded-full p-2 shadow cursor-pointer"
        >
          <ChevronLeftIcon />
        </IconButton>
        <span className="min-w-[4rem] text-center">
          {currentPage} of {maxPages}
        </span>
        <IconButton
          className="!bg-white rounded-full p-2 shadow cursor-pointer"
          disabled={currentPage === maxPages || disableControls}
          onClick={() => {
            setCurrentPage((prev) => {
              const nextNumber = prev + 1;
              if (nextNumber > maxPages) {
                return maxPages;
              }
              return nextNumber;
            });
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </div>

      <div className="flex flex-row items-center gap-4">
        <IconButton
          onClick={() => {
            handleZoom(false);
          }}
          disabled={zoomLevel <= 0.6 || disableControls}
          className="!bg-white rounded-full p-2 shadow cursor-pointer"
        >
          <RemoveIcon />
        </IconButton>
        <span className="min-w-[4rem] text-center">
          {currentZoomPercentage}%
        </span>
        <IconButton
          onClick={() => {
            handleZoom(true);
          }}
          disabled={zoomLevel >= 2.4 || disableControls}
          className="!bg-white rounded-full p-2 shadow cursor-pointer"
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}
