import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useCallback, useState } from 'react';
import { Button, Flex, Modal, CustomTypography } from 'ui-kit';
import { type CompactWorkflowMetadata } from '../types';
import { useDuplicateWorkflow } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { handleException } from 'sentry-browser-shared';

interface Props {
  onCancel: () => void;
  workflow: CompactWorkflowMetadata;
}

export default function CloneModal({ onCancel, workflow }: Props) {
  const navigate = useNavigate();
  const [workflowName, setWorkflowName] = useState<string>(
    `${workflow.workflowName} (clone)`,
  );
  const { mutateAsync: cloneWorkflow, status: workflowCloneStatus } =
    useDuplicateWorkflow();

  const onSubmit = useCallback(async () => {
    try {
      const { newWorkflowId } = await cloneWorkflow({
        workflowId: workflow.workflowId,
        workflowName,
      });
      navigate(`/editor/${newWorkflowId}`);
    } catch (error) {
      handleException(error, {
        name: 'Clone workflow',
        source: 'CloneModal',
        extra: {
          workflowId: workflow.workflowId,
          workflowName,
        },
      });
    }
    onCancel();
  }, [onCancel, cloneWorkflow, workflow.workflowId, workflowName, navigate]);

  return (
    <Modal className="w-full !max-w-3xl" onClose={onCancel} open>
      <CustomTypography variant="h5">Clone workflow</CustomTypography>

      <div className="mt-7">
        <InputLabel className="!text-primary">Workflow name</InputLabel>
        <TextField
          color="secondary"
          focused
          fullWidth
          onChange={(e) => {
            setWorkflowName(e.target.value);
          }}
          size="medium"
          value={workflowName}
          variant="outlined"
        />
      </div>

      <Flex className="gap-4 mt-10">
        <Button
          color="secondary"
          loading={workflowCloneStatus === 'pending'}
          onClick={onSubmit}
          variant="contained"
        >
          Clone workflow
        </Button>
        <Button color="secondary" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
}
