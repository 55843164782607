import { useMemo } from 'react';
import {
  ActionValueCriteriaEnum,
  elementText,
  MultiChoiceVariable,
  type MultiSelectVariable,
  type TabVariable,
  type TargetMap,
  type TemplateVariable,
  type VariableMap,
  VariableTypeEnum,
  type WorkflowImageNode,
  DocumentSourceEnum,
  type DocumentVariable,
  parseTemplateString,
  ParseVariableMode,
} from 'types-shared/workflowTypes';
import { type ExecutionStepData } from 'types-shared/executionTypes';
import { isAdmin } from '../../../utils/env';
import isEmpty from 'lodash/isEmpty';
import { ActionsEnum, VariableString } from 'types-shared';
import { handleException } from 'sentry-browser-shared';

export default function useWorkflowCurrentStepActions({
  currentNode,
  targets,
  variablesData,
  steps,
}: {
  currentNode: WorkflowImageNode | undefined;
  targets: TargetMap;
  variablesData?: VariableMap;
  steps: ExecutionStepData['stepIds'];
}) {
  const currentStepActions = useMemo(() => {
    if (!currentNode) {
      return [];
    }

    const nodeData = currentNode.data;
    return nodeData.actionOrder
      .map((actionId) => {
        const action = nodeData.actionData[actionId];
        const data = steps.find((step) => step.id === actionId)?.data;

        const value = (() => {
          if (action.actionType === ActionsEnum.MultiChoice) {
            const multiChoiceData = MultiChoiceVariable.safeParse(data);
            if (!multiChoiceData.success) {
              return '';
            }
            const { executionData, multiChoiceOptions = [] } =
              multiChoiceData.data;
            const option =
              multiChoiceOptions[Number(executionData?.initialValue ?? 0)];
            return option.labelText ? elementText(option.labelText) : '';
          }

          if (action.actionType === ActionsEnum.MultiSelect) {
            if (
              !variablesData ||
              !action.variableId ||
              !(action.variableId in variablesData)
            ) {
              return '';
            }
            const variable = variablesData[
              action.variableId
            ] as MultiSelectVariable;
            if (action.criteria === ActionValueCriteriaEnum.Variable) {
              return parseTemplateString({
                data: variable.data,
                variableMap: variablesData,
                mode: ParseVariableMode.Execution,
                handleException,
              });
            }
            if (isEmpty(targets) || !action.targetId) {
              return '';
            }
            const target = targets[action.targetId];
            const options = target.ref.selectOptions ?? [];
            return variable.multiSelectedOptions
              .map((option) => options[option].text)
              .join(', ');
          }

          if (action.actionType === ActionsEnum.PickFromList) {
            if (!action.variableId || !variablesData) {
              return undefined;
            }
            const variable = variablesData[action.variableId];

            return (variable as TemplateVariable).data[0] as string;
          }

          if (
            action.actionType === ActionsEnum.SwitchTab ||
            action.actionType === ActionsEnum.NewTab
          ) {
            if (!action.variableId || !variablesData) {
              return undefined;
            }
            const variable = variablesData[action.variableId];

            return (variable as TabVariable).data.url[0];
          }

          if (action.actionType === ActionsEnum.Arbitrary) {
            return action.description;
          }

          if (action.actionType === ActionsEnum.MagicLoop) {
            if (!action.variableId || !variablesData) {
              return undefined;
            }
            const variable = variablesData[
              action.variableId
            ] as TemplateVariable;
            return parseTemplateString({
              data: variable.data,
              variableMap: variablesData,
              mode: ParseVariableMode.Execution,
              handleException,
            });
          }

          const outputString = VariableString.safeParse(
            data && 'value' in data
              ? data.value
              : data?.executionData?.transformedValue ??
                  data?.executionData?.initialValue,
          );

          return outputString.success ? outputString.data : undefined;
        })();

        let variableName = action.variableId
          ? variablesData?.[action.variableId]?.name
          : undefined;

        if (
          action.variableId &&
          !variablesData?.[action.variableId]?.name &&
          variablesData?.[action.variableId]?.type === VariableTypeEnum.Document
        ) {
          const documentVar = variablesData[
            action.variableId
          ] as DocumentVariable;

          if (documentVar.data.source === DocumentSourceEnum.Execution) {
            variableName = documentVar.name ?? undefined;
          }
        }
        // {action: WorkflowAction}

        return {
          action: nodeData.actionData[actionId],
          value,
          variableName,
          outputs:
            data && !('value' in data) && data.type === VariableTypeEnum.Scrape
              ? {
                  title: data.name,
                  description:
                    typeof value === 'string'
                      ? value
                      : JSON.stringify(value, null, 2),
                }
              : undefined,
        };
      })
      .filter(({ action }) => {
        if (
          !isAdmin &&
          (Boolean(action.options?.hidden) ||
            Boolean(action.options?.adminOnly) ||
            action.actionType === ActionsEnum.SwitchTab ||
            action.actionType === ActionsEnum.NewTab)
        ) {
          return false;
        }
        return true;
      });
  }, [currentNode, steps, targets, variablesData]);

  return currentStepActions;
}

export type StepActions = ReturnType<typeof useWorkflowCurrentStepActions>;
