"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteApiKeySchema = void 0;
var types_shared_1 = require("types-shared");
exports.deleteApiKeySchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.ApiKey.pick({ keyId: true }),
    }),
    response: types_shared_1.z.object({}),
};
