import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export function JumpToStepOptionIcon(props: SvgIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M12 15.75 15.75 12m0 0L12 8.25M15.75 12H2m0-5.94V6c0-1.4 0-2.1.272-2.635.24-.47.622-.853 1.093-1.093C3.9 2 4.6 2 6 2h12c1.4 0 2.1 0 2.634.272.47.24.854.622 1.094 1.093C22 3.899 22 4.599 22 5.996v12.008c0 1.398 0 2.097-.272 2.63-.24.471-.623.854-1.094 1.094-.534.272-1.233.272-2.63.272H5.996C4.6 22 3.9 22 3.365 21.728a2.501 2.501 0 0 1-1.093-1.094C2 20.1 2 19.4 2 18v-.063"
      />
    </svg>
  );
}

export function RetryOptionIcon(props: SvgIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="26"
      fill="none"
      viewBox="0 0 24 26"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M15.333 8.618H22V2m-.388 17.678a10.653 10.653 0 0 1-5.201 4.148 10.743 10.743 0 0 1-6.667.269 10.669 10.669 0 0 1-5.523-3.715A10.533 10.533 0 0 1 3.955 7.8a10.66 10.66 0 0 1 5.362-3.942 10.742 10.742 0 0 1 6.672-.009 10.661 10.661 0 0 1 5.372 3.928"
      />
    </svg>
  );
}

export function StopOptionIcon(props: SvgIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="26"
      fill="none"
      viewBox="0 0 24 26"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M2 24v-6.494m0 0c7.111-5.56 12.444 5.56 19.556 0v-13.9C14.444 9.165 9.11-1.956 2 3.605v13.9Z"
      />
    </svg>
  );
}

export function RecordingOptionIcon(props: SvgIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="23"
      fill="none"
      viewBox="0 0 25 23"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.028 3.9c0-.22.18-.4.4-.4h4.144c.22 0 .4.18.4.4a3.4 3.4 0 0 0 3.4 3.4h.895c.68 0 1.092.001 1.4.029.142.012.226.028.276.04a.41.41 0 0 1 .056.02c.15.083.293.227.389.431.017.035.059.142.084.48.027.353.028.819.028 1.55v7.1c0 .731-.001 1.196-.027 1.549-.026.338-.068.444-.085.48a.949.949 0 0 1-.389.433l-.011.005a.392.392 0 0 1-.045.013 1.78 1.78 0 0 1-.276.041c-.307.028-.718.029-1.397.029H5.73c-.68 0-1.09-.001-1.398-.029a1.786 1.786 0 0 1-.276-.04.401.401 0 0 1-.056-.02.947.947 0 0 1-.388-.432c-.017-.036-.059-.143-.084-.481-.027-.353-.028-.819-.028-1.551V9.854c0-.733.001-1.199.028-1.552.025-.34.068-.446.084-.482a.943.943 0 0 1 .4-.436.405.405 0 0 1 .044-.014c.05-.013.135-.029.277-.041.308-.028.72-.029 1.4-.029h.895a3.4 3.4 0 0 0 3.4-3.4Zm.4-3.4a3.4 3.4 0 0 0-3.4 3.4.4.4 0 0 1-.4.4h-.952c-.604 0-1.151 0-1.608.04-.492.044-1.014.143-1.52.424A3.941 3.941 0 0 0 .896 6.546C.66 7.052.574 7.57.536 8.076.5 8.557.5 9.137.5 9.802V17c0 .665 0 1.245.036 1.724.038.506.123 1.024.36 1.53l1.358-.637-1.358.637c.35.746.92 1.38 1.652 1.784.506.28 1.027.379 1.52.423.455.04 1.001.04 1.604.04h13.656c.603 0 1.149 0 1.605-.04.491-.044 1.012-.143 1.518-.423a3.947 3.947 0 0 0 1.653-1.784c.237-.506.322-1.024.36-1.53.036-.478.036-1.057.036-1.72V9.797c0-.663 0-1.243-.036-1.721-.038-.506-.123-1.024-.36-1.53a3.943 3.943 0 0 0-1.653-1.782l-.726 1.312.726-1.312c-.506-.28-1.027-.38-1.52-.424-.455-.04-1.003-.04-1.606-.04h-.953a.4.4 0 0 1-.4-.4 3.4 3.4 0 0 0-3.4-3.4h-4.144ZM12.5 14a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM8 12.5a4.5 4.5 0 1 0 9 0 4.5 4.5 0 0 0-9 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function FreeformOptionIcon(props: SvgIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M8.667 4.222H5.556c-1.245 0-1.868 0-2.343.242a2.221 2.221 0 0 0-.97.971C2 5.911 2 6.533 2 7.778v10.667c0 1.244 0 1.866.242 2.341.213.419.553.759.971.972C3.688 22 4.31 22 5.553 22h10.673c1.242 0 1.863 0 2.338-.242.418-.213.759-.554.972-.972.242-.475.242-1.096.242-2.338v-3.115m-11.111 0V12l6.666-6.667L18.667 2 22 5.333l-3.333 3.334L12 15.333H8.667Z"
      />
    </svg>
  );
}

export function ApiRequestOptionIcon(props: SvgIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
    >
      <path
        d="M3.7669 15.902C2.69409 16.7079 2 17.9907 2 19.4358C2 21.8754 3.97767 23.853 6.41725 23.853C8.85683 23.853 10.8345 21.8754 10.8345 19.4358H21.8776"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.7669 15.902C2.69409 16.7079 2 17.9907 2 19.4358C2 21.8754 3.97767 23.853 6.41725 23.853C8.85683 23.853 10.8345 21.8754 10.8345 19.4358H21.8776"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.2299 23.3403C21.4642 23.8664 22.9222 23.8261 24.1737 23.1036C26.2864 21.8838 27.0103 19.1822 25.7905 17.0695C24.5707 14.9567 21.8692 14.2329 19.7565 15.4527L14.2349 5.88904"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.825 5.50578C18.6635 4.17375 17.8996 2.93125 16.6481 2.2087C14.5354 0.988913 11.8338 1.71279 10.614 3.82553C9.39423 5.93826 10.1181 8.63981 12.2308 9.8596L6.70929 19.4232"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DocumentOptionIcon(props: SvgIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
    >
      <path
        d="M7.41538 16.8923H15.5385M12.8313 2.00118C12.7019 2 12.5564 2 12.3904 2H6.33257C4.81612 2 4.05733 2 3.47812 2.29512C2.96864 2.55472 2.55472 2.96864 2.29512 3.47812C2 4.05733 2 4.81612 2 6.33257V19.6679C2 21.1844 2 21.9422 2.29512 22.5214C2.55472 23.0309 2.96864 23.4456 3.47812 23.7052C4.05677 24 4.81465 24 6.32816 24L16.6257 24C18.1392 24 18.896 24 19.4746 23.7052C19.9841 23.4456 20.3994 23.0309 20.659 22.5214C20.9538 21.9428 20.9538 21.186 20.9538 19.6725V10.564C20.9538 10.3979 20.9537 10.2524 20.9525 10.1231M12.8313 2.00118C13.2177 2.0047 13.4613 2.01904 13.6948 2.0751C13.971 2.14143 14.2359 2.25082 14.4781 2.39928C14.7513 2.56667 14.9858 2.80122 15.4538 3.26923L19.6853 7.50065C20.1536 7.96895 20.3864 8.20246 20.5539 8.47571C20.7023 8.71797 20.8122 8.98213 20.8785 9.25841C20.9345 9.49189 20.949 9.73661 20.9525 10.1231M12.8313 2.00118L12.8308 5.79105C12.8308 7.3075 12.8308 8.06543 13.1259 8.64464C13.3855 9.15413 13.7994 9.56865 14.3089 9.82824C14.8875 10.1231 15.6454 10.1231 17.1589 10.1231H20.9525"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LoopOptionIcon(props: SvgIconProps) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
    >
      <path
        d="M8.35375 9.36571L12.0366 5.68286L8.35375 2.00001"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2778 5.5787H5C3.34315 5.5787 2 6.92185 2 8.57871V17.5787C2 19.2356 3.34315 20.5787 5 20.5787H19C20.6569 20.5787 22 19.2356 22 17.5787V8.57871C22 6.92185 20.6569 5.5787 19 5.5787L17.625 5.5787"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}
