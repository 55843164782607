"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWorkflowFilesSchema = void 0;
var types_shared_1 = require("types-shared");
var file_schema_1 = require("./file.schema");
exports.getWorkflowFilesSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
        }),
    }),
    response: types_shared_1.z.object({
        filesMetadata: types_shared_1.z.array(file_schema_1.fileMetadataSchema),
    }),
};
