import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function RetryIcon(props: SvgIconProps) {
  return (
    <svg
      {...props}
      fill="none"
      height="49"
      viewBox="0 0 58 49"
      width="58"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 47V33.7177M16 33.7177C30.5455 22.3443 41.4545 45.0907 56 33.7172V5.28369C41.4545 16.6571 30.5455 -6.09026 16 5.28316V33.7177Z"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="13" cy="27" r="13" fill="white" />
      <path
        d="M14.9999 24.1177H19V20M18.7674 31C18.0219 32.1817 16.9281 33.0862 15.6464 33.5808C14.3648 34.0754 12.9628 34.1339 11.6464 33.7479C10.33 33.3619 9.16875 32.5518 8.33267 31.4363C7.49659 30.3207 7.03007 28.9585 7.00141 27.5498C6.97274 26.1411 7.38323 24.7601 8.1732 23.6094C8.96316 22.4588 10.0907 21.5993 11.3902 21.1569C12.6898 20.7145 14.093 20.7124 15.3936 21.1513C16.6943 21.5902 17.824 22.4467 18.6169 23.5952"
        stroke="#103D61"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
