import { clsx } from 'clsx';
import React from 'react';
import type { MultiSelectVariable, SelectOption, Target } from 'types-shared';
import { Checkbox, FormControlLabel } from 'ui-kit';

interface PreviewProps {
  variable: MultiSelectVariable;
  target: Target;
}

export function MultiSelectPreview({ variable, target }: PreviewProps) {
  const options = target.ref.selectOptions ?? [];
  const previewText = variable.multiSelectedOptions
    .map((option: number) => options[option].text)
    .join(', ');
  return <span className="px-3 flex items-center">{previewText}</span>;
}

interface Props {
  className?: string;
  target: Target;
  variable: MultiSelectVariable;
  onChange: (val: MultiSelectVariable) => void;
}

export default function MultiSelect({
  className,
  variable,
  target,
  onChange,
}: Props) {
  const options = target.ref.selectOptions ?? [];
  const selectedOptions = variable.multiSelectedOptions;

  const handleItemSelect = (i: number) => {
    const isSelected = selectedOptions.includes(i);
    const newSelectedOptions = isSelected
      ? selectedOptions.filter((option) => option !== i)
      : [...selectedOptions, i];
    onChange({ ...variable, multiSelectedOptions: newSelectedOptions });
  };

  return (
    <div
      className={clsx(
        'relative flex flex-col items-start max-w-full rounded',
        className,
      )}
    >
      {options.map((option: SelectOption, i) => (
        <div
          className="w-full max-w-full pr-5 flex flex-row items-center mb-4 last:!mb-0"
          key={option.value}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedOptions.includes(i)}
                color="secondary"
                onClick={() => {
                  handleItemSelect(i);
                }}
              />
            }
            label={<span className="text-sm">{option.text}</span>}
            sx={{
              '& .MuiFormControlLabel-label': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        </div>
      ))}
    </div>
  );
}
