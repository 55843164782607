import { useState, useMemo, useEffect } from 'react';
import { GlobalVariableModalSkeleton } from './GlobalVariablesSkeleton';
import { Button, Input, Modal } from 'ui-kit';

export interface FormValues {
  name: string;
  value: string;
}

const defaultFormValue: FormValues = {
  name: '',
  value: '',
};

interface Props {
  initialValues?: FormValues;
  onCreate: (formValues: FormValues) => Promise<void>;
  onEdit: (formValues: FormValues) => Promise<void>;
  createVariableStatus: 'error' | 'idle' | 'pending' | 'success';
  open: boolean;
  setOpen: (newOpen: boolean) => void;
  loading: boolean;
}

export function CreateGlobalVariableModal({
  initialValues,
  onCreate,
  onEdit,
  createVariableStatus,
  setOpen,
  open,
  loading,
}: Props) {
  const isEditing = Boolean(initialValues);
  const [formValues, setFormValues] = useState<FormValues>(
    initialValues ?? defaultFormValue,
  );

  const setFormValue = (key: string, value: string | boolean) => {
    setFormValues((form) => ({
      ...form,
      [key]: value,
    }));
  };

  const closeAndReset = () => {
    setFormValues(defaultFormValue);
    setOpen(false);
  };

  const onSubmit = async () => {
    if (isEditing) {
      await onEdit(formValues);
      closeAndReset();
    } else {
      await onCreate(formValues);
      closeAndReset();
    }
  };

  const submitDisabled = useMemo(() => {
    if (loading) {
      return loading;
    }
    let canProceed = false;
    const valuesPresent = formValues.name && formValues.value;
    if (isEditing && initialValues) {
      const hasEdited =
        formValues.name !== initialValues.name ||
        formValues.value !== initialValues.value;
      canProceed = Boolean(hasEdited && valuesPresent);
    } else {
      canProceed = Boolean(valuesPresent);
    }
    return !canProceed;
  }, [formValues, isEditing, loading, initialValues]);

  useEffect(() => {
    if (initialValues) {
      setFormValues(initialValues);
    }
  }, [initialValues]);

  return (
    <Modal
      className="flex item-center justify-items-center min-w-[50rem]"
      onClose={closeAndReset}
      open={open}
      enableEventBubbling
    >
      <div className="min-w-[50rem]">
        <p className="font-medium mb-7 text-info-dark">
          {isEditing ? 'Edit' : 'Add'} shared variable
        </p>

        {loading ? (
          <GlobalVariableModalSkeleton />
        ) : (
          <>
            <Input
              classes={{ wrapper: 'flex flex-col mt-10' }}
              floatingLabel
              label="Name"
              onChange={(name: string) => {
                setFormValue('name', name);
              }}
              placeholder="Add name"
              value={formValues.name}
            />
            <Input
              classes={{ wrapper: 'flex flex-col mt-10' }}
              floatingLabel
              label="Value"
              multiline
              onChange={(value: string) => {
                setFormValue('value', value);
              }}
              placeholder="Add value"
              rows={1}
              value={formValues.value}
            />
          </>
        )}

        <div className="flex flex-row gap-5 mt-10">
          <Button
            className="w-40 h-9"
            color="secondary"
            loading={createVariableStatus === 'pending'}
            onClick={onSubmit}
            variant="contained"
            disabled={submitDisabled}
          >
            {isEditing ? 'SAVE CHANGES' : 'ADD VARIABLE'}
          </Button>
          <Button
            className="h-9"
            color="secondary"
            onClick={closeAndReset}
            variant="outlined"
          >
            CANCEL
          </Button>
        </div>
      </div>
    </Modal>
  );
}
