import React from 'react';
import MuiAlert from '@mui/material/Alert';

export enum AlertVariant {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
}

export interface Props {
  className?: string;
  open?: boolean;
  dismissible?: boolean;
  onClose?: () => void;
  timeoutInMs?: number;
  variant?: AlertVariant;
  action?: React.ReactNode;
  children?: string | React.ReactNode;
  doNotTimeout?: boolean;
}

export default function Alert({
  className,
  variant,
  children,
  open,
  dismissible = true,
  action,
  onClose,
}: Props) {
  return open ? (
    <MuiAlert
      action={action}
      className={className}
      onClose={() => dismissible && onClose?.()}
      severity={variant}
      variant="filled"
    >
      {children}
    </MuiAlert>
  ) : null;
}
