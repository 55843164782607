import {
  ArrowLeftIcon,
  InfoOutlined,
  SendIcon,
  Button,
  CheckCircleIcon,
  IconButton,
  Input,
  Label,
  Spinner,
  SubLabel,
  MailIcon,
  Tooltip,
} from 'ui-kit';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  type SourceVariable,
  type TemplateData,
  type Variable,
  VariableString,
  VariableTypeEnum,
  type WorkflowSourceNode,
  type GlobalVariable,
  type VariableMap,
  type EmailTriggerVariable,
  type QueryVariable,
  QueryValueTypeEnum,
} from 'types-shared';
import {
  VariableInput,
  type VariableInputRef,
} from '../../VariableTypes/VariableInput';
import { clsx } from 'clsx';
import { v4 as uuid } from 'uuid';
import merge from 'lodash/merge';
import {
  getTriggerBlockShortTitle,
  getTriggerBlockTitle,
} from './trigger.helpers';
import { SameVariableNameError } from '../../SameVariableNameError';

interface Props {
  node: WorkflowSourceNode;
  sourceVariable: SourceVariable;
  onCancel: () => void;

  variable?: QueryVariable;
  updateVariable: (variable: QueryVariable) => void;
  addVariable: (variable: QueryVariable) => void;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;

  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    query: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;

  addingVariableSource?: EmailTriggerVariable;
}

export function AddEditQueryVariable({
  node,
  onCancel,
  variable,
  variablesMap,
  updateVariable,
  addVariable,
  sourceVariable,
  addingVariableSource,
  globalVariablesMap,
  transformApiReqStatus,
  onTransformApiReq,
}: Props) {
  const prevFormValues = useRef<TemplateData>([]);
  const inputVariableInputRef = useRef<VariableInputRef>();

  const [localData, setLocalData] = useState<{
    name: string;
    query: TemplateData;
    transformedValue: string;
    initialValue: string;
  }>({
    name: variable?.name ?? '',
    initialValue: VariableString.parse(
      variable?.dashboardData?.initialValue ?? '',
    ),
    query: variable?.dashboardData?.transformInputs?.query ?? [],
    transformedValue: variable
      ? VariableString.parse(
          variable.dashboardData?.transformInputs?.transformedValue ?? '',
        )
      : '',
  });
  const [isFormDirty, setIsFormDirty] = useState(false);

  const transformApiReq = () => {
    onTransformApiReq(localData.query, localData.initialValue || '')
      .then((transformedValue) => {
        if (transformedValue) {
          setLocalData({
            ...localData,
            transformedValue,
          });
        }
      })
      .catch(() => {
        // do nothing, we are already showing an error below
      })
      .finally(() => {
        setIsFormDirty(false);
      });
  };

  const resetForm = () => {
    setLocalData({
      name: '',
      query: [],
      transformedValue: '',
      initialValue: '',
    });
  };

  const handleOnSave = () => {
    const sourceIds = variable
      ? variable.data.sourceIds
      : [addingVariableSource?.id ?? sourceVariable.id];

    const commonFields = {
      name: localData.name,
      dashboardData: {
        initialValue: localData.initialValue,
        transformInputs: {
          query: localData.query,
          transformedValue: localData.transformedValue,
        },
      },
      data: {
        sourceIds,
        query: localData.query,
        valueType: QueryValueTypeEnum.String,
      },
    };

    if (variable) {
      const updatedVariable = merge({}, variable, commonFields);
      updateVariable(updatedVariable);
      onCancel();
    } else {
      addVariable({
        id: uuid(),
        type: VariableTypeEnum.Query,
        ...commonFields,
      });
      resetForm();
      onCancel();
    }
  };

  const submitTransformDisabled = useMemo(() => {
    const hasNoQuery =
      !localData.query.length ||
      (localData.query[0] === '' && localData.query.length === 1);

    const isSendIcon =
      !['success', 'pending', 'error'].includes(transformApiReqStatus) ||
      isFormDirty ||
      hasNoQuery;

    if (!isSendIcon) {
      return false;
    }

    const hasNoInput =
      !localData.initialValue.length ||
      // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
      (localData.initialValue[0] === '' && localData.initialValue.length === 1);

    return !isFormDirty || hasNoInput || hasNoQuery;
  }, [
    isFormDirty,
    localData.initialValue,
    localData.query,
    transformApiReqStatus,
  ]);

  const buttonIcon = useMemo(() => {
    const hasNoQuery =
      !localData.query.length ||
      (localData.query[0] === '' && localData.query.length === 1);

    if (transformApiReqStatus === 'pending') {
      return <Spinner size={16} />;
    } else if (
      transformApiReqStatus === 'success' &&
      !isFormDirty &&
      !hasNoQuery
    ) {
      return <CheckCircleIcon className="text-transparent" />;
    } else if (transformApiReqStatus === 'error') {
      <InfoOutlined className="text-error" />;
    }
    return <SendIcon className="text-white" />;
  }, [transformApiReqStatus, isFormDirty, localData.query]);

  useEffect(() => {
    const variableQuery = variable?.dashboardData?.transformInputs?.query;
    if (variableQuery?.length && !prevFormValues.current.length) {
      // Force a re-render if the content has changed, but not yet reflected
      prevFormValues.current = variableQuery;
      inputVariableInputRef.current?.reRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variable, prevFormValues.current]);

  return (
    <div className="absolute left-0 top-0 bottom-0 w-120 bg-white rounded-lg z-[10] flex flex-col justify-between space-y-5">
      <div className="overflow-auto h-full mb-20">
        <div className="flex justify-between items-center pt-8 px-8">
          <div className="flex items-center">
            <span
              className="flex !border !border-solid !border-info !rounded-lg cursor-pointer mr-3.5"
              onClick={onCancel}
              role="presentation"
            >
              <ArrowLeftIcon className="text-info !h-7 !w-7" />
            </span>
            <span className="text-sm font-medium text-info-dark">
              {getTriggerBlockShortTitle(sourceVariable)}&nbsp;
            </span>
            <span className="text-sm font-medium text-primary-blue">
              / Create a Variable
            </span>
          </div>
        </div>
        <div className="py-6 px-8 flex justify-between items-center bg-white z-50 sticky top-0">
          <h2 className="text-lg font-medium text-info-dark">
            {getTriggerBlockTitle(node, sourceVariable)}
          </h2>

          <hr className="border-b border-color-gray-200 absolute bottom-0 left-0 w-full" />
        </div>

        <div className="p-8">
          <SubLabel className="mb-6">
            Leverage Sola's AI to extract key data from email content and turn
            it into reusable variables for workflow outputs or future steps.
          </SubLabel>

          <div className="flex flex-col gap-4 mt-5">
            <div>
              <Input
                floatingLabel
                label="Variable name"
                placeholder="Name the variable"
                value={localData.name}
                onChange={(newName) => {
                  setLocalData((oldData) => ({ ...oldData, name: newName }));
                }}
              />
              <SameVariableNameError
                name={localData.name}
                variablesMap={variablesMap}
                editingVariableId={variable?.id}
                globalVariablesMap={globalVariablesMap}
              />
            </div>

            <div className="my-6">
              <Label className="mb-2">Source</Label>
              <div className="rounded-lg border p-2 bg-primary-blue-extralight-2 border-indigo-light">
                <div className="flex gap-4 items-center">
                  <div className="py-[0.5rem] px-[0.5rem] rounded-md bg-white flex items-center justify-center">
                    <MailIcon className="!w-5 !h-5 text-cyan-900" />
                  </div>
                  <Label>{addingVariableSource?.name}</Label>
                </div>
              </div>
            </div>

            <div>
              <span className="text-cyan-900 text-sm font-medium leading-tight tracking-tight block mb-2">
                Extract data from the source
              </span>
              <SubLabel className="mt-2">
                To extract variables from the email content, provide a query.
                Optionally, use an example to preview a sample response and
                refine your results.
              </SubLabel>
            </div>

            <div className="relative flex flex-col mt-2 pt-1">
              <VariableInput
                ref={inputVariableInputRef}
                label="Instructions to extract data from email content"
                value={localData.query}
                onChange={(newQuery) => {
                  setLocalData((oldData) => ({ ...oldData, query: newQuery }));
                  setIsFormDirty(true);
                }}
                className={clsx('min-h-40')}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                placeholder="Instructions to extract data"
                allowAddVariable={false}
                hideVariableMenuButton
              />
              <IconButton
                className={clsx({
                  '!absolute bottom-1 right-1': true,
                  'opacity-50': submitTransformDisabled,
                })}
                // disabled={submitTransformDisabled}
                onClick={submitTransformDisabled ? undefined : transformApiReq}
              >
                <Tooltip
                  hidden={!submitTransformDisabled}
                  title="Optionally provide example email content in the space below to test your query"
                  arrow
                  placement="right"
                >
                  {buttonIcon}
                </Tooltip>
              </IconButton>
            </div>
            {transformApiReqStatus === 'error' && !isFormDirty ? (
              <p className="px-1 pt-1 text-error text-xs">
                We couldn't execute that instructions, please try with another
                one.
              </p>
            ) : null}

            <Input
              floatingLabel
              label="Email content example"
              placeholder="Example email content for testing queries"
              value={localData.initialValue}
              onChange={(newData) => {
                setLocalData((oldData) => ({
                  ...oldData,
                  initialValue: newData,
                }));
              }}
            />

            <div>
              <span className="text-cyan-900 text-sm font-medium leading-tight tracking-tight block mb-2">
                Example query result
              </span>
              <div
                className={clsx('p-[1px] !rounded bg-gray-300', {
                  '!bg-gradient-to-r from-primary-blue to-primary-purple !p-0.5':
                    localData.transformedValue &&
                    transformApiReqStatus !== 'pending',
                })}
              >
                <div className="bg-white !rounded-sm p-3 min-h-[2.75rem] break-all">
                  {localData.transformedValue}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full gap-9 px-8 py-6 absolute bottom-0 bg-white">
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            // TODO(Rafic): Add back !localData.transformedValue?
            disabled={!localData.name || !localData.query.length}
            onClick={handleOnSave}
            variant="contained"
          >
            {variable ? 'update variable' : 'Create variable'}
          </Button>
          <Button
            className="!flex-1"
            color="secondary"
            fullWidth
            onClick={onCancel}
            variant="outlined"
          >
            cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
