import { useMemo } from 'react';
import { SourceTypeEnum, SourceVariable, type Variable } from 'types-shared';

interface UseSourceVariableResult {
  sourceVariable: SourceVariable | undefined;
  isApi: boolean;
  isDataSource: boolean;
  isTrigger: boolean;
  isEmailTrigger: boolean;
}

const allowedSourceTypes = [
  SourceTypeEnum.API,
  SourceTypeEnum.Datasource,
  SourceTypeEnum.EmailTrigger,
];

export const useSourceVariable = (
  variables: Record<string, Variable>,
): UseSourceVariableResult => {
  const sourceVariable = useMemo(() => {
    const sourceVariables = Object.values(variables).filter(
      (variable): variable is SourceVariable =>
        SourceVariable.safeParse(variable).success &&
        allowedSourceTypes.includes(
          (variable as SourceVariable).data.sourceType,
        ),
    );
    if (sourceVariables.length === 0) {
      return undefined;
    }
    return sourceVariables[0];
  }, [variables]);

  const { isApi, isDataSource, isTrigger, isEmailTrigger } = useMemo(() => {
    return {
      isApi: sourceVariable?.data.sourceType === SourceTypeEnum.API,
      isDataSource:
        sourceVariable?.data.sourceType === SourceTypeEnum.Datasource,
      isTrigger:
        sourceVariable?.data.sourceType === SourceTypeEnum.EmailTrigger, // can add more trigger type here
      isEmailTrigger:
        sourceVariable?.data.sourceType === SourceTypeEnum.EmailTrigger, // can add more trigger type here
    };
  }, [sourceVariable]);

  return { sourceVariable, isApi, isDataSource, isTrigger, isEmailTrigger };
};
