import type {
  WorkflowEdge,
  WorkflowNode,
  WorkflowData,
  WorkflowSourceNode,
  SourceVariable,
} from 'types-shared';
import { NodeStatusEnum, NodeTypesEnum } from 'types-shared';
import { v4 as uuid } from 'uuid';

export const appendDatasourceNode = (
  workflowData: WorkflowData,
  defaultSourceVariable: SourceVariable,
): WorkflowData => {
  const { nodes, edges } = workflowData;
  const dataSourceNodeId = uuid();

  const newNode: WorkflowSourceNode = {
    id: dataSourceNodeId,
    position: {
      x: 0,
      y: 0,
    },
    type: NodeTypesEnum.Source,
    width: 256,
    height: 232,
    data: {
      nodeStatus: NodeStatusEnum.NotViewed,
      variableId: defaultSourceVariable.id,
    },
  };
  const newEdges: WorkflowEdge[] = [
    {
      id: uuid(),
      source: dataSourceNodeId,
      target: nodes[0]?.id,
    },
    ...edges,
  ];

  const newNodes = [newNode, ...nodes] as WorkflowNode[];
  return {
    ...workflowData,
    nodes: newNodes,
    edges: newEdges,
  };
};
