import type { SVGProps } from 'react';
import React from 'react';

export default function FileSearchIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      fill="none"
      height="167"
      viewBox="0 0 131 167"
      width="131"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M92.2802 136.932L77.4803 122.132M74.4319 3.00778C73.579 3 72.6194 3 71.5242 3H31.5732C21.5722 3 16.568 3 12.7482 4.94632C9.38815 6.65835 6.65834 9.38815 4.94631 12.7482C3 16.568 3 21.5722 3 31.5732V135.145C3 145.146 3 150.143 4.94632 153.963C6.65834 157.323 9.38815 160.058 12.7482 161.77C16.5643 163.714 21.5625 163.714 31.5441 163.714L99.4562 163.714C109.438 163.714 114.428 163.714 118.245 161.77C121.605 160.058 124.344 157.323 126.056 153.963C128 150.147 128 145.156 128 135.175V59.4793C128 58.3841 127.999 57.4244 127.991 56.5714M74.4319 3.00778C76.9807 3.03103 78.5868 3.12559 80.1266 3.49527C81.9487 3.93271 83.6951 4.65415 85.2928 5.63323C87.0943 6.7372 88.6412 8.28405 91.7277 11.3705L119.634 39.2766C122.722 42.3651 124.258 43.905 125.362 45.7071C126.341 47.3048 127.066 49.0469 127.503 50.869C127.873 52.4088 127.968 54.0227 127.991 56.5714M74.4319 3.00778L74.4286 28.0019C74.4286 38.0028 74.4286 43.0014 76.3749 46.8212C78.0869 50.1812 80.8167 52.915 84.1768 54.627C87.9929 56.5714 92.9912 56.5714 102.972 56.5714H127.991M61.0357 128C48.7079 128 38.7143 118.006 38.7143 105.679C38.7143 93.3508 48.7079 83.3571 61.0357 83.3571C73.3635 83.3571 83.3571 93.3508 83.3571 105.679C83.3571 118.006 73.3635 128 61.0357 128Z"
        stroke="#7A859C"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
