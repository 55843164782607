import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { clsx } from 'clsx';
import React, { useMemo } from 'react';
import type { EventMap } from 'ui-kit';
import { createEventBus } from 'ui-kit';
import {
  NodeSelectionModeEnums,
  NodeStatusEnum,
  NodeTypesEnum,
  type WorkflowImageNode,
  type WorkflowNode,
} from 'types-shared';

interface NodeSelectionEvents extends EventMap {
  onToggleSelection: (mode: NodeSelectionModeEnums | null) => void;
}
export const nodeSelectEventChannel = createEventBus<NodeSelectionEvents>();

interface Props {
  nodes: WorkflowNode[];
  onEnableSelectionMode: () => void;
  onApplyChanges: (mode: NodeSelectionModeEnums) => void;
  onCancel: () => void;
  setNodes: (nodes: WorkflowNode[]) => void;
  allowNodesMerging?: boolean;
  showSelectAllButton?: boolean;
  selectedMode: NodeSelectionModeEnums | null;
  setSelectedMode: (newMode: NodeSelectionModeEnums | null) => void;
}

export function ActionsHeader({
  nodes,
  onApplyChanges,
  onCancel,
  setNodes,
  onEnableSelectionMode,
  allowNodesMerging = false,
  showSelectAllButton = false,
  selectedMode,
  setSelectedMode,
}: Props) {
  const allNodesChecked = useMemo(() => {
    const imageNodes = nodes.filter(({ type }) => type === NodeTypesEnum.Image);
    const selectedNodes = imageNodes.filter(
      (node) =>
        (node as WorkflowImageNode).data.nodeStatus === NodeStatusEnum.Checked,
    );
    return imageNodes.length === selectedNodes.length;
  }, [nodes]);
  const selectedNodes = useMemo(
    () => nodes.filter((node) => (node as WorkflowImageNode).data.selected),
    [nodes],
  );

  const startSelectionFlow = (mode: NodeSelectionModeEnums) => {
    setSelectedMode(mode);
    nodeSelectEventChannel.emit('onToggleSelection', mode);
  };

  const stopSelectionFlow = () => {
    setSelectedMode(null);
    nodeSelectEventChannel.emit('onToggleSelection', null);
  };

  const handleApplyChanges = () => {
    if (selectedMode) {
      onApplyChanges(selectedMode);
    }
    stopSelectionFlow();
  };

  const handleCancelChanges = () => {
    onCancel();
    stopSelectionFlow();
  };

  const toggleNodes = () => {
    const filteredNodes = nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          selected: false,
          nodeStatus: allNodesChecked
            ? NodeStatusEnum.NotViewed
            : NodeStatusEnum.Checked,
        },
      };
    }) as WorkflowNode[];
    setNodes(filteredNodes);
    stopSelectionFlow();
  };

  return (
    <div
      className={clsx(
        'zoom-adjusted-container',
        selectedMode && 'bg-white',
        'px-5 py-3 min-h-[4.5rem] flex items-center  absolute top-0 left-0 z-10 w-full border-t transition-all duration-200',
      )}
    >
      <div className="flex gap-2 w-full">
        {selectedMode ? (
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <span className="p-0 text-sm font-medium text-info-dark">
                {selectedMode === NodeSelectionModeEnums.BulkCheck
                  ? 'Quick Review Steps'
                  : 'Merge Steps'}
              </span>
              <Divider
                orientation="vertical"
                sx={{
                  height: '1rem',
                  mx: 2,
                  borderColor: '#7A859C',
                  borderWidth: '0.5px',
                }}
              />
              <span className="font-medium text-sm text-info-light mr-4">
                {selectedNodes.length}
                {selectedMode === NodeSelectionModeEnums.BulkCheck
                  ? ' new steps checked as done'
                  : ' new steps selected to merge'}
              </span>
              {selectedMode === NodeSelectionModeEnums.BulkCheck &&
              showSelectAllButton ? (
                <Button
                  color="secondary"
                  onClick={toggleNodes}
                  variant="outlined"
                >
                  {allNodesChecked ? 'Uncheck All' : 'Check All'}
                </Button>
              ) : null}
            </div>
            <div className="flex items-center gap-2">
              <Button
                color="secondary"
                disabled={
                  selectedMode === NodeSelectionModeEnums.MergeNodes &&
                  selectedNodes.length < 2
                }
                onClick={handleApplyChanges}
                variant="outlined"
              >
                Apply Changes
              </Button>
              <Button
                color="secondary"
                onClick={handleCancelChanges}
                variant="text"
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-row gap-3">
            <Button
              className={clsx({
                'h-10 min-w-40 !text-info-dark !rounded !px-4 !bg-white':
                  !allowNodesMerging,
              })}
              color="secondary"
              onClick={() => {
                startSelectionFlow(NodeSelectionModeEnums.BulkCheck);
                onEnableSelectionMode();
              }}
              variant={allowNodesMerging ? 'contained' : 'text'}
            >
              Quick Review Steps
            </Button>
            {allowNodesMerging ? (
              <Button
                color="secondary"
                onClick={() => {
                  startSelectionFlow(NodeSelectionModeEnums.MergeNodes);
                }}
                variant="contained"
              >
                Merge Steps
              </Button>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
