import {
  SourceTypeEnum,
  type SourceVariable,
  type WorkflowNode,
} from 'types-shared';

export const getTriggerBlockTitle = (
  node: WorkflowNode,
  variable: SourceVariable,
) => {
  const shortTitle = getTriggerBlockShortTitle(variable);

  if (shortTitle && node.name) return `${shortTitle}: ${node.name}`;
  if (node.name) return node.name;

  return shortTitle;
};

export const getTriggerBlockShortTitle = (variable: SourceVariable) => {
  const isEmail = variable.data.sourceType === SourceTypeEnum.EmailTrigger;

  return isEmail ? 'Email trigger' : undefined;
};
