import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useMemo,
  useImperativeHandle,
  useCallback,
} from 'react';
import Quill from 'quill';
import {
  TemplateData,
  type GlobalVariable,
  isVariableAllowedToAddInInput,
  Variable,
  type VariableMap,
  VariableRef,
  VariableIdContainer,
} from 'types-shared';
import { AddCircleOutlineOutlined } from 'ui-kit';
import { clsx } from 'clsx';
import VariablesMenu from '../Menu';
import values from 'lodash/values';
import isEqual from 'lodash/isEqual';
import { v4 as uuid } from 'uuid';
import { type VariableInputProps } from './types';
import {
  checkIfVariableHasTransformations,
  isDerivedFromDocumentVariable,
  isEmailVariable,
} from '../../../../utils/helper';
import { handleException } from 'sentry-browser-shared';
import isNil from 'lodash/isNil';

export const VARIABLE_ID_DATA_ATTRIBUTE = 'data-variable-id';
const stripNewLinesGracefully = (str: string) =>
  str.replace(/^\n+|\n+$/g, '').replace(/\n{2,}/g, '\n');
const stripAllNewLines = (str: string) => str.replace(/\n+/g, '');

interface QuillKeyboardBinding {
  key: number;
  handler: () => boolean;
}

interface QuillModules {
  mention: {
    allowedChars: RegExp;
    mentionDenotationChars: string[];
    dataAttributes: string[];
  };
  keyboard: {
    bindings: {
      Enter?: QuillKeyboardBinding | null;
    };
  };
  clipboard: {
    _multiline: boolean;
  };
}

export const VariableInput = forwardRef(
  (
    {
      className,
      value = [],
      label,
      onChange,
      onClickAddNew,
      onClickVariableChip,
      disabled = false,
      allowAddVariable = true,
      showPlusButton = true,
      variablesMap,
      globalVariablesMap,
      placeholder,
      multiline = true,
      willRerender = false,
      containerClassName,
      hideVariableMenuButton = false,
    }: VariableInputProps,
    ref,
  ) => {
    const editorRef = useRef<Quill | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [showVariableMenu, setShowVariableMenu] = useState<boolean>(false);
    const [localVariables, setLocalVariables] = useState<VariableMap>({});
    const initialDataLoadDone = useRef<boolean>(false);

    const hasDeletedVariables = useMemo(() => {
      return value.some(
        (v) =>
          typeof v !== 'string' &&
          isNil(variablesMap[v.id]) &&
          isNil(globalVariablesMap[v.id]),
      );
    }, [variablesMap, globalVariablesMap, value]);

    const editorId = useMemo(() => uuid(), []);

    const stripNewLines = useMemo(
      () => (multiline ? stripNewLinesGracefully : stripAllNewLines),
      [multiline],
    );

    const allowedVariables = useMemo(() => {
      return values(variablesMap).filter((variable: Variable) => {
        return isVariableAllowedToAddInInput(variable);
      });
    }, [variablesMap]);

    const allowedGlobalVariables: GlobalVariable[] = useMemo(
      () => values(globalVariablesMap) as GlobalVariable[],
      [globalVariablesMap],
    );

    const insertVariable = useCallback(
      (variable: Variable, indexForVariableInsert?: number) => {
        if (editorRef.current) {
          const quill = editorRef.current;

          const hasNoContent =
            !value.length || (value.length === 1 && value[0] === '');
          const validIndexForInsert = hasNoContent ? 0 : indexForVariableInsert;
          if (hasNoContent) {
            //  Quill will insert a new line at the top by default so we clear that.
            quill.clipboard.dangerouslyPasteHTML('');
          }
          let range = quill.getSelection();

          // If there is no selection (i.e., the cursor is not placed), place it at the end
          if (!range && !indexForVariableInsert) {
            const length = quill.getLength();
            range = { index: length, length: 0 };
            quill.setSelection(range);
          } else if (indexForVariableInsert) {
            range = {
              index: hasNoContent ? 0 : indexForVariableInsert,
              length: 0,
            };
            quill.setSelection(range);
          }

          const rangeIndex = range ? range.index : quill.getLength();

          let index = validIndexForInsert ?? rangeIndex;

          const textBefore = quill.getText(index - 1, 1);

          // If there's a solitary line break, delete it
          if (textBefore === '\n') {
            quill.deleteText(index - 1, 1);
            index = index - 1 > 0 ? index - 1 : index;
          }

          editorRef.current.insertEmbed(index, 'mention', {
            id: variable.id,
            name: variable.name,
            type: variable.type,
            value: variable.name,
            onClickVariableChip,
            variable,
            isEmailVariable: isEmailVariable(variable, variablesMap),
            isDerivedFromDocument: isDerivedFromDocumentVariable(
              variable,
              variablesMap,
            ),
          });

          editorRef.current.setSelection(index + 1);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onClickVariableChip],
      // We do not want this to fire every time value changes. That would create an infinite render loop
    );

    const insertString = useCallback(
      (content: string) => {
        if (editorRef.current) {
          const cleanedContent = stripNewLines(content);

          if (cleanedContent.length) {
            const range = editorRef.current.getSelection();
            const index = range ? range.index : editorRef.current.getLength();
            editorRef.current.insertText(index, cleanedContent);
            editorRef.current.setSelection(index + cleanedContent.length);
          }
        }
      },
      [stripNewLines],
    );

    const reRenderContent = useCallback(() => {
      if (editorRef.current) {
        editorRef.current.clipboard.dangerouslyPasteHTML('');

        const _variablesUsed = (
          value.filter((v) => {
            if (typeof v !== 'string') {
              const check = VariableRef.safeParse(v);
              if (check.success) {
                return true;
              }
              handleException(
                new Error('Invalid VariableRef data in template data'),
                {
                  name: 'Failed VariableRef parse',
                  source: 'Variable Input re-render',
                  extra: {
                    templateData: value,
                    errorValue: v,
                    err: check.error,
                  },
                },
              );
            }
            return false;
          }) as VariableRef[]
        )
          .map((item) => globalVariablesMap[item.id] ?? variablesMap[item.id])
          .filter((item) => {
            const check = Variable.safeParse(item);
            if (check.success) {
              return true;
            }
            handleException(
              new Error('Invalid Variable data in template data'),
              {
                name: 'Failed Variable parse',
                source: 'Variable Input re-render',
                extra: {
                  templateData: value,
                  errorValue: item,
                  err: check.error,
                },
              },
            );
            return false;
          });

        const _newLocalVariableMap: Record<string, Variable> =
          _variablesUsed.reduce<VariableMap>((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {});

        setLocalVariables(_newLocalVariableMap);

        value.forEach((item) => {
          if (VariableRef.safeParse(item).success) {
            const parsedItem = VariableRef.parse(item);
            const variable = Variable.safeParse(
              globalVariablesMap[parsedItem.id] ?? variablesMap[parsedItem.id],
            );
            if (variable.success) {
              insertVariable(variable.data);
            } else {
              insertVariable({ id: parsedItem.id } as Variable);
              handleException(
                new Error('Invalid Variable data in template data'),
                {
                  name: 'Failed Variable parse',
                  source: 'Variable Input re-render',
                  extra: {
                    templateData: value,
                    errorValue: item,
                    err: variable.error,
                  },
                },
              );
            }
          } else if (typeof item === 'string') {
            const strResult = stripNewLines(item);
            if (strResult) {
              insertString(strResult);
            }
          } else {
            handleException(new Error('Invalid data in template data'), {
              name: 'Failed Variable parse',
              source: 'Variable Input re-render',
              extra: {
                templateData: value,
                errorValue: item,
              },
            });
          }
        });
      }
    }, [
      value,
      variablesMap,
      globalVariablesMap,
      insertString,
      insertVariable,
      stripNewLines,
    ]);

    useImperativeHandle(
      ref,
      () => ({
        addVariable(variable: Variable, indexForVariableInsert?: number) {
          insertVariable(variable, indexForVariableInsert);
        },
        reRender() {
          reRenderContent();
        },
      }),
      [insertVariable, reRenderContent],
    );

    const { variablesHaveChanged, variablesUsed } = useMemo(() => {
      const _variablesUsed = (
        value.filter((v) => {
          if (typeof v !== 'string') {
            const check = VariableRef.safeParse(v);
            if (check.success) {
              return true;
            }
            handleException(
              new Error('Invalid VariableRef data in template data'),
              {
                name: 'Failed VariableRef parse',
                source: 'Variable Input variables changed memo',
                extra: {
                  templateData: value,
                  errorValue: v,
                  err: check.error,
                },
              },
            );
          }
          return false;
        }) as VariableRef[]
      )
        .map((item) => globalVariablesMap[item.id] ?? variablesMap[item.id])
        .filter((item) => {
          const check = Variable.safeParse(item);
          if (check.success) {
            return true;
          }
          handleException(new Error('Invalid Variable data in template data'), {
            name: 'Failed Variable parse',
            source: 'Variable Input variables changed memo',
            extra: {
              templateData: value,
              errorValue: item,
              err: check.error,
            },
          });
          return false;
        });

      const _newLocalVariableMap: Record<string, Variable> =
        _variablesUsed.reduce<VariableMap>((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});

      const _variablesHaventChanged = Object.keys(localVariables).length
        ? _variablesUsed.every((v) => {
            const localVariable = localVariables[v.id];

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const transformationsAreSame = localVariable
              ? checkIfVariableHasTransformations(localVariable) ===
                checkIfVariableHasTransformations(v)
              : true;

            return (
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              localVariable &&
              localVariable.name === v.name &&
              transformationsAreSame
            );
          })
        : true;

      const payload = {
        variablesHaveChanged: !_variablesHaventChanged,
        variablesUsed: _newLocalVariableMap,
      };

      return payload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, variablesMap, localVariables]);
    // We do not need to check globalVariables for changes since we can't update them anyway

    // Resolve mismatch between local state and external variable state
    useEffect(() => {
      const actualLocalVariables = value.filter(
        (v) => typeof v !== 'string',
      ) as { id: string }[];
      const localNotUpdated = actualLocalVariables.some(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (v) => !localVariables[v.id],
      );

      if (localNotUpdated) {
        setLocalVariables(variablesUsed);
      }
    }, [value, localVariables, variablesUsed]);

    // Handle re-rendering variables when the name of a variable changes || when it gets transformed
    useEffect(() => {
      const noValues = !value.length || !Object.keys(localVariables).length;
      if (noValues) return;

      if (variablesHaveChanged) {
        setLocalVariables(variablesUsed);
        editorRef.current?.clipboard.dangerouslyPasteHTML('');

        if (value.length) {
          value.forEach((item) => {
            if (VariableRef.safeParse(item).success) {
              const parsedItem = VariableRef.parse(item);
              const variable =
                globalVariablesMap[parsedItem.id] ??
                variablesMap[parsedItem.id];
              if (Variable.safeParse(variable).success) {
                insertVariable(variable);
              } else {
                insertVariable({ id: parsedItem.id } as Variable);
                handleException(
                  new Error('Invalid Variable data in template data'),
                  {
                    name: 'Failed Variable parse',
                    source: 'Variable Input re-render',
                    extra: {
                      templateData: value,
                      errorValue: item,
                      variable,
                    },
                  },
                );
              }
            } else if (typeof item === 'string') {
              const strResult = stripNewLines(item);
              if (strResult) {
                insertString(strResult);
              }
            }
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variablesHaveChanged]); // We want to listen only to variablesHaveChanged

    const extractInputContent = (): TemplateData => {
      const content = editorRef.current?.getContents();

      const templateMap = content
        ?.map((op) => {
          if (typeof op.insert === 'object' && op.insert.mention) {
            const check = VariableIdContainer.safeParse(op.insert.mention);

            if (check.success) {
              const mention = check.data.variableId;

              return { id: mention };
            }
            handleException(
              new Error('Invalid VariableIdContainer data in templateMap'),
              {
                name: 'Failed VariableIdContainer parse',
                source: 'Variable Input extractInputContent',
                extra: {
                  op,
                  err: check.error,
                },
              },
            );
          }
          return op.insert;
        })

        .filter((v) => Boolean(v)) as TemplateData;

      const check = TemplateData.safeParse(templateMap);

      if (check.success) {
        return check.data;
      }

      handleException(new Error('Invalid TemplateData'), {
        name: 'Failed TemplateData parse',
        source: 'Variable Input extractInputContent',
        extra: {
          templateMap,
          err: check.error,
        },
      });

      return [];
    };

    const showPlaceholder =
      placeholder && (!value.length || (value.length === 1 && value[0] === ''));

    useEffect(() => {
      if (!editorRef.current) {
        const quill = new Quill(`#editor-${editorId}`, {
          modules: {
            clipboard: {
              _multiline: multiline,
            },
            mention: {
              allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
              mentionDenotationChars: ['@', '#'],
              dataAttributes: ['id', 'name', 'denotationChar', 'type'],
            },

            // Disable the enter key if multiline is false
            ...(!multiline
              ? {
                  keyboard: {
                    bindings: {
                      enter: {
                        key: 13,
                        handler() {
                          return false;
                        },
                      },
                    },
                  },
                }
              : {}),
          },
        });

        // Disable the enter key again if multiline is false. Both disable blocks are needed
        if (!multiline) {
          const keyboard = quill.getModule(
            'keyboard',
          ) as QuillModules['keyboard'];

          keyboard.bindings.Enter = null;
        } else {
          // Include any new lines in pasted content if multiline is true
          const clipboard = quill.getModule(
            'clipboard',
          ) as QuillModules['clipboard'];

          clipboard._multiline = true;
        }

        editorRef.current = quill;

        // Clear the new lines that quill inserts by default.
        quill.clipboard.dangerouslyPasteHTML('');

        quill.on('text-change', () => {
          if (onChange) {
            const templateData = extractInputContent();
            const hasChanged = !isEqual(templateData, value);

            if (hasChanged) {
              const cleanedData = templateData
                .map((val) => {
                  if (typeof val === 'string') {
                    return val === '\n' ? '' : stripNewLines(val);
                  }
                  return val;
                })
                .filter((val) => val);

              onChange(cleanedData);
            }
          }
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variablesMap, value]);

    const contentDidNotLoad = useMemo(() => {
      if (initialDataLoadDone.current || !editorRef.current || willRerender) {
        return false;
      }
      const templateData = extractInputContent();
      const hasChanged = !isEqual(templateData, value);

      const valueIsOnlyNewLine =
        value.length && value[0] === '\n' && value.length === 1;

      const valueExists =
        value.length && !valueIsOnlyNewLine && !initialDataLoadDone.current;

      return hasChanged && valueExists;

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, editorRef.current, willRerender]);
    // Need to keep track of if the editor has been initialized

    useEffect(() => {
      if (
        contentDidNotLoad &&
        !initialDataLoadDone.current &&
        editorRef.current
      ) {
        initialDataLoadDone.current = true;

        editorRef.current.clipboard.dangerouslyPasteHTML('');

        const _variablesUsed = (
          value.filter((v) => {
            if (typeof v !== 'string') {
              const check = VariableRef.safeParse(v);
              if (check.success) {
                return true;
              }
              handleException(
                new Error('Invalid VariableRef data in template data'),
                {
                  name: 'Failed VariableRef parse',
                  source: 'Variable Input useEffect',
                  extra: {
                    templateData: value,
                    errorValue: v,
                    err: check.error,
                  },
                },
              );
            }
            return false;
          }) as VariableRef[]
        )
          .map((item) => globalVariablesMap[item.id] ?? variablesMap[item.id])
          .filter((item) => {
            const check = Variable.safeParse(item);
            if (check.success) {
              return true;
            }
            handleException(
              new Error('Invalid Variable data in template data'),
              {
                name: 'Failed Variable parse',
                source: 'Variable Input useEffect',
                extra: {
                  templateData: value,
                  errorValue: item,
                  err: check.error,
                },
              },
            );
            return false;
          });

        const _newLocalVariableMap: Record<string, Variable> =
          _variablesUsed.reduce<VariableMap>((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {});

        setLocalVariables(_newLocalVariableMap);

        value.forEach((item) => {
          if (VariableRef.safeParse(item).success) {
            const parsedItem = VariableRef.parse(item);
            const variable =
              globalVariablesMap[parsedItem.id] ?? variablesMap[parsedItem.id];
            if (Variable.safeParse(variable).success) {
              insertVariable(variable);
            } else {
              insertVariable({ id: parsedItem.id } as Variable);
              handleException(
                new Error('Invalid Variable data in template data'),
                {
                  name: 'Failed Variable parse',
                  source: 'Variable Input useEffect',
                  extra: {
                    templateData: value,
                    errorValue: item,
                    variable,
                  },
                },
              );
            }
          } else if (typeof item === 'string') {
            const strResult = stripNewLines(item);
            if (strResult) {
              insertString(strResult);
            }
          } else {
            handleException(
              new Error('Invalid Variable data in template data'),
              {
                name: 'Failed Variable parse',
                source: 'Variable Input useEffect',
                extra: {
                  templateData: value,
                  errorValue: item,
                },
              },
            );
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentDidNotLoad]); // Don't need to listen to global variables

    return (
      <div
        className={clsx('flex flex-col space-y-1 w-full', containerClassName)}
      >
        <div
          className={clsx(
            'relative flex border pr-1 w-full rounded min-h-14 focus-within:border-gray-400',
            !multiline && '!h-[56px]',
            className,
            { '!border-error': hasDeletedVariables },
          )}
          ref={containerRef}
          style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}
        >
          {label ? (
            <span className="absolute -top-2 left-3 text-gray-500 bg-white px-1 text-xs">
              {label}
            </span>
          ) : null}

          {showPlaceholder ? (
            <div
              className="absolute top-[12px] left-[15px] right-[40px] text-gray-400 pointer-events-none whitespace-pre-wrap"
              style={{
                fontSize: '16px',
              }}
            >
              {placeholder}
            </div>
          ) : null}

          <div
            id={`editor-${editorId}`}
            className={clsx({
              'w-full outline-none mt-1 leading-7 text-base h-full ql-align':
                true,
              'no-multiline': !multiline,
            })}
            style={{
              fontSize: '16px',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              marginTop: '4px',
              ...(showPlusButton
                ? {
                    paddingRight: '40px',
                  }
                : {}),
              ...(!multiline
                ? {
                    whiteSpace: 'nowrap',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                  }
                : {}),
            }}
          />

          {hideVariableMenuButton ? null : (
            <button
              className={clsx('absolute right-1 top-2.5 flex p-1.5 rounded', {
                'text-gray-400  !cursor-not-allowed': disabled,
                'text-blue-500 hover:bg-blue-500 hover:text-white': !disabled,
                '!hidden': !showPlusButton,
              })}
              disabled={disabled}
              onClick={() => {
                setShowVariableMenu(true);
              }}
              type="button"
            >
              <AddCircleOutlineOutlined className="!w-5 !h-5" />
            </button>
          )}

          {showVariableMenu ? (
            <VariablesMenu
              allowAddVariable={allowAddVariable}
              anchorEl={containerRef.current}
              onAddNew={() => {
                setShowVariableMenu(false);
                onClickAddNew?.(
                  editorRef.current?.getSelection()?.length ??
                    editorRef.current?.getLength(),
                );
              }}
              onClose={() => {
                setShowVariableMenu(false);
              }}
              onSelect={(maybeVariable) => {
                const check = Variable.safeParse(maybeVariable);
                if (check.success) {
                  insertVariable(check.data);
                }
              }}
              open={showVariableMenu}
              variables={allowedVariables}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              globalVariables={allowedGlobalVariables}
            />
          ) : null}
        </div>
        {hasDeletedVariables ? (
          <span className="text-error text-xs px-2 mt-1.5">
            One or more variables have been deleted. Replace or remove them to
            run the workflow.
          </span>
        ) : null}
      </div>
    );
  },
);

VariableInput.displayName = 'VariableInput';
