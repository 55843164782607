"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveLinearizedFileSchema = void 0;
var types_shared_1 = require("types-shared");
exports.resolveLinearizedFileSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            fileId: types_shared_1.z.string(),
        }),
    }),
    response: types_shared_1.z.object({
        url: types_shared_1.z.string(),
        status: types_shared_1.z.enum(['ready', 'processing', 'error']),
        error: types_shared_1.z.string().optional(),
    }),
};
