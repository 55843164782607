import { useState, useEffect, useRef, useMemo } from 'react';
import type { SelectedAction } from './ActionsList';
import { Button, Input } from 'ui-kit';
import { EditorStore } from '../../store/EditorState';
import type { Target, WorkflowNode } from 'types-shared';
import { ActionHeader } from './ActionHeader';
import { useSearchParams } from 'react-router-dom';
import { removeNode } from '../../utils/helper';

interface Props {
  action: SelectedAction;
  onClose: () => void;
  editingMultiple?: boolean;
}

function EditTarget({ action, onClose, editingMultiple }: Props) {
  const {
    targets,
    updateTarget,
    nodes,
    selectedNode,
    setSelectedNode,
    variables,
    globalVariables,
    edges,
    setNodes,
    setEdges,
  } = EditorStore();
  const [, setSearchParams] = useSearchParams();
  const targetInfo = action.targetId ? targets[action.targetId] : null;
  const initialTargetRef = useRef<Target | null>(
    action.targetId ? targets[action.targetId] : null,
  );
  const [updatedTargetInfo, setUpdatedTargetInfo] = useState<string>(
    JSON.stringify(targetInfo, null, 2),
  );
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    try {
      const json = JSON.parse(updatedTargetInfo) as Target;
      setHasError(false);
      updateTarget(json);
    } catch (error) {
      setHasError(true);
    }
  }, [updateTarget, updatedTargetInfo]);

  const onCancel = () => {
    updateTarget((initialTargetRef.current ?? {}) as Target);
    onClose();
  };

  const selectedNodeData: WorkflowNode | undefined = useMemo(() => {
    return nodes.find((_node) => _node.id === selectedNode);
  }, [nodes, selectedNode]);

  const selectedVariable = action.variableId
    ? variables[action.variableId] ?? globalVariables?.[action.variableId]
    : null;

  const onDeleteNode = () => {
    if (!selectedNode) return;
    const { nodes: filteredNodes, edges: filteredEdges } = removeNode(
      nodes,
      edges,
      selectedNode,
    );
    setSearchParams({});
    setSelectedNode(null);
    setNodes(filteredNodes);
    setEdges(filteredEdges);
  };

  return (
    <>
      {editingMultiple ? null : (
        <ActionHeader
          handleOnClose={onClose}
          node={selectedNodeData}
          selectedAction={action}
          selectedVariable={selectedVariable}
          setSelectedNode={setSelectedNode}
          onDeleteNode={onDeleteNode}
        />
      )}
      <div className="flex-1 flex flex-col my-10 border rounded-lg px-4 py-6">
        <div className="flex justify-between items-center">
          <h2 className="flex items-center space-x-3">
            <span className="text-xs text-white rounded-full h-6 w-6 flex justify-center items-center bg-gray-800">
              {action.i}
            </span>
            <span className="font-medium text-lg">{action.actionType}</span>
          </h2>
        </div>

        <Input
          classes={{ wrapper: 'flex flex-col mt-3' }}
          error={hasError}
          errorText={hasError ? 'Invalid Target Object' : ''}
          floatingLabel
          label="Edit Target"
          multiline
          onChange={setUpdatedTargetInfo}
          rows={28}
          value={updatedTargetInfo}
        />

        <div className="flex flex-row justify-between gap-4 mt-auto pt-4">
          <Button
            className="flex-1"
            color="secondary"
            disabled={hasError}
            onClick={onClose}
            variant="contained"
          >
            SAVE
          </Button>
          <Button
            className="flex-1"
            color="secondary"
            onClick={onCancel}
            variant="outlined"
          >
            CANCEL
          </Button>
        </div>
      </div>
    </>
  );
}

export default EditTarget;
