import type { GridColDef } from '@mui/x-data-grid/models';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import Skeleton from '@mui/material/Skeleton';
import { v4 as uuid } from 'uuid';
import { type GlobalVariable } from 'hasura-gql';

interface Props {
  columns: GridColDef<GlobalVariable>[];
}

const fakeData = Array.from({ length: 10 }, () => ({
  id: uuid(),
}));

export function GlobalVariablesListSkeleton({ columns }: Props) {
  return (
    <DataGrid
      columns={columns.map((column) => ({
        ...column,
        renderCell: () => (
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem' }}
            className="w-4/12"
            height={40}
          />
        ),
      }))}
      hideFooter
      getRowId={(row) => row.id}
      rows={fakeData as never}
      sx={{
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: 'white',
        },
      }}
    />
  );
}

export function GlobalVariableModalSkeleton() {
  return (
    <>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={56}
        className="mb-10 rounded-sm"
      />

      <Skeleton
        variant="rectangular"
        width="100%"
        height={100}
        className="mb-10 rounded-sm"
      />
    </>
  );
}
