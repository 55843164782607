import { SourceTypeEnum, type SourceVariable } from 'types-shared';
import { clsx } from 'clsx';
import { TriggerBlockIcon, MailIcon } from 'ui-kit';
import React from 'react';

export default function TriggerBlockImage({
  variable,
  className,
}: {
  variable: SourceVariable;
  className?: string;
}) {
  const isEmail = variable.data.sourceType === SourceTypeEnum.EmailTrigger;

  if (isEmail)
    return (
      <MailIcon className={clsx(className, '!w-16 !h-16 text-cyan-900')} />
    );

  return (
    <TriggerBlockIcon
      className={clsx(className, '!w-16 !h-16 text-cyan-900')}
    />
  );
}
