import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function TrashIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.667 8.333v5.834M8.334 8.333v5.834M5 5v9.833c0 .934 0 1.4.182 1.757.16.313.414.569.728.729.356.181.823.181 1.754.181h4.672c.932 0 1.397 0 1.754-.181.313-.16.569-.416.729-.73.181-.355.181-.821.181-1.753V5M5 5h1.667M5 5H3.333m3.334 0h6.667M6.667 5c0-.777 0-1.165.127-1.471.169-.408.493-.733.901-.902.307-.127.695-.127 1.472-.127h1.667c.776 0 1.164 0 1.47.127.409.169.733.494.902.902.127.306.128.694.128 1.471m0 0H15m0 0h1.667"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
