import Menu from '@mui/material/Menu';
import { useState } from 'react';
import {
  type SelectOption,
  type GlobalVariable,
  type Variable,
  type VariableMap,
} from 'types-shared';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import SelectMenuContent from './SelectMenuContent';
import VariableMenuContent from './VariableMenuContent';

interface Props {
  anchorEl: Element | null;
  open: boolean;
  onClose: () => void;
  onSelect: (val: Variable | string) => void;
  onAddNew?: () => void;
  variables: Variable[];
  globalVariables: GlobalVariable[];
  allowAddVariable: boolean;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  options?: SelectOption[];
  isSelect?: boolean;
}

enum MenuTypes {
  'Select Options' = 'Select Options',
  Variables = 'Variables',
}

function VariablesMenu({
  anchorEl,
  open,
  onClose,
  onSelect,
  onAddNew,
  variables = [],
  globalVariables = [],
  allowAddVariable = true,
  variablesMap,
  globalVariablesMap,
  options,
  isSelect,
}: Props) {
  const [selectedTab, setSelectedTab] = useState<MenuTypes>(
    MenuTypes['Select Options'],
  );

  const onTabChange = (_event: React.SyntheticEvent, newValue: MenuTypes) => {
    setSelectedTab(newValue);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
      classes={{
        paper: '!max-w-none',
        list: '!p-0',
      }}
      className="min-w-[30rem]"
      onClose={onClose}
      open={open}
      key={selectedTab}
      slotProps={{
        paper: {
          sx: {
            width: 'auto',
            minHeight: '400px',
            maxHeight: '650px',
            display: 'flex',
            flexDirection: 'column',
          },
        },
      }}
      sx={{
        '& .MuiBackdrop-root': { backgroundColor: 'transparent' },
        '& .MuiList-root': {
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {isSelect ? (
        <Box
          className="zoom-adjusted-container"
          sx={{
            flexShrink: 0,
          }}
        >
          <Tabs
            onChange={onTabChange}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: '#3274fb',
              },
              '& .Mui-selected': {
                color: '#3274fb !important',
              },
            }}
            value={selectedTab}
          >
            <Tab
              className="flex-1"
              value={MenuTypes['Select Options']}
              label={MenuTypes['Select Options']}
            />

            <Tab
              className="flex-1"
              value={MenuTypes.Variables}
              label={MenuTypes.Variables}
            />
          </Tabs>
        </Box>
      ) : null}

      {isSelect &&
      selectedTab === MenuTypes['Select Options'] &&
      options?.length ? (
        <SelectMenuContent
          onClose={onClose}
          onSelect={onSelect}
          options={options}
        />
      ) : null}

      {Boolean(isSelect && selectedTab === MenuTypes.Variables) || !isSelect ? (
        <VariableMenuContent
          onClose={onClose}
          onSelect={onSelect}
          onAddNew={onAddNew}
          variables={variables}
          globalVariables={globalVariables}
          allowAddVariable={allowAddVariable}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
        />
      ) : null}
    </Menu>
  );
}

export default VariablesMenu;
