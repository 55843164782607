import { Flex } from 'ui-kit';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import type { GridColDef } from '@mui/x-data-grid/models';
import { v4 as uuid } from 'uuid';
import Skeleton from '@mui/material/Skeleton';
import type { ExecutionBase } from 'types-shared';

interface Props {
  columns: GridColDef<ExecutionBase>[];
}

const fakeData = Array.from({ length: 10 }, () => ({
  executionId: uuid(),
}));

export function WorkflowDetailSkeleton({ columns }: Props) {
  return (
    <DataGrid
      columns={columns.map((column) => ({
        ...column,
        renderCell: () => (
          <Skeleton
            variant="text"
            sx={{ fontSize: '1rem' }}
            className="w-9/12"
            height={40}
          />
        ),
      }))}
      getRowId={(row) => row.executionId}
      rows={fakeData as never}
    />
  );
}

export function WorkflowDetailSkeletonHeader() {
  return (
    <Flex alignItems="flex-start" justifyContent="space-between">
      <Flex alignItems="flex-end">
        <Skeleton
          variant="text"
          sx={{ fontSize: '1.5rem' }}
          height={60}
          width={400}
        />
      </Flex>
      <Skeleton variant="rounded" width={100} height={45} />
    </Flex>
  );
}
