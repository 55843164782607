import { produce } from 'immer';
import {
  VariableTypeEnum,
  type QueryVariable,
  type ScrapeVariable,
  type SourceVariable,
  type TemplateVariable,
  type Variable,
  type VariableMap,
  type VariableStateData,
} from 'types-shared/workflowTypes';
import type { StateCreator } from 'zustand';
import { executionVariableMap } from '../utils/constants';
import { posthog } from 'posthog-js';
import { FeatureFlag } from '../../../utils/constants';
import values from 'lodash/values';
import pickBy from 'lodash/pickBy';

type Data =
  | Pick<SourceVariable, 'data'>
  | Pick<ScrapeVariable, 'data'>
  | Pick<TemplateVariable, 'data'>
  | Pick<QueryVariable, 'data'>;

export const initialVariableState: VariableStateData = {
  variables: {},
  globalVariables: {},
};

export interface VariableStateActions {
  setVariables: (variables: VariableMap) => void;
  setGlobalVariables: (variables: VariableMap) => void;
  addVariable: (variable: Variable) => void;
  addVariables: (variables: VariableMap) => void;
  deleteVariable: (variableId: string) => void;
  updateVariableData: (variableId: string, data: Data) => void;
  updateVariable: (variable: Variable) => void;
  resetVariables: () => void;
}

export const VariableState: StateCreator<
  VariableStateData & VariableStateActions
> = (set) => ({
  ...initialVariableState,
  setVariables: (variables: VariableMap) => {
    const executionVariablesEnabled =
      posthog.getFeatureFlag(FeatureFlag.SystemVariables) ?? true;
    set(
      produce((state: VariableStateData) => {
        const executionVariableNamesList = values(variables)
          .filter((variable) => variable.type === VariableTypeEnum.Execution)
          .map((variable) => variable.name);
        state.variables = {
          ...variables,
          ...(executionVariablesEnabled
            ? pickBy(
                executionVariableMap,
                (variable) =>
                  !executionVariableNamesList.includes(variable.name),
              )
            : {}),
        };
      }),
    );
  },
  setGlobalVariables: (globalVariables: VariableMap) => {
    set(
      produce((state: VariableStateData) => {
        state.globalVariables = globalVariables;
      }),
    );
  },
  addVariable: (variable: Variable) => {
    set(
      produce((state: VariableStateData) => {
        state.variables[variable.id] = variable;
      }),
    );
  },
  addVariables: (variables: VariableMap) => {
    set(
      produce((state: VariableStateData) => {
        state.variables = {
          ...state.variables,
          ...variables,
        };
      }),
    );
  },
  deleteVariable: (variableId: string) => {
    set(
      produce((state: VariableStateData) => {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete state.variables[variableId];
      }),
    );
  },
  updateVariableData: (variableId: string, { data }: Data) => {
    set(
      produce((state: VariableStateData) => {
        if (variableId in state.variables) {
          const oldVariable = state.variables[variableId];
          oldVariable.data = data;
          state.variables[variableId] = oldVariable;
        }
      }),
    );
  },
  updateVariable: (variable: Variable) => {
    set(
      produce((state: VariableStateData) => {
        state.variables[variable.id] = variable;
      }),
    );
  },
  resetVariables: () => {
    set(initialVariableState);
  },
});
