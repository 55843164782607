import { CloseIcon, EditOutlined, Button, Input } from 'ui-kit';
import {
  TemplateVariable,
  type WorkflowFreeformNode,
  type GlobalVariable,
  type VariableMap,
  type TemplateData,
  type Variable,
  SourceTypeEnum,
  type DatasourceMetadata,
  isVariableAllowedToAddInInput,
  NodeStatusEnum,
} from 'types-shared';
import { useState, useRef, useMemo, useEffect } from 'react';
import { NodeCheck } from '../NodeCheck';
import {
  VariableInput,
  type VariableInputRef,
} from '../VariableTypes/VariableInput';
import {
  openAddVariableModal,
  openPreviewVariableModal,
} from '../../../../utils/helper';
import { handleException } from 'sentry-browser-shared';

interface Props {
  node: WorkflowFreeformNode;
  variablesMap: VariableMap;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  updateNodeName: (name: string) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  onCancel: () => void;
  sourceType?: SourceTypeEnum;
  datasourceMetadata: DatasourceMetadata | null;
  updateVariable: (variable: Variable) => void;
}

export function FreeformBlock({
  node,
  variablesMap,
  globalVariablesMap,
  onCancel,
  updateNodeName,
  updateNodeStatus,
  sourceType,
  datasourceMetadata,
  updateVariable,
}: Props) {
  const inputRef = useRef<VariableInputRef>();
  const prevInputData = useRef<TemplateData>([]);

  const [editing, setEditing] = useState(false);
  const hasDatasource = Boolean(datasourceMetadata);

  const instructions = useMemo(() => {
    if (!node.data.instructions.variableId) {
      return [];
    }
    const instructionsVariable =
      variablesMap[node.data.instructions.variableId];
    if (!TemplateVariable.safeParse(instructionsVariable).success) {
      handleException(
        new Error('freeform instruction variable is not a template variable'),
        {
          name: 'FreeformBlock',
          source: 'FreeformBlock',
          extra: {
            variableId: node.data.instructions.variableId,
            instructionsVariable,
            variablesMap,
          },
        },
      );
      return [];
    }
    return instructionsVariable.data as TemplateData;
  }, [variablesMap, node.data.instructions.variableId]);

  const handleUpdateNodeInstructions = (data: TemplateData) => {
    const instructionsVariable = variablesMap[
      node.data.instructions.variableId
    ] as TemplateVariable;

    updateVariable({
      ...instructionsVariable,
      data,
    });
  };

  const updateBranchData = (variableToAdd: Variable) => {
    if (!isVariableAllowedToAddInInput(variableToAdd)) {
      return;
    }
    const data: TemplateData = [{ id: variableToAdd.id }];
    const instructionsVariable = variablesMap[
      node.data.instructions.variableId
    ] as TemplateVariable;

    updateVariable({
      ...instructionsVariable,
      data: instructionsVariable.data.length
        ? [...instructionsVariable.data, ...data]
        : data,
    });
  };

  useEffect(() => {
    if (instructions.length && !prevInputData.current.length) {
      // Force a re-render if the content has changed, but not yet reflected
      prevInputData.current = instructions;
      inputRef.current?.reRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructions, prevInputData.current]);

  return (
    <div className="zoom-adjusted-container node-block absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] p-8 flex flex-col justify-between space-y-5">
      <div className="overflow-auto">
        <div className="flex justify-between items-center">
          <span className="text-sm text-primary-blue font-medium">
            Freeform Instructions
          </span>
          <Button
            className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
            color="secondary"
            onClick={onCancel}
            variant="outlined"
          >
            <CloseIcon className="text-info" />
          </Button>
        </div>
        <div className="my-6">
          <h2 className="text-lg font-medium">Freeform Instructions</h2>
          <p className="text-sm font-normal mt-2 text-info-dark">
            For complex or unsupported actions, provide instructions or requests
            so that we can handle them in your workflow.
          </p>
        </div>
        <div className="freeform-block flex-1 flex flex-col gap-4">
          <div className="relative pt-1">
            <Input
              InputProps={{ className: '!py-1' }}
              label={
                <label
                  className="text-xs absolute bg-white z-10 top-0 ml-3 px-1 mt-0.5 text-gray-400"
                  htmlFor="name"
                >
                  Step Name
                </label>
              }
              onChange={updateNodeName}
              placeholder="Step Name"
              value={node.name ?? ''}
            />
          </div>
          <div className="flex flex-row items-center mt-6 justify-between">
            <p className="font-medium text-info-dark text-sm">Instructions</p>
            {editing ? (
              <button
                className="cursor-pointer"
                onClick={() => {
                  setEditing(true);
                }}
                type="button"
              >
                <EditOutlined className="text-info-dark" />
              </button>
            ) : null}
          </div>
          <div className="relative pt-1">
            <VariableInput
              ref={inputRef}
              willRerender
              multiline
              label="Instructions"
              onChange={(val) => {
                if (val.length === 0) {
                  handleUpdateNodeInstructions(['']);
                } else {
                  handleUpdateNodeInstructions(val);
                }
              }}
              value={instructions}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              placeholder="Provide detailed instructions for this step in your workflow"
              className="!min-h-[174px]"
              allowAddVariable={
                hasDatasource ||
                sourceType === SourceTypeEnum.API ||
                sourceType === SourceTypeEnum.EmailTrigger
              }
              onClickVariableChip={(variableId) => {
                openPreviewVariableModal({
                  variableId,
                });
              }}
              onClickAddNew={(indexForVariableInsert) => {
                openAddVariableModal({
                  insertVariable(newVariable: Variable) {
                    inputRef.current?.addVariable(
                      newVariable,
                      indexForVariableInsert,
                    );
                  },
                  updateBranchData,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-7">
        {!node.hideFromUser ? (
          <NodeCheck
            isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
            updateNodeStatus={updateNodeStatus}
          />
        ) : null}
        <Button
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          Back to flow view
        </Button>
      </div>
    </div>
  );
}
