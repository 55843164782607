import { type SourceVariable, type WorkflowNode } from 'types-shared';
import TriggerBlockImage from '../../EditNodePanel/TriggerBlock/TriggerBlockImage';
import { getTriggerBlockTitle } from '../../EditNodePanel/TriggerBlock/trigger.helpers';

interface Props {
  node: WorkflowNode;
  onClick?: () => void;
  variable?: SourceVariable;
}

export function TriggerNode({ node, onClick, variable }: Props) {
  if (!variable) return null;
  return (
    <>
      <button
        className="flex-1 rounded-lg border border-indigo-light bg-gray-100 flex justify-center items-center"
        onClick={onClick}
        type="button"
      >
        <TriggerBlockImage variable={variable} />
      </button>
      <span className="text-sm font-bold truncate text-white max-w-[12rem]">
        {getTriggerBlockTitle(node, variable)}
      </span>
    </>
  );
}
