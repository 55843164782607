import { clsx } from 'clsx';
import type {
  WorkflowAction,
  WorkflowNode,
  Variable,
  ScrapeVariable,
} from 'types-shared';
import { ArrowLeftIcon, Button, IconButton } from 'ui-kit';
import { useSearchParams } from 'react-router-dom';
import { isAdmin } from '../../../../utils/env';

interface Props {
  onDeleteNode?: () => void;
  selectedAction?: WorkflowAction;
  selectedActionIndex?: number;
  selectedVariable?: Variable | ScrapeVariable | null;
  handleOnClose?: () => void;
  node: WorkflowNode | undefined;
  setSelectedNode: (nodeId: string | null) => void;
}

export function ActionHeader({
  onDeleteNode,
  selectedAction,
  selectedActionIndex,
  selectedVariable,
  handleOnClose,
  node,
  setSelectedNode,
}: Props) {
  const [, setSearchParams] = useSearchParams();

  const onClose = () => {
    if (handleOnClose) {
      handleOnClose();
    } else {
      setSearchParams({});
      setSelectedNode(null);
    }
  };
  const paddedActionIndex = selectedActionIndex
    ? `${selectedActionIndex.toString().padStart(2, '0')}.`
    : '';

  const truncateName = (name: string) => {
    return name.length > 12 ? `${name.slice(0, 12 - 3)}...` : name;
  };

  if (!node) {
    return null;
  }

  return (
    <div className="flex items-center space-x-2">
      <IconButton
        className="!border !border-solid !border-info !rounded-lg !h-10 !w-10"
        onClick={onClose}
      >
        <ArrowLeftIcon className="text-info" />
      </IconButton>
      <div className="flex flex-col text-xs !ml-2">
        <span className="text-gray-500">Workflow</span>
        <div className="flex items-center space-x-1">
          <span
            className={clsx('font-semibold', {
              'text-info': !selectedAction,
            })}
          >
            {`Step: ${node.id.slice(0, 5)} ${selectedVariable?.name ? '/' : ''}`}
          </span>
          {selectedVariable?.name ? (
            <span className="font-semibold text-info">
              {paddedActionIndex}
              {truncateName(selectedVariable.name)}
            </span>
          ) : null}
        </div>
      </div>
      {isAdmin ? (
        <Button
          className="!ml-auto"
          color="secondary"
          onClick={onDeleteNode}
          variant="outlined"
        >
          Delete Node
        </Button>
      ) : null}
    </div>
  );
}
