import {
  type DocumentVariable,
  type QueryVariable,
  type Variable,
  type GlobalVariable,
  type VariableMap,
} from 'types-shared';
import React from 'react';
import { useDocumentQueryVariables } from './useDocumentQueryVariables';
import { VariableChip } from '../../../../../components/VariableChip';

interface Props {
  documentVariables: DocumentVariable[];
  variablesMap: Record<string, Variable>;
  noDataUI?: React.ReactNode;
  onClick?: (variable: QueryVariable) => void;
  selectedVariableId?: string;
  onDelete?: (variable: QueryVariable) => void;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
}

export function DocumentLinkedQueryVariables({
  documentVariables,
  variablesMap,
  globalVariablesMap,
  noDataUI,
  onClick,
  selectedVariableId,
  onDelete,
}: Props) {
  const queryVariablesLinkedToDocumentVariables = useDocumentQueryVariables({
    documentVariables,
    variablesMap,
  });

  if (queryVariablesLinkedToDocumentVariables.length === 0)
    return <>{noDataUI}</>;

  return (
    <div className="flex flex-wrap gap-2 mt-2">
      {queryVariablesLinkedToDocumentVariables.map((v) => {
        return (
          <VariableChip
            key={v.id}
            variableId={v.id}
            variablesMap={variablesMap}
            onClick={() => {
              if (onClick) {
                onClick(v);
              }
            }}
            bordered={v.id === selectedVariableId}
            globalVariablesMap={globalVariablesMap}
            onRemove={
              onDelete
                ? () => {
                    onDelete(v);
                  }
                : undefined
            }
          />
        );
      })}
    </div>
  );
}
