import { useMutation } from '@tanstack/react-query';
import { useAPI } from './useApi';
import {
  type SendSlackMessageWithFileRequestPayload,
  type SendSlackMessageWithFileResponse,
} from 'api-types-shared';
import { AlertVariant, notify } from 'ui-kit';

export function useSendSlackMessageWithFile() {
  const { miscSDK: sdk } = useAPI();
  return useMutation<
    SendSlackMessageWithFileResponse,
    Error,
    SendSlackMessageWithFileRequestPayload
  >({
    mutationFn: async (request) => {
      const data = await sdk.sendSlackMessageWithFile(request);
      notify({
        message: `Your query has been submitted to our support team. We will get back to you soon!`,
        variant: AlertVariant.INFO,
      });
      return data;
    },
  });
}
