import { QueryVariable } from 'types-shared';
import { LoadingManualRun } from './LoadingManualRun';
import { GrayedOutInput, Input } from 'ui-kit';

export interface WorkflowRunVariablesProps {
  variables: QueryVariable[];
  isFetchingNodeViewData: boolean;
  isPendingTest: boolean;
  setVariableState: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  variableState: Record<string, string>;
  isSchedule?: boolean;
  isEditing?: boolean;
}

export function WorkflowRunVariables({
  variables,
  isFetchingNodeViewData,
  isPendingTest,
  setVariableState,
  variableState,
  isSchedule,
  isEditing,
}: WorkflowRunVariablesProps) {
  return (
    <>
      {variables.length ? (
        <span className="text-info-dark text-normal font-medium">
          Workflow variables
        </span>
      ) : null}

      {isSchedule && !isEditing ? null : (
        <span className="text-gray-500 text-sm">
          {variables.length > 0
            ? 'Enter values for variables in the workflow and execute a test.'
            : 'Once you add API variables to your workflow, you can enter values in this section to test API requests with different input data.'}
        </span>
      )}

      {isFetchingNodeViewData && variables.length === 0 ? (
        <LoadingManualRun />
      ) : null}
      {variables.length > 0 ? (
        <div className="flex flex-col !mt-4 gap-y-4 overflow-y-auto dark-scrollbar">
          {variables.map((v) => {
            const parseResult = QueryVariable.safeParse(v);

            if (!parseResult.success) return null;

            const variableName = parseResult.data.name;

            return variableName ? (
              <>
                {isSchedule && !isEditing ? (
                  <GrayedOutInput
                    label={parseResult.data.name}
                    value={variableState[variableName] ?? ''}
                  />
                ) : (
                  <Input
                    disabled={isPendingTest}
                    floatingLabel
                    id={parseResult.data.id}
                    label={parseResult.data.name}
                    placeholder="Enter value"
                    key={parseResult.data.id}
                    onChange={(data) => {
                      setVariableState((s) => ({
                        ...s,
                        [variableName]: data,
                      }));
                    }}
                    value={variableState[variableName] ?? ''}
                  />
                )}
              </>
            ) : null;
          })}
        </div>
      ) : null}
    </>
  );
}
