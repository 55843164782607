import { useNavigate, useParams } from 'react-router-dom';
import { useAddApiKey, useGetApiKey, useUpdateApiKey } from '../hooks';
import {
  AlertVariant,
  Button,
  ContentContainer,
  Input,
  notify,
  ScrollableContainer,
  CustomTypography,
  ContentCopy,
} from 'ui-kit';
import { Toolbar } from '../../../components/Toolbar';
import { useEffect, useState } from 'react';
import { type ApiKeyFull } from 'types-shared/userTypes';

export default function CreateEditApiKey() {
  const { id: editingApiKeyId } = useParams();
  const navigate = useNavigate();

  const { mutateAsync: addApiKey, isPending: isAdding } = useAddApiKey();
  const { mutateAsync: updateApiKey, isPending: isUpdating } =
    useUpdateApiKey();
  const { data: editingApiKey } = useGetApiKey(editingApiKeyId);

  const isLoading = isAdding || isUpdating;
  const isEditMode = Boolean(editingApiKeyId);
  const [name, setName] = useState<string>(editingApiKey?.name ?? '');
  const [addedApiKey, setAddedApiKey] = useState<ApiKeyFull>();

  const goBack = () => {
    navigate('/api-keys-manager', { replace: true });
  };

  const handleOnClickCreate = async () => {
    const added = await addApiKey({ name });
    setAddedApiKey(added);
    notify({
      message: 'The API Key has been created successfully.',
      variant: AlertVariant.SUCCESS,
    });
  };

  const handleOnClickUpdate = async () => {
    if (!editingApiKey) return;

    await updateApiKey({ name, keyId: editingApiKey.keyId });
    notify({
      message: 'The API Key has been updated successfully.',
      variant: AlertVariant.SUCCESS,
    });
    goBack();
  };

  const handleOnCopy = () => {
    if (!addedApiKey) return;

    navigator.clipboard
      .writeText(addedApiKey.key)
      .then(() => {
        notify({
          message: 'API Key copied!',
          variant: AlertVariant.SUCCESS,
        });
      })
      .catch(() => {
        notify({
          message: 'Unable to copy API Key.',
          variant: AlertVariant.ERROR,
        });
      });
  };

  useEffect(() => {
    if (editingApiKey) setName(editingApiKey.name || '');
  }, [editingApiKey]);

  return (
    <ScrollableContainer>
      <Toolbar
        className="border border-b-2 border-gray-100"
        onBack={goBack}
        pageInfo={isEditMode ? 'Edit API Key' : 'Create API Key'}
        title="API Keys"
      />
      <ContentContainer withToolbar>
        <CustomTypography variant="h4">
          {isEditMode ? 'Edit API Key' : 'Create API Key'}
        </CustomTypography>

        {addedApiKey ? (
          <>
            <p className="mt-2 font-normal text-sm text-zinc-500 max-w-xl leading-relaxed">
              Please save this API Key somewhere safe and accessible. For
              security reasons, you won’t be able to view it again in your Sola
              account. If you lose this API Key, you’ll need to generate a new
              one.
            </p>
            <div className="mb-11" />
            <p className="text-slate-500 text-sm leading-normal">
              API Key name
            </p>
            <p className="text-cyan-900 text-sm leading-normal">{name}</p>
            <div className="mb-6" />

            <div className="flex bg-primary-blue-extralight-2 rounded-xl w-fit">
              <div className="px-6 flex items-center border-r border-gray-300">
                <span className="text-cyan-900 text-sm font-medium leading-normal">
                  API Key
                </span>
              </div>
              <div className="p-2">
                <div className="flex items-center">
                  <p className="text-cyan-900 text-sm font-normal leading-normal pl-12 pr-32">
                    {addedApiKey.key}
                  </p>
                  <Button
                    color="secondary"
                    onClick={handleOnCopy}
                    variant="contained"
                  >
                    <ContentCopy className="text-white !w-4 mr-2" />
                    Copy
                  </Button>
                </div>
              </div>
            </div>

            <div className="mb-12" />

            <Button color="secondary" onClick={goBack} variant="outlined">
              Done, Back to API Keys section
            </Button>
          </>
        ) : (
          <>
            <div className="mb-11" />
            <p className="text-cyan-900 text-sm font-medium mb-4">Name</p>
            <Input
              classes={{ wrapper: 'max-w-sm' }}
              floatingLabel
              label="Name"
              onChange={setName}
              disabled={isLoading}
              value={name}
            />
            <div className="mb-6" />

            <p className="text-cyan-900 text-sm font-medium mb-4">
              Resources accessed
            </p>
            <div className="mb-6" />

            <div className="px-4 py-6 rounded-lg border-2 border-slate-200 justify-start items-start inline-flex w-[600px]">
              <div className="flex-col justify-start items-start gap-2 inline-flex">
                <div className="text-cyan-900 text-sm font-medium">
                  Execution
                </div>
                <p className="text-slate-500 text-sm font-medium leading-relaxed">
                  /v1/execution/list <br />
                  /v1/execution/queue-remote <br />
                  /v1/execution/outputs-remote <br />
                  /v1/execution/status-remote <br />
                </p>
              </div>
            </div>
            <div className="mb-6" />

            <div className="flex gap-4">
              {isEditMode ? (
                <Button
                  color="secondary"
                  disabled={!name}
                  loading={isLoading}
                  onClick={handleOnClickUpdate}
                  variant="contained"
                >
                  Save changes
                </Button>
              ) : (
                <Button
                  color="secondary"
                  disabled={!name}
                  loading={isLoading}
                  onClick={handleOnClickCreate}
                  variant="contained"
                >
                  Create API Key
                </Button>
              )}
              <Button color="secondary" onClick={goBack} variant="outlined">
                Cancel
              </Button>
            </div>
          </>
        )}
      </ContentContainer>
    </ScrollableContainer>
  );
}
