import { clsx } from 'clsx';
import { Button, DataCopyIcon, Flex } from 'ui-kit';
import { copyToClipboard } from '../utils/helper';

interface CopyDataProps {
  data: string;
  queryMethod?: string;
  className?: string;
  dataType: 'url' | 'json';
}

export function CopyData({
  data,
  queryMethod,
  dataType,
  className,
}: CopyDataProps) {
  return (
    <Flex
      className={clsx(
        'pl-7 pr-2 rounded-lg bg-primary-blue-extralight-2 text-sm text-info-dark',
        { 'max-h-[32rem]': dataType === 'json' },
        className,
      )}
      flexDirection="row"
    >
      {queryMethod ? (
        <div className="border-r border-indigo-light py-4 pr-7">
          <span className="font-medium ">{queryMethod}</span>
        </div>
      ) : null}

      <Flex
        className="px-6 rounded-lg bg-primary-blue-extralight-2 text-sm text-info-dark flex-1 max-w-[100%] relative"
        flexDirection="row"
        justifyContent="space-between"
      >
        <div className="my-4 overflow-scroll">
          {dataType === 'url' ? (
            <span className="font-normal">{data}</span>
          ) : null}
          {dataType === 'json' ? (
            <pre className="whitespace-pre-wrap font-normal pt-11 pb-2">
              {data}
            </pre>
          ) : null}
        </div>
        <div className="absolute top-1.5 right-1">
          <Button
            className="!py-2 !px-6 !text-sm !font-medium"
            color="secondary"
            onClick={() => {
              copyToClipboard(data);
            }}
            startIcon={<DataCopyIcon className="!text-info" fontSize="small" />}
            variant="outlined"
          >
            COPY
          </Button>
        </div>
      </Flex>
    </Flex>
  );
}
