import type {
  ExtensionData,
  SourceVariable,
  TargetMap,
  VariableMap,
  WorkflowData,
  WorkflowImageNode,
} from 'types-shared';
import {
  NodeStatus,
  NodeTypesEnum,
  Position,
  SourceTypeEnum,
  VariableTypeEnum,
} from 'types-shared';
import { v4 as uuidv4 } from 'uuid';
import { extensionDataParse } from './parseActions';
import { appendDatasourceNode } from './postprocess';

export interface ParseExtensionDataReturnType {
  workflowData: WorkflowData;
  variableStore: VariableMap;
  targetStore: TargetMap;
  newImages: string[];
}

export const parseExtensionData = async (
  extensionData: ExtensionData,
  prependDatasourceNode = false,
): Promise<ParseExtensionDataReturnType> => {
  const { states, actions } = extensionDataParse(extensionData);
  const newImages: string[] = [];
  const newNodesPromises: WorkflowImageNode[] = states.map(
    ({ imageId }, index) => {
      const nodeId = uuidv4();
      const nodeData = actions.actionDataArray[index] ?? {
        actionData: {},
        nodeUrls: [],
        actionOrder: [],
      };
      newImages.push(imageId);
      const { actionData, nodeUrls, actionOrder } = nodeData;
      return {
        id: nodeId,
        type: NodeTypesEnum.Image as const,
        data: {
          imageData: {
            imageId,
          },
          actionData,
          nodeUrls,
          actionOrder,
          nodeStatus: NodeStatus.enum.NotViewed,
        },
        position: { x: (index + 1) * 400, y: 0 },
        width: 256,
        height: 232,
      };
    },
  );
  const newNodes: WorkflowImageNode[] = await Promise.all(newNodesPromises);
  const newEdges = states.slice(0, -1).map((action, index) => {
    const id = uuidv4();
    const source = newNodes[index].id;
    const target = newNodes[index + 1].id;
    return {
      id,
      source,
      target,
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    };
  });

  const { variableStore, targetStore } = actions;

  const defaultSourceVariable: SourceVariable = {
    id: uuidv4(),
    type: VariableTypeEnum.Source,
    data: {
      sourceType: SourceTypeEnum.API,
    },
  };

  let workflowData: WorkflowData = {
    nodes: newNodes,
    edges: newEdges,
    selectedAction: null,
    selectedNode: null,
    bulkSelectMode: false,
  };
  if (prependDatasourceNode) {
    variableStore[defaultSourceVariable.id] = defaultSourceVariable;
    workflowData = appendDatasourceNode(workflowData, defaultSourceVariable);
  }
  return { workflowData, newImages, variableStore, targetStore };
};
