import type { ChipProps } from '@mui/material/Chip';
import MuiChip from '@mui/material/Chip';
import React, { type CSSProperties } from 'react';

interface StatusChipProps {
  statusStyles?: CSSProperties;
}
export default function Chip(props: ChipProps & StatusChipProps) {
  return (
    <MuiChip
      {...props}
      sx={{
        ...(props.statusStyles?.backgroundColor
          ? { backgroundColor: props.statusStyles.backgroundColor }
          : {}),
        ...(props.statusStyles?.color
          ? { color: props.statusStyles.color }
          : {}),
        ...(props.statusStyles?.borderRadius
          ? {
              borderRadius: props.statusStyles.borderRadius,
            }
          : {}),
        '& .MuiChip-label': {
          textTransform: 'capitalize',
          overflow: 'visible',
          lineHeight: '100%',
          ...props.statusStyles,
        },
      }}
    />
  );
}
