import { type WorkflowMetadataType } from 'api-types-shared';
import * as React from 'react';
import { useState } from 'react';
import { Modal, Button, WarningAmberOutlined, DoneAll } from 'ui-kit';

export default function ToolbarModal({
  open,
  onAccept,
  onReject,
  loading,
  isForceErrorPush,
  workflowMetadata,
}: {
  open: boolean;
  onAccept: () => void;
  onReject: () => void;
  loading: boolean;
  isForceErrorPush?: boolean;
  workflowMetadata?: WorkflowMetadataType | null;
}) {
  const [isRejecting, setIsRejecting] = useState(false);
  const issues = workflowMetadata?.updates?.issuesFound ?? [];
  const improvements = workflowMetadata?.updates?.improvements ?? [];

  return (
    <Modal
      className="flex item-center justify-items-center max-w-[50rem]"
      onClose={() => {
        if (!isRejecting && !isForceErrorPush) {
          setIsRejecting(true);
        }
      }}
      open={open}
      showCloseIcon={!isRejecting && !isForceErrorPush}
      enableEventBubbling
    >
      <div className="text-sm flex">
        <div className="flex-1 flex flex-col">
          <h2 className="font-medium text-xl mb-7 text-info-dark">
            {isRejecting
              ? 'Are you sure you want to reject these Improvements?'
              : 'Improvements review'}
          </h2>

          {isRejecting ? null : (
            <>
              <p className="text-gray-600 mb-5">
                Review the list of issues and improvements that Sola{' '}
                {isForceErrorPush ? 'has made' : 'recommends you apply'} to this
                workflow. By applying these improvements, Sola{' '}
                {isForceErrorPush ? 'has improved' : 'will improve'} the future
                behavior of your automation.
              </p>

              <div className="bg-[#f7f9ff] rounded-xl border border-gray-300 p-6 flex gap-6">
                <div className="flex-1">
                  <p className="inline-flex items-center font-medium text-info-dark mb-3">
                    {' '}
                    <WarningAmberOutlined className="text-error mr-2" /> Issues
                    found
                  </p>
                  <ul className="list-none">
                    {issues.map((issue) => (
                      <li key={issue}>- {issue}</li>
                    ))}
                  </ul>
                </div>

                <div className="flex-1">
                  <p className="inline-flex items-center font-medium text-info-dark mb-3">
                    <DoneAll className="text-success mr-2" /> Improvements
                    applied
                  </p>

                  <ul className="list-none">
                    {improvements.map((improvement) => (
                      <li key={improvement}>- {improvement}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          )}

          {isForceErrorPush ? null : (
            <div className="bg-[#FFF4E5] flex space-x-2 px-4 py-3 rounded text-sm mt-5">
              <WarningAmberOutlined className="!w-5 !h-5 !text-warning !mt-0.5" />
              <span className="text-[#663C00]">
                If you choose to reject the improvements, your workflow may
                continue to fail.
              </span>
            </div>
          )}

          <div className="flex flex-row gap-7 mt-5 ">
            <Button
              loading={loading}
              color={isRejecting ? 'error' : 'secondary'}
              onClick={() => {
                if (isRejecting) {
                  onReject();
                } else {
                  onAccept();
                }
              }}
              variant={isRejecting ? 'outlined' : 'contained'}
              className="min-w-[12rem]"
            >
              {isRejecting
                ? 'YES, REJECT IMPROVEMENTS'
                : `${!isForceErrorPush ? 'APPLY ' : ''}IMPROVEMENTS${isForceErrorPush ? ' REVIEWED' : ''}`}
            </Button>

            {isForceErrorPush ? null : (
              <Button
                disabled={loading}
                className="h-9 min-w-[12rem]"
                color={isRejecting ? 'secondary' : 'error'}
                onClick={() => {
                  setIsRejecting(!isRejecting);
                }}
                variant="outlined"
              >
                {isRejecting
                  ? "I DON'T WANT TO REJECT THE IMPROVEMENTS"
                  : 'REJECT'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
