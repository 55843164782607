"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RfEdge = exports.RfMarker = exports.RfMarkerType = exports.MarkerType = exports.RfNode = exports.Position = void 0;
var zod_1 = require("zod");
var Position;
(function (Position) {
    Position["Left"] = "left";
    Position["Top"] = "top";
    Position["Right"] = "right";
    Position["Bottom"] = "bottom";
})(Position || (exports.Position = Position = {}));
exports.RfNode = zod_1.z.object({
    id: zod_1.z.string(),
    position: zod_1.z.object({
        x: zod_1.z.number(),
        y: zod_1.z.number(),
    }),
    type: zod_1.z.string().optional(),
    sourcePosition: zod_1.z.nativeEnum(Position).optional(),
    targetPosition: zod_1.z.nativeEnum(Position).optional(),
    hidden: zod_1.z.boolean().optional(),
    hideFromUser: zod_1.z.boolean().optional(),
    selected: zod_1.z.boolean().optional(),
    dragging: zod_1.z.boolean().optional(),
    draggable: zod_1.z.boolean().optional(),
    selectable: zod_1.z.boolean().optional(),
    connectable: zod_1.z.boolean().optional(),
    resizing: zod_1.z.boolean().optional(),
    deletable: zod_1.z.boolean().optional(),
    dragHandle: zod_1.z.string().optional(),
    width: zod_1.z.union([zod_1.z.number(), zod_1.z.null()]).optional(),
    height: zod_1.z.union([zod_1.z.number(), zod_1.z.null()]).optional(),
    parentNode: zod_1.z.string().optional(),
    zIndex: zod_1.z.number().optional(),
    extent: zod_1.z
        .union([
        zod_1.z.literal('parent'),
        zod_1.z.tuple([
            zod_1.z.tuple([zod_1.z.number(), zod_1.z.number()]),
            zod_1.z.tuple([zod_1.z.number(), zod_1.z.number()]),
        ]),
    ])
        .optional(),
    expandParent: zod_1.z.boolean().optional(),
    positionAbsolute: zod_1.z
        .object({
        x: zod_1.z.number(),
        y: zod_1.z.number(),
    })
        .optional(),
    ariaLabel: zod_1.z.string().optional(),
    focusable: zod_1.z.boolean().optional(),
    style: zod_1.z.record(zod_1.z.any()).optional(),
    className: zod_1.z.string().optional(),
    name: zod_1.z.string().optional(),
});
var MarkerType;
(function (MarkerType) {
    MarkerType["Arrow"] = "arrow";
    MarkerType["ArrowClosed"] = "arrowclosed";
})(MarkerType || (exports.MarkerType = MarkerType = {}));
exports.RfMarkerType = zod_1.z.nativeEnum(MarkerType);
exports.RfMarker = zod_1.z.object({
    type: exports.RfMarkerType,
    color: zod_1.z.string().optional(),
    width: zod_1.z.number().optional(),
    height: zod_1.z.number().optional(),
    markerUnits: zod_1.z.string().optional(),
    orient: zod_1.z.string().optional(),
    strokeWidth: zod_1.z.number().optional(),
});
exports.RfEdge = zod_1.z.object({
    id: zod_1.z.string(),
    type: zod_1.z.string().optional(),
    source: zod_1.z.string(),
    target: zod_1.z.string(),
    sourceHandle: zod_1.z.string().nullable().optional(),
    targetHandle: zod_1.z.string().nullable().optional(),
    style: zod_1.z.record(zod_1.z.any()).optional(),
    animated: zod_1.z.boolean().optional(),
    hidden: zod_1.z.boolean().optional(),
    deleted: zod_1.z.boolean().optional(),
    data: zod_1.z.any().optional(),
    className: zod_1.z.string().optional(),
    // sourceNode: WorkflowNode.optional(),
    // targetNode: WorkflowNode.optional(),
    selected: zod_1.z.boolean().optional(),
    markerStart: zod_1.z.union([exports.RfMarker, zod_1.z.string()]).optional(),
    markerEnd: zod_1.z.union([exports.RfMarker, zod_1.z.string()]).optional(),
    zIndex: zod_1.z.number().optional(),
    ariaLabel: zod_1.z.string().optional(),
    interactionWidth: zod_1.z.number().optional(),
    focusable: zod_1.z.boolean().optional(),
    updatable: zod_1.z
        .union([zod_1.z.boolean(), zod_1.z.literal('source'), zod_1.z.literal('target')])
        .optional(),
    label: zod_1.z.any().nullable().optional(),
    labelStyle: zod_1.z.record(zod_1.z.any()).optional(),
    labelShowBg: zod_1.z.boolean().optional(),
    labelBgStyle: zod_1.z.record(zod_1.z.any()).optional(),
    labelBgPadding: zod_1.z.tuple([zod_1.z.number(), zod_1.z.number()]).optional(),
    labelBgBorderRadius: zod_1.z.number().optional(),
});
