"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.retryExecutionSchema = void 0;
var types_shared_1 = require("types-shared");
exports.retryExecutionSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
        body: types_shared_1.z.object({}).optional(),
    }),
    response: types_shared_1.z.object({
        executionId: types_shared_1.z.string(),
    }),
};
