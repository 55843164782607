import { S3Shim } from 's3-utils';
import { DDBShim } from 'ddb-utils';
import { awsAccessKey, awsRegion, awsSecretKey } from '../utils/env';

export const awsConfig = {
  region: awsRegion,
  credentials: {
    accessKeyId: awsAccessKey,
    secretAccessKey: awsSecretKey,
  },
};

// S3 Shim
export const s3Shim = new S3Shim(awsConfig);

// DynamoDB
export const ddbShim = new DDBShim(awsConfig);
