"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QUERY_NODES_INFO = exports.QUERY_GRID_INFO = exports.QUERY_SESSION_INFO_BY_ID = void 0;
var graphql_request_1 = require("graphql-request");
exports.QUERY_SESSION_INFO_BY_ID = (0, graphql_request_1.gql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query SessionInfoById($sessionId: String!) {\n    session(id: $sessionId) {\n      id\n      capabilities\n      startTime\n      uri\n      nodeId\n      nodeUri\n      sessionDurationMillis\n    }\n  }\n"], ["\n  query SessionInfoById($sessionId: String!) {\n    session(id: $sessionId) {\n      id\n      capabilities\n      startTime\n      uri\n      nodeId\n      nodeUri\n      sessionDurationMillis\n    }\n  }\n"])));
exports.QUERY_GRID_INFO = (0, graphql_request_1.gql)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query GridInfo {\n    grid {\n      totalSlots\n      nodeCount\n      maxSession\n      sessionCount\n      sessionQueueSize\n    }\n  }\n"], ["\n  query GridInfo {\n    grid {\n      totalSlots\n      nodeCount\n      maxSession\n      sessionCount\n      sessionQueueSize\n    }\n  }\n"])));
exports.QUERY_NODES_INFO = (0, graphql_request_1.gql)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query NodesInfo {\n    nodesInfo {\n      nodes {\n        id\n        uri\n        status\n        slotCount\n        maxSession\n        sessionCount\n      }\n    }\n  }\n"], ["\n  query NodesInfo {\n    nodesInfo {\n      nodes {\n        id\n        uri\n        status\n        slotCount\n        maxSession\n        sessionCount\n      }\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
