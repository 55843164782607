import { enableMapSet } from 'immer';
import type {
  DatasourceStateData,
  TargetStateData,
  VariableStateData,
  WorkflowStateData,
} from 'types-shared';
import { create, type StoreApi } from 'zustand';
import type {
  PersistStorage,
  StateStorage,
  StorageValue,
} from 'zustand/middleware';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import type { DatasourceStateActions } from './DatasourceState';
import { DatasourceState } from './DatasourceState';
import { TargetState, type TargetStateActions } from './TargetState';
import { VariableState, type VariableStateActions } from './VariableState';
import { WorkflowState, type WorkflowStateActions } from './WorkflowState';
import type {
  IntegrationStateActions,
  IntegrationStateData,
} from './IntegrationState';
import { IntegrationState } from './IntegrationState';
import type { TriggerStateActions, TriggerStateData } from './TriggerState';
import { TriggerState } from './TriggerState';
import pickBy from 'lodash/pickBy';
import isFunction from 'lodash/isFunction';
import {
  MiscState,
  type MiscStateActions,
  type MiscStateData,
} from './MiscState';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { deepDiff } from '../../../utils/helper';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import {
  ImagesState,
  type ImagesStateActions,
  type ImagesStateData,
} from './ImagesState';

enableMapSet();

const keysToIgnore = [
  'fullImages',
  'thumbnails',
  'apiSettings',
  'unsavedChanges',
];

const keysToIgnoreForUnsavedChanges = [
  ...keysToIgnore,
  'currentViewport',
  'currentVersionId',
  'selected',
  'dragging',
  'height',
  'width',
  'x',
  'y',
  'position',
  'positionAbsolute',
  'selectedAction',
  'selectedNode',
  'bulkSelectMode',
  'datasourceMetadata',
  'tableData',
  'watchedExecutionIds',
  'integrations',
];

const getWorkflowIdFromPath = () => {
  const urlPathnameArray = window.location.pathname.split('/');
  return urlPathnameArray[urlPathnameArray.length - 1];
};

export function createEditorStorage<S>(): PersistStorage<S> | undefined {
  const storage = localStorage as StateStorage;
  const persistStorage: PersistStorage<S> = {
    getItem: async (name) => {
      const urlPathnameArray = window.location.pathname.split('/');
      const workflowId = urlPathnameArray[urlPathnameArray.length - 1];
      if (name !== workflowId && name !== 'root') {
        return null;
      }
      const parse = (str: string | null) => {
        if (str === null) {
          return null;
        }
        return JSON.parse(str, (key, value: unknown) => {
          return value;
        }) as StorageValue<S>;
      };
      const str = storage.getItem(workflowId) ?? null;
      if (str instanceof Promise) {
        return str.then(parse);
      }
      return parse(str);
    },
    setItem: (name, newValue) => {
      const workflowId = getWorkflowIdFromPath();
      if (name !== workflowId) {
        return;
      }
      return storage.setItem(
        workflowId,
        JSON.stringify(newValue, (key, value: unknown) => {
          if (value instanceof Blob) {
            return null;
          }
          return value;
        }),
      );
    },
    removeItem: (name) => storage.removeItem(name),
  };
  return persistStorage;
}

export type EditorWorkflowDataProps = WorkflowStateData &
  VariableStateData &
  TargetStateData &
  DatasourceStateData;

export type EditorStoreDataProps = EditorWorkflowDataProps &
  IntegrationStateData &
  ImagesStateData &
  TriggerStateData &
  MiscStateData;

export type EditorStoreActionsProps = WorkflowStateActions &
  VariableStateActions &
  TargetStateActions &
  DatasourceStateActions &
  IntegrationStateActions &
  ImagesStateActions &
  TriggerStateActions &
  MiscStateActions;

export type EditorStoreProps = EditorStoreDataProps & EditorStoreActionsProps;

type SetStateFunction<T> = (
  partial: T | Partial<T> | ((state: T) => T | Partial<T>),
  replace?: boolean | undefined,
) => void;

function watcherMiddleware<T extends EditorStoreProps>(
  set: SetStateFunction<T>,
  get: () => T,
  // eslint-disable-next-line
  api: StoreApi<T>,
): SetStateFunction<T> {
  return (nextState, replace) => {
    const currentState = get();
    const newState =
      typeof nextState === 'function' ? nextState(currentState) : nextState;
    let hasUnsavedChanges = false;

    Object.keys(newState).forEach((key) => {
      const _key = key as keyof EditorStoreProps;
      if (
        !keysToIgnoreForUnsavedChanges.includes(_key) &&
        !isEmpty(currentState[_key]) &&
        !isEmpty(newState[_key]) &&
        !isEqual(currentState[_key], newState[_key])
      ) {
        const diff = deepDiff(
          currentState[_key] as object,
          newState[_key] as object,
        );
        hasUnsavedChanges = !isEmpty(diff);
        if (
          isArray(diff) &&
          (diff.length === 0 ||
            diff.every(
              (change) =>
                isObject(change) &&
                Object.keys(change).every((x) =>
                  keysToIgnoreForUnsavedChanges.includes(x),
                ),
            ))
        ) {
          hasUnsavedChanges = false;
        }
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (hasUnsavedChanges) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      set({ unsavedChanges: hasUnsavedChanges });
    }

    set(nextState, replace);
  };
}

export const EditorStore = create<EditorStoreProps>()(
  subscribeWithSelector(
    persist(
      devtools(
        (...args) => {
          const [, get, api] = args;
          const wrappedSet = watcherMiddleware(...args);

          return {
            ...WorkflowState(wrappedSet, get, api),
            ...VariableState(wrappedSet, get, api),
            ...TargetState(wrappedSet, get, api),
            ...DatasourceState(wrappedSet, get, api),
            ...IntegrationState(wrappedSet, get, api),
            ...ImagesState(wrappedSet, get, api),
            ...TriggerState(wrappedSet, get, api),
            ...MiscState(wrappedSet, get, api),
          };
        },
        {
          name: 'EditorStore',
          enabled: process.env.NODE_ENV === 'development',
        },
      ),
      {
        name: 'root',
        skipHydration: false,
        storage: createEditorStorage(),
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(
              ([key]) => !keysToIgnore.includes(key),
            ),
          ),
        merge: (persistedState, currentState: EditorStoreProps) => {
          const _state = persistedState as EditorStoreProps | undefined;
          const workflowId = getWorkflowIdFromPath();
          const newState =
            workflowId === _state?.workflowId ? _state : currentState;
          const functions = pickBy(currentState, isFunction);
          return {
            ...currentState,
            ...newState,
            ...functions,
            workflowId,
          };
        },
      },
    ),
  ),
);
