import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type dayjs from 'dayjs';
import type {} from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3274fb',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#e6e7eb',
          },
          '&:hover fieldset': {
            borderColor: '#e6e7eb',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#e6e7eb',
          },
        },
      },
    },
  },
});
export default function BasicDateTimePicker({
  label,
  onChange,
  value,
}: {
  label: string;
  onChange: (newValue: dayjs.Dayjs) => void;
  value?: dayjs.Dayjs;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        setIsOpen(true);
      };
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          inputRef={inputRef}
          open={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          disablePast
          label={label}
          onChange={(newValue) => {
            if (newValue) {
              onChange(newValue);
            }
          }}
          value={value ?? null}
          sx={{
            '& .MuiInputLabel-root': {
              transition: 'display 0s 200ms',
            },
            '& .MuiInputLabel-shrink': {
              display: 'block',
              background: '#ffffff',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#e6e7eb',
            },
            '& .MuiOutlinedInput-notchedOutline legend': {
              display: 'none',
            },
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
