import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { useAPI } from '../../hooks/useApi';

export interface VersionInfo {
  version: string;
  url: string;
}

export function useExtensionVersion(): UseQueryResult<VersionInfo> {
  const { miscSDK: sdk } = useAPI();
  return useQuery<VersionInfo>({
    queryKey: ['extensionVersion'],
    queryFn: async () => {
      return sdk.getExtension();
    },
    staleTime: 60 * 1000, // Invalidate the cache after 1 minute
  });
}
