import Quill from 'quill';
import { Mention } from 'quill-mention';
import 'quill-mention/autoregister';
import 'quill/dist/quill.snow.css';

import CustomMentionBlot, {
  PlainClipboard,
} from '../components/CustomMentionBlot';

Quill.register({
  'blots/mention': CustomMentionBlot,
  'modules/mention': Mention,
  'modules/clipboard': PlainClipboard,
});
