import { FileUploadIcon } from 'ui-kit';
import { clsx } from 'clsx';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import type { WorkflowNodeProps } from 'types-shared';
import { EditorStore } from '../../../store/EditorState';

interface DragDropZoneProps {
  workflowData: WorkflowNodeProps;
  onFileSelection: (file: File[]) => void;
  className?: string;
}

export default function DragDropZone({
  workflowData,
  onFileSelection,
  className,
}: DragDropZoneProps) {
  const selectedNodeId = workflowData.id;
  const { workflowId = '' } = useParams<{ workflowId: string }>();
  const editorData = EditorStore();
  const { nodes } = editorData;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFileSelection(acceptedFiles);
      if (!workflowId) {
        throw new Error('workflowId is undefined');
      }
      const selectedNode = nodes.find((n) => n.id === selectedNodeId);
      if (!selectedNode) {
        throw new Error('no node selected!');
      }
    },
    [nodes, workflowId, selectedNodeId, onFileSelection],
  );

  const dropzone = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
    maxFiles: 1,
    onDrop,
  });

  return (
    <div className={clsx(className)} {...dropzone.getRootProps()}>
      <label className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
        <span className="flex items-center space-x-2">
          <FileUploadIcon className="w-6 h-6 text-gray-600" color="secondary" />
          <span className="font-medium text-gray-600">
            Drop files to Attach, or&nbsp;
            <span className="text-blue-600 underline">Browse</span>
          </span>
        </span>
        <input {...dropzone.getInputProps()} hidden name="file_upload" />
      </label>
    </div>
  );
}
