import { FilePlaceholderIcon, CustomTypography, Flex } from 'ui-kit';
import React from 'react';
import { clsx } from 'clsx';

interface Props {
  heading: string;
  subHeading: string;
  className?: string;
  showIcon?: boolean;
}
export function NoDataFound({
  heading,
  subHeading,
  showIcon = true,
  className,
}: Props) {
  return (
    <Flex
      className={clsx(
        'px-11 py-8 bg-color-light-blue rounded-lg gap-2',
        !showIcon && 'pt-12 pb-14',
        className,
      )}
      justifyContent="space-between"
    >
      <div className="w-3/4">
        <CustomTypography
          className="font-medium text-lg"
          color="text.info"
          variant="h6"
        >
          {heading}
        </CustomTypography>
        <CustomTypography
          className="font-medium mt-8"
          color="text.tertiary"
          variant="body1"
        >
          {subHeading}
        </CustomTypography>
      </div>

      {showIcon ? (
        <Flex alignItems="center" className="w-1/4 " justifyContent="center">
          <FilePlaceholderIcon className="!text-info" />
        </Flex>
      ) : null}
    </Flex>
  );
}
