import {
  type WorkflowMetadataType,
  WorkflowStatus,
  type WorkflowStatusEnum,
} from 'api-types-shared';
import { z } from 'types-shared';
import type { ExecutionStatusEnum } from 'types-shared/executionTypes';

export const mockWorkflow: WorkflowMetadataType = {
  userId: 'test-user',
  workflowId: 'test-workflow',
  createdAt: new Date().toISOString(),
  workflowName: 'Test Workflow',
  status: WorkflowStatus.enum.ProcessedImport,
};

export type CompactWorkflowMetadata = Pick<
  WorkflowMetadataType,
  'workflowId' | 'workflowName' | 'status'
>;

export interface WorkflowListMenuProps extends CompactWorkflowMetadata {
  anchorEl: HTMLButtonElement;
}

export interface WorkflowStatusSelectItem {
  label: string;
  value: WorkflowStatusEnum;
}

export const workflowListFiltersSchema = z.object({
  name: z.string().optional(),
  email: z.string().optional(),
  status: z.string().optional(),
  hideInternal: z.boolean().optional(),
});
export type WorkflowListFilters = z.infer<typeof workflowListFiltersSchema>;

export interface TeamDetails {
  teamId: string;
  teamName: string;
  roleName: string;
  isAdmin: boolean;
}

export interface WorkflowDetailsFilter {
  status: ExecutionStatusEnum | undefined;
  hideAdminRuns?: boolean;
  statuses: ExecutionStatusEnum[];
  executionId: string;
  text?: string;
}
