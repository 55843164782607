import type {
  QueueTemporalExecutionRequest,
  QueueTemporalExecutionResponse,
} from 'api-types-shared';
import { useMutation } from '@tanstack/react-query';

import { ExecutionTriggerEnum } from 'types-shared';
import { useAPI } from '../../hooks/useApi';

export function useRunTestExecution(desktop = false) {
  const { executionSDK: sdk } = useAPI();

  return useMutation<
    QueueTemporalExecutionResponse,
    Error,
    QueueTemporalExecutionRequest
  >({
    mutationFn: ({ params, body }) => {
      return desktop
        ? sdk.queueDesktopExecutions({
            params: { workflowId: params.workflowId },
            body: {
              variableData: body?.variableData ?? {},
              trigger: ExecutionTriggerEnum.Manual,
            },
          })
        : sdk.queueTemporalExecutions({
            params: { workflowId: params.workflowId },
            body: {
              variableData: body?.variableData ?? {},
              trigger: ExecutionTriggerEnum.Manual,
            },
          });
    },
  });
}
