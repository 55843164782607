export const transformationSvg = `<svg
  style="height:12px; width:12px; fill:white; position: absolute; right:5px; top:5px;"
  focusable="false"
  aria-hidden="true"
  viewBox="0 0 14 14"
  fill="none"
  height="14"
  width="14"
  xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M5.30344 2.17753C5.32596 2.22258 5.36249 2.25911 5.40755 2.28164L6.86167 3.0087C7.03325 3.09449 7.03325 3.33935 6.86167 3.42514L5.40755 4.1522C5.36249 4.17472 5.32596 4.21125 5.30344 4.25631L4.57638 5.71042C4.49059 5.88201 4.24573 5.88201 4.15994 5.71043L3.43288 4.25631C3.41035 4.21125 3.37382 4.17472 3.32877 4.1522L1.87465 3.42514C1.70307 3.33935 1.70307 3.09449 1.87465 3.0087L3.32877 2.28164C3.37382 2.25911 3.41035 2.22258 3.43288 2.17753L4.15994 0.723413C4.24573 0.551831 4.49059 0.551831 4.57638 0.723413L5.30344 2.17753Z"
        fill="white"
    ></path>
    <path
        d="M3.55747 9.74338C3.58 9.78844 3.61653 9.82497 3.66158 9.84749L5.1157 10.5746C5.28728 10.6603 5.28728 10.9052 5.1157 10.991L3.66158 11.718C3.61653 11.7406 3.58 11.7771 3.55747 11.8222L2.83041 13.2763C2.74462 13.4479 2.49976 13.4479 2.41397 13.2763L1.68691 11.8222C1.66439 11.7771 1.62786 11.7406 1.5828 11.718L0.128686 10.991C-0.0428956 10.9052 -0.0428953 10.6603 0.128686 10.5746L1.5828 9.84749C1.62786 9.82497 1.66439 9.78844 1.68691 9.74338L2.41397 8.28927C2.49976 8.11768 2.74462 8.11768 2.83041 8.28927L3.55747 9.74338Z"
        fill="white"
    ></path>
    <path
        d="M5.91736 7.66741C5.75302 7.75505 5.75302 7.99061 5.91736 8.07824L8.37353 9.38795C8.41791 9.41162 8.45343 9.44902 8.47478 9.49457L9.68626 12.0791C9.77001 12.2577 10.0241 12.2577 10.1078 12.0791L11.3193 9.49457C11.3407 9.44902 11.3762 9.41162 11.4206 9.38795L13.8767 8.07824C14.0411 7.99061 14.0411 7.75505 13.8767 7.66741L11.4206 6.3577C11.3762 6.33404 11.3407 6.29663 11.3193 6.25109L10.1078 3.6666C10.0241 3.48794 9.77001 3.48795 9.68626 3.6666L8.47478 6.25109C8.45343 6.29663 8.41791 6.33404 8.37353 6.3577L5.91736 7.66741Z"
        fill="white"
    ></path>
</svg>`;

export const globalVariableIcon = `<svg
    style="height:16px; width:16px; fill:white; position: absolute; left:5px; top:3px;"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="LanguageOutlinedIcon"
>
        <path 
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2m6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56M12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96M4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56m2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8M12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96M14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2m.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56M16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2z"
        >
        </path>
</svg>`;

export const executionIcon = `<svg
    class=""
    style="height:15px; width:15px; fill:white; position: absolute; left:7px; top:4px;"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.66669 5.33331H13.3334M2.66669 5.33331V11.2001C2.66669 11.9468 2.66669 12.32 2.81201 12.6052C2.93984 12.8561 3.14367 13.0603 3.39455 13.1881C3.67949 13.3333 4.05268 13.3333 4.79796 13.3333H11.2021C11.9474 13.3333 12.32 13.3333 12.6049 13.1881C12.8558 13.0603 13.0603 12.8561 13.1882 12.6052C13.3334 12.3203 13.3334 11.9476 13.3334 11.2024V5.33331M2.66669 5.33331V4.80011C2.66669 4.05337 2.66669 3.67973 2.81201 3.39451C2.93984 3.14363 3.14367 2.9398 3.39455 2.81197C3.67977 2.66665 4.05341 2.66665 4.80015 2.66665H5.33335M13.3334 5.33331V4.79792C13.3334 4.05264 13.3334 3.67945 13.1882 3.39451C13.0603 3.14363 12.8558 2.9398 12.6049 2.81197C12.3197 2.66665 11.9469 2.66665 11.2002 2.66665H10.6667M10.6667 1.33331V2.66665M10.6667 2.66665H5.33335M5.33335 1.33331V2.66665"
            fill="none"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
        ></path>
</svg>`;

export const documentIcon = `<svg
    style="height:14px; width:12px; fill:white; position: absolute; left:7px; top:4px;"
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill="none"
            d="M3.99967 10.3333H7.99967M3.99967 8.33333H7.99967M6.66659 1.00058C6.6029 1 6.53126 1 6.44948 1H3.46647C2.71973 1 2.34609 1 2.06087 1.14533C1.80999 1.27316 1.60616 1.47698 1.47833 1.72786C1.33301 2.01308 1.33301 2.38673 1.33301 3.13346V10.8668C1.33301 11.6135 1.33301 11.9867 1.47833 12.2719C1.60616 12.5228 1.80999 12.727 2.06087 12.8548C2.34581 13 2.71901 13 3.4643 13L8.53507 13C9.28034 13 9.653 13 9.93794 12.8548C10.1888 12.727 10.3933 12.5228 10.5212 12.2719C10.6663 11.987 10.6663 11.6143 10.6663 10.8691V5.21712C10.6663 5.13535 10.6663 5.06369 10.6657 5M6.66659 1.00058C6.8569 1.00232 6.97682 1.00938 7.09179 1.03698C7.22784 1.06964 7.35824 1.12351 7.47754 1.19661C7.61205 1.27904 7.72755 1.39454 7.95801 1.625L10.0417 3.70866C10.2723 3.93926 10.3869 4.05424 10.4694 4.1888C10.5425 4.30809 10.5966 4.43817 10.6292 4.57422C10.6568 4.68919 10.664 4.8097 10.6657 5M6.66659 1.00058L6.66634 2.86681C6.66634 3.61354 6.66634 3.98677 6.81167 4.27198C6.9395 4.52287 7.14332 4.72699 7.39421 4.85482C7.67914 5 8.05235 5 8.79762 5H10.6657"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
</svg>`;

export const deletedVariableSvg = `<svg
  style="height:16px; width:14px; fill:white; position: absolute; right:5px; top:5px;"
  focusable="false"
  aria-hidden="true"
  viewBox="0 0 14 14"
  fill="none"
  height="14"
  width="14"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.6665 11.3333V9.99999M9.99984 8.66666H11.3332M2.69981 5.52864L1.757 6.47144C0.715601 7.51284 0.716275 9.20108 1.75767 10.2425C2.79907 11.2839 4.48704 11.2841 5.52844 10.2428L6.47154 9.29979M1.99984 3.33332H0.666504M3.33317 0.666656V1.99999M5.52848 2.70029L6.47129 1.75748C7.51269 0.716079 9.20093 0.715777 10.2423 1.75717C11.2837 2.79857 11.2834 4.48719 10.242 5.52859L9.29932 6.47137"
    stroke="white"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>`;
