import { createEventBus, type EventMap } from 'ui-kit';
import { useEffect, useState } from 'react';

interface VersionHistoryEvents extends EventMap {
  open: (show: boolean) => void;
}

export const versionHistoryEventBus = createEventBus<VersionHistoryEvents>();

export const useVersionHistory = () => {
  const [showVersionHistory, setShowVersionHistory] = useState<boolean>(false);

  useEffect(() => {
    const unsubscribe = versionHistoryEventBus.on(
      'open',
      setShowVersionHistory,
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return {
    showVersionHistory,
    toggleVersionHistory: (show: boolean) => {
      versionHistoryEventBus.emit('open', show);
    },
  };
};
