import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function Error(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.9999 21.125V31.125M33.1151 8.64698L36.1675 11.2482C36.9365 11.9036 37.8914 12.2998 38.8985 12.3801L42.8962 12.6987C45.2465 12.8863 47.1134 14.752 47.3009 17.1023L47.6188 21.1006C47.6991 22.1078 48.0967 23.064 48.7521 23.833L51.3522 26.8845C52.8815 28.6791 52.8817 31.3188 51.3524 33.1134L48.7523 36.1654C48.097 36.9344 47.6999 37.891 47.6195 38.8982L47.2997 42.8958C47.1121 45.2461 45.2482 47.113 42.8978 47.3006L38.8988 47.6196C37.8916 47.7 36.9359 48.0952 36.1669 48.7505L33.1151 51.3518C31.3206 52.8811 28.6792 52.8814 26.8846 51.3521L23.8329 48.7507C23.0639 48.0954 22.1081 47.6995 21.101 47.6192L17.1019 47.3005C14.7516 47.113 12.8876 45.2466 12.7001 42.8962L12.3803 38.8984C12.2999 37.8913 11.9028 36.9355 11.2474 36.1665L8.64734 33.1135C7.11803 31.319 7.11731 28.6801 8.64662 26.8855L11.2491 23.8325C11.9044 23.0635 12.2977 22.1078 12.3781 21.1006L12.6979 17.1028C12.8854 14.7525 14.7548 12.8861 17.1051 12.6985L21.0997 12.3799C22.1069 12.2995 23.0631 11.9036 23.8321 11.2483L26.8849 8.64698C28.6795 7.11767 31.3206 7.11767 33.1151 8.64698ZM30.1244 38.625V38.875L29.8754 38.874V38.625H30.1244Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </SvgIcon>
  );
}
