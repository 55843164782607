import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function Clock(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        fill="none"
        d="M10 6.226v4.167h4.167M10 17.892a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15Z"
      />
    </SvgIcon>
  );
}
