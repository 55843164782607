import { NodeTypesEnum, type WorkflowNode } from 'types-shared';
import { s3Shim } from '../../../config/aws';
import { workflowDataBucket } from '../../../utils/env';
import { uploadBlobToS3 } from '../../../utils/extension';
import { S3Operation } from 's3-utils';
import { AlertVariant, notify } from 'ui-kit';
import CryptoJS from 'crypto-js';

export const createThumbnail = async (blob: Blob) => {
  const img = await createImageBitmap(blob);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to get 2d context');
  }

  // Crop and resize the image in one step
  const resizeWidth = img.width / 3;
  const resizeHeight = img.width / 6;
  canvas.width = resizeWidth;
  canvas.height = resizeHeight;
  ctx.drawImage(
    img,
    0,
    0,
    img.width,
    img.width / 2,
    0,
    0,
    resizeWidth,
    resizeHeight,
  );

  // Convert canvas to Blob
  const processedImageBlob = await new Promise((resolve) => {
    canvas.toBlob(resolve);
  });
  return processedImageBlob as Blob;
};

export const calculateMd5 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(blob);
    reader.onloadend = () => {
      const hash = CryptoJS.MD5(reader.result as string).toString();
      resolve(hash);
    };
    reader.onerror = (ev: ProgressEvent<FileReader>) => {
      reject(ev.target?.error as Error);
    };
  });
};

export async function updateNodeImage(
  workflowId: string,
  nodeImage: File,
  nodeId: string,
  setNodes: (nodes: WorkflowNode[]) => void,
  nodes: WorkflowNode[],
) {
  const thumbnailImage: Blob = await createThumbnail(nodeImage);
  const [, fileType] = nodeImage.type.split('image/');

  const imageId = await calculateMd5(nodeImage);

  const [nodeImagePreSignedUrl, thumbnailImagePreSignedUrl] = await Promise.all(
    [
      s3Shim.getPresignedUrl({
        bucket: workflowDataBucket,
        key: `${workflowId}/images/${imageId}.${fileType}`,
        operation: S3Operation.PutObject,
      }),
      s3Shim.getPresignedUrl({
        bucket: workflowDataBucket,
        key: `${workflowId}/thumbnails/${imageId}.${fileType}`,
        operation: S3Operation.PutObject,
      }),
    ],
  );

  // Wait for all promises to resolve and check if any of them are false
  const results = await Promise.allSettled([
    uploadBlobToS3(nodeImage, nodeImagePreSignedUrl),
    uploadBlobToS3(thumbnailImage, thumbnailImagePreSignedUrl),
  ]);
  const failed = results.filter(({ status }) => status === 'rejected');

  if (failed.length) {
    notify({
      message: 'Error while updating node image',
      variant: AlertVariant.ERROR,
    });
  } else {
    notify({
      message: 'Node image updated successfully',
      variant: AlertVariant.SUCCESS,
    });
    setNodes(
      nodes.map((node) => {
        if (node.id === nodeId && node.type === NodeTypesEnum.Image) {
          return {
            ...node,
            data: {
              ...node.data,
              imageData: {
                ...node.data.imageData,
                imageId,
                originalData: nodeImage,
                thumbnailData: thumbnailImage,
              },
            },
          };
        }
        return node;
      }),
    );
  }

  return imageId;
}
