import { useRef, useEffect } from 'react';
import type { VariableMap, GlobalVariable, TemplateData } from 'types-shared';
import { clsx } from 'clsx';
import { VariableInput } from '../../Editor/components/VariableTypes/VariableInput/Input2.0';

interface Props {
  label?: string;
  placeholder?: string;
  variablesMap: VariableMap;
  onChange?: (variable: TemplateData) => void;
  disabled?: boolean;
  className?: string;
  isHalf?: boolean;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  multiline?: boolean;
  containerClassName?: string;
  allowAddVariable?: boolean;
  value?: TemplateData;
}

interface VariableInputRef {
  reRender: () => void;
}

export function FormVariableInput({
  label,
  variablesMap,
  onChange,
  globalVariablesMap,
  disabled,
  className,
  isHalf,
  placeholder,
  multiline = true,
  containerClassName,
  allowAddVariable = true,
  value = [],
}: Props) {
  const inputRef = useRef<VariableInputRef>();
  const prevInputData = useRef<TemplateData>([]);

  useEffect(() => {
    if (value.length && !prevInputData.current.length) {
      // Force a re-render if the content has changed, but not yet reflected
      prevInputData.current = value;
      inputRef.current?.reRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, prevInputData.current]);

  return (
    <VariableInput
      ref={inputRef}
      willRerender
      allowAddVariable={allowAddVariable}
      containerClassName={clsx(containerClassName, isHalf && '!w-[45%]')}
      className={className}
      value={value}
      disabled={disabled}
      label={label}
      onChange={(newData) => {
        onChange?.(newData);
      }}
      variablesMap={variablesMap}
      globalVariablesMap={globalVariablesMap}
      placeholder={value.length ? '' : placeholder}
      multiline={multiline}
    />
  );
}
