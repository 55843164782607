import {
  addFilesSchema,
  getFileSchema,
  type ListFileMetasRequest,
  listFileMetasSchema,
  resolveLinearizedFileSchema,
  type ZodFetcher,
} from 'api-types-shared';
import type { KyInstance, Options } from 'ky';
import { createZodKyFetcher } from '../fetcher';
import { apiEndpoints, type NodeEnv } from 'ui-kit';
import { handleException } from 'sentry-browser-shared';
import ky from 'ky';

export class FileSDK {
  readonly endpoint: string;
  private _kyFetcher: ZodFetcher<KyInstance>;

  constructor(env: NodeEnv, kyOpts?: Options) {
    this.endpoint = apiEndpoints[env].fileApiV1;
    this._kyFetcher = createZodKyFetcher(kyOpts);
  }

  addFiles = async (files: File[], workflowId: string) => {
    const filesPayload = files.map((file) => ({
      name: file.name,
      workflowId,
    }));
    const fileUploadData = await this._kyFetcher(
      addFilesSchema.response,
      `${this.endpoint}/add`,
      {
        method: 'PUT',
        body: JSON.stringify({ files: filesPayload }),
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Failed to initialize file upload',
        source: 'FileSDK.addFiles',
        extra: { workflowId, fileCount: files.length },
      });
      throw error;
    });

    const uploadPromise = files.map((file, i) => {
      const formData = new FormData();
      formData.append('file', file);
      return fetch(fileUploadData[i].uploadUrl, {
        method: 'PUT',
        body: formData,
      });
    });
    await Promise.all(uploadPromise).catch((error: unknown) => {
      handleException(error, {
        name: 'Failed to upload files',
        source: 'FileSDK.addFiles',
        extra: { workflowId, fileCount: files.length },
      });
      throw error;
    });
    return fileUploadData.map((file) => file.fileId);
  };

  listFiles = async (req: ListFileMetasRequest) => {
    const resp = await this._kyFetcher(
      listFileMetasSchema.response,
      `${this.endpoint}/list`,
      {
        method: 'GET',
        searchParams: req.query,
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Failed to list files',
        source: 'FileSDK.listFiles',
        extra: { query: req.query },
      });
      throw error;
    });
    return resp.filesMetadata;
  };

  getFileBlob = async (fileId: string) => {
    const { fileDownloadUrl } = await this._kyFetcher(
      getFileSchema.response,
      `${this.endpoint}/${fileId}`,
      {
        method: 'GET',
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Failed to get file download URL',
        source: 'FileSDK.getFileBlob',
        extra: { fileId },
      });
      throw error;
    });

    return ky
      .get(fileDownloadUrl)
      .blob()
      .catch((error: unknown) => {
        handleException(error, {
          name: 'Failed to download file',
          source: 'FileSDK.getFileBlob',
          extra: { fileId, fileDownloadUrl },
        });
        throw error;
      });
  };

  getFileJson = async (fileId: string) => {
    const { fileDownloadUrl } = await this._kyFetcher(
      getFileSchema.response,
      `${this.endpoint}/${fileId}`,
      {
        method: 'GET',
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Failed to get file download URL',
        source: 'FileSDK.getFileJson',
        extra: { fileId },
      });
      throw error;
    });

    return ky
      .get(fileDownloadUrl)
      .json()
      .catch((error: unknown) => {
        handleException(error, {
          name: 'Failed to parse file content',
          source: 'FileSDK.getFileJson',
          extra: { fileId, fileDownloadUrl },
        });
        throw error;
      });
  };

  resolveLinearizedFile = async (fileId: string) => {
    const response = await this._kyFetcher(
      resolveLinearizedFileSchema.response,
      `${this.endpoint}/${fileId}/linearized`,
      {
        method: 'POST',
      },
    ).catch((error: unknown) => {
      handleException(error, {
        name: 'Failed to resolve linearized file',
        source: 'FileSDK.resolveLinearizedFile',
        extra: { fileId },
      });
      throw error;
    });

    return response;
  };
}
