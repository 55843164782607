import {
  useMutation,
  type UseMutationResult,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query';
import { useAPI } from '../../hooks/useApi';
import { type WorkflowWebhook } from 'types-shared/workflowTypes';
import { AlertVariant, notify } from 'ui-kit';

export const useGetWorkflowWebhooks = (
  id: string,
): UseQueryResult<WorkflowWebhook[]> => {
  const { workflowSDK } = useAPI();
  return useQuery({
    queryKey: ['workflow-webhooks', id],
    queryFn: () => workflowSDK.getWorkflowWebhooks(id),
    staleTime: 0,
  });
};

interface UpsertWorkflowWebhookPayload {
  workflowId: string;
  webhooks: WorkflowWebhook[];
}

export const useUpsertWorkflowWebhook = (): UseMutationResult<
  void,
  Error,
  UpsertWorkflowWebhookPayload
> => {
  const { workflowSDK } = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ workflowId, webhooks }: UpsertWorkflowWebhookPayload) =>
      workflowSDK.updateWorkflowWebhooks(workflowId, webhooks),
    onSuccess: (_, { workflowId }) => {
      void queryClient.invalidateQueries({
        queryKey: ['workflow-webhooks', workflowId],
      });
      notify({
        variant: AlertVariant.SUCCESS,
        message: 'Workflow webhooks updated',
      });
    },
  });
};
