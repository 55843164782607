import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function ConditionalErrorIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 200 153"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 119H19.25C11.3799 119 5 125.38 5 133.25C5 141.12 11.3799 147.5 19.25 147.5H180.75C188.62 147.5 195 141.12 195 133.25C195 125.38 188.62 119 180.75 119H176M24 119H176M24 119V35.4019C24 24.7609 24 19.4364 26.0709 15.3721C27.8925 11.797 30.797 8.89247 34.3721 7.07088C38.4364 5 43.7609 5 54.4019 5H117.1M176 119V79.1"
        stroke="currentColor"
        strokeWidth="10"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157 42.9997L176 23.9998M176 23.9998L195 5M176 23.9998L157 5M176 23.9998L195 42.9997"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
