"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStatusTemporalExecutionSchema = exports.queueTemporalExecutionSchema = exports.retryConfigSchema = exports.queueExecutionsSchema = void 0;
var types_shared_1 = require("types-shared");
exports.queueExecutionsSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.uuidSchema,
        }),
        query: types_shared_1.z.object({}).optional(),
        body: types_shared_1.z.object({
            setRecordKeys: types_shared_1.z.array(types_shared_1.z.string()),
            bypassSQS: types_shared_1.z.boolean().optional().default(false),
            adminRun: types_shared_1.z.boolean().optional().default(false),
        }),
    }),
    response: types_shared_1.z.object({
        executionSetId: types_shared_1.uuidSchema,
        queuedExecutionIds: types_shared_1.z.array(types_shared_1.uuidSchema),
        unprocessedRecordKeys: types_shared_1.z.array(types_shared_1.z.string()).optional(),
    }),
};
exports.retryConfigSchema = types_shared_1.z
    .object({
    retryInterval: types_shared_1.z.number().gte(5).default(5), // 5 minutes is minimum retry interval
    maxAttempts: types_shared_1.z.number().gte(1).lte(100).default(1), // 1 means no retries, max attempts is 100
})
    .strict();
exports.queueTemporalExecutionSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.uuidSchema,
        }),
        body: types_shared_1.z
            .object({
            adminRun: types_shared_1.z.boolean().optional().default(true),
            trigger: types_shared_1.ExecutionTrigger.default(types_shared_1.ExecutionTriggerEnum.API),
            variableData: types_shared_1.z.record(types_shared_1.z.string(), types_shared_1.z.coerce.string()).default({}),
            retryConfig: exports.retryConfigSchema.optional(),
            versionId: types_shared_1.z.string().optional(),
        })
            .strict()
            .default({}),
    }),
    response: types_shared_1.z.object({
        executionId: types_shared_1.z.string(),
    }),
};
exports.getStatusTemporalExecutionSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
    }),
    response: types_shared_1.z.object({
        executionId: types_shared_1.z.string(),
        workflowId: types_shared_1.z.string(),
        status: types_shared_1.ExecutionStatus,
        createdAt: types_shared_1.z.string(),
        lastUpdatedAt: types_shared_1.z.string().optional(),
        variableData: types_shared_1.z.record(types_shared_1.z.string(), types_shared_1.z.string()).optional(),
        dashboardURL: types_shared_1.z.string().optional(),
        statusDescription: types_shared_1.z.string().optional(),
    }),
};
