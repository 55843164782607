import MenuItem from '@mui/material/MenuItem';
import type { ChangeEvent } from 'react';
import { useState, useRef, useMemo } from 'react';
import { type SelectOption } from 'types-shared';
import Box from '@mui/material/Box';
import { NotFound } from 'ui-kit';
import SearchIcon from '@mui/icons-material/Search';
import { clsx } from 'clsx';

interface Props {
  onClose: () => void;
  onSelect: (val: string) => void;
  options: SelectOption[];
}

function SelectMenuContent({ onClose, onSelect, options }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState<string>('');

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const filteredOptions = useMemo(() => {
    if (!search.length) {
      return options;
    }

    return options.filter((opt) =>
      opt.text.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, options]);

  return (
    <>
      <Box
        sx={{
          flexShrink: 0,
        }}
      >
        {options.length > 0 ? (
          <MenuItem
            className="!bg-white !px-3 !py-4 "
            disableRipple
            onClick={() => inputRef.current?.focus()}
          >
            <div
              className={clsx({
                'outline-none border rounded w-full': true,
                ' pl-1': !search.length,
                'focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500':
                  !search.length,
              })}
              onClick={() => inputRef.current?.focus()}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              role="presentation"
            >
              {search.length ? null : (
                <SearchIcon className="text-info-dark mr-1" />
              )}

              <input
                ref={inputRef}
                className={clsx({
                  'p-1 text-sm': true,
                  'border-0 focus:outline-none': !search.length,
                  'w-full ': Boolean(search.length),
                })}
                onChange={onSearchChange}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                placeholder="Search options"
                value={search}
              />
            </div>
          </MenuItem>
        ) : (
          <MenuItem
            className="!px-3 !py-2.5 pointer-events-none text-sm"
            divider
          >
            No options found.
          </MenuItem>
        )}
      </Box>

      <Box
        sx={{
          flex: '1 1 auto',
        }}
      >
        {filteredOptions.length ? (
          <div className="overflow-y-auto" style={{ maxHeight: '500px' }}>
            {filteredOptions.map(({ text, value }: SelectOption) => (
              <MenuItem
                className="!px-3 !py-2.5"
                divider
                key={value}
                onClick={() => {
                  onSelect(text);
                  onClose();
                }}
              >
                {text}
              </MenuItem>
            ))}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center p-5">
            {search.length ? (
              <div>
                <NotFound
                  fontSize="large"
                  style={{
                    height: '55px',
                    width: '43px',
                  }}
                />
              </div>
            ) : null}
            <p
              className={clsx({
                'text-gray-500 text-normal text-center text-sm': true,
                'mt-3': search.length,
                'mt-10': !search.length,
              })}
            >
              {search.length
                ? `There are no matching results for "${search}".`
                : ''}
            </p>
          </div>
        )}
      </Box>
    </>
  );
}

export default SelectMenuContent;
