import { useMemo } from 'react';
import {
  type GlobalVariable,
  type VariableMap,
  type DocumentVariable,
  QueryVariable,
} from 'types-shared/workflowTypes';
import { VariableChip } from '../../../../../../../components/VariableChip';
import { Button } from 'ui-kit';
import values from 'lodash/values';

interface DocumentVariableCardProps {
  variable: DocumentVariable;
  variableMap: VariableMap;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  onPreview: (chosenVariable: DocumentVariable) => void;
}
export function DocumentVariableCard({
  variable,
  variableMap,
  globalVariablesMap,
  onPreview,
}: DocumentVariableCardProps) {
  const queryVariables = useMemo(() => {
    return values(variableMap).filter(
      (_variable) =>
        QueryVariable.safeParse(_variable).success &&
        (_variable as QueryVariable).data.sourceIds.includes(variable.id),
    );
  }, [variableMap, variable]);

  const queryVariablesMap = useMemo(() => {
    return queryVariables.reduce((acc: VariableMap, v) => {
      acc[v.id] = v;
      return acc;
    }, {});
  }, [queryVariables]);

  return (
    <div className="border border-gray-300 rounded-lg mt-5">
      <div className="border-b border-gray-300 p-4">
        <p className="text-sm font-medium text-info-dark pb-4">
          Downloaded document{' '}
        </p>
        <VariableChip
          variableId={variable.id}
          variablesMap={variableMap}
          globalVariablesMap={globalVariablesMap}
        />
      </div>

      <div className="p-4 pb-6">
        <p className="text-sm font-medium text-info-dark pb-2">
          Values extracted{' '}
        </p>
        <div className="flex flex-row flex-wrap mb-2">
          {queryVariables.map((_variable) => (
            <VariableChip
              key={_variable.id}
              className="!bg-secondary-purple my-2 mr-2"
              leftIconClassName="!hidden"
              variableId={_variable.id}
              variablesMap={queryVariablesMap}
              globalVariablesMap={{}}
            />
          ))}
        </div>
        <Button
          variant="outlined"
          color="secondary"
          className="mt-4"
          onClick={() => {
            onPreview(variable);
          }}
        >
          PREVIEW DOCUMENT EXTRACT
        </Button>
      </div>
    </div>
  );
}
