import { useState, useMemo } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { type WorkflowRunVariablesProps } from './WorkflowRunVariables';
import {
  Select,
  Button,
  GrayedOutInput,
  DateTimePicker,
  notify,
  AlertVariant,
} from 'ui-kit';
import dayjs from '../../../config/dayjs';
import { clsx } from 'clsx';
import {
  useGetSchedules,
  useCreateSchedule,
  useEditSchedule,
  useDeleteSchedule,
} from '../hooks';
import { useParams } from 'react-router-dom';
import { RRule } from 'rrule';
import { handleException } from 'sentry-browser-shared';
import {
  convertScheduleToRRuleString,
  getScheduleTitle,
  parseRRuleStringToScheduleState,
  type ScheduleState,
  TriggerInterval,
  triggerIntervalOptsMap,
} from '../helper';
import type { GetWorkflowMetadataResponse } from 'api-types-shared';
import { isAdmin } from '../../../utils/env';
import { type Create_Schedule_AdminMutationVariables as CreateScheduleAdminMutationVariables } from 'hasura-gql';

export const defaultState: ScheduleState = {
  triggerInterval: TriggerInterval.DoesNotRepeat,
  triggerHr: '',
  customTriggerHr: undefined,
};

const generateTriggerHours = (): string[] => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    const hour = i % 12 === 0 ? 12 : i % 12;
    const period = i < 12 ? 'AM' : 'PM';
    hours.push(`${hour.toString().padStart(2, '0')}:00 ${period}`);
  }
  return hours;
};

const triggerHrs = generateTriggerHours();

type Props = Pick<
  WorkflowRunVariablesProps,
  'isFetchingNodeViewData' | 'isPendingTest'
> & {
  workflowMetadata?: GetWorkflowMetadataResponse | null;
};

export default function ScheduleRun({
  isFetchingNodeViewData,
  isPendingTest,
  workflowMetadata,
}: Props) {
  const [isCreatingNew, setIsCreatingNew] = useState(true);
  const [isEditing, setIsEditing] = useState(true);
  const { workflowId } = useParams();
  const { data: workflowSchedules, refetch: refetchSchedules } =
    useGetSchedules(workflowId);
  const [scheduleState, setScheduleState] =
    useState<ScheduleState>(defaultState);
  const deleteSchedule = useDeleteSchedule();
  const createSchedule = useCreateSchedule();
  const editSchedule = useEditSchedule();

  const intervalOptions = useMemo(
    () => Object.keys(triggerIntervalOptsMap),
    [],
  );

  const schedules = useMemo(() => {
    return (workflowSchedules ?? []).map((s) => {
      const rrule = RRule.fromString(s.rrule);
      rrule.options.tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return {
        ...s,
        title: getScheduleTitle(parseRRuleStringToScheduleState(s.rrule)),
      };
    });
  }, [workflowSchedules]);

  const isSingleExecution = useMemo(
    () => scheduleState.triggerInterval === TriggerInterval.DoesNotRepeat,
    [scheduleState],
  );

  const valuesMissing = useMemo(() => {
    let disabled = false;
    const thisIsASingleExec =
      scheduleState.triggerInterval === TriggerInterval.DoesNotRepeat;
    if (thisIsASingleExec) {
      disabled =
        !scheduleState.customTriggerHr || !scheduleState.triggerInterval;
    } else {
      disabled = !scheduleState.triggerHr || !scheduleState.triggerInterval;
    }
    return disabled;
  }, [scheduleState]);

  const resetStateToEmpty = () => {
    setIsEditing(true);
    setScheduleState(defaultState);
    setIsCreatingNew(true);
  };

  const onDelete = async (id: string) => {
    await deleteSchedule({ variables: { id } });
    resetStateToEmpty();
    notify({
      message: 'Workflow run schedule has been deleted.',
      variant: AlertVariant.WARNING,
    });
    await refetchSchedules();
  };

  const handleSubmit = async () => {
    setIsEditing(false);
    const rruleString = convertScheduleToRRuleString(scheduleState);
    if (scheduleState.id) {
      await editSchedule({
        variables: { id: scheduleState.id, rrule: rruleString },
      });
    } else {
      if (!workflowId) {
        handleException(new Error('Workflow ID is not defined'), {
          name: 'Workflow ID is not defined',
          source: 'ScheduleRun',
        });
        return;
      }
      const payload: CreateScheduleAdminMutationVariables = {
        workflowId,
        rrule: rruleString,
      };
      if (isAdmin) {
        payload.ownerId = workflowMetadata?.userId;
      }
      await createSchedule({
        variables: payload,
      });
    }
    await refetchSchedules();
    resetStateToEmpty();
    notify({
      message: 'Workflow run has been scheduled successfully.',
      variant: AlertVariant.SUCCESS,
    });
  };

  return (
    <div className="flex flex-row space-x-4 text-sm">
      <div className="flex-shrink-0 w-1/3 pr-4 max-h-[50vh] overflow-auto flex flex-col space-y-3">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <p
          className={clsx(
            'px-3 py-5 flex items-center gap-2 w-full cursor-pointer',
            {
              'text-info bg-blue-50 !border-secondary-blue !border-r-2':
                isCreatingNew,
              'text-gray-700': !isCreatingNew,
            },
          )}
          onClick={() => {
            setScheduleState({ ...defaultState });
            setIsEditing(true);
            setIsCreatingNew(true);
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <CalendarTodayIcon />
          <span className="truncate">NEW SCHEDULE</span>
        </p>

        {schedules.map((sRun) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <p
            key={sRun.id}
            className={clsx(
              'px-3 py-5 flex items-center gap-2 w-full cursor-pointer',
              {
                'text-info bg-blue-50 !border-secondary-blue !border-r-2':
                  sRun.id === scheduleState.id,
                'text-gray-700': sRun.id !== scheduleState.id,
              },
            )}
            onClick={() => {
              setScheduleState({
                id: sRun.id,
                ...parseRRuleStringToScheduleState(sRun.rrule),
              });
              setIsCreatingNew(false);
              setIsEditing(false);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <CalendarTodayIcon />
            <span className="truncate">{sRun.title}</span>
          </p>
        ))}
      </div>

      <div className="flex-grow flex flex-col max-h-[50vh] overflow-auto">
        <span className="text-info-dark text-normal font-medium">
          Execution Date
        </span>

        {isEditing ? (
          <span className="text-gray-500 text-sm">
            Determine when you want to execute the test
          </span>
        ) : null}

        <div className="mt-3 flex flex-col mb-3">
          {!isEditing ? (
            <GrayedOutInput
              label="Frequency"
              value={
                scheduleState.triggerInterval
                  ? triggerIntervalOptsMap[scheduleState.triggerInterval]
                  : 'Does not repeat'
              }
              isSelect
            />
          ) : (
            <Select
              size="medium"
              classes={{ select: '!py-4' }}
              defaultValue={TriggerInterval.DoesNotRepeat}
              getLabel={(opt: string) => triggerIntervalOptsMap[opt] ?? opt}
              getValue={(opt: string) => opt}
              label="Frequency"
              labelId="template-select-variable-source"
              onChange={(e) => {
                setScheduleState((oldState) => ({
                  ...oldState,
                  triggerInterval: e.target.value as TriggerInterval,
                }));
              }}
              options={intervalOptions}
              value={scheduleState.triggerInterval}
            />
          )}
        </div>

        <div className="mt-3 flex flex-col mb-3">
          {!isEditing ? (
            <GrayedOutInput
              label="Trigger at"
              value={
                scheduleState.customTriggerHr
                  ? dayjs(scheduleState.customTriggerHr).format(
                      'MM/DD/YYYY hh:mm A',
                    )
                  : scheduleState.triggerHr
              }
              isSelect
            />
          ) : (
            <>
              {!isSingleExecution ? (
                <Select
                  classes={{ select: '!py-4' }}
                  defaultValue={triggerHrs[0]}
                  disabled={triggerHrs.length < 2}
                  getLabel={(opt: string) => opt}
                  getValue={(opt: string) => opt}
                  size="small"
                  label="Trigger at"
                  onChange={(e) => {
                    setScheduleState((oldState) => ({
                      ...oldState,
                      triggerHr: e.target.value,
                    }));
                  }}
                  options={triggerHrs}
                  value={scheduleState.triggerHr}
                />
              ) : (
                <DateTimePicker
                  label="Trigger at hour"
                  onChange={(e) => {
                    setScheduleState((oldState) => ({
                      ...oldState,
                      customTriggerHr: e.toISOString(),
                    }));
                  }}
                  value={
                    scheduleState.customTriggerHr
                      ? dayjs(scheduleState.customTriggerHr)
                      : undefined
                  }
                />
              )}
            </>
          )}
        </div>

        <div className="!mt-10 flex space-x-4 flex-row justify-between gap-4">
          <Button
            className="px-5 !min-w-[120px]"
            color="secondary"
            loading={isPendingTest}
            onClick={() => {
              if (!isEditing) {
                setIsEditing(true);
              } else {
                void handleSubmit();
              }
            }}
            disabled={isFetchingNodeViewData || (isEditing && valuesMissing)}
            variant={isEditing ? 'contained' : 'outlined'}
          >
            {isEditing ? 'SCHEDULE RUN' : 'EDIT'}
          </Button>
          {scheduleState.id && !isEditing ? (
            <Button
              color="error"
              onClick={() => {
                if (scheduleState.id) {
                  void onDelete(scheduleState.id);
                }
              }}
              variant="outlined"
              className="px-5 !min-w-[120px]"
            >
              DELETE
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
