import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function IntegrationsIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9999 6.99999H17.8486C17.3511 6.99999 17 6.4975 17 5.99999C17 4.34314 15.6568 3 14 3C12.3431 3 11 4.34314 11 5.99999C11 6.4975 10.6488 6.99999 10.1513 6.99999H7.99999C7.44771 6.99999 6.99999 7.44771 6.99999 7.99999V10.1513C6.99999 10.6488 6.4975 11 5.99999 11C4.34314 11 3 12.3431 3 14C3 15.6568 4.34314 17 5.99999 17C6.4975 17 6.99999 17.3511 6.99999 17.8486V20C6.99999 20.5523 7.44771 21 7.99999 21L20 21C20.5523 21 21 20.5522 21 19.9999V17.8486C21 17.3511 20.4975 17 20 17C18.3431 17 17 15.6568 17 14C17 12.3431 18.3431 11 20 11C20.4975 11 21 10.6488 21 10.1513L21 7.99999C21 7.44771 20.5522 6.99999 19.9999 6.99999Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
