import type { FallbackProps } from 'react-error-boundary';
import { Button, useEnvironment, Logo } from 'ui-kit';
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import { handleException } from 'sentry-browser-shared';

export function ErrorFallbackUI({ error, resetErrorBoundary }: FallbackProps) {
  const { selectedEnv } = useEnvironment();

  const errorTrace: string | null = useMemo(() => {
    if (selectedEnv !== 'development') return null;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return 'stack' in error && typeof error.stack === 'string'
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (error.stack as string)
      : null;
  }, [error, selectedEnv]);

  useEffect(() => {
    handleException(error, {
      name: 'Crash on dashboard error boundary',
      source: 'ErrorBoundary',
    });
  }, [error]);

  return (
    <section className="h-[100vh] relative flex flex-col" role="alert">
      <header className="py-5 flex !h-20 px-6 w-full">
        <div className="flex items-center">
          <Link to="/">
            <Logo className="!w-7 !h-7 !cursor-pointer" />
          </Link>
        </div>
      </header>

      <div className="flex flex-col space-y-8 justify-center items-center h-full">
        <div className={clsx('space-y-2', errorTrace && 'bg-red-50 p-4')}>
          <h1 className="text-xl text-center text-black">
            Oops, Something Went Wrong
          </h1>
          {errorTrace ? <pre className="text-black">{errorTrace}</pre> : null}
        </div>
        <Button
          color="secondary"
          onClick={() => {
            resetErrorBoundary();
            window.location.reload();
          }}
          type="button"
          variant="contained"
        >
          Restart App
        </Button>
      </div>
    </section>
  );
}
