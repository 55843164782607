"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileMetadataSchema = void 0;
var types_shared_1 = require("types-shared");
exports.fileMetadataSchema = types_shared_1.z.object({
    fileId: types_shared_1.z.string(),
    name: types_shared_1.z.string(),
    workflowId: types_shared_1.z.string(),
    uploadedAt: types_shared_1.z
        .union([types_shared_1.z.string(), types_shared_1.z.date()])
        .transform(function (x) { return (typeof x === 'string' ? new Date(x) : x); })
        .optional(),
    userId: types_shared_1.z.string(),
    teamId: types_shared_1.z.string(),
});
