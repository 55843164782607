"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAgentOverride = exports.UserAgentMetadata = exports.UserAgentBrandVersion = void 0;
var zod_1 = require("zod");
// See: https://chromedevtools.github.io/devtools-protocol/tot/Emulation/#type-UserAgentBrandVersion
exports.UserAgentBrandVersion = zod_1.z.object({
    brand: zod_1.z.string(),
    version: zod_1.z.string(),
});
// See: https://chromedevtools.github.io/devtools-protocol/tot/Emulation/#type-UserAgentMetadata
exports.UserAgentMetadata = zod_1.z.object({
    brands: zod_1.z.array(exports.UserAgentBrandVersion).optional(),
    fullVersionList: zod_1.z.array(exports.UserAgentBrandVersion).optional(),
    fullVersion: zod_1.z.string().optional(), // deprecated
    platform: zod_1.z.string(),
    platformVersion: zod_1.z.string(),
    architecture: zod_1.z.string(),
    model: zod_1.z.string(),
    mobile: zod_1.z.boolean(),
    bitness: zod_1.z.string().optional(),
    wow64: zod_1.z.boolean().optional(),
});
exports.UserAgentOverride = zod_1.z.object({
    userAgent: zod_1.z.string(),
    acceptLanguage: zod_1.z.string().optional(),
    platform: zod_1.z.string().optional(),
    userAgentMetadata: exports.UserAgentMetadata.optional(),
});
