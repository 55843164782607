import { FeatureFlag } from '../../utils/constants';
import { type GridColDef } from '@mui/x-data-grid/models';
import { type GlobalVariable } from 'hasura-gql';
import {
  AlertVariant,
  Button,
  ContentContainer,
  CopyIcon,
  IconButton,
  Menu,
  MenuItem,
  notify,
  ScrollableContainer,
  Tooltip,
  CustomTypography,
  TrashIcon,
} from 'ui-kit';
import { useEffect, useMemo, useState } from 'react';
import { isAdmin } from '../../utils/env';
import { useNavigate } from 'react-router-dom';
import { MOCK_USER_ID } from 'types-shared';
import { formatDate, useAuth, useFeatureFlag } from '../../utils/helper';
import { NavigationPanel } from '../../components/NavigationPanel';
import { GlobalVariablesListSkeleton } from './components/GlobalVariablesSkeleton';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { NoDataFound } from '../../components/NoDataFound';
import {
  CreateGlobalVariableModal,
  type FormValues,
} from './components/CreateGlobalVariableModal';
import {
  useGetGlobalVariables,
  useSaveGlobalVariable,
  useUpdateGlobalVariable,
} from './hooks.gql';

interface MenuProps extends GlobalVariable {
  anchorEl: HTMLButtonElement;
}

const copyToClipboard = (text: string) => {
  void navigator.clipboard.writeText(text).then(() => {
    notify({
      message: 'Copied to clipboard',
      variant: AlertVariant.SUCCESS,
    });
  });
};

export default function GlobalVariablesUser() {
  const navigate = useNavigate();
  const globalVariablesEnabled = useFeatureFlag(FeatureFlag.GlobalVariables);

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [initialEditValues, setInitialEditValues] = useState<GlobalVariable>();
  const [menuProps, setMenuProps] = useState<MenuProps | null>(null);
  const { user } = useAuth();
  const userID = user?.sub ?? (isAdmin ? MOCK_USER_ID : undefined);
  const { data, loading, error: getListError } = useGetGlobalVariables();
  const {
    saveGlobalVariable,
    loading: saveLoading,
    error: saveError,
  } = useSaveGlobalVariable();

  const {
    updateGlobalVariable,
    loading: updateLoading,
    error: updateError,
  } = useUpdateGlobalVariable();

  const loadingChanges = saveLoading || updateLoading;

  const handleCreateGlobalVariable = async ({ name, value }: FormValues) => {
    if (!userID) return;

    await saveGlobalVariable({
      name,
      value,
      ownerId: userID,
    });

    const success = !saveError;
    if (success) {
      notify({
        message: 'Shared variable created successfully.',
        variant: AlertVariant.SUCCESS,
      });
    } else {
      notify({
        message: isAdmin
          ? saveError.message
          : 'Failed to save Shared Variable!',
        variant: AlertVariant.ERROR,
      });
    }
  };

  const closeMenu = () => {
    setMenuProps(null);
  };

  const onDelete = () => {
    // Placeholder for Delete Logic
    closeMenu();
  };

  const handleEditGlobalVariable = async ({ name, value }: FormValues) => {
    if (initialEditValues) {
      await updateGlobalVariable({ name, value, id: initialEditValues.id });
      const success = !updateError;
      if (success) {
        notify({
          message: 'Shared variable edited successfully.',
          variant: AlertVariant.SUCCESS,
        });
      } else {
        notify({
          message: isAdmin
            ? updateError.message
            : 'Failed to edit Shared Variable!',
          variant: AlertVariant.ERROR,
        });
      }
      setInitialEditValues(undefined);
    }
  };

  const columns: GridColDef<GlobalVariable>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 3,
      },
      {
        field: 'value',
        headerName: 'Value',
        flex: 4,
        renderCell: (params) => {
          const itemValue = params.value as string;
          return (
            <div className="flex justify-between w-full pr-2">
              <div className="flex-[10]">
                <Tooltip
                  title={itemValue}
                  hidden={itemValue.length < 30}
                  placement="bottom-start"
                >
                  <p className="truncate w-[25rem] max-w-[90%]">{itemValue}</p>
                </Tooltip>
              </div>
              <div className="flex-[2]">
                <IconButton
                  className="!p-0"
                  onClick={() => {
                    copyToClipboard(itemValue);
                  }}
                >
                  <CopyIcon className="text-white" />
                </IconButton>
              </div>
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Last updated',
        flex: 3,
        renderCell: (params) => (
          <span className="flex items-center gap-4">
            {formatDate(params.row.updatedAt)}
          </span>
        ),
      },
      {
        field: 'action',
        headerName: '',
        align: 'right',
        renderCell: (params) => (
          <span className="flex items-center gap-4">
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setInitialEditValues(params.row);
                setShowCreateModal(true);
              }}
            >
              Edit
            </Button>
          </span>
        ),
        flex: 2,
        sortable: false,
      },
    ],
    [],
  );

  useEffect(() => {
    if (!isAdmin && globalVariablesEnabled === false) {
      navigate('/workflows');
    }
  }, [globalVariablesEnabled, navigate]);

  useEffect(() => {
    if (getListError) {
      notify({
        message: getListError.message,
        variant: AlertVariant.ERROR,
      });
    }
  }, [getListError]);

  return (
    <ScrollableContainer>
      <div className="w-full h-full flex">
        <NavigationPanel />
        <ContentContainer>
          <div className="flex justify-between w-full items-center">
            <div className="w-4/6">
              <CustomTypography variant="h4">Shared Variables</CustomTypography>
              <div className="mt-2">
                <span className="font-normal text-sm">
                  Use shared variables across your workflows.
                </span>
              </div>
            </div>

            <Button
              color="secondary"
              // disabled={addDisabled}
              onClick={() => {
                setShowCreateModal(true);
              }}
              variant="contained"
            >
              Add shared variable
            </Button>
          </div>

          <div className="mt-11">
            {loading ? (
              <GlobalVariablesListSkeleton columns={columns} />
            ) : (
              <>
                {data?.length ? (
                  <DataGrid
                    columns={columns}
                    getRowId={(row) => row.id}
                    hideFooter
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'updatedAt', sort: 'desc' }],
                      },
                    }}
                    rows={data}
                    sx={{
                      '& .MuiDataGrid-columnHeader': {
                        backgroundColor: 'white',
                      },
                    }}
                  />
                ) : (
                  <NoDataFound
                    heading="You don’t have Shared Variables yet"
                    subHeading="Start by clicking “Add Shared Variable” to create your first one."
                  />
                )}
              </>
            )}
          </div>
        </ContentContainer>
      </div>
      <CreateGlobalVariableModal
        initialValues={
          initialEditValues
            ? { name: initialEditValues.name, value: initialEditValues.value }
            : undefined
        }
        open={showCreateModal}
        setOpen={(val: boolean) => {
          setShowCreateModal(val);
          if (!val) {
            setInitialEditValues(undefined);
          }
        }}
        createVariableStatus="idle"
        onCreate={handleCreateGlobalVariable}
        onEdit={handleEditGlobalVariable}
        loading={loadingChanges}
      />
      <Menu
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        anchorEl={menuProps?.anchorEl ?? null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={closeMenu}
        open={Boolean(menuProps)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem className="group min-w-72 h-12" onClick={onDelete}>
          <span className="font-normal leading-6 group-hover:text-red-500">
            Delete
          </span>
          <TrashIcon className="!ml-auto text-cyan-900 group-hover:text-red-500" />
        </MenuItem>
      </Menu>
    </ScrollableContainer>
  );
}
