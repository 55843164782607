import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function EditIconOutline(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66699 11.3321H12.3337M1.66699 11.3321V8.66544L7.00033 3.33211M1.66699 11.3321L4.33366 11.3321L9.66698 5.99877M7.00033 3.33211L8.91274 1.41967L8.91389 1.41854C9.17715 1.15528 9.30901 1.02342 9.46101 0.974035C9.59491 0.93053 9.73915 0.93053 9.87305 0.974035C10.0249 1.02339 10.1567 1.1551 10.4195 1.41798L11.5794 2.57785C11.8434 2.84186 11.9755 2.97392 12.0249 3.12614C12.0684 3.26004 12.0684 3.40427 12.0249 3.53816C11.9755 3.69027 11.8436 3.82214 11.58 4.08577L11.5794 4.08634L9.66698 5.99877M7.00033 3.33211L9.66698 5.99877"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
