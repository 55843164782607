import { useNavigate, useParams } from 'react-router-dom';
import { useFetchDatasourceTable, useGetDatasourceForWorkflow } from '../hooks';
import { useFetchWorkflowMetadata } from '../../Workflows/hooks';
import { NoDataFound } from '../../../components/NoDataFound';
import { DatasourcePreviewTable } from '../components/DatasourcePreviewTable';

export default function DatasourcePreview() {
  const { workflowId } = useParams<{ workflowId: string }>();
  if (!workflowId) {
    throw new Error('workflowId not found!');
  }

  const navigate = useNavigate();
  const { data: workflowMetadata } = useFetchWorkflowMetadata(workflowId);
  const { data: datasourceList } = useGetDatasourceForWorkflow(workflowId);

  const datasourceMetadata = datasourceList?.[0];

  const { data: tableData, isFetching } = useFetchDatasourceTable(
    datasourceMetadata?.datasourceId,
    true,
  );

  const handleGoBack = () => {
    navigate(`/editor/${workflowId}`); // navigate back
  };

  if (!datasourceMetadata) {
    return (
      <NoDataFound
        heading="No datasource"
        subHeading="No datasource to added to this workflow"
      />
    );
  }

  return (
    <DatasourcePreviewTable
      fileName={datasourceMetadata.name}
      isFetching={isFetching}
      onBack={handleGoBack}
      tableData={tableData}
      workflowId={workflowId}
      workflowMetadata={workflowMetadata}
    />
  );
}
