import { clsx } from 'clsx';
import type { HTMLAttributes, ReactNode } from 'react';
import React from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: ReactNode;
  withToolbar?: boolean;
}
export default function ContentContainer({
  className,
  children,
  withToolbar = false,
  ...props
}: Props) {
  return (
    <div
      className={clsx(
        'pt-12 pb-8 px-8 overflow-y-auto relative flex-grow',
        withToolbar && 'h-[calc(100%_-_92px)]',
        !withToolbar && 'h-full',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
