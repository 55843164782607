import type { Theme, ThemeOptions } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import type { ReactNode } from 'react';
import React from 'react';

import {
  primary as main,
  primaryDark as dark,
  primaryLight as light,
  secondary,
  secondaryDark,
  secondaryLight,
} from '../utils/constants';

const theme: ThemeOptions = {
  palette: {
    primary: {
      light,
      main,
      dark,
    },
    secondary: {
      light: secondaryLight,
      main: secondary,
      dark: secondaryDark,
    },
  },
};

interface Props {
  theme?: Theme;
  children: ReactNode;
}

export default function UIKITProvider({ theme: customTheme, children }: Props) {
  return (
    <ThemeProvider theme={customTheme ?? createTheme(theme)}>
      {children}
    </ThemeProvider>
  );
}

export { theme, type ThemeOptions };
