import {
  QueryValueTypeEnum,
  type QueryVariable,
  RequestIntegrationTypeEnum,
  RequestMethodEnum,
  SourceTypeEnum,
  type SourceVariable,
  type TemplateVariable,
  type Variable,
  VariableTypeEnum,
  type WorkflowRequestNode,
} from 'types-shared';
import { v4 as uuid } from 'uuid';
import type { WorkflowRequestNodeCoreData } from './RequestBlockAdmin';

export const createQueryVariable = (
  num: number,
  sourceId: string,
  addVariable: (variable: Variable) => void,
): QueryVariable => {
  const variable: QueryVariable = {
    id: uuid(),
    type: VariableTypeEnum.Query,
    name: `Response Value ${num.toString()}`,
    data: {
      sourceIds: [sourceId],
      query: [],
      valueType: QueryValueTypeEnum.String,
    },
  };

  // add variable in the global variables map
  addVariable(variable);

  return variable;
};

export const createTemplateVariable = (
  addVariable: (variable: Variable) => void,
): TemplateVariable => {
  const variable: TemplateVariable = {
    id: uuid(),
    type: VariableTypeEnum.Template,
    data: [''],
  };

  // add variable in the global variables map
  addVariable(variable);

  return variable;
};

const createRequestSourceVariable = (
  addVariable: (variable: Variable) => void,
): SourceVariable => {
  const id = uuid();
  const variable: SourceVariable = {
    id,
    name: 'Request Step',
    type: VariableTypeEnum.Source,
    data: {
      sourceType: SourceTypeEnum.Request,
    },
  };

  // add variable in the global variables map
  addVariable(variable);

  return variable;
};

export const initialRequestBlockData = (
  addVariable: (variable: Variable) => void,
  isAdmin: boolean,
): WorkflowRequestNodeCoreData => {
  const requestSourceVariable = createRequestSourceVariable(addVariable);

  return {
    integrationType: RequestIntegrationTypeEnum.None,
    changesResolved: true,
    method: RequestMethodEnum.Get,
    url: { variableId: createTemplateVariable(addVariable).id },
    urlParameters: [],
    headers: [],
    body: [],
    auth: {
      forUserId: {
        enabled: isAdmin,
        value: { variableId: createTemplateVariable(addVariable).id },
      },
      authKey: {
        enabled: isAdmin,
        value: { variableId: createTemplateVariable(addVariable).id },
      },
    },
    responseVariableId: requestSourceVariable.id,
    blocking: true,
  };
};

export const getRequestNodeTitle = (node: WorkflowRequestNode) => {
  if (node.name) return node.name;

  return node.data.integrationType === RequestIntegrationTypeEnum.Salesforce
    ? 'Salesforce integration'
    : 'Request step';
};

export const getRequestBlockTitle = (
  node: WorkflowRequestNode,
  short?: boolean,
) => {
  const initial =
    node.data.integrationType === RequestIntegrationTypeEnum.Salesforce
      ? 'Salesforce integration'
      : 'Request step';

  if (short) return initial;
  if (node.name) return `${initial}: ${node.name}`;
  return initial;
};

export const getRequestNodeDescription = (node: WorkflowRequestNode) => {
  switch (node.data.integrationType) {
    case RequestIntegrationTypeEnum.Salesforce:
      return 'Find, create, and update records in Salesforce';
    case RequestIntegrationTypeEnum.None:
    default:
      return 'Push and pull data from external platforms using API ';
  }
};
