import { Button, Input, Modal, Label, SubLabel } from 'ui-kit';
import { useState } from 'react';

interface Props {
  workflowId: string | undefined;
  workflowName?: string;
  statusDesc?: string;
  onUpdateStatusDesc: (statusDesc: string) => void;
  setOpen: (open: boolean) => void;
  open: boolean;
  updateStatus: 'error' | 'idle' | 'pending' | 'success';
}

export function UpdateStatusDescModal({
  onUpdateStatusDesc,
  workflowId,
  workflowName,
  updateStatus,
  statusDesc,
  open,
  setOpen,
}: Props) {
  const [newStatusDesc, setNewStatusDesc] = useState(statusDesc ?? '');

  const onSubmit = () => {
    if (workflowId) {
      onUpdateStatusDesc(newStatusDesc);
      setOpen(false);
    }
  };

  return (
    <Modal
      className="flex item-center justify-items-center max-w-[62rem] min-w-[40rem]"
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <div className="text-sm">
        <div className="flex-1 max-w-lg">
          <p className="font-medium mb-7 text-black">
            Update Status Description
          </p>
          <div className="flex flex-col gap-2 mt-14">
            {workflowName ? (
              <>
                <Label>Workflow</Label>
                <SubLabel>{workflowName}</SubLabel>
              </>
            ) : (
              <>
                <Label>Workflow</Label>
                <SubLabel>{workflowId}</SubLabel>
              </>
            )}
          </div>

          <Input
            classes={{ wrapper: 'flex flex-col mt-10' }}
            floatingLabel
            label="Error Description"
            multiline
            onChange={(description: string) => {
              setNewStatusDesc(description);
            }}
            placeholder="Describe the issue in detail"
            rows={5}
            value={newStatusDesc}
          />

          <div className="flex flex-row gap-7 mt-10">
            <Button
              className="w-40 h-9"
              color="secondary"
              loading={updateStatus === 'pending'}
              onClick={onSubmit}
              variant="contained"
            >
              SUBMIT
            </Button>
            <Button
              className="h-9"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
              variant="text"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
