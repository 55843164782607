import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function CalendarIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66669 5.33331H13.3334M2.66669 5.33331V11.2001C2.66669 11.9468 2.66669 12.32 2.81201 12.6052C2.93984 12.8561 3.14367 13.0603 3.39455 13.1881C3.67949 13.3333 4.05268 13.3333 4.79796 13.3333H11.2021C11.9474 13.3333 12.32 13.3333 12.6049 13.1881C12.8558 13.0603 13.0603 12.8561 13.1882 12.6052C13.3334 12.3203 13.3334 11.9476 13.3334 11.2024V5.33331M2.66669 5.33331V4.80011C2.66669 4.05337 2.66669 3.67973 2.81201 3.39451C2.93984 3.14363 3.14367 2.9398 3.39455 2.81197C3.67977 2.66665 4.05341 2.66665 4.80015 2.66665H5.33335M13.3334 5.33331V4.79792C13.3334 4.05264 13.3334 3.67945 13.1882 3.39451C13.0603 3.14363 12.8558 2.9398 12.6049 2.81197C12.3197 2.66665 11.9469 2.66665 11.2002 2.66665H10.6667M10.6667 1.33331V2.66665M10.6667 2.66665H5.33335M5.33335 1.33331V2.66665"
        fill="none"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
