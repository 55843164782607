import type { StateCreator } from 'zustand';

export interface MiscStateData {
  unsavedChanges: boolean;
}

export const initialMiscState: MiscStateData = {
  unsavedChanges: false,
};

export interface MiscStateActions {
  resetUnsavedChanges: () => void;
}

export const MiscState: StateCreator<MiscStateData & MiscStateActions> = (
  set,
) => ({
  ...initialMiscState,
  resetUnsavedChanges: () => {
    set({ unsavedChanges: false });
  },
});
