import {
  NodeTypesEnum,
  type Variable,
  type SelectedAction,
  type WorkflowAction,
  type WorkflowEdge,
  type WorkflowNode,
  type WorkflowStateData,
} from 'types-shared';
import type {
  Connection,
  EdgeChange,
  NodeChange,
  NodeRemoveChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  Viewport,
} from 'types-shared/reactflow';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from 'types-shared/reactflow';
import type { StateCreator } from 'zustand';
import {
  addBranch,
  removeBranch,
  syncBranches,
  removeNode,
} from '../utils/helper';
import { type VariableStateActions } from './VariableState';

type PartialVariableStateActions = Partial<
  Pick<VariableStateActions, 'addVariable'>
>;

export const initialWorkflowState: WorkflowStateData = {
  nodes: [],
  edges: [],
  selectedAction: null,
  selectedNode: null,
  bulkSelectMode: false,
  workflowId: undefined,
  currentVersionId: undefined,
  currentViewport: undefined,
};

export interface WorkflowStateActions {
  addNodes: (nodes: WorkflowNode[]) => void;
  addEdges: (edges: WorkflowEdge[]) => void;
  setNodes: (nodes: WorkflowNode[]) => void;
  updateNode: (node: WorkflowNode) => void;
  updateImageNodeAction: (nodeId: string, action: WorkflowAction) => void;
  setEdges: (edges: WorkflowEdge[]) => void;
  setSelectedAction: (action: SelectedAction | null) => void;
  setWorkflowId: (workflowId: string | undefined) => void;
  setSelectedNode: (nodeId: string | null) => void;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  resetWorkflow: () => void;
  setCurrentVersionId: (currentVersionId: string | undefined) => void;
  setCurrentViewport: (viewport: Viewport) => void;
}

export const WorkflowState: StateCreator<
  WorkflowStateData & WorkflowStateActions & PartialVariableStateActions
> = (set, get) => ({
  ...initialWorkflowState,
  addNodes: (nodes: WorkflowNode[]) => {
    set({ nodes: [...get().nodes, ...nodes] });
  },
  updateNode: (node: WorkflowNode) => {
    const oldNodes = [...get().nodes];
    set({
      nodes: oldNodes.map((_node) => {
        if (_node.id === node.id) {
          return node;
        }
        return _node;
      }),
    });
  },
  updateImageNodeAction: (nodeId: string, action: WorkflowAction) => {
    const oldNodes = [...get().nodes];
    set({
      nodes: oldNodes.map((_node) => {
        if (_node.id === nodeId && _node.type === NodeTypesEnum.Image) {
          return {
            ..._node,
            data: {
              ..._node.data,
              actionData: {
                ..._node.data.actionData,
                [action.id]: {
                  ...action,
                },
              },
            },
          };
        }
        return _node;
      }),
    });
  },
  addEdges: (edges: WorkflowEdge[]) => {
    set({ edges: [...get().edges, ...edges] });
  },
  setNodes: (nodes: WorkflowNode[] = []) => {
    set({ nodes });
  },
  setEdges: (newEdges: WorkflowEdge[] = []) => {
    const { nodes: nodesInitial, addVariable } = get();
    const handleAddVariable = (val: Variable) => {
      addVariable?.(val);
    };

    const { nodes, edges } = syncBranches(
      nodesInitial,
      newEdges,
      handleAddVariable,
    );
    set({ nodes, edges });
  },
  onNodesChange: (changes: NodeChange[]) => {
    const { nodes, edges } = get();
    const nodesToDelete = changes
      .filter(({ type }) => type === 'remove')
      .map((change) => (change as NodeRemoveChange).id);
    const filteredChanges = changes.filter(({ type }) => type !== 'remove');
    let filteredNodes = nodes;
    let filteredEdges = edges;
    nodesToDelete.forEach((id) => {
      const { nodes: n, edges: e } = removeNode(
        filteredNodes,
        filteredEdges,
        id,
      );
      filteredNodes = n;
      filteredEdges = e;
    });
    set({
      nodes: applyNodeChanges(filteredChanges, filteredNodes) as WorkflowNode[],
      edges: filteredEdges,
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    const { nodes, edges } = get();
    const newEdges = applyEdgeChanges(changes, edges) as WorkflowEdge[];
    const newNodes = removeBranch(changes, nodes);
    set({
      nodes: newNodes,
      edges: newEdges,
    });
  },
  onConnect: (connection: Connection) => {
    const { nodes, edges, addVariable } = get();

    const handleAddVariable = (val: Variable) => {
      addVariable?.(val);
    };

    const { edge, nodes: newNodes } = addBranch(
      nodes,
      connection,
      handleAddVariable,
    );
    set({
      edges: addEdge(edge, edges),
      nodes: newNodes,
    });
  },
  setSelectedAction: (selectedAction: SelectedAction | null) => {
    set({ selectedAction });
  },
  setSelectedNode: (selectedNode: string | null) => {
    set({ selectedNode });
  },
  setWorkflowId: (workflowId: string | undefined) => {
    set({ workflowId });
  },
  resetWorkflow: () => {
    set(initialWorkflowState);
  },
  setCurrentVersionId: (currentVersionId: string | undefined) => {
    set({ currentVersionId });
  },
  setCurrentViewport: (currentViewport: Viewport) => {
    set({ currentViewport });
  },
});
