import { useAPI } from '../../hooks/useApi';
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query';
import type {
  AddApiKeyRequest,
  AddApiKeyResponse,
  DeleteApiKeyResponse,
  ListApiKeysResponse,
  UpdateApiKeyResponse,
} from 'api-types-shared';
import type { ApiKey } from 'types-shared';
import { handleException } from 'sentry-browser-shared';

export const useAddApiKey = () => {
  const { userSDK: sdk } = useAPI();
  const queryClient = useQueryClient();

  return useMutation<AddApiKeyResponse, Error, AddApiKeyRequest['body']>({
    mutationFn: async (body) => {
      const apiKey = await sdk.addApiKey({ body });

      await queryClient.invalidateQueries({
        queryKey: ['apiKeys'],
      });

      return apiKey;
    },
  });
};

export const useGetApiKey = (keyId?: string): UseQueryResult<ApiKey> => {
  const { userSDK: sdk } = useAPI();

  return useQuery<ApiKey>({
    enabled: Boolean(keyId),
    queryKey: ['apiKeys', keyId],
    queryFn: async () => {
      if (!keyId) {
        throw new Error('KeyId is not defined');
      }

      const resp = await sdk.getApiKey({
        params: { keyId },
      });
      return resp.apiKey;
    },
  });
};

export const useDeleteApiKey = () => {
  const { userSDK: sdk } = useAPI();
  const queryClient = useQueryClient();

  return useMutation<DeleteApiKeyResponse, Error, string>({
    mutationFn: async (keyId) => {
      const resp = await sdk.deleteApiKey({ params: { keyId } });

      await queryClient.invalidateQueries({
        queryKey: ['apiKeys'],
      });

      return resp;
    },
  });
};

export const useUpdateApiKey = () => {
  const { userSDK: sdk } = useAPI();
  const queryClient = useQueryClient();

  return useMutation<
    UpdateApiKeyResponse['apiKey'],
    Error,
    { name: string; keyId: string }
  >({
    mutationFn: async ({ keyId, name }) => {
      const response = await sdk.updateApiKey({
        params: { keyId },
        body: { name },
      });

      await queryClient.invalidateQueries({
        queryKey: ['apiKeys'],
      });

      return response.apiKey;
    },
  });
};

export const useFetchApiKeysList = (): UseQueryResult<
  ListApiKeysResponse['apiKeys']
> => {
  const { userSDK: sdk } = useAPI();
  return useQuery<ApiKey[]>({
    queryKey: ['apiKeys'],
    queryFn: async () => {
      const resp = await sdk.listApiKeys().catch((err: unknown) => {
        handleException(err, {
          name: 'API Keys fetch failed',
          source: 'hooks/useFetchApiKeysList',
        });
        return { apiKeys: [] };
      });

      return resp.apiKeys;
    },
  });
};
