import { useFeatureFlagPayload } from 'posthog-js/react';
import { FeatureFlag } from '../../../utils/constants';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export default function useReadonlyWorkflow() {
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('Workflow ID not provided');
  }
  const readOnlyWorkflowsPayload = useFeatureFlagPayload(
    FeatureFlag.ReadonlyWorkflows,
  ) as { readOnlyWorkflows?: string[] } | undefined;
  const isReadonlyView = useMemo(
    () => readOnlyWorkflowsPayload?.readOnlyWorkflows?.includes(workflowId),
    [readOnlyWorkflowsPayload, workflowId],
  );
  return isReadonlyView;
}
