import * as React from 'react';
import { type Ref, forwardRef, type ReactElement, type ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Slide from '@mui/material/Slide';
import { type TransitionProps } from '@mui/material/transitions';

interface FullScreenTransitionProps extends TransitionProps {
  children: ReactElement;
}

interface FullScreenModalProps {
  open: boolean;
  setOpen: (newOpen: boolean) => void;
  children: ReactNode;
  toolbarChildren?: ReactNode;
}

const Transition = forwardRef(function Transition(
  props: FullScreenTransitionProps,
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({
  open,
  setOpen,
  children,
  toolbarChildren,
}: FullScreenModalProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      {toolbarChildren ? (
        <AppBar
          sx={{ position: 'relative', backgroundColor: '#ffffff' }}
          elevation={0}
        >
          <Toolbar>{toolbarChildren}</Toolbar>
        </AppBar>
      ) : null}

      {children}
    </Dialog>
  );
}
