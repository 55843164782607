"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeSelectionModeEnums = exports.WorkflowEdge = exports.WorkflowNode = exports.WorkflowRetryNode = exports.WorkflowStopNode = exports.WorkflowRequestNode = exports.RequestPayloadType = exports.RequestIntegrationType = exports.RequestIntegrationTypeEnum = exports.RequestMethod = exports.RequestMethodEnum = exports.WorkflowDocumentNode = exports.WorkflowContinueNode = exports.StopNodeStatus = exports.StopNodeStatusEnum = exports.WorkflowFreeformNode = exports.WorkflowConditionalNode = exports.BranchData = exports.BranchMode = exports.BranchModeEnum = exports.WorkflowNewNode = exports.WorkflowSourceNode = exports.WorkflowImageNode = exports.NodeData = exports.NodePlatform = exports.NodePlatformEnum = exports.NodeImageData = exports.NodeStatus = exports.NodeStatusEnum = exports.ActionMap = exports.SelectedAction = exports.TargetStateData = exports.TargetMap = exports.Target = exports.BulkCheckableNodeTypes = exports.NodeTypes = exports.NodeTypesEnum = void 0;
var zod_1 = require("zod");
var extensionTypes_1 = require("../extensionTypes");
var logic_schema_1 = require("../logicTypes/logic.schema");
var react_flow_schema_1 = require("./react-flow.schema");
var variable_schema_1 = require("./variable.schema");
var action_schema_1 = require("./action.schema");
var NodeTypesEnum;
(function (NodeTypesEnum) {
    NodeTypesEnum["Image"] = "image";
    NodeTypesEnum["Source"] = "source";
    NodeTypesEnum["New"] = "new";
    NodeTypesEnum["Conditional"] = "conditional";
    NodeTypesEnum["Freeform"] = "freeform";
    NodeTypesEnum["Continue"] = "continue";
    NodeTypesEnum["Retry"] = "retry";
    NodeTypesEnum["Stop"] = "stop";
    NodeTypesEnum["Request"] = "request";
    NodeTypesEnum["Document"] = "document";
})(NodeTypesEnum || (exports.NodeTypesEnum = NodeTypesEnum = {}));
exports.NodeTypes = zod_1.z.nativeEnum(NodeTypesEnum);
exports.BulkCheckableNodeTypes = [
    NodeTypesEnum.Image,
    NodeTypesEnum.Continue,
    NodeTypesEnum.Document,
    NodeTypesEnum.Freeform,
    NodeTypesEnum.Conditional,
    NodeTypesEnum.Stop,
    NodeTypesEnum.Retry,
    NodeTypesEnum.Request,
];
exports.Target = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string().optional(),
    ref: extensionTypes_1.QueryPaths,
    coordinates: extensionTypes_1.RelativeCoordinates,
});
exports.TargetMap = zod_1.z.record(exports.Target);
exports.TargetStateData = zod_1.z.object({
    targets: exports.TargetMap,
});
exports.SelectedAction = zod_1.z.object({
    nodeId: zod_1.z.string(),
    actionId: zod_1.z.string(),
});
exports.ActionMap = zod_1.z.record(action_schema_1.WorkflowAction);
var NodeStatusEnum;
(function (NodeStatusEnum) {
    NodeStatusEnum["NotViewed"] = "NotViewed";
    NodeStatusEnum["Viewed"] = "Viewed";
    NodeStatusEnum["Checked"] = "Checked";
    NodeStatusEnum["Autolinked"] = "Autolinked";
    NodeStatusEnum["Warning"] = "Warning";
    NodeStatusEnum["Error"] = "Error";
    NodeStatusEnum["Imported"] = "Imported";
})(NodeStatusEnum || (exports.NodeStatusEnum = NodeStatusEnum = {}));
exports.NodeStatus = zod_1.z.nativeEnum(NodeStatusEnum);
exports.NodeImageData = zod_1.z.object({
    imageId: zod_1.z.string(),
});
var NodePlatformEnum;
(function (NodePlatformEnum) {
    NodePlatformEnum["Web"] = "web";
    NodePlatformEnum["Windows"] = "windows";
})(NodePlatformEnum || (exports.NodePlatformEnum = NodePlatformEnum = {}));
exports.NodePlatform = zod_1.z.nativeEnum(NodePlatformEnum);
exports.NodeData = zod_1.z.object({
    imageData: exports.NodeImageData,
    actionData: exports.ActionMap,
    actionOrder: zod_1.z.array(zod_1.z.string()),
    nodeUrls: zod_1.z.array(zod_1.z.string()),
    nodeStatus: exports.NodeStatus,
    selected: zod_1.z.boolean().optional(),
    // MARK: Desktop support
    // TODO: Make this required when backwards compatibility is removed (?)
    // TODO: Set this on web image nodes
    platform: exports.NodePlatform.optional(),
});
exports.WorkflowImageNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Image),
    data: exports.NodeData,
});
exports.WorkflowSourceNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Source),
    data: zod_1.z
        .object({
        variableId: zod_1.z.string(),
        description: zod_1.z.string().optional(),
    })
        .merge(exports.NodeData.pick({ nodeStatus: true })),
});
exports.WorkflowNewNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.New),
    data: exports.NodeData.pick({ nodeStatus: true }),
});
var BranchModeEnum;
(function (BranchModeEnum) {
    BranchModeEnum["Rule"] = "Rule";
    BranchModeEnum["Instruction"] = "Instruction";
})(BranchModeEnum || (exports.BranchModeEnum = BranchModeEnum = {}));
exports.BranchMode = zod_1.z.nativeEnum(BranchModeEnum);
exports.BranchData = zod_1.z.object({
    branchId: zod_1.z.string(),
    rule: logic_schema_1.Rule.optional(),
    instruction: variable_schema_1.VariableIdContainer,
    selectedMode: exports.BranchMode,
});
exports.WorkflowConditionalNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Conditional),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        branchesData: zod_1.z.array(exports.BranchData).optional(),
    }),
    // TODO: remove this -- this is for a specific M&M feature
    errorOverlay: zod_1.z.boolean().optional(),
});
exports.WorkflowFreeformNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Freeform),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        instructions: variable_schema_1.VariableIdContainer,
    }),
});
var StopNodeStatusEnum;
(function (StopNodeStatusEnum) {
    StopNodeStatusEnum["Failure"] = "failure";
    StopNodeStatusEnum["Success"] = "success";
})(StopNodeStatusEnum || (exports.StopNodeStatusEnum = StopNodeStatusEnum = {}));
exports.StopNodeStatus = zod_1.z.nativeEnum(StopNodeStatusEnum);
exports.WorkflowContinueNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Continue),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }),
});
exports.WorkflowDocumentNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Document),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        surfaceOutputVariables: zod_1.z.boolean(),
    }),
});
var RequestMethodEnum;
(function (RequestMethodEnum) {
    RequestMethodEnum["Get"] = "GET";
    RequestMethodEnum["Post"] = "POST";
    RequestMethodEnum["Put"] = "PUT";
    RequestMethodEnum["Patch"] = "PATCH";
    RequestMethodEnum["Delete"] = "DELETE";
})(RequestMethodEnum || (exports.RequestMethodEnum = RequestMethodEnum = {}));
exports.RequestMethod = zod_1.z.nativeEnum(RequestMethodEnum);
var RequestIntegrationTypeEnum;
(function (RequestIntegrationTypeEnum) {
    RequestIntegrationTypeEnum["None"] = "None";
    RequestIntegrationTypeEnum["Salesforce"] = "Salesforce";
})(RequestIntegrationTypeEnum || (exports.RequestIntegrationTypeEnum = RequestIntegrationTypeEnum = {}));
exports.RequestIntegrationType = zod_1.z.nativeEnum(RequestIntegrationTypeEnum);
exports.RequestPayloadType = zod_1.z.object({
    key: variable_schema_1.VariableIdContainer,
    value: variable_schema_1.VariableIdContainer,
});
exports.WorkflowRequestNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Request),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        integrationType: exports.RequestIntegrationType.optional(),
        description: zod_1.z.string().optional(),
        changesResolved: zod_1.z.boolean().default(true).optional(),
        method: exports.RequestMethod,
        url: variable_schema_1.VariableIdContainer,
        urlParameters: zod_1.z.array(exports.RequestPayloadType),
        headers: zod_1.z.array(exports.RequestPayloadType),
        body: zod_1.z.array(exports.RequestPayloadType).or(variable_schema_1.VariableIdContainer),
        auth: zod_1.z.object({
            authKey: zod_1.z.object({
                enabled: zod_1.z.boolean(),
                value: variable_schema_1.VariableIdContainer,
            }),
            forUserId: zod_1.z.object({
                enabled: zod_1.z.boolean(),
                value: variable_schema_1.VariableIdContainer,
            }),
        }),
        responseVariableId: zod_1.z.string(),
        blocking: zod_1.z.boolean(),
    }),
});
exports.WorkflowStopNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Stop),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        status: exports.StopNodeStatus.optional(),
    }),
});
exports.WorkflowRetryNode = react_flow_schema_1.RfNode.extend({
    type: zod_1.z.literal(NodeTypesEnum.Retry),
    data: exports.NodeData.pick({ nodeStatus: true, selected: true }).extend({
        // TODO: rename to retryIntervalMinutes
        // TODO: make retryInterval required
        retryInterval: zod_1.z.number().nullable(),
        // TODO: make maxAttempts required
        maxAttempts: zod_1.z.number().nullable(),
    }),
});
// TODO: BACKWARDS COMPATIBILITY:
// END BACKWARDS COMPATIBILITY
exports.WorkflowNode = zod_1.z.union([
    exports.WorkflowImageNode,
    exports.WorkflowSourceNode,
    exports.WorkflowNewNode,
    exports.WorkflowConditionalNode,
    exports.WorkflowFreeformNode,
    exports.WorkflowStopNode,
    exports.WorkflowRetryNode,
    exports.WorkflowContinueNode,
    exports.WorkflowRequestNode,
    exports.WorkflowDocumentNode,
]);
exports.WorkflowEdge = react_flow_schema_1.RfEdge.extend({
    data: zod_1.z
        .object({
        actionIndex: zod_1.z.number(),
    })
        .optional(),
});
var NodeSelectionModeEnums;
(function (NodeSelectionModeEnums) {
    NodeSelectionModeEnums["BulkCheck"] = "BulkCheck";
    NodeSelectionModeEnums["MergeNodes"] = "MergeNodes";
})(NodeSelectionModeEnums || (exports.NodeSelectionModeEnums = NodeSelectionModeEnums = {}));
