import React from 'react';
import type { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';

export default function DocumentIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        d="M6 11.333h4m-4-2h4M8.667 2.001 8.45 2H5.467c-.747 0-1.12 0-1.406.145-.25.128-.454.332-.582.583-.146.285-.146.659-.146 1.405v7.734c0 .746 0 1.12.146 1.405.128.25.331.455.582.583.285.145.659.145 1.404.145h5.07c.746 0 1.118 0 1.403-.145.251-.128.456-.332.584-.583.145-.285.145-.658.145-1.403V6m-4-4c.19.002.31.01.425.037.136.033.267.087.386.16.135.082.25.198.48.428l2.084 2.084c.23.23.345.345.428.48.073.12.127.25.16.385.027.115.034.236.036.426M8.667 2v1.867c0 .747 0 1.12.145 1.405.128.25.332.455.583.583C9.68 6 10.053 6 10.798 6h1.868"
      />
    </SvgIcon>
  );
}
