"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DDBShim = exports.defaultClientConfigsFromEnv = exports.ResourceNotFoundException = exports.ConditionalCheckFailedException = exports.DynamoDB = void 0;
var client_dynamodb_1 = require("@aws-sdk/client-dynamodb");
Object.defineProperty(exports, "DynamoDB", { enumerable: true, get: function () { return client_dynamodb_1.DynamoDB; } });
Object.defineProperty(exports, "ConditionalCheckFailedException", { enumerable: true, get: function () { return client_dynamodb_1.ConditionalCheckFailedException; } });
Object.defineProperty(exports, "ResourceNotFoundException", { enumerable: true, get: function () { return client_dynamodb_1.ResourceNotFoundException; } });
var defaults_1 = require("./defaults");
Object.defineProperty(exports, "defaultClientConfigsFromEnv", { enumerable: true, get: function () { return defaults_1.defaultClientConfigsFromEnv; } });
var shim_1 = require("./shim");
Object.defineProperty(exports, "DDBShim", { enumerable: true, get: function () { return shim_1.DDBShim; } });
