import SvgIcon from '@mui/material/SvgIcon';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function TriggerBlockIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="35"
      height="54"
      fill="none"
      viewBox="0 0 35 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M14.51 53.97a1.908 1.908 0 0 1-.683 0 2.364 2.364 0 0 1-1.68-2.307v-.313l2.562-18.078H2.354a2.278 2.278 0 0 1-2.022-1.224 2.42 2.42 0 0 1 0-2.448l5.694-9.936 11.672-18.56a2.25 2.25 0 0 1 2.62-.996 2.363 2.363 0 0 1 1.68 2.305v.314l-2.563 18.077h12.384a2.279 2.279 0 0 1 2.021 1.224 2.42 2.42 0 0 1 0 2.449l-5.694 9.935-11.672 18.562a2.25 2.25 0 0 1-1.964.996ZM5.514 28.887H16.9a2.391 2.391 0 0 1 2.363 2.42v.313l-1.68 11.786 7.118-11.387 4.1-7.06H17.413a2.391 2.391 0 0 1-2.363-2.42v-.313l1.68-11.786-7.118 11.387-4.1 7.06Z"
      />
    </SvgIcon>
  );
}
