import {
  AdminVersionEnum,
  type GetWorkflowMetadataResponse,
} from 'api-types-shared';
import * as React from 'react';
import { useState } from 'react';
import {
  Button,
  Input,
  Modal,
  SubLabel,
  TrashIcon,
  WarningAmberOutlined,
  DoneAll,
  IconButton,
  notify,
  AlertVariant,
} from 'ui-kit';
import { extractGlobalVariablesFromTemplates } from '../../utils/helper';
import { EditorStore } from '../../store/EditorState';
import { useAddWorkflowUpdates, useUpdateWorkflowData } from '../../hooks';
import { type VariableMap } from 'types-shared';
import startCase from 'lodash/startCase';
import { handleException } from 'sentry-browser-shared';

const defaultFormValues: {
  improvements: string[];
  issues: string[];
} = {
  improvements: [],
  issues: [],
};

interface AdminPushToolbarModalProps {
  workflowId: string;
  workflowName?: string;
  workflowMetadata?: GetWorkflowMetadataResponse | null;
  versionPush: AdminVersionEnum;
  open: boolean;
  setOpen: (newOpen: boolean) => void;
}

const versionPushOptionMapping: Record<string, string> = {
  [AdminVersionEnum.ForcePush]: 'Error Push',
};

export default function AdminPushToolbarModal({
  workflowId,
  workflowMetadata,
  versionPush,
  open,
  workflowName,
  setOpen,
}: AdminPushToolbarModalProps) {
  const {
    datasourceMetadata,
    // tableData,
    nodes,
    edges,
    variables,
    targets,
    globalVariables,
    setCurrentVersionId,
  } = EditorStore();
  const {
    mutateAsync,
    // status,
    isPending: isUpdatingWorkflow,
  } = useUpdateWorkflowData();
  const { mutateAsync: addUpdates, isPending: addingUpdates } =
    useAddWorkflowUpdates();

  const [formValues, setFormValues] = useState(defaultFormValues);
  const [currentIssue, setCurrentIssue] = useState('');
  const [currentImprovement, setCurrentImprovement] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const loading = addingUpdates || isUpdatingWorkflow || isLoading;

  const closeModal = () => {
    setOpen(false);
    setFormValues(defaultFormValues);
  };

  const saveCurrentItem = (type: 'issue' | 'improvement') => {
    const updateFunction =
      type === 'issue' ? setCurrentIssue : setCurrentImprovement;
    const payload =
      type === 'issue'
        ? {
            ...formValues,
            ...(formValues.issues.includes(currentIssue) || !currentIssue
              ? {}
              : { issues: [...formValues.issues, currentIssue] }),
          }
        : {
            ...formValues,
            ...(formValues.improvements.includes(currentImprovement) ||
            !currentImprovement
              ? {}
              : {
                  improvements: [
                    ...formValues.improvements,
                    currentImprovement,
                  ],
                }),
          };
    setFormValues(payload);
    updateFunction('');
  };

  const saveWorkflow = async () => {
    const workflowData = {
      edges,
      nodes,
      bulkSelectMode: false,
      selectedAction: null,
      selectedNode: null,
    };

    const withGlobalVars = extractGlobalVariablesFromTemplates(
      variables,
      globalVariables as VariableMap,
      nodes,
    );

    const payload = {
      workflowId,
      workflowData,
      variableData: withGlobalVars,
      targetData: targets,
      datasourceMetadata,
      versionPush:
        versionPush === AdminVersionEnum.ProcessingPush
          ? undefined
          : versionPush,
      status: workflowMetadata?.status,
    };

    try {
      await mutateAsync(payload);
      setCurrentVersionId(undefined);
    } catch (error) {
      const errMsg = 'Error while saving admin push';
      setIsLoading(false);
      handleException(error, {
        name: errMsg,
        source: 'saveWorkflow',
        extra: {
          workflowId,
          payload,
        },
      });
      notify({
        message: errMsg,
        variant: AlertVariant.ERROR,
      });
    }
  };

  const saveAdminUpdates = async () => {
    try {
      await addUpdates({
        workflowId,
        updates: {
          issuesFound: formValues.issues,
          improvements: formValues.improvements,
        },
      });
    } catch (error) {
      const errMsg = 'Error while saving admin push updates';
      setIsLoading(false);
      handleException(error, {
        name: errMsg,
        source: 'saveAdminUpdates',
        extra: {
          workflowId,
          updates: {
            issuesFound: formValues.issues,
            improvements: formValues.improvements,
          },
        },
      });
      notify({
        message: errMsg,
        variant: AlertVariant.ERROR,
      });
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await saveAdminUpdates();
    await saveWorkflow();
    setIsLoading(false);
    closeModal();
  };

  return (
    <Modal
      className="flex item-center justify-items-center max-w-[80rem] min-w-[50rem] max-h-70vh overflow-y-auto"
      onClose={closeModal}
      open={open}
      enableEventBubbling
    >
      <p className="mb-7">
        <span className="font-medium text-black">
          {versionPushOptionMapping[versionPush] ?? startCase(versionPush)}{' '}
        </span>
        <SubLabel className="mt-3">{workflowName ?? workflowId}</SubLabel>
      </p>

      <div className="text-sm flex flex-row gap-20 mt-10">
        <div className="flex-1 flex flex-col">
          <div className="flex flex-col h-full">
            <div className="flex-1 flex justify-between items-start">
              <div className="flex-1 pr-5">
                <p className="inline-flex items-center font-medium text-info-dark mb-3">
                  {' '}
                  <WarningAmberOutlined className="text-error mr-2" /> Issues
                  found
                </p>
                <ul className="list-none">
                  {formValues.issues.map((issue) => (
                    <li key={issue} className="flex justify-between mb-2">
                      <span>-{issue}</span>{' '}
                      <IconButton
                        className="!p-0"
                        onClick={() => {
                          setFormValues({
                            ...formValues,
                            issues: formValues.issues.filter(
                              (i) => i !== issue,
                            ),
                          });
                        }}
                      >
                        <TrashIcon className="text-error text-xs" />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex-1">
                <Input
                  classes={{ wrapper: 'flex flex-col' }}
                  floatingLabel
                  label="Issue"
                  multiline
                  onChange={setCurrentIssue}
                  placeholder="Press enter to save an issue"
                  rows={5}
                  value={currentIssue}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      saveCurrentItem('issue');
                    }
                  }}
                />{' '}
              </div>
            </div>

            <div className="flex-1 flex justify-between items-start">
              <div className="flex-1 pr-5">
                <p className="inline-flex items-center font-medium text-info-dark mb-3">
                  <DoneAll className="text-success mr-2" /> Improvements applied
                </p>
                <ul className="list-none">
                  {formValues.improvements.map((improvement) => (
                    <li key={improvement} className="flex justify-between mb-2">
                      <span>-{improvement}</span>{' '}
                      <IconButton
                        className="!p-0"
                        onClick={() => {
                          setFormValues({
                            ...formValues,
                            improvements: formValues.improvements.filter(
                              (i) => i !== improvement,
                            ),
                          });
                        }}
                      >
                        <TrashIcon className="text-error text-xs" />
                      </IconButton>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="flex-1">
                <Input
                  classes={{ wrapper: 'flex flex-col mt-10' }}
                  floatingLabel
                  label="Improvement"
                  multiline
                  onChange={setCurrentImprovement}
                  placeholder="Press enter to save an improvement"
                  rows={5}
                  value={currentImprovement}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      saveCurrentItem('improvement');
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-7 mt-10">
        <Button
          className="w-40 h-9"
          color="secondary"
          loading={loading}
          onClick={onSubmit}
          variant="contained"
          disabled={
            !formValues.improvements.length && !formValues.issues.length
          }
        >
          SUBMIT
        </Button>
        <Button
          className="h-9"
          color="secondary"
          onClick={() => {
            setOpen(false);
          }}
          variant="text"
        >
          CANCEL
        </Button>
      </div>
    </Modal>
  );
}
