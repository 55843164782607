import React, { useEffect, useState } from 'react';

import type { EventMap } from '../../utils/eventBus';
import createEventBus from '../../utils/eventBus';
import type { Props } from './ModalContent';
import Modal from './Modal';
import ModalContent from './ModalContent';

type EventProps = Omit<Props, 'onClose'> & {
  onCancel?: () => void;
  showCloseIcon?: boolean;
};

interface ModalEvents extends EventMap {
  open: (props: EventProps) => void;
  close: () => void;
}

export const modalEventChannel = createEventBus<ModalEvents>();

export default function ModalContainer() {
  const [open, setOpen] = useState<boolean>(false);
  const [props, setProps] = useState<EventProps>({} as EventProps);

  const handleOpen = (options: EventProps) => {
    setProps(options);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setProps({} as EventProps);
  };

  useEffect(() => {
    const unsubscribeOpen = modalEventChannel.on('open', handleOpen);
    const unsubscribeClose = modalEventChannel.on('close', handleClose);

    return () => {
      unsubscribeOpen();
      unsubscribeClose();
    };
  }, []);

  return (
    <Modal
      onClose={handleClose}
      open={open}
      showCloseIcon={props.showCloseIcon}
    >
      <ModalContent {...props} />
    </Modal>
  );
}
