import { useState, type ReactElement, useMemo, useCallback } from 'react';
import {
  CredentialVariableName,
  type Variable,
  type WorkflowAction,
  type WorkflowActionsOptions,
} from 'types-shared';
import {
  DeleteIcon,
  DesktopIcon,
  EditIcon,
  EyeIcon,
  HideEyeIcon,
  IconButton,
  Menu,
  MenuItem,
  modalEventChannel,
  PersonIcon,
  QuestionAnswerIcon,
  ClosedCaption,
  Logo,
  LowPriority,
  MoreVert,
  PhonelinkLock,
  Publish,
  LockIcon,
  Tooltip,
  CopyIcon,
} from 'ui-kit';
import { contactModalEventChannel } from '../../../../../utils/contactModal';
import { useParams } from 'react-router-dom';
import { isAdmin } from '../../../../../utils/env';
import { ActionsEnum } from 'types-shared';
import { clsx } from 'clsx';
import { EditorStore } from '../../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { useFeatureFlag } from '../../../../../utils/helper';
import { FeatureFlag } from '../../../../../utils/constants';

interface Props {
  action: WorkflowAction;
  allowDeleteAction: boolean;
  isReadonlyView: boolean;
  onEditTarget?: () => void;
  onMoveAction?: () => void;
  onDeleteAction?: () => void;
  onCloneAction?: (action: WorkflowAction) => void;
  onUpdateActionOptions: (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => void;
  showManualHandleOption?: boolean;
}

interface MenuItemProps {
  text: string;
  action: () => void;
  renderWhen: boolean;
  icon: ReactElement;
  disabled?: boolean;
  tooltip?: string;
}

function CustomMenuItem({
  text,
  action,
  renderWhen,
  icon,
  disabled,
  tooltip,
}: MenuItemProps) {
  return renderWhen ? (
    <Tooltip arrow placement="right" title={tooltip} hidden={!disabled}>
      <MenuItem
        className="min-w-64 h-8 !px-4 !w-full"
        disabled={disabled}
        onClick={action}
      >
        <span className="font-normal mr-4">{text}</span>
        {icon}
      </MenuItem>
    </Tooltip>
  ) : null;
}

function ActionMenu({
  action,
  allowDeleteAction,
  isReadonlyView,
  onUpdateActionOptions,
  onEditTarget,
  onDeleteAction,
  onMoveAction,
  onCloneAction,
  showManualHandleOption,
}: Props) {
  const { workflowId } = useParams();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    options: {
      hidden,
      hitl,
      sitl,
      adminOnly,
      submitted,
      captcha,
      reCaptcha,
      mfa,
      adminManual,
      adminSkipped,
      terminal,
    } = {},
    actionType,
    variableId,
  } = action;
  const HideIcon = hidden ? EyeIcon : HideEyeIcon;
  const AdminIcon = adminOnly ? EyeIcon : HideEyeIcon;
  const SkipIcon = hitl ? DesktopIcon : PersonIcon;
  const actionHandledBySolaEnabled = useFeatureFlag(
    FeatureFlag.ActionHandledBySola,
  );
  const hitlLiveViewEnabled = useFeatureFlag(FeatureFlag.HitlLiveView);
  const { variables, updateVariable } = EditorStore(
    useShallow((s) => ({
      variables: s.variables,
      updateVariable: s.updateVariable,
    })),
  );
  const actionVariable = variableId ? variables[variableId] : null;
  const isCredential = actionVariable?.name === CredentialVariableName;

  const isMfaOrCaptcha = Boolean(mfa) || Boolean(captcha) || Boolean(reCaptcha);
  const isMagicLoopAction = actionType === ActionsEnum.MagicLoop;

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const closeModal = useCallback(() => {
    modalEventChannel.emit('close');
  }, []);

  const onUpdateVariable = useCallback(
    (updates: Pick<Variable, 'name'>) => {
      if (actionVariable) {
        updateVariable({
          ...actionVariable,
          ...updates,
        });
      }
    },
    [updateVariable, actionVariable],
  );

  const openModal = useCallback(() => {
    modalEventChannel.emit('open', {
      title: 'Actions handled by Sola',
      descriptions: [
        'Our team can take care of some manually handled actions for you, including Captchas and basic approvals.',
        'Chat with us to setup the timing and pricing that works for you!',
      ],
      actions: [
        {
          text: 'Confirm',
          onClick: () => {
            closeModal();
          },
        },
        {
          text: 'Contact Us',
          onClick: () => {
            contactModalEventChannel.emit('open', {
              problemType: 'General Issue',
              workflowId,
            });
            closeModal();
          },
          variant: 'text',
        },
      ],
    });
  }, [closeModal, workflowId]);

  const toggleActionOption = useCallback(
    (name: keyof WorkflowActionsOptions) => {
      const exclusive = [
        'hidden',
        'adminManual',
        'adminOnly',
        'sitl',
        'hitl',
        'adminSkipped',
      ].includes(name);
      let newHitl =
        name === 'hitl' ? !action.options?.hitl : action.options?.hitl;
      let newSitl =
        name === 'sitl' ? !action.options?.sitl : action.options?.sitl;

      if (name === 'mfa') {
        newSitl = !action.options?.[name];
        if (!action.options?.[name]) {
          openModal();
        }
      }

      if (name === 'hitl') {
        newSitl = false;
      }

      if (name === 'sitl') {
        newHitl = false;
      }
      onUpdateActionOptions(action, {
        ...(exclusive ? action.options : {}),
        hidden: action.options?.hidden,
        adminManual: action.options?.adminManual,
        [name]: !action.options?.[name],
        hitl: newHitl,
        sitl: newSitl,
        ...(name === 'captcha' && action.options?.captcha
          ? {
              reCaptcha: false,
            }
          : {}),
      });
      onClose();
    },
    [onUpdateActionOptions, openModal, onClose, action],
  );

  const menuItems = useMemo(() => {
    const adminMenu: MenuItemProps[] = [
      {
        renderWhen: !isMagicLoopAction,
        text: 'Clone Action',
        action: () => {
          onClose();
          onCloneAction?.(action);
        },
        icon: (
          <CopyIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: !isMfaOrCaptcha && Boolean(showManualHandleOption),
        text: hitl
          ? 'Handle action automatically (Removes - Action manually handled)'
          : 'User will handle action manually (shows to users). (Action manually handled)',
        action: () => {
          toggleActionOption('hitl');
        },
        icon: (
          <SkipIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: Boolean(isMfaOrCaptcha && sitl && showManualHandleOption),
        text: 'User will handle action manually (shows to users) ',
        action: () => {
          toggleActionOption('hitl');
        },
        icon: (
          <PersonIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: !sitl,
        text: 'Sola will handle this action (shows to users) (Action handled by Sola)',
        action: () => {
          toggleActionOption('sitl');
          openModal();
        },
        icon: <Logo className="!w-5 !h-5 !ml-auto" />,
      },
      {
        renderWhen: Boolean(sitl),
        text: 'Handle this action automatically (Removes Action handled by Sola)',
        action: () => {
          toggleActionOption('sitl');
        },
        icon: <Logo className="!w-5 !h-5 !ml-auto" />,
      },
      {
        renderWhen: true,
        text: `${hidden ? 'Enable' : 'Skip'} Action (shows to users)`,
        action: () => {
          toggleActionOption('hidden');
        },
        icon: (
          <HideIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: actionType === ActionsEnum.Input,
        text: `${isCredential ? 'Unset' : 'Set'} as credential`,
        action: () => {
          onUpdateVariable({
            name: isCredential ? '' : CredentialVariableName,
          });
          onClose();
        },
        icon: (
          <LockIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: Boolean(isAdmin),
        text: `${adminSkipped ? 'Disable' : 'Enable'} Pass through (${adminSkipped ? 'Removes ' : ''}Pass Through)`,
        action: () => {
          toggleActionOption('adminSkipped');
        },
        icon: (
          <HideIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: adminManual
          ? 'Unmark as Manually handled by Admin (Removes Admin Manual)'
          : 'Mark as Manually handled by Admin (Admin Manual)',
        action: () => {
          toggleActionOption('adminManual');
        },
        icon: (
          <SkipIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: `${adminOnly ? 'Show Action To' : 'Hide Action From'} Users (${adminOnly ? 'Removes ' : ''}Admin Only)`,
        action: () => {
          toggleActionOption('adminOnly');
        },
        icon: (
          <AdminIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: 'Edit Target',
        action: () => {
          onClose();
          onEditTarget?.();
        },
        icon: (
          <EditIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: 'Move Action',
        action: () => {
          onClose();
          onMoveAction?.();
        },
        icon: <LowPriority className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
      {
        renderWhen: allowDeleteAction,
        text: 'Delete Action',
        action: () => {
          onClose();
          onDeleteAction?.();
        },
        icon: (
          <DeleteIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: true,
        text: submitted ? 'Unmark as Submit' : 'Mark as Submit',
        action: () => {
          toggleActionOption('submitted');
        },
        icon: <Publish className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
      {
        renderWhen: true,
        text: captcha ? 'Unmark as Captcha' : 'Mark as Captcha',
        action: () => {
          toggleActionOption('captcha');
        },
        icon: <ClosedCaption className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
      {
        renderWhen: true,
        text: mfa
          ? 'Unmark as MFA (Removes Two-factor authentication and Action handled by Sola)'
          : 'Mark as MFA (Two-factor authentication and Action handled by Sola)',
        action: () => {
          toggleActionOption('mfa');
        },
        icon: <PhonelinkLock className="!w-5 !h-5 !ml-auto !text-info-dark" />,
      },
    ];

    const userMenu: MenuItemProps[] = [
      {
        renderWhen: true,
        text: `${hidden ? 'Enable' : 'Skip'} Action`,
        action: () => {
          toggleActionOption('hidden');
        },
        icon: (
          <HideIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: !isMfaOrCaptcha && Boolean(showManualHandleOption),
        disabled: !hitlLiveViewEnabled,
        tooltip:
          'Transform this action into a Human-in-the-Loop process. Contact sales to learn more!',
        text: hitl ? 'Handle action automatically' : 'Handle action manually ',
        action: () => {
          toggleActionOption('hitl');
        },
        icon: (
          <SkipIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: Boolean(
          sitl &&
            showManualHandleOption &&
            (isMfaOrCaptcha ||
              [
                ActionsEnum.Arbitrary,
                ActionsEnum.Click,
                ActionsEnum.Input,
                ActionsEnum.MultiChoice,
                ActionsEnum.MultiSelect,
                ActionsEnum.PickFromList,
                ActionsEnum.Select,
                ActionsEnum.UploadDocument,
              ].includes(action.actionType)),
        ),
        text: 'Handle action manually',
        action: () => {
          toggleActionOption('hitl');
        },
        icon: (
          <PersonIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: !sitl,
        disabled: !actionHandledBySolaEnabled,
        tooltip: 'Sola automatically manages this action for you',
        text: 'I want Sola to handle this action',
        action: () => {
          toggleActionOption('sitl');
          openModal();
        },
        icon: <Logo className="!w-5 !h-5 !ml-auto" />,
      },
      {
        renderWhen: Boolean(sitl),
        text: 'Handle this action automatically',
        action: () => {
          toggleActionOption('sitl');
        },
        icon: <Logo className="!w-5 !h-5 !ml-auto" />,
      },
      {
        renderWhen: true,
        text: 'Report Inconsistency',
        action: () => {
          onClose();
          contactModalEventChannel.emit('open', {
            problemType: 'Incorrect Action',
            workflowId,
          });
        },
        icon: (
          <QuestionAnswerIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
      {
        renderWhen: allowDeleteAction,
        text: 'Delete Action',
        action: () => {
          onClose();
          onDeleteAction?.();
        },
        icon: (
          <DeleteIcon className="!w-5 !h-5 !ml-auto !text-info-dark !fill-none" />
        ),
      },
    ];

    if (isMagicLoopAction && terminal) {
      return isAdmin
        ? [
            {
              renderWhen: true,
              text: 'Move Action',
              action: () => {
                onClose();
                onMoveAction?.();
              },
              icon: (
                <LowPriority className="!w-5 !h-5 !ml-auto !text-info-dark" />
              ),
            },
          ]
        : [];
    }

    return isAdmin ? adminMenu : userMenu;
  }, [
    isMagicLoopAction,
    isMfaOrCaptcha,
    showManualHandleOption,
    hitl,
    SkipIcon,
    sitl,
    hidden,
    HideIcon,
    actionType,
    isCredential,
    adminSkipped,
    adminManual,
    adminOnly,
    AdminIcon,
    allowDeleteAction,
    submitted,
    captcha,
    mfa,
    hitlLiveViewEnabled,
    action,
    actionHandledBySolaEnabled,
    terminal,
    onClose,
    onCloneAction,
    toggleActionOption,
    openModal,
    onUpdateVariable,
    onEditTarget,
    onMoveAction,
    onDeleteAction,
    workflowId,
  ]);

  return (
    <>
      <IconButton
        className="!p-0"
        disabled={isReadonlyView}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorEl(e.target as HTMLButtonElement);
        }}
      >
        <MoreVert
          className={clsx('!w-5 !h-5', {
            'text-black': !isMagicLoopAction,
            'text-white': isMagicLoopAction,
          })}
        />
      </IconButton>
      <Menu
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}
        onClose={onClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map((item: MenuItemProps) => (
          <CustomMenuItem
            text={item.text}
            renderWhen={item.renderWhen}
            icon={item.icon}
            action={item.action}
            key={item.text}
            disabled={item.disabled}
            tooltip={item.tooltip}
          />
        ))}
      </Menu>
    </>
  );
}

export default ActionMenu;
