import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  type BatchCancelExecutionsResponse,
  WorkflowConversionFormatEnum,
  type AddWorkflowRequest,
  type GetWorkflowMetadataResponse,
  type UploadMetaRequest,
  type UploadVideoRequest,
  type UploadVideoResponse,
  type WorkflowMetadataType,
} from 'api-types-shared';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState, useRef } from 'react';
import { handleException } from 'sentry-browser-shared';
import type { ExecutionBase } from 'types-shared';
import { BrowserInitialTabAction, ExtensionData } from 'types-shared';
import { AlertVariant, notify, useEnvironment, controlledNotify } from 'ui-kit';
import { useAPI } from '../../../hooks/useApi';
import {
  FeatureFlag,
  EXECUTIONS_LIST_REFETCH_INTERVAL_IN_MS,
} from '../../../utils/constants';
import { isUUID } from '../../Editor/utils/helper';
import { sendToBackgroundViaRelay } from '@plasmohq/messaging';
import { v4 as uuid } from 'uuid';
import { useSearchParams } from 'react-router-dom';
import { getExtensionData, uploadBlobToS3 } from '../../../utils/extension';
import { parseExtensionData } from '../utils/parseExtensionData';
import { isAdmin } from '../../../utils/env';
import { type GridSortModel } from '@mui/x-data-grid/models';
import { useFeatureFlag } from '../../../utils/helper';

export * as useMetrics from './useMetrics';
export { default as useWorkflowCols } from './useWorkflowCols';
export { default as useWorkflowDetailCols } from './useWorkflowDetailCols';

export const useImportWorkflowFromExtension = () => {
  const [lastMsg, setLastMsg] = useState<ExtensionData | undefined>();

  const messageListener = useCallback((event: MessageEvent) => {
    const extensionData = ExtensionData.safeParse(event.data);
    const { success } = extensionData;
    if (success) {
      setLastMsg(extensionData.data);
      notify({
        message: 'Workflow imported successfully',
        variant: AlertVariant.SUCCESS,
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [messageListener]);

  return { lastMsg };
};

const filterDeletedWorkflows = (workflows: WorkflowMetadataType[]) =>
  workflows.filter((workflow) => isNil(workflow.deletedAt));

export const useFetchWorkflowsList = (
  filter?: string,
): UseQueryResult<WorkflowMetadataType[]> => {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<WorkflowMetadataType[]>({
    queryKey: ['workflows', filter],
    queryFn: () => {
      // const user = await getAuthUser();
      return sdk.fetchWorkflowsList();
    },
    select: filterDeletedWorkflows,
    staleTime: 0,
  });
};

export const useFetchWorkflowsListBatched = (
  filter?: string,
): UseQueryResult<WorkflowMetadataType[]> => {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<WorkflowMetadataType[]>({
    queryKey: ['workflows', filter],
    queryFn: () => {
      // const user = await getAuthUser();
      return sdk.fetchWorkflowsListBatched();
    },
    select: filterDeletedWorkflows,
    staleTime: 0,
  });
};

export function useFetchWorkflowData(
  workflowId: string,
  type: 'json' | 'text' = 'json',
): UseQueryResult<string> {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<string>({
    queryKey: ['workflowData', workflowId],
    queryFn: () => sdk.fetchWorkflowData(workflowId, type),
  });
}

export function useFetchWorkflowMetadata(
  workflowId: string,
): UseQueryResult<GetWorkflowMetadataResponse | null> {
  const { workflowSDK: sdk } = useAPI();
  return useQuery<GetWorkflowMetadataResponse | null>({
    queryKey: ['workflows', workflowId],
    queryFn: () => sdk.fetchWorkflowMetadata(workflowId),
    enabled: isUUID(workflowId),
    staleTime: 0,
  });
}

export function useCreateWorkflow(): UseMutationResult<
  string,
  Error,
  { workflowName: string; extensionData: ExtensionData }
> {
  const { selectedEnv } = useEnvironment();
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  const isCookiesEnabled = useFeatureFlag(FeatureFlag.ExtensionCookies, false);
  const isLocalStorageEnabled = useFeatureFlag(
    FeatureFlag.ExtensionLocalStorage,
    false,
  );
  const isUploadRawExtensionDataEnabled = useFeatureFlag(
    FeatureFlag.UploadRawExtensionData,
    false,
  );

  return useMutation<
    string,
    Error,
    { workflowName: string; extensionData: ExtensionData }
  >({
    mutationFn: async ({ workflowName, extensionData }) => {
      try {
        const {
          workflowData,
          newImages: imageHashes,
          variableStore,
          targetStore,
        } = await parseExtensionData(extensionData, true);

        const addWorkflowReq: AddWorkflowRequest['body'] = {
          workflowName,
          imageHashes,
        };
        const {
          workflowId,
          imageUploadMap,
          stateUploadUrl,
          variableStoreUploadUrl,
          targetStoreUploadUrl,
          videoUpload,
          cookiesUploadUrl,
          localStorageUploadUrl,
        } = await sdk.createWorkflow(addWorkflowReq, selectedEnv);

        try {
          if (isUploadRawExtensionDataEnabled) {
            const timeoutPromise = new Promise((_, reject) => {
              setTimeout(() => {
                reject(
                  new Error('Upload raw extension data timed out after 5s'),
                );
              }, 5000);
            });
            await Promise.race([
              sdk.uploadRawExtensionData(workflowId, extensionData),
              timeoutPromise,
            ]);
          }
        } catch (e) {
          handleException(e, {
            name: 'Upload raw extension data failed',
            source: 'Workflows/useCreateWorkflow',
          });
        }

        const extensionUploadResponse = sendToBackgroundViaRelay<
          UploadVideoRequest['body']
        >({
          name: 'onUploadVideo.handler' as never,
          body: {
            workflowId,
            videoUpload,
            imageUploadMap,
          },
        }).then(async (uploadVideoData: UploadVideoResponse) => {
          await sdk.completeUploadVideo({
            workflowId,
            uploadId: videoUpload.uploadId,
            uploadVideoData,
          });
        });

        if (
          cookiesUploadUrl &&
          localStorageUploadUrl &&
          (Boolean(isCookiesEnabled) || Boolean(isLocalStorageEnabled))
        ) {
          const metaUploadResponse =
            sendToBackgroundViaRelay<UploadMetaRequest>({
              name: 'onUploadMeta.handler' as never,
              body: {
                workflowId,
                cookiesUploadUrl: isCookiesEnabled
                  ? cookiesUploadUrl
                  : undefined,
                localStorageUploadUrl: isLocalStorageEnabled
                  ? localStorageUploadUrl
                  : undefined,
              },
            }).catch((error: unknown) => {
              handleException(error as Error, {
                name: 'Upload workflow metadata on create failed',
                source: 'Workflows/useCreateWorkflow',
                extra: {
                  workflowName,
                  extensionData,
                },
              });
              return null;
            });

          await Promise.race([
            metaUploadResponse,
            new Promise((resolve) => {
              setTimeout(() => {
                handleException(new Error('Meta upload timed out'), {
                  name: 'Meta upload timeout',
                  source: 'Workflows/useCreateWorkflow',
                  extra: {
                    workflowName,
                    extensionData,
                    workflowId,
                  },
                });
                resolve(null);
              }, 5000);
            }),
          ]);
        }

        await queryClient.invalidateQueries({ queryKey: ['workflows'] });

        // Upload workflow state, variable store, and target store
        const uploadItems = [
          { url: stateUploadUrl, data: workflowData, name: 'state.json' },
          {
            url: variableStoreUploadUrl,
            data: variableStore,
            name: 'variable.json',
          },
          { url: targetStoreUploadUrl, data: targetStore, name: 'target.json' },
        ];

        const uploadPromises = uploadItems.map(({ url, data, name }) =>
          uploadBlobToS3(
            new Blob([JSON.stringify(data)], { type: 'application/json' }),
            url,
          ).catch(() => {
            notify({
              message: `Error while uploading ${name}`,
              variant: AlertVariant.ERROR,
              debug: true,
            });
          }),
        );
        await Promise.all(uploadPromises);

        // Wait for all uploads to complete
        await Promise.all([extensionUploadResponse]);

        if (isUploadRawExtensionDataEnabled) {
          try {
            await Promise.race([
              sdk.startWorkflowProcessing(
                workflowId,
                WorkflowConversionFormatEnum.LegacyRecording,
                WorkflowConversionFormatEnum.LegacyWorkflow,
              ),
              new Promise((_, reject) => {
                setTimeout(() => {
                  reject(new Error('Workflow conversion timed out'));
                }, 5000);
              }),
            ]);
          } catch (error) {
            handleException(error, {
              name: 'Workflow conversion timeout',
              source: 'Workflows/useCreateWorkflow',
              extra: { workflowId },
            });
          }
        }

        return workflowId;
      } catch (e) {
        handleException(e, {
          name: 'Import workflow failed',
          source: 'Workflows/useCreateWorkflow',
          extra: {
            workflowName,
            extensionData,
          },
        });

        throw e;
      }
    },
  });
}

export function useDeleteWorkflow(): UseMutationResult<unknown, Error, string> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();
  return useMutation<unknown, Error, string>({
    mutationFn: async (id: string) => {
      await sdk.deleteWorkflow(id);
      await queryClient.invalidateQueries({ queryKey: ['workflows'] });
    },
    onSuccess: () => {
      notify({
        message: 'Workflow deleted successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
  });
}

export const useFetchWorkflowExecutionDetails = (
  conditions: Record<string, unknown>,
  variables: {
    page: number;
    pageSize: number;
    orderBy: GridSortModel;
    doNotRefetch: boolean;
  },
) => {
  const { executionSDK: sdk } = useAPI();

  const { doNotRefetch, ...restVariables } = variables;
  return useQuery<{
    executions: ExecutionBase[];
    filteredExecutionsCount: number;
    totalExecutionsCount: number;
  }>({
    refetchInterval: doNotRefetch
      ? undefined
      : EXECUTIONS_LIST_REFETCH_INTERVAL_IN_MS,
    refetchIntervalInBackground: false,
    retry() {
      return document.hasFocus();
    },
    queryKey: ['workflowExecutions', conditions, restVariables],
    queryFn: () => {
      const { page, pageSize, orderBy } = restVariables;
      const orderByMap = orderBy.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.field]: curr.sort,
        }),
        {},
      );
      return sdk
        .fetchExecutionsListBatched(conditions, page, pageSize, orderByMap)
        .catch((e: unknown) => {
          handleException(e, {
            name: 'Fetch workflow executions failed',
            source: 'Workflows/useFetchWorkflowExecutionDetails',
            extra: {
              conditions,
              page,
              pageSize,
              orderByMap,
            },
          });
          return {
            executions: [],
            filteredExecutionsCount: 0,
            totalExecutionsCount: 0,
          };
        });
    },
    staleTime: 0,
  });
};

export function useUpdateWorkflowName(): UseMutationResult<
  unknown,
  Error,
  Pick<WorkflowMetadataType, 'workflowId' | 'workflowName'>
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    Error,
    Pick<WorkflowMetadataType, 'workflowId' | 'workflowName'>
  >({
    mutationFn: async ({ workflowId, workflowName }) => {
      if (workflowName) {
        await sdk.updateWorkflowName(workflowId, {
          workflowName,
        });
        await queryClient.invalidateQueries({ queryKey: ['workflows'] });
      }
    },
    onSuccess: () => {
      notify({
        message: 'Workflow updated successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
    onError: (error) => {
      handleException(error, {
        name: 'Workflow name update failed',
        source: 'Workflows/useUpdateWorkflowName',
      });
      notify({
        message: 'Failed to update workflow',
        variant: AlertVariant.ERROR,
      });
    },
  });
}

export interface WorkflowPayload {
  workflowId: string;
  workflowName: string;
}

export function useDuplicateWorkflow(): UseMutationResult<
  { newWorkflowId: string },
  Error,
  WorkflowPayload
> {
  const { workflowSDK: sdk } = useAPI();
  return useMutation<{ newWorkflowId: string }, Error, WorkflowPayload>({
    mutationFn: async ({ workflowId, workflowName }: WorkflowPayload) => {
      const result = await sdk.cloneWorkflow({
        params: { workflowId },
        query: {},
        body: { workflowName },
      });
      if (!result?.newWorkflowId) {
        throw new Error('Failed to clone workflow');
      }
      return result;
    },
    onSuccess: () => {
      notify({
        message: 'Workflow duplicated successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
  });
}

export function useUpdateWorkflowStatus(): UseMutationResult<
  unknown,
  Error,
  Pick<WorkflowMetadataType, 'workflowId' | 'status'>
> {
  const { workflowSDK: sdk } = useAPI();
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    Error,
    Pick<WorkflowMetadataType, 'workflowId' | 'status'>
  >({
    mutationFn: async ({ workflowId, status }) => {
      if (status) {
        await sdk.updateWorkflowStatus(workflowId, {
          status,
        });
        await queryClient.invalidateQueries({ queryKey: ['workflows'] });
      }
    },
    onSuccess: () => {
      notify({
        message: 'Workflow updated successfully',
        variant: AlertVariant.SUCCESS,
      });
    },
    onError: (error) => {
      handleException(error, {
        name: 'Workflow status update failed',
        source: 'Workflows/useUpdateWorkflowStatus',
      });
      notify({
        message: 'Failed to update workflow',
        variant: AlertVariant.ERROR,
      });
    },
  });
}

export function useImportWorkflowButton() {
  const [searchParams] = useSearchParams();
  const { mutateAsync: createWorkflow } = useCreateWorkflow();
  const [loading, setLoading] = useState<boolean>(false);
  const longImportCountdownRef = useRef<NodeJS.Timeout | null>(null);

  let workflowName = searchParams.get('workflowName');

  const { open: openControlledNotify, close: closeControlledNotify } =
    controlledNotify({
      message: 'Still importing... Please don’t close or refresh this tab.',
      variant: AlertVariant.INFO,
    });

  const stopLoading = () => {
    setLoading(false);
  };

  const onlyClearMessage = () => {
    if (longImportCountdownRef.current) {
      clearTimeout(longImportCountdownRef.current);
      longImportCountdownRef.current = null;
    }
    closeControlledNotify();
  };

  const stopLoadingAndClearMessage = () => {
    stopLoading();
    onlyClearMessage();
  };

  const handleOnClickImport = async () => {
    setLoading(true);

    longImportCountdownRef.current = setTimeout(() => {
      openControlledNotify();
    }, 8000);

    try {
      const extensionData = await getExtensionData();

      if (!extensionData) {
        notify({
          message:
            'No recording found – begin a new workflow by recording from the extension!',
          variant: AlertVariant.ERROR,
        });

        stopLoadingAndClearMessage();
        return;
      }

      if (
        !workflowName &&
        extensionData.actions.length > 0 &&
        BrowserInitialTabAction.safeParse(extensionData.actions[0][0]).success
      ) {
        workflowName =
          (extensionData.actions[0][0] as BrowserInitialTabAction).data.title ??
          uuid();
      }

      const workflowId = await createWorkflow({
        workflowName: workflowName ?? 'Untitled Workflow',
        extensionData,
      });

      onlyClearMessage();

      const message = isAdmin
        ? `Workflow ${workflowId} created successfully`
        : 'Workflow created successfully';
      notify({
        message,
        variant: AlertVariant.SUCCESS,
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log('Import failed', e);
      notify({
        message: 'Import failed',
        variant: AlertVariant.ERROR,
      });
    } finally {
      stopLoadingAndClearMessage();
    }
  };

  return { loadingImport: loading, handleOnClickImport };
}

export const useFetchExecutionsBatched = (
  executionIds: string[],
  enabled = true,
): UseQueryResult<Record<string, ExecutionBase>> => {
  const { executionSDK: sdk } = useAPI();
  return useQuery<Record<string, ExecutionBase>>({
    queryKey: ['executions', executionIds],
    queryFn: async () => {
      const { executions } = await sdk.batchGetExecutions(executionIds);
      return executions;
    },
    enabled: enabled && executionIds.length > 0,
    staleTime: 0,
  });
};

export function useBatchCancelExecutions(): UseMutationResult<
  unknown,
  Error,
  {
    executionIds: string[];
  }
> {
  const { executionSDK: sdk } = useAPI();
  const queryClient = useQueryClient();

  return useMutation<
    BatchCancelExecutionsResponse,
    Error,
    {
      executionIds: string[];
    }
  >({
    mutationFn: ({ executionIds }) =>
      sdk.batchCancelExecutions(executionIds).catch((error: unknown) => {
        handleException(error, {
          name: 'Error batch cancelling executions',
          source: 'Execution/useBatchCancelExecutions',
          extra: {
            executionIds,
          },
        });
        throw error;
      }),
    onSuccess: async (data, { executionIds }) => {
      const failedCount = data.failedCancelExecutionIds?.length;
      const cancelledCount = data.cancelledExecutionIds.length;
      const nonRunningCount = data.nonRunningExecutionIds?.length;

      await queryClient.invalidateQueries({
        queryKey: ['workflowExecutions', 'executions'],
      });
      notify({
        message: `Successfully cancelled ${cancelledCount.toString()}/${executionIds.length.toString()} executions.`,
        variant: AlertVariant.SUCCESS,
      });

      if (nonRunningCount) {
        notify({
          message: `${nonRunningCount.toString()}/${executionIds.length.toString()} executions were not running and could not be cancelled.`,
          variant: AlertVariant.WARNING,
        });
      }

      if (failedCount) {
        notify({
          message: `Failed to cancel ${failedCount.toString()}/${executionIds.length.toString()} executions.`,
          variant: AlertVariant.WARNING,
        });
      }
    },
    onError: () => {
      notify({
        message: 'Error cancelling executions.',
        variant: AlertVariant.ERROR,
      });
    },
  });
}
