"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.batchCancelExecutionsSchema = void 0;
var types_shared_1 = require("types-shared");
exports.batchCancelExecutionsSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z.object({
            executionIds: types_shared_1.z.array(types_shared_1.uuidSchema),
        }),
    }),
    response: types_shared_1.z.object({
        cancelledExecutionIds: types_shared_1.z.array(types_shared_1.uuidSchema),
        failedCancelExecutionIds: types_shared_1.z.array(types_shared_1.uuidSchema).optional(),
        nonRunningExecutionIds: types_shared_1.z.array(types_shared_1.uuidSchema).optional(),
    }),
};
