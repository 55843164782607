"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChromeTab = exports.TabStatusEnum = exports.MutedInfo = exports.MutedInfoReasonEnum = void 0;
var zod_1 = require("zod");
var MutedInfoReasonEnum;
(function (MutedInfoReasonEnum) {
    MutedInfoReasonEnum["User"] = "user";
    MutedInfoReasonEnum["Capture"] = "capture";
    MutedInfoReasonEnum["Extension"] = "extension";
})(MutedInfoReasonEnum || (exports.MutedInfoReasonEnum = MutedInfoReasonEnum = {}));
exports.MutedInfo = zod_1.z.object({
    muted: zod_1.z.boolean(),
    reason: zod_1.z.nativeEnum(MutedInfoReasonEnum).optional(),
    extensionId: zod_1.z.string().optional(),
});
var TabStatusEnum;
(function (TabStatusEnum) {
    TabStatusEnum["Unloaded"] = "unloaded";
    TabStatusEnum["Loading"] = "loading";
    TabStatusEnum["Complete"] = "complete";
})(TabStatusEnum || (exports.TabStatusEnum = TabStatusEnum = {}));
exports.ChromeTab = zod_1.z.object({
    active: zod_1.z.boolean(),
    audible: zod_1.z.boolean().optional(),
    autoDiscardable: zod_1.z.boolean(),
    discarded: zod_1.z.boolean(),
    favIconUrl: zod_1.z.string().optional(),
    groupId: zod_1.z.number(),
    height: zod_1.z.number().optional(),
    highlighted: zod_1.z.boolean(),
    id: zod_1.z.union([zod_1.z.string(), zod_1.z.number().transform(String)]),
    incognito: zod_1.z.boolean(),
    index: zod_1.z.number(),
    lastAccessed: zod_1.z.number().optional(),
    mutedInfo: exports.MutedInfo.optional(),
    openerTabId: zod_1.z.number().optional(),
    pendingUrl: zod_1.z.string().optional(),
    pinned: zod_1.z.boolean(),
    sessionId: zod_1.z.string().optional(),
    status: zod_1.z.nativeEnum(TabStatusEnum).optional(),
    title: zod_1.z.string().optional(),
    url: zod_1.z.string().optional(),
    width: zod_1.z.number().optional(),
    windowId: zod_1.z.number(),
});
