"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.batchGetExecutionsSchema = void 0;
var types_shared_1 = require("types-shared");
exports.batchGetExecutionsSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({}).nullable(),
        query: types_shared_1.z.object({}).nullable(),
        body: types_shared_1.z.object({
            ids: types_shared_1.z.array(types_shared_1.z.string()),
        }),
    }),
    response: types_shared_1.z.object({
        executions: types_shared_1.z.record(types_shared_1.z.string(), types_shared_1.ExecutionBase),
    }),
};
