"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceType = exports.SourceTypeEnum = exports.DatasourceStateData = exports.DatasourceMetadata = exports.DatasourceTable = void 0;
var zod_1 = require("zod");
var helper_types_1 = require("../helper-types");
exports.DatasourceTable = zod_1.z.object({
    columnDefinitions: zod_1.z.array(zod_1.z.object({
        field: zod_1.z.string(),
    })),
    rowData: zod_1.z.array(zod_1.z.record(zod_1.z.string(), zod_1.z.unknown())),
});
exports.DatasourceMetadata = zod_1.z.object({
    datasourceId: helper_types_1.uuidSchema,
    workflowId: helper_types_1.uuidSchema,
    userId: zod_1.z.string(),
    name: zod_1.z.string(),
    description: zod_1.z.string(),
    createdAt: zod_1.z.string(),
    updatedAt: zod_1.z.string(),
    sheetId: zod_1.z.string().optional(),
});
exports.DatasourceStateData = zod_1.z.object({
    datasourceMetadata: exports.DatasourceMetadata.nullable(),
    tableData: exports.DatasourceTable.nullable(),
});
var SourceTypeEnum;
(function (SourceTypeEnum) {
    SourceTypeEnum["Datasource"] = "Datasource";
    SourceTypeEnum["API"] = "API";
    SourceTypeEnum["EmailTrigger"] = "EmailTrigger";
    SourceTypeEnum["Request"] = "Request";
})(SourceTypeEnum || (exports.SourceTypeEnum = SourceTypeEnum = {}));
exports.SourceType = zod_1.z.nativeEnum(SourceTypeEnum);
