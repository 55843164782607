import { Flex } from 'ui-kit';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type {
  ExecutionBase,
  ExecutionStatus,
  ExecutionVariables,
  GlobalVariable,
  TargetMap,
  VariableMap,
  WorkflowData,
  WorkflowEdge,
  WorkflowNode,
} from 'types-shared';
import { WorkflowImageNode } from 'types-shared';
import type { ExecutionDocument, SignalTypeEnum } from 'api-types-shared';
import isEmpty from 'lodash/isEmpty';
import { WorkflowScreenshots } from './WorkflowScreenshots';
import { isAdmin } from '../../../utils/env';
import { type ScreenshotForDelete, type ScreenshotUrl } from '../utils';
import { Skeleton } from '@mui/material';

interface Props {
  executionDetail?: {
    metadata: ExecutionBase;
    artifacts: ExecutionDocument[];
    inputFiles: ExecutionDocument[];
  };
  executionDetailLoading: boolean;
  executionScreenshotDetailsEnabled: boolean;
  screenshotUrls: ScreenshotUrl[];
  loading: boolean;
  workflowData?: WorkflowData;
  workflowName?: string;
  showLive?: boolean;
  status?: ExecutionStatus;
  targetData?: TargetMap;
  variablesData?: VariableMap;
  nodes?: WorkflowNode[];
  edges: WorkflowEdge[];
  children?: ReactNode;
  onSignalClick?: (signal: SignalTypeEnum, payload?: object) => void;
  isAPITrigger?: boolean;
  showAllNodeLogs: boolean;
  executionVariables: ExecutionVariables;
  onDownloadLinkData?: (url: string) => void;
  updateExecution?: (newStatusDesc: string) => void;
  updateExecutionStatus?: 'error' | 'success' | 'pending' | 'idle';
  hasSuggestions?: boolean;
  workflowId?: string;
  liveExecutionViewEnabled?: boolean;
  onDeleteScreenshot?: (
    id: string,
    isImage: boolean,
    item: ScreenshotUrl,
  ) => void;
  globalVariables: Record<string, GlobalVariable> | VariableMap;
  signalLoading: boolean;
  setStopPolling: (newPolling: boolean) => void;
  onAddScreenshotForDelete: (newItem: ScreenshotForDelete) => void;
  screenshotsForDelete: ScreenshotForDelete[];
  onBulkDeleteScreenshots: () => void;
  deletingScreenshots: boolean;
}

export function RunOverview({
  setStopPolling,
  executionDetail,
  executionScreenshotDetailsEnabled,
  executionDetailLoading,
  screenshotUrls,
  loading,
  workflowData,
  targetData = {},
  showLive,
  nodes = [],
  children,
  onSignalClick,
  status,
  workflowName,
  workflowId,
  isAPITrigger,
  variablesData,
  showAllNodeLogs,
  executionVariables,
  onDownloadLinkData,
  updateExecution,
  updateExecutionStatus,
  hasSuggestions,
  liveExecutionViewEnabled,
  onDeleteScreenshot,
  onAddScreenshotForDelete,
  onBulkDeleteScreenshots,
  screenshotsForDelete,
  edges,
  globalVariables,
  signalLoading,
  deletingScreenshots,
}: Props) {
  const [currentStepTarget] = useMemo(() => {
    if (isAdmin && !loading && executionDetail) {
      const currentStepId = executionDetail.metadata.currentStep?.stepId;
      const imageNodes = workflowData?.nodes.filter(
        (node) => WorkflowImageNode.safeParse(node).success,
      ) as WorkflowImageNode[];
      const imageNodeWithCurrentAction = imageNodes.find((node) =>
        Object.keys(node.data.actionData).includes(currentStepId ?? ''),
      );
      const currentAction = Object.values(
        imageNodeWithCurrentAction?.data.actionData ?? {},
      ).find((actionData) => actionData.id === currentStepId);
      const target = Object.values(targetData).find(
        (t) => t.id === currentAction?.targetId,
      );
      return [target];
    }
    return [{}, {}];
  }, [executionDetail, workflowData, targetData, loading]);
  return (
    <>
      <WorkflowScreenshots
        edges={edges}
        setStopPolling={setStopPolling}
        updateExecution={updateExecution}
        updateExecutionStatus={updateExecutionStatus}
        completedSteps={executionDetail?.metadata.completedSteps ?? []}
        currentStepId={executionDetail?.metadata.currentStep?.stepId}
        executionMetadata={executionDetail?.metadata}
        executionArtifacts={executionDetail?.artifacts}
        inputFiles={executionDetail?.inputFiles}
        executionScreenshotDetailsEnabled={executionScreenshotDetailsEnabled}
        screenshotUrls={screenshotUrls}
        isAPITrigger={isAPITrigger}
        loading={loading}
        nodes={nodes}
        onSignalClick={onSignalClick}
        showAllNodeLogs={showAllNodeLogs}
        showLive={showLive}
        status={status}
        targetData={targetData}
        variablesData={variablesData}
        workflowName={workflowName}
        executionVariables={executionVariables}
        onDownloadLinkData={onDownloadLinkData}
        workflowId={workflowId}
        hasSuggestions={hasSuggestions}
        targets={targetData}
        liveExecutionViewEnabled={liveExecutionViewEnabled}
        onDeleteScreenshot={onDeleteScreenshot}
        onAddScreenshotForDelete={onAddScreenshotForDelete}
        screenshotsForDelete={screenshotsForDelete}
        globalVariables={globalVariables}
        signalLoading={signalLoading}
        onBulkDeleteScreenshots={onBulkDeleteScreenshots}
        deletingScreenshots={deletingScreenshots}
      >
        {children}
      </WorkflowScreenshots>
      {isAdmin ? (
        <Flex className="space-x-4 mt-8">
          <div className="flex-1 overflow-x-hidden">
            <p className="text-info-dark text-2xl font-medium">
              Completed Steps
            </p>
            {!executionDetailLoading ? (
              <div className="rounded-lg mt-8 bg-color-light-blue p-9 min-h-[30rem] max-h-64 overflow-y-auto dark-scrollbar">
                <pre className="whitespace-pre-wrap text-info-dark">
                  {executionDetail?.metadata.completedSteps &&
                  !isEmpty(executionDetail.metadata.completedSteps)
                    ? JSON.stringify(
                        executionDetail.metadata.completedSteps,
                        null,
                        2,
                      )
                    : null}
                </pre>
              </div>
            ) : (
              <Skeleton
                variant="rounded"
                className="rounded-lg mt-8 bg-color-light-blue p-9 min-h-[30rem] max-h-64 overflow-y-auto dark-scrollbar"
              />
            )}
          </div>

          <div className="flex-1 overflow-x-hidden">
            <p className="text-info-dark text-2xl font-medium">Current Step</p>
            {!executionDetailLoading ? (
              <div className="rounded-lg mt-8 bg-color-light-blue p-9 min-h-[30rem] max-h-64 overflow-y-auto dark-scrollbar">
                <pre className="whitespace-pre-wrap text-info-dark">
                  {executionDetail?.metadata.currentStep &&
                  !isEmpty(executionDetail.metadata.currentStep)
                    ? JSON.stringify(
                        {
                          ...executionDetail.metadata.currentStep,
                          currentStepTarget,
                        },
                        null,
                        2,
                      )
                    : null}
                </pre>
              </div>
            ) : (
              <Skeleton
                variant="rounded"
                className="rounded-lg mt-8 bg-color-light-blue p-9 min-h-[30rem] max-h-64 overflow-y-auto dark-scrollbar"
              />
            )}
          </div>
          <div className="flex-1 overflow-x-hidden">
            <p className="text-info-dark text-2xl font-medium">
              Variables Data
            </p>

            {!executionDetailLoading ? (
              <div className="rounded-lg mt-8 bg-color-light-blue p-9 min-h-[30rem] max-h-64 overflow-y-auto">
                <pre className="whitespace-pre-wrap text-info-dark">
                  {executionDetail?.metadata.variableData &&
                  !isEmpty(executionDetail.metadata.variableData)
                    ? JSON.stringify(
                        executionDetail.metadata.variableData,
                        null,
                        2,
                      )
                    : null}
                </pre>
              </div>
            ) : (
              <Skeleton
                variant="rounded"
                className="rounded-lg mt-8 bg-color-light-blue p-9 min-h-[30rem] max-h-64 overflow-y-auto dark-scrollbar"
              />
            )}
          </div>
        </Flex>
      ) : null}
    </>
  );
}
