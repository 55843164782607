import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import {
  CustomTypography,
  Flex,
  SortOrder,
  ScrollableContainer,
  ContentContainer,
} from 'ui-kit';
import CircularProgress from '@mui/material/CircularProgress';
import { EXECUTIONS_LIST_REFETCH_INTERVAL } from '../../utils/constants';
import { useFetchGlobalExecutionsList, useExtensionListCols } from './hooks';
import {
  executionListFiltersSchema,
  defaultFilters,
  mapStatuses,
} from '../Workflows/utils/helper';
import usePersistedURLState from '../../hooks/usePersistedURLState';
import { type GridSortModel, type GridColDef } from '@mui/x-data-grid/models';
import { NavigationPanel } from '../../components/NavigationPanel';
import ExecutionListFilter from '../Workflows/components/ExecutionListFilter';
import { WorkflowDetailSkeleton } from '../Workflows/components/WorkflowDetailSkeleton';
import { useUpdateExecution } from '../Execution/hooks';
import { NoDataFound } from '../../components/NoDataFound';
import type { ExecutionBase } from 'types-shared/executionTypes';
import { DataGrid } from '@mui/x-data-grid/DataGrid';

const sortOrder = [SortOrder.DESC, SortOrder.ASC];

export default function ExecutionsList() {
  const [timeToRefresh, setTimeToRefresh] = useState<number>(
    EXECUTIONS_LIST_REFETCH_INTERVAL,
  );
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [orderBy, setOrderBy] = useState<GridSortModel>([
    { field: 'createdAt', sort: SortOrder.DESC },
  ]);

  const [filters, setFilters] = usePersistedURLState(
    executionListFiltersSchema,
    defaultFilters,
    'global-executions-list-filter',
  );

  const statusValue = useMemo(() => {
    const status = filters.status[0];
    return !status || (filters.status.length === 1 && status === 'All')
      ? undefined
      : mapStatuses(filters.status);
  }, [filters.status]);

  const {
    isLoading: executionsListLoading,
    refetch: refetchExecutions,
    data: executionsData,
    isRefetching: executionsListFetching,
    dataUpdatedAt: executionsListDataUpdatedAt,
  } = useFetchGlobalExecutionsList(
    {
      adminRun:
        typeof filters.hideAdminRuns === 'boolean'
          ? filters.hideAdminRuns
          : undefined,
      status: statusValue,
      executionId: filters.id ? filters.id : undefined,
      owner: filters.owner ? filters.owner : undefined,
    },
    {
      page: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      orderBy,
    },
  );

  const { status: executionUpdateStatus, mutate: updateExecution } =
    useUpdateExecution();

  const {
    executions = [],
    filteredExecutionsCount = 0,
    // totalExecutionsCount = 0,
  } = executionsData ?? {};

  const onResetPage = () => {
    setPaginationModel({
      ...paginationModel,
      page: 0,
    });
  };

  useEffect(() => {
    setTimeToRefresh(EXECUTIONS_LIST_REFETCH_INTERVAL);
  }, [executionsListDataUpdatedAt]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToRefresh((prev) => {
        if (prev === 0) {
          return prev;
        }
        return prev - 1;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const columns = useExtensionListCols({
    updateExecution,
    executionUpdateStatus,
    executionDetailsEnabled: true,
    hitlLiveViewEnabled: true,
  });

  return (
    <ScrollableContainer>
      <div className="w-full h-full flex">
        <NavigationPanel />

        <ContentContainer>
          <CustomTypography variant="h4">Executions</CustomTypography>

          <div className="flex items-end justify-between mt-8">
            <div className="flex flex-col space-y-1">
              <Flex className="items-center" flexDirection="row">
                <div className="min-w-max">
                  <CustomTypography color="text.secondary" variant="body2">
                    Details for all users current and past runs.
                  </CustomTypography>
                </div>
              </Flex>
            </div>

            <div className="flex items-center min-w-max">
              <span className="text-sm text-gray-500 min-w-max mr-4">
                Auto-updating
              </span>
              <CircularProgress
                color="secondary"
                size={20}
                thickness={5}
                sx={{ scale: '-1 1' }}
                variant="determinate"
                value={((20 - timeToRefresh) / 20) * 100}
              />
            </div>
          </div>

          <ExecutionListFilter
            filters={filters}
            setFilters={setFilters}
            onResetPage={onResetPage}
            onRefresh={() => {
              void refetchExecutions();
            }}
          />

          <div>
            {executionsListLoading || executionsListFetching ? (
              <WorkflowDetailSkeleton
                columns={columns as GridColDef<ExecutionBase>[]}
              />
            ) : (
              <>
                {executions.length > 0 ? (
                  <DataGrid
                    columns={columns}
                    getRowId={(row) => row.executionId}
                    rows={executions}
                    sortingOrder={sortOrder}
                    rowCount={filteredExecutionsCount}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    paginationMode="server"
                    sortModel={orderBy}
                    onSortModelChange={(val) => {
                      onResetPage();
                      setOrderBy(val);
                    }}
                  />
                ) : (
                  <NoDataFound
                    className="mt-4"
                    heading="Execution listing"
                    subHeading="No execution matches those filters."
                  />
                )}
              </>
            )}
          </div>
        </ContentContainer>
      </div>
    </ScrollableContainer>
  );
}
