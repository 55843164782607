"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendTemporalMessageSchema = exports.MessageType = exports.MessageTypeEnum = void 0;
var types_shared_1 = require("types-shared");
var MessageTypeEnum;
(function (MessageTypeEnum) {
    MessageTypeEnum["Resume"] = "Resume";
    MessageTypeEnum["Terminate"] = "Terminate";
})(MessageTypeEnum || (exports.MessageTypeEnum = MessageTypeEnum = {}));
exports.MessageType = types_shared_1.z.nativeEnum(MessageTypeEnum);
exports.sendTemporalMessageSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
        body: types_shared_1.z.object({
            message: exports.MessageType,
            // TODO(michael): Support messages with payloads
        }),
    }),
    response: types_shared_1.z.object({
        execution: types_shared_1.z.object({
            id: types_shared_1.uuidSchema,
            status: types_shared_1.ExecutionStatus,
        }),
        temporalStatus: types_shared_1.z.string(),
    }),
};
