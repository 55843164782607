import {
  Add,
  CloseIcon,
  DragIndicator,
  Button,
  Input,
  Select,
  Switch,
  MenuItem,
  Menu,
  IconButton,
  MoreVert,
  ContentCopy,
  EditOutlined,
  DeleteOutlineIcon,
} from 'ui-kit';
import { clsx } from 'clsx';
import { EditBranch } from './EditBranch';
import type {
  BranchData,
  DatasourceMetadata,
  DatasourceTable,
  GlobalVariable,
  VariableMap,
  Group,
  SourceTypeEnum,
  TemplateData,
  Variable,
  WorkflowConditionalNode,
  WorkflowEdge,
  WorkflowNode,
} from 'types-shared';
import { NodeStatusEnum, NodeTypesEnum } from 'types-shared';
import { useMemo, useState } from 'react';
import { NodeCheck } from '../NodeCheck';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { isAdmin } from '../../../../utils/env';
import { FeatureFlag } from '../../../../utils/constants';
import { useFeatureFlag } from '../../../../utils/helper';

interface Props {
  node: WorkflowConditionalNode;
  nodes: WorkflowNode[];
  editingEdge: WorkflowEdge | undefined;
  setEditingEdge: (val: undefined | WorkflowEdge) => void;
  edges: WorkflowEdge[];
  setEdges: (edges: WorkflowEdge[]) => void;
  setNodes: (nodes: WorkflowNode[]) => void;
  duplicateBranch: (node: WorkflowConditionalNode, edge: WorkflowEdge) => void;
  deleteBranch: (branch: WorkflowEdge) => void;
  insertNode: (sourceId: string) => void;
  updateNodeName: (name: string) => void;
  updateErrorOverlay: (val: boolean) => void;
  onUpdateEdge: (
    data: Partial<{
      name: string;
      group: Group;
      instruction: { variableId: string };
    }>,
  ) => void;
  onCancel: () => void;

  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  sourceType?: SourceTypeEnum;
  onTransformApiReq: (
    prompt: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  allowBranchReordering?: boolean;
}
const typeOptions: string[] = [
  'Execute the first branch that evaluates to true.',
  'Execute all branches that hold true',
];

export function ConditionalBlock({
  edges,
  node,
  editingEdge,
  onCancel,
  insertNode,
  updateNodeName,
  updateErrorOverlay,
  deleteBranch,
  duplicateBranch,
  setEditingEdge,
  addVariable,
  variablesMap,
  globalVariablesMap,
  datasourceMetadata,
  tableData,
  onTransformApiReq,
  transformApiReqStatus,
  sourceType,
  updateNodeStatus,
  allowBranchReordering = false,
  nodes,
  setNodes,
  setEdges,
}: Props) {
  const branchesOrder = useMemo(
    () => (node.data.branchesData ?? []).map((branch) => branch.branchId),
    [node.data.branchesData],
  );

  const nodeEdges = useMemo(() => {
    const outgoingEdges = edges.filter((edge) => edge.source === node.id);
    return outgoingEdges
      .filter((edge) => branchesOrder.includes(edge.id))
      .sort((e1, e2) => {
        const e1Index = branchesOrder.indexOf(e1.id);
        const e2Index = branchesOrder.indexOf(e2.id);
        return e1Index - e2Index;
      })
      .concat(outgoingEdges.filter((edge) => !branchesOrder.includes(edge.id)));
  }, [edges, node.id, branchesOrder]);

  const branchData: BranchData | undefined = useMemo(
    () =>
      node.data.branchesData?.find(
        (b: BranchData) => b.branchId === editingEdge?.id,
      ),
    [editingEdge?.id, node.data.branchesData],
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor);

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id === over?.id) {
      return;
    }
    const tempEdgesOrder = [...nodeEdges.map((edge) => edge.id)];
    const activeIndex = tempEdgesOrder.findIndex((id) => id === active.id);
    const overIndex = tempEdgesOrder.findIndex((id) => id === over?.id);
    const newEdgesOrder = arrayMove(tempEdgesOrder, activeIndex, overIndex);
    const newEdges = [...edges].sort((e1, e2) => {
      const e1Index = newEdgesOrder.indexOf(e1.id);
      const e2Index = newEdgesOrder.indexOf(e2.id);
      return e1Index - e2Index;
    });
    const newNodes = nodes.map((_node) => {
      if (_node.id === node.id && _node.type === NodeTypesEnum.Conditional) {
        return {
          ..._node,
          data: {
            ..._node.data,
            branchesData: [...(_node.data.branchesData ?? [])].sort(
              (b1, b2) => {
                const b1Index = newEdgesOrder.indexOf(b1.branchId);
                const b2Index = newEdgesOrder.indexOf(b2.branchId);
                return b1Index - b2Index;
              },
            ),
          },
        };
      }
      return _node;
    });
    setEdges(newEdges);
    setNodes(newNodes);
  };

  const errorOverlay = Boolean(node.errorOverlay && !isAdmin);
  return (
    <>
      {!editingEdge ? (
        <div className="zoom-adjusted-container node-block absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] p-8 flex flex-col justify-between space-y-5">
          <div className="overflow-auto">
            <div className="flex justify-between items-center">
              <span className="text-sm text-primary-blue font-medium">
                {errorOverlay ? 'Error Handling' : 'Conditional logic'}
              </span>
              <Button
                className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
                color="secondary"
                onClick={onCancel}
                variant="outlined"
              >
                <CloseIcon className="text-info" />
              </Button>
            </div>
            <div className="my-6">
              <h2 className="text-lg font-medium">
                {errorOverlay ? 'Error Handling' : 'Conditional logic'}
              </h2>
              <p className="text-sm font-normal text-info-dark">
                {errorOverlay
                  ? 'Handle errors via conditional logic and branching.'
                  : 'Create multiple branches and set conditions for their execution.'}
              </p>
            </div>
            <div className="flex-1 flex flex-col gap-4 conditional-block">
              <Input
                floatingLabel
                label="Step Name"
                onChange={updateNodeName}
                placeholder="Step Name"
                value={node.name ?? ''}
              />

              {!errorOverlay && (
                <Select
                  classes={{ select: 'w-100' }}
                  disabled
                  getLabel={(opt: string) => opt}
                  getValue={(opt: string) => opt}
                  label="Condition Type"
                  labelId="type"
                  options={typeOptions}
                  value={typeOptions[0]}
                />
              )}

              <div className="mt-8">
                <p className="font-bold text-sm">
                  {errorOverlay ? 'Error Branches' : 'Branches'}
                </p>
                <div className="mt-2">
                  <DndContext
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={onDragEnd}
                    sensors={sensors}
                  >
                    <SortableContext
                      disabled={!allowBranchReordering}
                      items={nodeEdges}
                      strategy={verticalListSortingStrategy}
                    >
                      {nodeEdges.map((edge) => {
                        return (
                          <Branch
                            allowBranchReordering={allowBranchReordering}
                            errorOverlay={errorOverlay}
                            id={edge.id}
                            key={edge.id}
                            label={edge.label as string}
                            onEdit={() => {
                              setEditingEdge(edge);
                            }}
                            onDelete={() => {
                              deleteBranch(edge);
                            }}
                            onDuplicate={() => {
                              duplicateBranch(node, edge);
                            }}
                          />
                        );
                      })}
                    </SortableContext>
                  </DndContext>
                  {!errorOverlay && (
                    <Button
                      className="!mt-6 !mb-2"
                      color="secondary"
                      onClick={() => {
                        insertNode(node.id);
                      }}
                      startIcon={<Add />}
                      variant="text"
                    >
                      Add Branch
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {isAdmin ? (
            <div className="mt-4 flex items-center">
              <Switch
                checked={Boolean(node.errorOverlay)}
                onChange={(event) => {
                  updateErrorOverlay(event.target.checked);
                }}
                color="primary"
                id="toggle-error-overlay"
              />
              <label
                htmlFor="toggle-error-overlay"
                className="ml-2 text-sm font-medium text-gray-700"
              >
                Toggle Error Overlay
              </label>
            </div>
          ) : null}

          <div className="flex flex-col space-y-7">
            {!node.hideFromUser ? (
              <NodeCheck
                description="Mark as reviewed if this step is ready to execute."
                isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
                updateNodeStatus={updateNodeStatus}
              />
            ) : null}
            <Button
              color="secondary"
              fullWidth
              onClick={onCancel}
              variant="outlined"
            >
              Back to flow view
            </Button>
          </div>
        </div>
      ) : (
        <EditBranch
          node={node}
          addVariable={addVariable}
          branchData={branchData}
          datasourceMetadata={datasourceMetadata}
          edge={editingEdge}
          onCancel={() => {
            setEditingEdge(undefined);
          }}
          onDelete={() => {
            deleteBranch(editingEdge);
          }}
          onTransformApiReq={onTransformApiReq}
          tableData={tableData}
          transformApiReqStatus={transformApiReqStatus}
          sourceType={sourceType}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
        />
      )}
    </>
  );
}

interface BranchProps {
  label: string;
  id: string;
  onEdit: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
  allowBranchReordering: boolean;
  errorOverlay?: boolean;
}
function Branch({
  label,
  onEdit,
  onDuplicate,
  onDelete,
  id,
  allowBranchReordering,
  errorOverlay,
}: BranchProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const allowBranchDuplication = useFeatureFlag(
    FeatureFlag.AllowCloningBranches,
    true,
  );

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={clsx(
        'px-3 py-4 mt-6 rounded-lg border bg-gray-100 flex space-x-4 items-center hover:shadow-primary transition ',
        {
          'border-gray-800 cursor-grab': isDragging,
          'hover:border-primary-blue cursor-pointer': !isDragging,
        },
      )}
      style={style}
      onClick={() => {
        // Do not attempt to edit if a user is just trying to close the menu by clicking outside it
        if (!anchorEl) {
          onEdit();
        }
      }}
      role="presentation"
    >
      <span className="flex-1">{label}</span>
      {allowBranchReordering && !errorOverlay ? (
        <div
          className={clsx(
            'rotate-90 text-gray-400 cursor-grab hover:text-gray-800',
            isDragging && 'text-gray-800',
          )}
        >
          <DragIndicator />
        </div>
      ) : null}
      {!errorOverlay && (
        <>
          <IconButton
            aria-controls={anchorEl ? 'basic-menu' : undefined}
            aria-expanded={anchorEl ? 'true' : undefined}
            aria-haspopup="true"
            aria-label="more"
            className="!p-0"
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVert />
          </IconButton>
          <Menu
            BackdropProps={{
              style: {
                backgroundColor: 'transparent',
              },
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            onClose={onMenuClose}
            open={Boolean(anchorEl)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem
              className="group min-w-72 h-12"
              onClick={() => {
                onEdit();
                onMenuClose();
              }}
            >
              <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
                Edit branch
              </span>
              <EditOutlined className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
            </MenuItem>
            {allowBranchDuplication ? (
              <MenuItem
                className="group min-w-72 h-12"
                onClick={() => {
                  onDuplicate();
                  onMenuClose();
                }}
              >
                <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
                  Duplicate branch
                </span>
                <ContentCopy className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
              </MenuItem>
            ) : null}
            <MenuItem
              className="group min-w-72 h-12"
              onClick={() => {
                onDelete();
                onMenuClose();
              }}
            >
              <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
                Delete branch
              </span>
              <DeleteOutlineIcon className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
}
