import {
  EmailTriggerVariableEnum,
  type SourceVariable,
  type TemplateData,
  type Variable,
  type WorkflowSourceNode,
  type GlobalVariable,
  type VariableMap,
  type QueryVariable,
  EmailTriggerVariable,
} from 'types-shared';
import React, { useMemo, useState, useCallback, type ReactNode } from 'react';
import { Label, MailIcon } from 'ui-kit';
import { TriggerQueryVariables } from './TriggerQueryVariables';
import { AddEditQueryVariable } from './AddEditQueryVariable';
import { emailTriggerVariableNames } from '../../../utils/constants';
import { isEmailVariable } from '../../../utils/helper';

interface Props {
  sourceVariable: SourceVariable;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  node: WorkflowSourceNode;
  updateVariable: (variable: QueryVariable) => void;
  addVariable: (variable: QueryVariable) => void;

  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  onTransformApiReq: (
    query: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
}

export function TriggerBlockData({
  sourceVariable,
  variablesMap,
  globalVariablesMap,
  ...props
}: Props) {
  const [addingVariable, setAddingVariable] =
    useState<EmailTriggerVariable | null>(null);
  const [editingVariable, setEditingVariable] = useState<QueryVariable | null>(
    null,
  );
  const [editingVariableSrc, setEditingVariableSrc] =
    useState<EmailTriggerVariable | null>(null);

  const emailVariables = useMemo(() => {
    return Object.values(variablesMap).filter(
      (v) =>
        isEmailVariable(v, variablesMap) &&
        !EmailTriggerVariable.safeParse(v).success,
    ) as QueryVariable[];
  }, [variablesMap]);

  const [
    subjectVariable,
    bodyVariable,
    emailDataVariable,
    attachmentsVariable,
  ] = useMemo(() => {
    const variableNames = emailTriggerVariableNames;
    return variableNames.map((name) => {
      const variables = Object.values(
        variablesMap,
      ).filter<EmailTriggerVariable>(
        (v): v is EmailTriggerVariable => v.name === name,
      );
      if (variables.length === 0) {
        throw new Error(`${name} variable not found`);
      }
      return variables[0];
    });
  }, [variablesMap]);

  const emailVariablesOnSource = useCallback(
    (variableId: string) => {
      return emailVariables.filter((v) =>
        v.data.sourceIds.includes(variableId),
      );
    },
    [emailVariables],
  );

  if (addingVariable) {
    return (
      <AddEditQueryVariable
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        sourceVariable={sourceVariable}
        addingVariableSource={addingVariable}
        onCancel={() => {
          setAddingVariable(null);
        }}
        {...props}
      />
    );
  }

  if (editingVariable && editingVariableSrc) {
    return (
      <AddEditQueryVariable
        variable={editingVariable}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        sourceVariable={sourceVariable}
        addingVariableSource={editingVariableSrc}
        onCancel={() => {
          setEditingVariable(null);
          setEditingVariableSrc(null);
        }}
        {...props}
      />
    );
  }

  return (
    <div className="rounded-lg border p-4 bg-gray-100 border-slate-300">
      <div className="flex gap-4 mb-8 items-center">
        <div className="py-[0.5rem] px-[0.5rem] rounded-md bg-white flex items-center justify-center">
          <MailIcon className="!w-5 !h-5 text-cyan-900" />
        </div>
        <Label>{EmailTriggerVariableEnum.EmailData}</Label>
      </div>

      <div className="mb-8">
        <TriggerQueryVariables
          queryVariables={emailVariablesOnSource(emailDataVariable.id)}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          onClickAdd={() => {
            setAddingVariable(emailDataVariable);
          }}
          onClick={(variableToEdit) => {
            setEditingVariable(variableToEdit);
            setEditingVariableSrc(emailDataVariable);
          }}
          tooltipText="Extract data from the entire email"
        />
      </div>

      <div className="flex flex-col gap-4">
        <VariableBlock title={EmailTriggerVariableEnum.Subject}>
          <TriggerQueryVariables
            queryVariables={emailVariablesOnSource(subjectVariable.id)}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            onClickAdd={() => {
              setAddingVariable(subjectVariable);
            }}
            onClick={(variableToEdit) => {
              setEditingVariable(variableToEdit);
              setEditingVariableSrc(emailDataVariable);
            }}
            tooltipText="Extract data from the email subject only"
          />
        </VariableBlock>

        <VariableBlock title={EmailTriggerVariableEnum.Body}>
          <TriggerQueryVariables
            queryVariables={emailVariablesOnSource(bodyVariable.id)}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            onClickAdd={() => {
              setAddingVariable(bodyVariable);
            }}
            onClick={(variableToEdit) => {
              setEditingVariable(variableToEdit);
              setEditingVariableSrc(emailDataVariable);
            }}
            tooltipText="Extract data from the email body only"
          />
        </VariableBlock>

        <VariableBlock title={EmailTriggerVariableEnum.Attachments}>
          <TriggerQueryVariables
            queryVariables={emailVariablesOnSource(attachmentsVariable.id)}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            onClickAdd={() => {
              setAddingVariable(attachmentsVariable);
            }}
            onClick={(variableToEdit) => {
              setEditingVariable(variableToEdit);
              setEditingVariableSrc(emailDataVariable);
            }}
            tooltipText="Extract data from the email attachments only"
          />
        </VariableBlock>
      </div>
    </div>
  );
}

function VariableBlock({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <div className="rounded-lg border px-4 pt-2 pb-4 bg-primary-blue-extralight-2 border-indigo-light">
      <Label>{title}</Label>
      <div className="mt-4">{children}</div>
    </div>
  );
}
