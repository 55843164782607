import { SubVariableExtractor } from 'types-shared';
import type { VariableMap, WorkflowNode } from 'types-shared';

/**
 * Checks if a variable is used in the workflow.
 *
 * @param variableId - The ID of the variable to check.
 * @param nodes - The workflow nodes to search through.
 * @param variableMap - The map of all variables in the workflow.
 * @param handleException - A function to handle exceptions.
 * @returns True if the variable is used, false otherwise.
 */
export function isVariableUsedInWorkflow(
  variableId: string,
  nodes: WorkflowNode[],
  variableMap: VariableMap,
  handleException: (error: Error, meta: Record<string, unknown>) => void,
): boolean {
  const subVariableExtractor = new SubVariableExtractor(
    variableMap,
    handleException,
  );
  const extractedVariables =
    subVariableExtractor.extractVariablesFromNodes(nodes);

  // Check if the variableId exists in the extracted variables
  return variableId in extractedVariables;
}

/**
 * Determines which variables are used in the workflow.
 *
 * @param nodes - The workflow nodes to search through.
 * @param variableMap - The map of all variables in the workflow.
 * @param handleException - A function to handle exceptions.
 * @returns An array of variable IDs that are used in the workflow.
 */
export function whichVariablesUsedInWorkflow(
  nodes: WorkflowNode[],
  variableMap: VariableMap,
  handleException: (error: Error, meta: Record<string, unknown>) => void,
): string[] {
  const subVariableExtractor = new SubVariableExtractor(
    variableMap,
    handleException,
  );
  const extractedVariables =
    subVariableExtractor.extractVariablesFromNodes(nodes);

  // Return the keys of the extractedVariables object, which are the variable IDs
  return Object.keys(extractedVariables);
}

/**
 * Determines which variables are not used in the workflow.
 *
 * @param nodes - The workflow nodes to search through.
 * @param variableMap - The map of all variables in the workflow.
 * @param handleException - A function to handle exceptions.
 * @returns An array of variable IDs that are not used in the workflow.
 */
export function whichVariablesNotUsedInWorkflow(
  nodes: WorkflowNode[],
  variableMap: VariableMap,
  handleException: (error: Error, meta: Record<string, unknown>) => void,
): string[] {
  const usedVariables = whichVariablesUsedInWorkflow(
    nodes,
    variableMap,
    handleException,
  );
  const allVariables = Object.keys(variableMap);

  // Filter out the used variables from all variables
  return allVariables.filter(
    (variableId) => !usedVariables.includes(variableId),
  );
}
