import {
  type TypedDocumentNode,
  useQuery,
  type ApolloError,
} from '@apollo/client';
import { graphql, type TeamNameAndRole } from 'hasura-gql';

interface TeamInfo {
  team: {
    name: string;
    id: string;
  };
  roles: {
    role: {
      identifier: string;
      description?: string;
    };
  }[];
}

const GET_TEAM_AND_ROLE = graphql(`
  query GetTeamAndRole($userId: String!) {
    organizations_team_memberships(
      order_by: { roles_aggregate: { count: desc } }
      where: { userId: { _eq: $userId } }
    ) {
      team {
        id
        name
      }
      roles {
        role {
          identifier
          description
        }
      }
    }
  }
`) as TypedDocumentNode<
  { organizations_team_memberships: TeamInfo[] },
  { userId: string }
>;

export const useTeamDetails = (
  userId = '',
): { data?: TeamNameAndRole; loading: boolean; error?: ApolloError } => {
  const { data, loading, error } = useQuery(GET_TEAM_AND_ROLE, {
    variables: { userId },
    skip: !userId,
  });

  // NOTE: We use the first membership -- this has undefined behavior for users on multiple teams
  // TODO: Support multiple teams properly
  const firstMembership = data?.organizations_team_memberships[0];
  if (firstMembership) {
    const teamNameAndRole = {
      teamId: firstMembership.team.id,
      teamName: firstMembership.team.name,
      // NOTE: We use the first role -- this has undefined behavior for users with multiple roles
      roleName: firstMembership.roles[0]?.role.description ?? 'Member',
      // FIXME: Hack based on existing role names to determine if the role is an `admin` role or not
      isAdmin:
        firstMembership.roles[0]?.role.identifier.startsWith('admin') ?? false,
    };
    return { data: teamNameAndRole, loading, error };
  }

  return { data: undefined, loading, error };
};
