import {
  type WorkflowNode,
  type WorkflowEdge,
} from 'types-shared/workflowTypes';
import { isAdmin } from '../../../utils/env';

export function adjustEdgesForHiddenNodes(
  nodes: WorkflowNode[],
  edges: WorkflowEdge[],
): { nodes: WorkflowNode[]; edges: WorkflowEdge[] } {
  if (isAdmin) {
    return { nodes, edges };
  }

  const nodeMap = new Map<string, WorkflowNode>();
  nodes.forEach((node) => nodeMap.set(node.id, node));

  const adjustedEdges: WorkflowEdge[] = [];
  const adjustedNodes: WorkflowNode[] = nodes.filter(
    (node) => !node.hideFromUser,
  );

  edges.forEach((edge) => {
    let source = edge.source;
    let target = edge.target;

    // Adjust source if it is part of a hidden chunk
    source = findNextVisibleSource(source, nodeMap, edges);

    // Adjust target if it is part of a hidden chunk
    target = findNextVisibleTarget(target, nodeMap, edges);

    // Only include edges where both source and target are not hidden
    if (
      !nodeMap.get(source)?.hideFromUser &&
      !nodeMap.get(target)?.hideFromUser
    ) {
      adjustedEdges.push({
        ...edge,
        source,
        target,
      });
    }
  });

  return {
    nodes: adjustedNodes,
    edges: adjustedEdges,
  };
}

function findNextVisibleSource(
  currentSource: string,
  nodeMap: Map<string, WorkflowNode>,
  edges: WorkflowEdge[],
): string {
  let source = currentSource;

  while (nodeMap.get(source)?.hideFromUser) {
    const currentSourceId = source; // Capture the current value of 'source'
    const incomingEdges = edges.filter((e) => e.target === currentSourceId);
    if (incomingEdges.length > 0) {
      // Move to the previous node in the edge
      source = incomingEdges[0].source;
    } else {
      // No more incoming edges, cannot adjust further
      break;
    }
  }
  return source;
}

function findNextVisibleTarget(
  currentTarget: string,
  nodeMap: Map<string, WorkflowNode>,
  edges: WorkflowEdge[],
): string {
  let target = currentTarget;

  while (nodeMap.get(target)?.hideFromUser) {
    const currentTargetId = target; // Capture the current value of 'target'
    const outgoingEdges = edges.filter((e) => e.source === currentTargetId);
    if (outgoingEdges.length > 0) {
      // Move to the next node in the edge
      target = outgoingEdges[0].target;
    } else {
      // No more outgoing edges, cannot adjust further
      break;
    }
  }
  return target;
}
