import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { IconButton, CustomTypography, Flex, LeftChevron, Logo } from 'ui-kit';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

interface Props {
  onBack: () => void;
  pageInfo?: string | ReactNode;
  children?: ReactNode;
  className?: string;
  title?: string;
  loading?: boolean;
}
export function Toolbar({
  onBack,
  title = 'Home',
  pageInfo,
  children,
  className,
  loading,
}: Props) {
  return (
    <div className={clsx('px-4 py-5 !h-20 flex', className)}>
      <Flex alignItems="center" className="space-x-6">
        <IconButton
          className="!border !border-solid !border-info !rounded-lg !p-0"
          onClick={onBack}
        >
          <LeftChevron className="text-info !h-10 !w-10" />
        </IconButton>
        <Link to="/">
          <Flex className="p-0 cursor-pointer">
            <Logo className="!w-7 !h-7" />
          </Flex>
        </Link>
        <Flex flexDirection="column">
          {loading ? (
            <Skeleton
              variant="rounded"
              width={200}
              height={16}
              className="mb-1"
            />
          ) : (
            <CustomTypography className="!font-medium !text-xs !text-gray-500">
              {title}
            </CustomTypography>
          )}

          {loading ? (
            <Skeleton variant="rounded" width={200} height={16} />
          ) : (
            <CustomTypography className="!font-medium !text-xs !text-primary-blue">
              {pageInfo}
            </CustomTypography>
          )}
        </Flex>
      </Flex>
      {children}
    </div>
  );
}
