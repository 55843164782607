import Box from '@mui/material/Box';
import { type SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Radio, Input, Select, Flex } from 'ui-kit';
import type { DatasourceStateData } from 'types-shared';

export enum RunType {
  Local = 'Local',
  Remote = 'Remote',
}

interface Props {
  localRunsEnabled?: boolean;
  recordIds: number[];
  datasourceState: DatasourceStateData;
  onChange: (val: number[]) => void;
  onConfirmRun: (runType: RunType) => void;
  onCancel: () => void;
  loading?: boolean;
}

const getRecordIds = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

export function ConfirmRunAutomation({
  localRunsEnabled,
  recordIds,
  datasourceState,
  onChange,
  onConfirmRun,
  onCancel,
  loading,
}: Props) {
  const { datasourceMetadata, tableData } = datasourceState;
  const [runType, setRunType] = useState(RunType.Remote);
  const isLocalRun = useMemo(() => runType === RunType.Local, [runType]);
  const [rangeType, setRangeType] = useState('some');
  const [range, setRange] = useState({
    start: 1,
    end: isLocalRun ? 1 : tableData?.rowData.length ?? 1,
  });

  const handleChange = (event: SelectChangeEvent) => {
    setRunType(event.target.value as RunType);
  };

  const validateRange = () => {
    const tableLength = tableData?.rowData.length ?? 1;
    let startValue = range.start || 1;
    let endValue = range.end || 1;

    if (startValue > endValue || startValue > tableLength) {
      startValue = 0;
    }
    if (endValue < startValue || endValue > tableLength) {
      endValue = tableData?.rowData.length ?? 1;
    }
    setRange({ ...range, start: startValue, end: endValue });
  };

  const updateRange =
    (type: 'start' | 'end' | 'both') => (value: string | number) => {
      if (!value) {
        if (type === 'both') {
          setRange({ ...range, start: value as number, end: value as number });
        } else {
          setRange({ ...range, [type]: value });
        }
        return;
      }

      const numericValue = Number(value);

      if (type === 'start') {
        if (numericValue <= range.end) {
          setRange({ ...range, [type]: numericValue });
        } else {
          throw new Error(
            'Start value must be less than or equal to end value',
          );
        }
      } else if (type === 'end') {
        setRange({ ...range, [type]: numericValue });
      } else {
        setRange({ ...range, start: numericValue, end: numericValue });
      }
    };

  useEffect(() => {
    setRangeType(isLocalRun ? 'some' : 'all');
    setRange({
      start: 1,
      end: isLocalRun ? 1 : tableData?.rowData.length ?? 1,
    });
  }, [isLocalRun, tableData?.rowData.length]);

  useEffect(() => {
    if (rangeType === 'all') {
      onChange(getRecordIds(0, tableData ? tableData.rowData.length - 1 : 0));
    }
    if (rangeType === 'some') {
      //Restrict Run automation to only the first row (DEMO)
      // onChange(getRecordIds(range.start - 1, range.end - 1));
      const val = getRecordIds(range.start - 1, range.end - 1);
      onChange(val);
    }
  }, [rangeType, range, onChange, tableData]);

  return (
    <Box>
      <Typography className="!font-medium" variant="h5">
        Execution settings
      </Typography>
      <Typography className="text-info-dark max-w-md !mt-4" variant="body2">
        Select deployment type and rows to execute before you execute your
        workflow.
      </Typography>
      <Box className="mt-7 bg-color-light-blue p-5 rounded-md">
        <Flex className="gap-2">
          <Typography
            className="!font-bold text-info-dark min-w-fit"
            variant="body2"
          >
            Rows to be executed:
          </Typography>
          <Typography className="text-info-dark" variant="body2">
            {recordIds.length}
          </Typography>
        </Flex>
        {datasourceMetadata ? (
          <Flex className="gap-2">
            <Typography
              className="!font-bold text-info-dark min-w-fit"
              variant="body2"
            >
              Data source:
            </Typography>
            <Typography className="text-info-dark" variant="body2">
              {datasourceMetadata.name}
            </Typography>
          </Flex>
        ) : null}
        <Flex className="gap-2">
          <Typography
            className="!font-bold text-info-dark min-w-fit"
            variant="body2"
          >
            Estimated execution time:
          </Typography>
          <Typography className="text-info-dark" variant="body2">
            -
          </Typography>
        </Flex>
      </Box>
      <Box className="mt-7">
        <FormControl sx={{ margin: 0 }}>
          <div className="relative">
            <Select
              classes={{ select: '!w-56' }}
              getLabel={(opt: string) => opt}
              getValue={(opt: string) => opt}
              label="Select the type of execution"
              labelId="select-run-type"
              onChange={handleChange}
              options={
                localRunsEnabled
                  ? [RunType.Local.valueOf(), RunType.Remote.valueOf()]
                  : [RunType.Remote.valueOf()]
              }
              value={runType}
            />
          </div>
        </FormControl>
      </Box>
      {datasourceMetadata ? (
        <Box className="mt-5">
          <FormControl sx={{ m: 0 }}>
            <RadioGroup
              defaultValue="some"
              name="radio-buttons-group"
              onChange={(_, val) => {
                setRangeType(val);
              }}
              value={rangeType}
            >
              <FormControlLabel
                control={<Radio color="secondary" />}
                label={
                  !isLocalRun ? 'Execute a range of rows' : 'Execute a row'
                }
                value="some"
              />
              <Flex className="gap-2 mt-3 mb-8">
                {!isLocalRun ? (
                  <>
                    <Input
                      InputProps={{ className: 'w-24', inputMode: 'numeric' }}
                      disabled={rangeType === 'all'}
                      floatingLabel
                      id="range-from"
                      label="From"
                      onBlur={validateRange}
                      onChange={updateRange('start')}
                      type="number"
                      value={range.start}
                    />
                    <span className="mx-3 text-black text-lg mt-4 !h-4">
                      {' - '}
                    </span>
                  </>
                ) : null}
                <div className="flex flex-col">
                  <Input
                    InputProps={{ className: 'w-24', inputMode: 'numeric' }}
                    disabled={rangeType === 'all'}
                    floatingLabel
                    id="range-to"
                    label={!isLocalRun ? 'To' : 'Row'}
                    onBlur={validateRange}
                    onChange={(e: string) => {
                      if (isLocalRun) {
                        updateRange('both')(e);
                      } else {
                        updateRange('end')(e);
                      }
                    }}
                    type="number"
                    value={range.end}
                  />
                  <span className="text-xs text-gray-500">
                    Max row: {tableData?.rowData.length}
                  </span>
                </div>
              </Flex>
              <FormControlLabel
                control={<Radio color="secondary" />}
                label="Execute all rows"
                value="all"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      ) : null}
      {isLocalRun ? (
        <Box className="mt-3">
          <FormControlLabel
            control={<Switch color="secondary" defaultChecked />}
            label="Close windows after execution"
          />
        </Box>
      ) : null}
      <Flex className="gap-2 mt-9">
        <Button
          color="secondary"
          disabled={!rangeType}
          loading={loading}
          onClick={() => {
            onConfirmRun(runType);
          }}
          variant="contained"
        >
          Confirm and execute
        </Button>
        <Button color="secondary" onClick={onCancel} variant="text">
          Cancel
        </Button>
      </Flex>
    </Box>
  );
}
