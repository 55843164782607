"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackwardsCompatibilityNodes = void 0;
var zod_1 = require("zod");
var workflow_schema_1 = require("./workflow.schema");
// TODO: remove this file when no longer needed
function zToNode(schema) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we need to preprocess the nodes to convert the retry node to the new type
    return zod_1.z.preprocess(function (backwardsNode) {
        try {
            if (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- To support old freeform nodes with pure string instructions
            backwardsNode.type === workflow_schema_1.NodeTypesEnum.Freeform &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Backwards compatibility layer
                typeof backwardsNode.data.instructions === 'string') {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Backwards compatibility layer
                backwardsNode.data.instructions = [backwardsNode.data.instructions];
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- To support old conditional nodes with string branches instructions
            else if (backwardsNode.type === workflow_schema_1.NodeTypesEnum.Conditional) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Backwards compatibility layer
                for (var _i = 0, _a = backwardsNode.data.branchesData; _i < _a.length; _i++) {
                    var branch = _a[_i];
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Backwards compatibility layer
                    if (branch && typeof branch.instruction === 'string') {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Backwards compatibility layer
                        branch.instruction = [branch.instruction];
                    }
                }
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console -- Backwards compatibility layer
            console.error('Error converting backwards compatibility layer', error);
            return backwardsNode;
        }
        if (workflow_schema_1.WorkflowNode.safeParse(backwardsNode).success) {
            return backwardsNode;
        }
    }, schema);
}
exports.BackwardsCompatibilityNodes = zToNode(workflow_schema_1.WorkflowNode);
