import React, { useMemo } from 'react';
import type { ConnectionLineComponentProps } from 'types-shared/reactflow';
import { getBezierPath } from 'types-shared/reactflow';

export function Connector({
  fromX,
  fromY,
  toX,
  toY,
  fromPosition,
  toPosition,
}: ConnectionLineComponentProps) {
  const [edgePath] = useMemo(
    () =>
      getBezierPath({
        sourceX: fromX,
        sourceY: fromY,
        sourcePosition: fromPosition,
        targetX: toX,
        targetY: toY,
        targetPosition: toPosition,
      }),
    [fromX, fromY, toX, toY, fromPosition, toPosition],
  );

  return (
    <g>
      <path
        className="animated"
        d={edgePath}
        fill="none"
        stroke="#222"
        strokeWidth={10}
      />
      <circle
        cx={toX}
        cy={toY}
        fill="#fff"
        r={3}
        stroke="#222"
        strokeWidth={1.5}
      />
    </g>
  );
}
