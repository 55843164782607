import { useGetParagonUserToken, useParagonAuth } from './hooks';
import {
  ContentContainer,
  Flex,
  ScrollableContainer,
  Spinner,
  CustomTypography,
} from 'ui-kit';
import { NavigationPanel } from '../../components/NavigationPanel';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeatureFlag } from '../../utils/constants';
import IntegrationTile from './components/IntegrationTile';
import ComingSoonIntegrationTile from './components/ComingSoonIntegrationTile';
import { paragon } from '@useparagon/connect';
import { useFeatureFlag } from '../../utils/helper';

const handleOnClickIntegration = (integrationType: string) => {
  const options = ['gmail', 'googledrive'].includes(integrationType)
    ? { overrideRedirectUrl: `${window.location.origin}/paragon` }
    : {};
  paragon.connect(integrationType, options);
};

export default function Integrations() {
  const navigate = useNavigate();
  const integrationsPageEnabled = useFeatureFlag(FeatureFlag.IntegrationsPage);
  const { data, isLoading } = useGetParagonUserToken();
  const { user, error } = useParagonAuth(data?.token);

  const integrationTiles = useMemo(() => {
    if (!user || error) return null;
    return paragon.getIntegrationMetadata().map((integration) => (
      <IntegrationTile
        iconUrl={integration.icon}
        isConnected={user.integrations[integration.type]?.enabled}
        key={integration.type}
        onClick={() => {
          handleOnClickIntegration(integration.type);
        }}
        platform={integration.name}
      />
    ));
  }, [user, error]);

  useEffect(() => {
    if (integrationsPageEnabled === false) {
      navigate('/');
    }
  }, [integrationsPageEnabled, navigate]);

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spinner className="!text-black" size={32} />
      </div>
    );
  }

  return (
    <ScrollableContainer>
      <div className="w-full h-full flex">
        <NavigationPanel />
        <ContentContainer>
          <div className="flex justify-between w-full items-center">
            <div>
              <CustomTypography variant="h4">Integrations</CustomTypography>
              <div className="mt-2">
                <span className="font-normal text-sm">
                  Configure external platforms and connect them with your
                  workflows.
                </span>
              </div>
            </div>
          </div>
          <div className="mt-11">
            <Flex className="gap-4 flex-wrap">
              {integrationTiles}
              <ComingSoonIntegrationTile />
            </Flex>
          </div>
        </ContentContainer>
      </div>
    </ScrollableContainer>
  );
}
