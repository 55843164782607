import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function MailIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      width="50"
      height="40"
      viewBox="0 0 50 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5L20.2691 16.5307L20.2742 16.5349C21.9696 17.7782 22.8178 18.4002 23.7469 18.6405C24.5681 18.8529 25.4313 18.8529 26.2524 18.6405C27.1823 18.4 28.033 17.7762 29.7314 16.5307C29.7314 16.5307 39.5252 9.01485 45 5M2.5 29.5005V10.5005C2.5 7.70023 2.5 6.29905 3.04497 5.22949C3.52433 4.28868 4.28868 3.52433 5.22949 3.04497C6.29905 2.5 7.70023 2.5 10.5005 2.5H39.5005C42.3008 2.5 43.6989 2.5 44.7685 3.04497C45.7093 3.52433 46.4762 4.28868 46.9556 5.22949C47.5 6.298 47.5 7.69749 47.5 10.4923V29.5089C47.5 32.3037 47.5 33.7012 46.9556 34.7697C46.4762 35.7105 45.7093 36.4762 44.7685 36.9556C43.7 37.5 42.3025 37.5 39.5077 37.5H10.4923C7.69749 37.5 6.298 37.5 5.22949 36.9556C4.28868 36.4762 3.52433 35.7105 3.04497 34.7697C2.5 33.7001 2.5 32.3008 2.5 29.5005Z"
        // stroke="#103D61"
        fill="none"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
