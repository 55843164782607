import type { RawCoordinates, RelativeCoordinates } from 'types-shared';
import CryptoJS from 'crypto-js';

export const createThumbnail = async (blob: Blob) => {
  const img = await createImageBitmap(blob);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to get 2d context');
  }

  // Crop and resize the image in one step
  const resizeWidth = img.width / 3;
  const resizeHeight = img.width / 6;
  canvas.width = resizeWidth;
  canvas.height = resizeHeight;
  ctx.drawImage(
    img,
    0,
    0,
    img.width,
    img.width / 2,
    0,
    0,
    resizeWidth,
    resizeHeight,
  );

  // Convert canvas to Blob
  const processedImageBlob = await new Promise((resolve) => {
    canvas.toBlob(resolve);
  });
  return processedImageBlob as Blob;
};

export const calculateMd5 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(blob);
    reader.onloadend = () => {
      const hash = CryptoJS.MD5(reader.result as string).toString();
      resolve(hash);
    };
    reader.onerror = (ev: ProgressEvent<FileReader>) => {
      reject(ev.target?.error as Error);
    };
  });
};

// Function to calculate relative coordinates
export const calculateRelativeCoordinates = (
  parentDimensions: { width: number; height: number },
  elementDimensions: RawCoordinates,
): RelativeCoordinates => {
  const { width: parentWidth, height: parentHeight } = parentDimensions;
  const { x, y, width, height } = elementDimensions;

  const xPercent = (x / parentWidth) * 100;
  const yPercent = (y / parentHeight) * 100;

  const widthPercent = (width / parentWidth) * 100;
  const heightPercent = (height / parentHeight) * 100;
  return {
    xPercent: isFinite(xPercent) ? xPercent : 0,
    yPercent: isFinite(yPercent) ? yPercent : 0,
    widthPercent: isFinite(widthPercent) ? widthPercent : 0,
    heightPercent: isFinite(heightPercent) ? heightPercent : 0,
  };
};
