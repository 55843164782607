import React, { useEffect, useState, useRef, useCallback } from 'react';
import type { ReactNode } from 'react';
import type { EventMap } from '../../utils/eventBus';
import createEventBus from '../../utils/eventBus';
import Alert, { AlertVariant, type Props } from './index';
import { useEnvironment } from '../../contexts/environment';
import { clsx } from 'clsx';
import { v4 as uuid } from 'uuid';

type AlertProps = Omit<Props, 'children'> & {
  message: string | ReactNode;
  variant: AlertVariant;
  debug?: boolean;
};

type AlertPropsWId = AlertProps & { id: string };

interface AlertEvents extends EventMap {
  open: (props: AlertPropsWId) => void;
  close: (id: string) => void;
}

const alertEventChannel = createEventBus<AlertEvents>();

export const notify = (props: AlertProps): void => {
  alertEventChannel.emit('open', { ...props, id: uuid() });
};

export const controlledNotify = (
  props: AlertProps,
): { open: () => void; close: () => void } => {
  const alertID = uuid();

  return {
    open: () => {
      alertEventChannel.emit('open', {
        ...props,
        id: alertID,
        doNotTimeout: true,
      });
    },
    close: () => {
      alertEventChannel.emit('close', alertID);
    },
  };
};

export default function AlertWrapper({ className }: { className?: string }) {
  const { selectedEnv } = useEnvironment();
  const [alerts, setAlerts] = useState<AlertPropsWId[]>([]);
  const timeouts = useRef<Record<string, ReturnType<typeof setTimeout>>>({});

  const showNotifications = (props: AlertPropsWId) =>
    !props.debug || selectedEnv !== 'production';

  const handleClose = useCallback((id: string) => {
    clearTimeout(timeouts.current[id]);
    // eslint-disable-next-line
    delete timeouts.current[id];
    setAlerts((prev) => prev.filter((alert) => alert.id !== id));
  }, []);

  const handleOpen = useCallback(
    (options: AlertPropsWId) => {
      const timeoutDuration =
        options.timeoutInMs ??
        (options.variant !== AlertVariant.SUCCESS ? 8000 : 5000);

      setAlerts((prev) => [
        ...prev,
        { ...options, timeoutInMs: timeoutDuration },
      ]);

      if (options.doNotTimeout) return;

      const timeoutId = setTimeout(() => {
        handleClose(options.id);
      }, timeoutDuration);

      timeouts.current[options.id] = timeoutId;
    },
    [handleClose],
  );

  useEffect(() => {
    const unsubscribeOpen = alertEventChannel.on('open', handleOpen);
    const unsubscribeClose = alertEventChannel.on('close', handleClose);

    return () => {
      unsubscribeOpen();
      unsubscribeClose();
    };
  }, [handleOpen, handleClose]);

  return (
    <div className="fixed top-8 left-[50%] -translate-x-1/2 z-[99999] space-y-4">
      {alerts.filter(showNotifications).map((alert) => (
        <Alert
          className={clsx(
            'shadow-2xl max-w-max mx-auto !whitespace-pre-wrap',
            className,
          )}
          key={alert.id}
          onClose={() => {
            handleClose(alert.id);
          }}
          open
          doNotTimeout={alert.doNotTimeout}
          {...alert}
        >
          {alert.message}
        </Alert>
      ))}
    </div>
  );
}
