import {
  DocumentSourceEnum,
  VariableTypeEnum,
  type DocumentVariable,
  type SelectVariable,
  TemplateVariable,
  type Variable,
  type VariableRef,
  type TabVariable,
  ActionsEnum,
  type Target,
  ActionValueCriteriaEnum,
  type MultiSelectVariable,
  type GlobalVariable,
  type VariableMap,
  type WorkflowAction,
  CredentialVariableName,
} from 'types-shared';
import { Chip, CloseFullscreenIcon, ListIcon, MessageIcon } from 'ui-kit';
import { MultiChoicePreview } from '../../VariableTypes/MultipleChoice';
import { clsx } from 'clsx';
import { MultiSelectPreview } from '../../VariableTypes/MultiSelect';
import { VariableChip } from '../../../../../components/VariableChip';
import { pickFromListOptions } from '../../../utils/helper';
import { handleException } from 'sentry-browser-shared';
import { openPreviewVariableModal } from '../../../../../utils/helper';

type VariableData = string | VariableRef;
interface VariableInputsProps {
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  label?: string;
  dataLike: unknown;
  className?: string;
}

export function TemplateDataPreview({
  dataLike,
  label,
  variablesMap,
  globalVariablesMap,
  className,
}: VariableInputsProps) {
  const onPreviewVariable = (variableID: string) => {
    openPreviewVariableModal({
      variableId: variableID,
    });
  };

  if (Array.isArray(dataLike as unknown[])) {
    return (
      <div
        className={clsx(
          'leading-7 max-h-40 overflow-auto flex-1 no-scrollbar flex flex-row items-center gap-1',
          className,
        )}
      >
        {(dataLike as VariableData[])
          .filter((val) => val !== '')
          .map((val: VariableData) => {
            if (typeof val === 'string') {
              return (
                <span key={val} className="whitespace-pre">
                  {label === 'Password' ? val.replace(/\w/g, '*') : val}
                </span>
              );
            }

            return (
              <VariableChip
                className="whitespace-pre "
                key={val.id}
                variableId={val.id}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                onClick={(e) => {
                  e.stopPropagation();
                  onPreviewVariable(val.id);
                }}
              />
            );
          })}
      </div>
    );
  }

  const val = dataLike as { datasourceId: string; key: string };

  return (
    <Chip
      className="!my-auto"
      color="secondary"
      key={val.key}
      label={<span className="!normal-case">{val.key}</span>}
      size="small"
    />
  );
}

const pickFromListTitleMapping = {
  [pickFromListOptions[0]]: pickFromListOptions[0],
  [pickFromListOptions[1]]: pickFromListOptions[1],
};

const pickFromListIconMapping = {
  [pickFromListOptions[0]]: ListIcon,
  [pickFromListOptions[1]]: CloseFullscreenIcon,
};

interface ActionRendererProps {
  actionType: ActionsEnum;
  criteria?: ActionValueCriteriaEnum;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  label: string | undefined;
  variable: Variable | null;
  target: Target;
  action?: WorkflowAction;
}

export function ActionRenderer({
  actionType,
  criteria,
  label,
  variablesMap,
  globalVariablesMap,
  variable,
  target,
  action,
}: ActionRendererProps) {
  if (!variable) {
    handleException(new Error('Variable not found'), {
      name: 'Variable not found in ActionRenderer',
      source: 'Action/ActionRenderer',
      extra: {
        action,
        variablesMap,
        globalVariablesMap,
      },
    });
    return null;
  }

  const onPreviewVariable = (variableID: string) => {
    openPreviewVariableModal({
      variableId: variableID,
    });
  };

  const isDocumentScrape =
    variable.type === VariableTypeEnum.Document &&
    variable.data.source === DocumentSourceEnum.Execution;

  const isTemplateVariable = TemplateVariable.safeParse(variable).success;
  const isCredential = variable.name === CredentialVariableName;

  if (actionType === ActionsEnum.PickFromList) {
    const value = (variable as TemplateVariable).data[0] as string;
    const Icon = pickFromListIconMapping[value] ?? MessageIcon;
    return (
      <Chip
        className="my-auto ml-3"
        color="primary"
        label={
          <div className="flex items-center">
            {pickFromListTitleMapping[value] ?? 'Defined with instructions'}
            <Icon className="!w-4 !h-4 !ml-1.5" />
          </div>
        }
        size="small"
      />
    );
  }

  if (
    actionType === ActionsEnum.MultiSelect &&
    criteria === ActionValueCriteriaEnum.Variable
  ) {
    return (
      <TemplateDataPreview
        dataLike={(variable as MultiSelectVariable).data}
        label={label}
        variablesMap={variablesMap}
        globalVariablesMap={globalVariablesMap}
        className="p-3"
      />
    );
  }

  return (
    <>
      {variable.type === VariableTypeEnum.Document || isDocumentScrape ? (
        <>
          {variable.data.source === DocumentSourceEnum.AWS ? (
            <div className="!ml-3 flex items-center min-w-0">
              <span className="!normal-case overflow-hidden whitespace-nowrap overflow-ellipsis">
                {variable.data.s3Ref?.fileName}
              </span>
            </div>
          ) : null}
          {variable.data.source === DocumentSourceEnum.Execution ||
          isDocumentScrape ? (
            <div className="!ml-3 !my-auto">
              <VariableChip
                variableId={variable.id}
                variablesMap={variablesMap}
                globalVariablesMap={globalVariablesMap}
                onClick={(e) => {
                  e.stopPropagation();
                  onPreviewVariable(variable.id);
                }}
              />
            </div>
          ) : null}

          {!(variable.data.source === DocumentSourceEnum.AWS) &&
          !(variable.data.source === DocumentSourceEnum.Execution) ? (
            <TemplateDataPreview
              dataLike={(variable as DocumentVariable).data}
              label={label}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              className="p-3"
            />
          ) : null}
        </>
      ) : null}
      {variable.type === VariableTypeEnum.Scrape ? (
        <div className="!ml-3 !my-auto">
          <VariableChip
            variableId={variable.id}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            onClick={(e) => {
              e.stopPropagation();
              onPreviewVariable(variable.id);
            }}
          />
        </div>
      ) : null}
      {variable.type === VariableTypeEnum.Global ? (
        <div className="!ml-3 !my-auto">
          <VariableChip
            variableId={variable.id}
            variablesMap={variablesMap}
            globalVariablesMap={globalVariablesMap}
            onClick={(e) => {
              e.stopPropagation();
              onPreviewVariable(variable.id);
            }}
          />
        </div>
      ) : null}
      {variable.type === VariableTypeEnum.Tab ? (
        <TemplateDataPreview
          dataLike={[variable as TabVariable]}
          label={label}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          className="p-3"
        />
      ) : null}
      {isTemplateVariable ? (
        <TemplateDataPreview
          dataLike={
            isCredential ? ['********'] : (variable as TemplateVariable).data
          }
          label={label}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          className="p-3"
        />
      ) : null}
      {variable.type === VariableTypeEnum.Select ? (
        <TemplateDataPreview
          dataLike={(variable as SelectVariable).data}
          label={label}
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
          className="p-3"
        />
      ) : null}
      {variable.type === VariableTypeEnum.MultiChoice ? (
        <MultiChoicePreview variable={variable} />
      ) : null}
      {variable.type === VariableTypeEnum.MultiSelect ? (
        <MultiSelectPreview variable={variable} target={target} />
      ) : null}
    </>
  );
}
