import { Checkbox, CheckCircle, RadioButtonUnchecked } from 'ui-kit';
import { NodeStatusEnum } from 'types-shared';
import React from 'react';
import { clsx } from 'clsx';

interface Props {
  isChecked: boolean;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  description?: string;
}

export function NodeCheck({
  description = 'If all actions are configured, mark as reviewed!',
  isChecked,
  updateNodeStatus,
}: Props) {
  const toggleCheckbox = () => {
    updateNodeStatus(
      isChecked ? NodeStatusEnum.NotViewed : NodeStatusEnum.Checked,
    );
  };
  return (
    <div
      className={clsx(
        'node-check border border-info p-4 pr-1 rounded-lg flex justify-between items-center ring-8 ring-transparent space-x-4 cursor-pointer',
        {
          'border-checked-green ring-primary-light-green': isChecked,
        },
      )}
      onClick={toggleCheckbox}
      role="presentation"
    >
      <div className="flex flex-col space-y-0.5 text-sm">
        <span className="font-medium">Ready to Execute</span>
        <span className="text-gray-500">{description}</span>
      </div>
      <Checkbox
        checked={isChecked}
        checkedIcon={<CheckCircle />}
        color="secondary"
        icon={<RadioButtonUnchecked className="!text-info" />}
        onChange={toggleCheckbox}
      />
    </div>
  );
}
