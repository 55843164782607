import { useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import {
  type GridColDef,
  type GridRenderCellParams,
} from '@mui/x-data-grid/models';
import {
  Button,
  DataLoader,
  IconButton,
  DataCopyIcon,
  CustomDownloadIcon,
  Modal,
} from 'ui-kit';
import Description, { type OutputItem, OutputItemActions } from './Description';
import {
  copyToClipboard,
  stringifyWithDynamicIndent,
} from '../../../../utils/helper';
import { NoDataFound } from '../../../../components/NoDataFound';
import { isJson } from '../../utils';

interface OutputsTableProps {
  loading: boolean;
  isOutput?: boolean;
  outputItems: OutputItem[];
}

export default function OutputsTab({
  outputItems,
  loading,
  isOutput = true,
}: OutputsTableProps) {
  const [previewString, setPreviewString] = useState<string | undefined>('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const columns: GridColDef<OutputItem>[] = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Name',
        flex: 2,
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 2,
      },
      {
        field: 'description',
        headerName: 'Value',
        renderCell: (params: GridRenderCellParams<OutputItem>) => (
          <Description
            isOutput={isOutput}
            outputItem={params.row}
            setPreviewString={setPreviewString}
            setPreviewTitle={setPreviewTitle}
            isJson={
              typeof params.row.description === 'string' &&
              isJson(params.row.description)
            }
          />
        ),
        flex: 4,
      },

      {
        field: ' ',
        flex: 2,
        renderCell: (params: GridRenderCellParams<OutputItem>) => {
          const hasNoDescription =
            typeof params.row.description === 'undefined' ||
            (typeof params.row.description === 'string' &&
              !params.row.description.length);

          return params.row.action === OutputItemActions.COPY ? (
            <IconButton
              className="!p-0"
              disabled={hasNoDescription}
              onClick={() => {
                const description = params.row.description;

                const contentIsJson =
                  typeof params.row.description === 'string' &&
                  isJson(params.row.description);

                let text = '';

                if (contentIsJson) {
                  text = stringifyWithDynamicIndent(
                    typeof description === 'string'
                      ? JSON.parse(description)
                      : description,
                  );
                } else if (typeof description === 'string') {
                  text = description;
                }

                copyToClipboard(text);
              }}
            >
              <DataCopyIcon
                className={
                  hasNoDescription ? 'text-gray-500' : 'text-secondary-blue'
                }
              />
            </IconButton>
          ) : (
            <IconButton
              className="!p-0"
              onClick={() => {
                if (params.row.uri) {
                  params.row.onDownloadLinkData?.(params.row.uri);
                }
              }}
            >
              <CustomDownloadIcon
                className="text-secondary-blue"
                fontSize="small"
              />
            </IconButton>
          );
        },
      },
    ],
    [isOutput],
  );

  return loading ? (
    <DataLoader />
  ) : (
    <div>
      {outputItems.length ? (
        <DataGrid
          columns={columns}
          getRowId={(row) => row.id}
          getRowHeight={() => 'auto'}
          rows={outputItems}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal !important',
            },
          }}
        />
      ) : (
        <NoDataFound
          heading={isOutput ? 'No outputs found' : 'No inputs found'}
          subHeading={
            isOutput
              ? 'If your execution has any outputs, they will show up here.'
              : 'Once you add API variables to your workflow, you will see the values in this tab.'
          }
        />
      )}

      <PreviewModal
        isOutput={isOutput}
        title={previewTitle}
        open={Boolean(previewString)}
        previewText={previewString}
        onClose={() => {
          setPreviewString('');
          setPreviewTitle('');
        }}
      />
    </div>
  );
}

function PreviewModal({
  previewText,
  title,
  onClose,
  open,
  isOutput = true,
}: {
  previewText: string | undefined;
  title: string;
  onClose: () => void;
  open: boolean;
  isOutput?: boolean;
}) {
  const { previewTextClasses, contentIsJson, previewTextContent } =
    useMemo(() => {
      const payload = {
        contentIsJson: false,
        previewTextClasses: '',
        previewTextContent: previewText,
      };
      if (!previewText) return payload;
      if (isJson(previewText)) {
        payload.previewTextClasses =
          'bg-[#eaf1f3] p-8 rounded-lg text-info-dark whitespace-pre-wrap break-words relative mb-12';
        payload.contentIsJson = true;
        payload.previewTextContent = stringifyWithDynamicIndent(
          JSON.parse(previewText),
        );
      } else {
        payload.previewTextClasses = 'text-gray-500 break-word-span';
      }
      return payload;
    }, [previewText]);

  return (
    <Modal
      className="max-h-[80vh] !max-w-[42rem]"
      containerClassName="flex flex-col space-y-3"
      open={open}
      onClose={onClose}
      borderOnCloseIcon={false}
    >
      <p className="text-info-dark text-xl font-medium leading-9 tracking-tight">
        {isOutput ? 'Output' : 'Input'} preview
      </p>
      <p className="text-info-dark text-lg font-medium leading-9 tracking-tight">
        {title}
      </p>

      <div>
        <p className={previewTextClasses}>
          {contentIsJson ? (
            <div className="absolute top-4 right-4">
              <Button
                startIcon={<DataCopyIcon />}
                color="secondary"
                disabled={!previewText}
                onClick={() => {
                  if (previewText) {
                    const copyContent = stringifyWithDynamicIndent(
                      JSON.parse(previewText),
                    );

                    copyToClipboard(copyContent);
                  }

                  onClose();
                }}
                variant="outlined"
              >
                COPY
              </Button>
            </div>
          ) : null}
          {previewTextContent ? (
            <span
              dangerouslySetInnerHTML={{
                __html: previewTextContent.replaceAll('\n', '<br/>'),
              }}
            />
          ) : (
            'No value provided'
          )}
        </p>
      </div>

      {!contentIsJson ? (
        <div className="!mt-5 flex space-x-4 pb-10">
          <Button
            startIcon={<DataCopyIcon className="text-white" />}
            color="secondary"
            disabled={!previewText}
            onClick={() => {
              if (previewText) {
                copyToClipboard(previewText);
              }

              onClose();
            }}
            variant="contained"
          >
            COPY {isOutput ? 'OUTPUT' : 'INPUT'}
          </Button>
        </div>
      ) : null}
    </Modal>
  );
}
