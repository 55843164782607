import Files from '@mui/icons-material/FileUploadSharp';
import { clsx } from 'clsx';
import noop from 'lodash/noop';
import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { AlertVariant } from './Alert';
import { notify } from './Alert/Wrapper';

interface Props {
  className?: string;
  count?: number;
  formats?: string[];
  onFileSelect: (files: File[]) => void;
  allowClickToUpload?: boolean;
  children: ReactNode;
}

export default function FilesDragDrop({
  className,
  count,
  formats,
  onFileSelect,
  allowClickToUpload,
  children,
}: Props) {
  const [dragging, setDragging] = useState(false);

  const drag = useRef<HTMLDivElement>(null);
  const drop = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleUpload = useCallback(
    (files: File[]) => {
      if (count && count < files.length) {
        notify({
          message: `You cannot select more than ${count.toString()} files`,
          variant: AlertVariant.ERROR,
        });

        return;
      }

      if (
        formats &&
        files.some(
          (file: File) =>
            !formats.some((format) =>
              file.name.toLowerCase().endsWith(format.toLowerCase()),
            ),
        )
      ) {
        notify({
          message: `Only files of type ${formats.join(', ')} are allowed`,
          variant: AlertVariant.ERROR,
        });

        return;
      }

      if (files.length > 0) {
        onFileSelect(files);
      }
    },
    [count, formats, onFileSelect],
  );

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setDragging(false);

      const files = Array.from(e.dataTransfer?.files ?? []);

      handleUpload(files);
    },
    [handleUpload],
  );

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== drag.current) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === drag.current) {
      setDragging(false);
    }
  };

  const handleSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files ?? []);

    handleUpload(files);
  };

  const openFileDialog = () => {
    input.current?.click();
  };

  useEffect(() => {
    const dropElement = drop.current;
    dropElement?.addEventListener('dragover', handleDragOver);
    dropElement?.addEventListener('drop', handleDrop);
    dropElement?.addEventListener('dragenter', handleDragEnter);
    dropElement?.addEventListener('dragleave', handleDragLeave);

    return () => {
      dropElement?.removeEventListener('dragover', handleDragOver);
      dropElement?.removeEventListener('drop', handleDrop);
      dropElement?.removeEventListener('dragenter', handleDragEnter);
      dropElement?.removeEventListener('dragleave', handleDragLeave);
    };
  }, [handleDrop]);

  return (
    <div
      className={clsx(
        'relative',
        { 'cursor-pointer': !dragging && allowClickToUpload },
        className,
      )}
      onClick={allowClickToUpload ? openFileDialog : noop}
      ref={drop}
      role="presentation"
    >
      <input
        accept={
          formats ? formats.map((format) => `.${format}`).join(', ') : undefined
        }
        className="hidden"
        disabled={dragging}
        multiple={!count || count > 1}
        onChange={handleSelectFiles}
        ref={input}
        type="file"
      />
      {dragging ? (
        <div className="absolute w-full h-full top-0 left-0 bg-gray-200 opacity-95 z-[2] flex flex-col justify-center items-center space-y-3">
          <Files className="!w-10 !h-10" />
          <span className="text-xl font-semibold">Drop files here</span>
        </div>
      ) : null}
      {children}
    </div>
  );
}
