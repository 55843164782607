import {
  useCallback,
  useRef,
  useState,
  type MouseEvent,
  type ChangeEvent,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  type GoogleDriveFileMetadata,
  NodeTypesEnum,
  type WorkflowNode,
  type DatasourceMetadata,
} from 'types-shared';
import {
  useFetchGoogleSheets,
  useUploadCsvFile,
  useUploadGoogleSheet,
  useFetchDatasourceTable,
} from '../hooks';
import { useFetchWorkflowMetadata } from '../../Workflows/hooks';
import { EditorStore } from '../../Editor/store/EditorState';
import { formatFileSize } from '../../../utils/helper';
import { DatasourcePreviewTable } from '../components/DatasourcePreviewTable';
import {
  AlertVariant,
  Button,
  IconButton,
  Input,
  notify,
  Select,
  Tab,
  Tabs,
  ArrowLeftIcon,
  Clear,
  FilePlaceholderIcon,
  Logo,
} from 'ui-kit';
import Box from '@mui/material/Box';
import { type SelectChangeEvent } from '@mui/material/Select';
import { UploadUrlContentTypeEnum } from 'api-types-shared';
import { DATASOURCE_FILE_MAX_SIZE } from '../../../utils/constants';

interface DatasourceFormData {
  workflowId: string;
  name?: string | undefined;
  description?: string | undefined;
}

enum DataUploadEnum {
  Local = 'Local',
  Remote = 'Remote',
}

export default function Datasource(): JSX.Element {
  const navigate = useNavigate();
  const { workflowId } = useParams<{ workflowId: string }>();

  if (!workflowId) {
    throw new Error('workflowId not found!');
  }

  const { data: workflowMetadata } = useFetchWorkflowMetadata(workflowId);
  const { data: googleSheetsMeta } = useFetchGoogleSheets();
  const { mutateAsync: uploadGoogleSheet } = useUploadGoogleSheet();
  const { mutateAsync: uploadCsvFile } = useUploadCsvFile();

  const [pendingDatasourceMeta, setPendingDatasourceMeta] =
    useState<DatasourceMetadata | null>(null);

  const { nodes, updateNode, setDatasourceMetadata, setDatasourceTable } =
    EditorStore();

  const { data: tableData, isFetching } = useFetchDatasourceTable(
    pendingDatasourceMeta?.datasourceId,
    true,
  );

  const [selectedTab, setSelectedTab] = useState(
    DataUploadEnum.Local.toString(),
  );

  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [selectedSheetId, setSelectedSheetId] = useState<string>();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [datasourceFormData, setDatasourceFormData] =
    useState<DatasourceFormData>({
      workflowId,
    });

  const uploadData = async () => {
    const { name, description } = datasourceFormData;
    if (!name) {
      notify({
        variant: AlertVariant.ERROR,
        message: 'Please provide a name for your datasource',
      });
      return;
    }

    if (!description) {
      notify({
        variant: AlertVariant.ERROR,
        message: 'Please provide a description for your datasource',
      });
      return;
    }

    setIsUploading(true);
    if (file) {
      const datasourceMeta = await uploadCsvFile({
        file,
        name,
        description,
        workflowId,
      });
      setPendingDatasourceMeta(datasourceMeta);
    } else if (selectedSheetId) {
      const { meta } = await uploadGoogleSheet({
        sheetId: selectedSheetId,
        name,
        description,
        workflowId,
      });
      setPendingDatasourceMeta(meta);
    } else {
      throw Error('No file or sheet id was selected');
    }
    setIsUploading(false);
  };

  const onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (selectedFile.size <= DATASOURCE_FILE_MAX_SIZE) {
        setFile(selectedFile);
      } else {
        notify({
          message: 'File size should be less than 10 MB',
          variant: AlertVariant.ERROR,
        });
      }
    }
  };

  const onDatasourceSubmit = useCallback(() => {
    if (!pendingDatasourceMeta) {
      throw new Error('No datasource was created');
    }

    const datasourceNode = nodes.find(
      (node) => node.type === NodeTypesEnum.Source,
    );
    if (!datasourceNode) {
      throw new Error('No datasource step found');
    }

    const updatedDSNode: WorkflowNode = {
      ...datasourceNode,
      data: {
        ...datasourceNode.data,
      },
    };

    updateNode(updatedDSNode);
    setDatasourceMetadata(pendingDatasourceMeta);
    navigate(`/editor/${workflowId}`);
  }, [
    navigate,
    nodes,
    pendingDatasourceMeta,
    setDatasourceMetadata,
    updateNode,
    workflowId,
  ]);

  return (
    <div className="h-full w-full relative">
      <div className="flex flex-col px-6 py-5 w-max min-w-[32rem]">
        <input
          accept={UploadUrlContentTypeEnum.CSV}
          hidden
          onChange={onFileSelect}
          onClick={(e: MouseEvent<HTMLInputElement>) => {
            e.currentTarget.value = '';
          }}
          ref={inputRef}
          type="file"
        />
        <div className="flex items-center space-x-6 h-10">
          <Logo className="!w-7 !h-7" />
          <div className="flex items-center space-x-6">
            <IconButton
              className="!border !border-solid !border-info !rounded-lg !p-0"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowLeftIcon className="text-info !h-10 !w-10" />
            </IconButton>
            <div className="flex flex-col text-xs">
              <span className="text-gray-500">
                {workflowMetadata?.workflowName}
              </span>
              <span className="text-info font-medium">Datasource</span>
            </div>
          </div>
        </div>
        <div className="pl-2">
          <h2 className="mt-12 text-3xl font-semibold">Add new datasource</h2>
          <Input
            classes={{ wrapper: 'mt-8' }}
            label="Name"
            onChange={(val: string) => {
              setDatasourceFormData((data) => ({
                ...data,
                name: val,
              }));
            }}
            value={datasourceFormData.name}
          />
          <Input
            classes={{ wrapper: 'mt-4' }}
            label="Description"
            onChange={(val: string) => {
              setDatasourceFormData((data) => ({
                ...data,
                description: val,
              }));
            }}
            value={datasourceFormData.description}
          />
          <Box className="mt-4">
            <Tabs
              onChange={(_, tab) => {
                setSelectedTab(tab as string);
              }}
              value={selectedTab}
              variant="fullWidth"
            >
              <Tab label="Local Datasource" value={DataUploadEnum.Local} />
              <Tab label="External Datasource" value={DataUploadEnum.Remote} />
            </Tabs>
            {selectedTab === DataUploadEnum.Local.toString() && (
              <>
                <p className="mt-2 text-gray-500 font-medium text-sm">
                  Upload a spreadsheet from your files.
                  <br />
                  <b>Supported formats:</b> CSV
                  <br />
                  <b>Maximum size:</b> 10mb
                </p>
                {file ? (
                  <div className="mt-3 p-4 border rounded flex items-start space-x-2 w-full">
                    <FilePlaceholderIcon className="!w-6 !h-6" />
                    <div className="flex flex-col text-sm">
                      <b className="text-sm">{file.name}</b>
                      <span className="text-gray-500">
                        {formatFileSize(file.size)}
                      </span>
                    </div>
                    <IconButton
                      className="!ml-auto !-mr-3 !my-auto"
                      onClick={() => {
                        setFile(null);
                      }}
                    >
                      <Clear className="!w-5 !h-5 !text-black" />
                    </IconButton>
                  </div>
                ) : (
                  <Button
                    className="!mt-8"
                    color="secondary"
                    onClick={() => {
                      inputRef.current?.click();
                    }}
                    variant="outlined"
                  >
                    Upload File
                  </Button>
                )}
              </>
            )}
            {selectedTab === DataUploadEnum.Remote.toString() && (
              <>
                <p className="mt-2 text-gray-500 font-medium text-sm">
                  Upload a spreadsheet from Google Sheets.
                </p>
                <Select
                  className="mt-2 w-full"
                  disabled={!googleSheetsMeta?.length}
                  getLabel={(meta: GoogleDriveFileMetadata): string =>
                    meta.name
                  }
                  getValue={(meta: GoogleDriveFileMetadata): string => meta.id}
                  label="Select Google Sheet"
                  onChange={(e) => {
                    const event = e as SelectChangeEvent;
                    setSelectedSheetId(event.target.value);
                  }}
                  options={googleSheetsMeta ?? []}
                />
              </>
            )}
          </Box>
          <div className="flex items-center space-x-4 mt-14">
            <Button
              className="!flex-1"
              color="secondary"
              disabled={!(file ?? selectedSheetId)}
              loading={isUploading}
              onClick={uploadData}
              variant="contained"
            >
              Preview datasource
            </Button>
            <Button
              className="!flex-1"
              color="secondary"
              onClick={() => {
                navigate(`/editor/${workflowId}`);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
      {pendingDatasourceMeta ? (
        <div className="absolute left-0 top-0 h-full w-full bg-white">
          <DatasourcePreviewTable
            fileName={datasourceFormData.name}
            isFetching={isFetching}
            onBack={() => {
              setPendingDatasourceMeta(null);
            }}
            onSubmit={onDatasourceSubmit}
            previewMode
            setDatasourceTable={setDatasourceTable}
            tableData={tableData}
            workflowId={workflowId}
            workflowMetadata={workflowMetadata}
          />
        </div>
      ) : null}
    </div>
  );
}
