import { Modal, Checkbox, FormControlLabel, Button } from 'ui-kit';

export default function DisclaimerModal({
  setOpen,
  open,
  hideInitialModalNextTime,
  hideInitialModalChkd,
  openHitl,
}: {
  setOpen: (newOpen: boolean) => void;
  open: boolean;
  hideInitialModalNextTime: (newValue: boolean) => void;
  hideInitialModalChkd: boolean;
  openHitl: () => void;
}) {
  return (
    <Modal
      className="flex item-center justify-items-center max-w-[40rem]"
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      enableEventBubbling
    >
      <div className="text-sm flex">
        <div className="flex-1 flex flex-col">
          <h2 className="font-medium text-xl mb-7 text-black">
            You are about to handle an action manually
          </h2>

          <p className="text-gray-600 mb-5">
            This will put the workflow in human-in-the-loop mode and give you
            control to handle this action manually. Be aware that any action you
            take from here will directly affect the run. If you encounter an
            error that you can't recover from, the run may fail.
          </p>

          <FormControlLabel
            control={
              <Checkbox
                checked={hideInitialModalChkd}
                color="secondary"
                onChange={(e, newChecked) => {
                  hideInitialModalNextTime(newChecked);
                }}
              />
            }
            label={
              <span className="text-info-dark text-sm font-medium">
                Don't show this again.
              </span>
            }
          />

          <div className="flex flex-row gap-7 mt-5">
            <Button color="secondary" onClick={openHitl} variant="contained">
              TAKE CONTROL FROM HERE
            </Button>
            <Button
              className="h-9"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
              variant="outlined"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
