import { clsx } from 'clsx';
import React from 'react';
import {
  ActionsEnum,
  type DocumentVariable,
  type GlobalVariable,
  type VariableMap,
  VariableTypeEnum,
  type WorkflowAction,
} from 'types-shared';
import { MoreVert, IconButton } from 'ui-kit';
import { type StepActions } from '../../../hooks/useWorkflowCurrentStepActions';
import HITLPrompt from './HITLModal/components/HITLPrompt';
import { getActionTitle } from '../../../utils';
import { isAdmin } from '../../../../../utils/env';
import { DocumentVariableCard } from './DocumentVariables/components/DocumentVariableCard';

const getActionFlags = (action: WorkflowAction) => {
  const flags: string[] = [];
  for (const [key, value] of Object.entries(action.options ?? {})) {
    if (value === true) {
      flags.push(key);
    }
  }

  return flags;
};

interface DefaultActionsProps {
  currentStepActions: StepActions;
  hasError: boolean;
  currentStepId?: string;
  showHITLPrompt: boolean;
  openHITLModal: () => void;
  signalLoading: boolean;
  variableMap: VariableMap;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  openDocumentVariablePreview: (chosenVariable: DocumentVariable) => void;
  setMenuProps: React.Dispatch<
    React.SetStateAction<
      | {
          el: HTMLButtonElement | null;
          actionId: string;
          isScrape: boolean;
        }
      | null
      | undefined
    >
  >;
}

export default function DefaultActions({
  currentStepActions,
  hasError,
  currentStepId,
  showHITLPrompt,
  openHITLModal,
  setMenuProps,
  signalLoading,
  variableMap,
  globalVariablesMap,
  openDocumentVariablePreview,
}: DefaultActionsProps) {
  return (
    <div className="flex flex-col space-y-4 p-9 overflow-y-auto">
      {currentStepActions.map(({ action, value, variableName }, i) => {
        const flags = getActionFlags(action);
        const flagStr =
          isAdmin && flags.length > 0 ? ` (${flags.join(', ')})` : null;
        const showError =
          hasError && currentStepId && action.id === currentStepId;

        const isCurrentAction = action.id === currentStepId;
        const shouldRenderHITLPrompt = isCurrentAction && showHITLPrompt;

        const _documentVariable =
          action.variableId &&
          variableMap[action.variableId].type === VariableTypeEnum.Document
            ? variableMap[action.variableId]
            : false;

        if (shouldRenderHITLPrompt) {
          return (
            <HITLPrompt
              key={action.id}
              variableName={variableName}
              action={action}
              actionIndex={i}
              openHITLModal={openHITLModal}
              signalLoading={signalLoading}
            />
          );
        }

        return (
          <div key={action.id}>
            <div className="flex items-center space-x-2">
              <span
                className={clsx('text-sm font-medium', {
                  'text-warning': action.id === currentStepId && isAdmin,
                })}
                key={action.id}
              >
                {showError ? (
                  <span className="text-error mr-1">
                    Error <sup>*</sup>
                  </span>
                ) : null}
                Action {i + 1} - {getActionTitle(action)}
                {variableName ? (
                  <span className="text-gray-600">&nbsp;({variableName})</span>
                ) : null}
                {flagStr}
                {showError ? (
                  <sup className="text-error font-bold ml-1">*</sup>
                ) : null}
              </span>

              {isAdmin ? (
                <IconButton
                  className="!p-0"
                  onClick={(e) => {
                    setMenuProps({
                      el: e.target as HTMLButtonElement,
                      actionId: action.id,
                      isScrape: action.actionType === ActionsEnum.Scrape,
                    });
                  }}
                >
                  <MoreVert className="!w-5 !h-5 text-black" />
                </IconButton>
              ) : null}
            </div>
            {isAdmin && typeof value === 'string' ? (
              <span className="block text-gray-500 break-all text-sm font-normal leading-normal">
                {value}
              </span>
            ) : null}

            {_documentVariable ? (
              <DocumentVariableCard
                key={action.id}
                globalVariablesMap={globalVariablesMap}
                variableMap={variableMap}
                onPreview={openDocumentVariablePreview}
                variable={_documentVariable as DocumentVariable}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
}
