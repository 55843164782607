import TextField from '@mui/material/TextField';
import type { TextFieldProps } from '@mui/material/TextField/TextField';
import { clsx } from 'clsx';
import noop from 'lodash/noop';
import type { ChangeEvent, ForwardedRef, ReactNode } from 'react';
import React, { forwardRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import type { Classes } from './types';
import Wrapper from './Wrapper';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

type Props = Omit<TextFieldProps, 'onChange' | 'className'> & {
  classes?: Classes;
  label?: string | ReactNode;
  onChange?: (val: string) => void;
  errorText?: string;
  showErrorText?: boolean;
  floatingLabel?: boolean;
  height?: string | number;
  isSearch?: boolean;
};
const StyledTextField = styled(TextField)(({ theme, value }) => {
  let valueExists = false;

  if (Array.isArray(value)) {
    const valueArr = value as string[];
    const isDefaultValue =
      valueArr.length === 1 &&
      (valueArr[0] === 'All' || valueArr[0] === 'Filter by status');
    valueExists = Boolean(valueArr.length && !isDefaultValue);
  }
  if (typeof value === 'string') {
    valueExists = Boolean(value.length);
  }

  return {
    '& .MuiOutlinedInput-root': {
      backgroundColor: valueExists ? '#f7f9ff' : '#ffffff',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      height: 'auto',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      userSelect: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      font: 'inherit',
      letterSpacing: 'inherit',
      animationDuration: '10ms',

      '&:hover fieldset': {
        borderColor: `${
          valueExists ? theme.palette.secondary.light : 'rgba(0, 0, 0, 0.23)'
        } !important`,
      },

      '&.Mui-focused fieldset': {
        borderColor: `${theme.palette.secondary.light} !important`,
      },

      '& fieldset': {
        borderColor: valueExists
          ? `${theme.palette.secondary.light} !important`
          : undefined,
      },
    },
  };
});

function Input(
  {
    classes,
    id,
    label,
    onChange = noop,
    required,
    error,
    errorText,
    showErrorText,
    floatingLabel,
    height,
    isSearch = false,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const textInputClasses = clsx(
    'custom-input',
    classes?.input,
    { 'has-error': error },
    { 'pointer-events-none': props.disabled },
  );

  return (
    <Wrapper
      className={classes?.wrapper}
      error={error}
      errorText={errorText}
      floatingLabel={floatingLabel}
      id={id}
      label={label}
      required={required}
      showErrorText={showErrorText}
    >
      <StyledTextField
        {...props}
        sx={{
          ...(height
            ? {
                '& .MuiOutlinedInput-root': {
                  maxHeight: height,
                  minHeight: height,
                },
              }
            : undefined),
        }}
        InputProps={
          isSearch
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon
                        color={props.value ? 'secondary' : 'disabled'}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}
        }
        className={textInputClasses}
        classes={{ root: classes?.root }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value);
        }}
        ref={ref}
        tabIndex={-1}
      />
    </Wrapper>
  );
}

export default forwardRef(Input);
