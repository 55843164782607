import { useState, useMemo } from 'react';
import {
  BranchModeEnum,
  type WorkflowConditionalNode,
  type WorkflowNode,
  type WorkflowEdge,
} from 'types-shared/workflowTypes';
import { SignalTypeEnum } from 'api-types-shared';
import { Button, Select, notify, AlertVariant } from 'ui-kit';

interface BranchDeciderProps {
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  onResume: (signal: SignalTypeEnum, payload?: object) => () => void;
  isLoading: boolean | undefined;
  signalNotAllowed: boolean;
  executionUpdateStatus?: 'error' | 'idle' | 'pending' | 'success';
}

function BranchDecider({
  nodes,
  edges,
  onResume,
  isLoading,
  executionUpdateStatus,
  signalNotAllowed,
}: BranchDeciderProps) {
  const [parent, setParent] = useState<WorkflowConditionalNode | undefined>();
  const [child, setChild] = useState<WorkflowNode | undefined>();

  const parentNodes = useMemo(() => {
    if (!nodes.length) return [];
    return nodes.filter((node) =>
      (node as WorkflowConditionalNode).data.branchesData?.filter(
        (branch) => branch.selectedMode === BranchModeEnum.Instruction,
      ),
    );
  }, [nodes]);

  const childNodes: WorkflowNode[] = useMemo(() => {
    if (!parent) return [];
    const childEdges = edges.filter((e) => e.source === parent.id);
    const filteredNodes = nodes.filter((node) =>
      childEdges.find((c) => c.target === node.id),
    );

    return filteredNodes;
  }, [parent, edges, nodes]);

  const disable = useMemo(() => {
    const disableAll =
      (!parentNodes.length || isLoading) ??
      (signalNotAllowed || executionUpdateStatus === 'pending');
    const disableChild = disableAll || !parent || !childNodes.length;
    const disableSubmit = disableAll || !parent || !child;

    return {
      disableAll,
      disableChild,
      disableSubmit,
    };
  }, [
    executionUpdateStatus,
    parent,
    childNodes,
    child,
    signalNotAllowed,
    isLoading,
    parentNodes,
  ]);

  const handleSubmit = () => {
    if (!child) {
      notify({
        variant: AlertVariant.WARNING,
        message: 'Please choose a child node first!',
      });
      return;
    }

    if (signalNotAllowed) {
      notify({
        variant: AlertVariant.WARNING,
        message:
          "Can't send signals to execution in Queue, Failed, Terminated, or Success",
      });
      return;
    }
    const payload = { nextNodeId: child.id };
    onResume(SignalTypeEnum.Resume, payload)();
  };
  return (
    <div className="flex justify-end items-stretch space-x-2 mb-4">
      <Select<WorkflowConditionalNode>
        label="Parent Node"
        disabled={disable.disableAll}
        className="!min-w-48 !mt-2"
        getLabel={(option) => option.name ?? option.id}
        getValue={(option) => option.id}
        onChange={(e) => {
          const newID = e.target.value as string;
          const chosenParent = parentNodes.find((i) => i.id === newID);
          setParent(chosenParent as WorkflowConditionalNode);

          if (child) {
            setChild(undefined);
          }
        }}
        options={parentNodes as WorkflowConditionalNode[]}
        value={parent}
      />

      <Select<WorkflowNode>
        label="Next Node"
        disabled={disable.disableChild}
        className="!min-w-48 !mt-2"
        getLabel={(option) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const edgeLabel: string | undefined = edges.find(
            (e) => e.target === option.id,
          )?.label;
          const altLabel = `${option.name ?? option.id} (${option.type})`;

          return edgeLabel ?? altLabel;
        }}
        getValue={(option) => option.id}
        onChange={(e) => {
          const newID = e.target.value as string;
          const chosenChild = nodes.find((i) => i.id === newID);
          setChild(chosenChild);
        }}
        options={childNodes}
        value={child}
      />

      <Button
        className="!mt-2"
        color="secondary"
        disabled={disable.disableSubmit}
        onClick={handleSubmit}
        variant="contained"
      >
        RESUME
      </Button>
    </div>
  );
}

export default BranchDecider;
