import type { WorkflowNodeProps } from 'types-shared';
import { useStore } from 'types-shared/reactflow';
import { minZoomLevelForFullContent } from '../../utils/constants';
import NodeElement from './index';
import { clsx } from 'clsx';
import { useEditingNodeId } from '../../hooks/useEditingNodeId';
import { EditPencilIcon } from 'ui-kit';

const zoomSelector = (s: { transform: number[] }) =>
  s.transform[2] >= minZoomLevelForFullContent;

export default function PlaceholderNode(workflowData: WorkflowNodeProps) {
  const { setEditingNodeId } = useEditingNodeId();
  const showFullContent = useStore(zoomSelector);

  return (
    <NodeElement
      onClick={() => {
        setEditingNodeId(workflowData.id);
      }}
      showAddButton
      showDeleteButton
      workflowData={workflowData}
    >
      <div
        className={clsx(
          'flex-1 flex justify-center items-center cursor-pointer',
          {
            'rounded border border-dashed border-gray-400': showFullContent,
          },
        )}
      >
        <button
          className={clsx('p-4 bg-color-extralight-blue rounded-lg', {
            '!p-6 !m-auto': showFullContent,
          })}
          type="button"
        >
          <EditPencilIcon
            className={clsx(
              '!text-info',
              showFullContent ? '!h-6 !w-6' : '!h-16 !w-16',
            )}
          />
        </button>
      </div>
    </NodeElement>
  );
}
