import 'types-shared/reactflow.css';
import {
  useFetchDatasourceTable,
  useGetDatasourceForWorkflow,
} from '../Datasource/hooks';
import {
  type ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import type { EdgeTypes, NodeProps } from 'types-shared/reactflow';
import { useShallow } from 'zustand/react/shallow';

import CustomEdge from './components/EdgeElement/CustomEdge';
import SourceNode from './components/NodeElement/SourceNode';
import ImageNode from './components/NodeElement/ImageNode';
import {
  useCloneWorkflowImages,
  useGetAllThumbnails,
  useGetRefData,
  useGetWorkflowData,
  useImportSubWorkflowAdmin,
  useSendSlackMessageWithFile,
  useTransformApiReq,
  useUpdateWorkflow,
  useUploadFiles,
} from './hooks';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';
import type { EditorStoreProps } from './store/EditorState';
import { EditorStore } from './store/EditorState';
import {
  NodeTypesEnum,
  SourceTypeEnum,
  SourceVariable,
  type WorkflowData,
  type WorkflowImageNode,
  GlobalVariable,
  VariableTypeEnum,
  type VariableMap,
  type NodeSelectionModeEnums,
} from 'types-shared';
import PlaceholderNode from './components/NodeElement/PlaceholderNode';
import ConditionalNode from './components/NodeElement/ConditionalNode';
import FreeformNode from './components/NodeElement/FreeformNode';
import DocumentNode from './components/NodeElement/DocumentNode';
import EndingNode from './components/NodeElement/EndingNode';
import { useQueryClient } from '@tanstack/react-query';
import ActionsManager from './components/ActionsManager';
import SelectedImageNodeContent from './components/NodeElement/SelectedImageNodeContent';
import AddRecordingNode from './components/NodeElement/AddRecordingNode';
import { AlertVariant, notify } from 'ui-kit';
import RequestNode from './components/NodeElement/RequestNode';
import isEqual from 'lodash/isEqual';
import {
  createEmailTriggerVariables,
  extractGlobalVariablesFromTemplates,
  replaceNodeWithSection,
  showVersionDiffDialog,
} from './utils/helper';
import { useGetGlobalVariables } from '../GlobalVariables/hooks.gql';
import { getExtensionData } from '../../utils/extension';
import { versionHistoryEventBus } from './hooks/useVersionHistory';
import { EditorCore } from './components/EditorCore';
import { ActionViewCore } from './components/ActionViewCore';
import Toolbar from './components/Toolbar/AdminToolbar';
import { autoFormat } from './utils/autoformat';
import noop from 'lodash/noop';
import { type RenderPdfProps } from './components/ActionsManager/DocumentVariables/AnnotatedPDF';

const nodeTypes: Record<NodeTypesEnum, ComponentType<NodeProps>> = {
  image: ImageNode,
  source: SourceNode,
  new: PlaceholderNode,
  conditional: ConditionalNode,
  freeform: FreeformNode,
  document: DocumentNode,
  stop: EndingNode,
  retry: EndingNode,
  continue: AddRecordingNode,
  request: RequestNode,
};

const edgeTypes: EdgeTypes = {
  default: CustomEdge,
};

export default function AdminEditor(): JSX.Element {
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('Workflow ID not provided');
  }

  const queryClient = useQueryClient();
  const { data: workflowMetadata } = useFetchWorkflowMetadata(workflowId);

  const versions = useMemo(() => {
    return workflowMetadata?.versionConfigs
      ? Object.entries(workflowMetadata.versionConfigs)
          .sort(([, a], [, b]) => {
            const getTime = (dateStr: string | undefined) =>
              new Date(
                dateStr ??
                  workflowMetadata.committedAt ??
                  workflowMetadata.createdAt,
              ).getTime();
            return getTime(b.committedAt) - getTime(a.committedAt);
          })
          .map(([key, version]) => ({
            ...version,
            versionId: key,
          }))
      : [];
  }, [
    workflowMetadata?.versionConfigs,
    workflowMetadata?.committedAt,
    workflowMetadata?.createdAt,
  ]);

  const [annotatedPDFData, setAnnotatedPDFData] = useState<RenderPdfProps>();

  const {
    nodes,
    edges,
    datasourceMetadata,
    tableData,
    setNodes,
    setEdges,
    setWorkflowId,
    resetWorkflow,
    setTargets,
    resetTargets,
    setVariables,
    setGlobalVariables,
    resetVariables,
    setDatasourceMetadata,
    setDatasourceTable,
    resetDatasource,
    onNodesChange,
    onEdgesChange,
    onConnect,
    selectedNode,
    setSelectedNode,
    addNodes,
    addEdges,
    updateNode,
    setThumbnails,
    updateVariable,
    addVariable,
    variables: variablesMap,
    globalVariables: globalVariablesMap,
    targets,
    addTargets,
    addVariables,
    currentViewport,
    setCurrentViewport,
    updateVariableData,
    currentVersionId,
    setCurrentVersionId,
    localWorkflowId,
  } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      localWorkflowId: state.workflowId,
      selectedNode: state.selectedNode,
      setSelectedNode: state.setSelectedNode,
      nodes: state.nodes,
      edges: state.edges,
      addEdges: state.addEdges,
      datasourceMetadata: state.datasourceMetadata,
      tableData: state.tableData,

      setNodes: state.setNodes,
      setEdges: state.setEdges,
      setWorkflowId: state.setWorkflowId,
      resetWorkflow: state.resetWorkflow,
      addNodes: state.addNodes,
      updateNode: state.updateNode,
      setThumbnails: state.setThumbnails,

      setTargets: state.setTargets,
      resetTargets: state.resetTargets,

      setVariables: state.setVariables,
      setGlobalVariables: state.setGlobalVariables,
      resetVariables: state.resetVariables,

      setDatasourceMetadata: state.setDatasourceMetadata,
      setDatasourceTable: state.setDatasourceTable,
      resetDatasource: state.resetDatasource,

      onNodesChange: state.onNodesChange,
      onEdgesChange: state.onEdgesChange,
      onConnect: state.onConnect,

      variables: state.variables,
      globalVariables: state.globalVariables,
      targets: state.targets,
      updateVariable: state.updateVariable,
      addVariable: state.addVariable,
      addTargets: state.addTargets,
      addVariables: state.addVariables,
      currentViewport: state.currentViewport,
      setCurrentViewport: state.setCurrentViewport,
      updateVariableData: state.updateVariableData,
      currentVersionId: state.currentVersionId,
      setCurrentVersionId: state.setCurrentVersionId,
    })),
  );
  const [selectedMode, setSelectedMode] =
    useState<NodeSelectionModeEnums | null>(null);
  const { mutateAsync: importSubWorkflow } = useImportSubWorkflowAdmin();
  const currentCloudVersionId = workflowMetadata?.currentVersionId;

  const hasPersistedData = workflowId
    ? Boolean(localStorage.getItem(workflowId)) &&
      [workflowId, 'root'].includes(EditorStore.persist.getOptions().name ?? '')
    : false;

  useEffect(() => {
    if (EditorStore.persist.getOptions().name !== workflowId) {
      EditorStore.persist.setOptions({
        name: 'root',
      });

      resetWorkflow();
      resetTargets();
      resetVariables();
      resetDatasource();
      setWorkflowId(workflowId);

      void EditorStore.persist.rehydrate();

      EditorStore.persist.setOptions({
        name: workflowId,
        skipHydration: false,
      });
    }
  }, [
    workflowId,
    setWorkflowId,
    resetWorkflow,
    resetTargets,
    resetVariables,
    resetDatasource,
  ]);
  const [localWorkflowData, setLocalWorkflowData] = useState<WorkflowData>({
    nodes: [],
    edges: [],
  });

  const imageIds = useMemo(() => {
    return nodes
      .filter((n): n is WorkflowImageNode => n.type === NodeTypesEnum.Image)
      .map((n) => n.data.imageData.imageId);
  }, [nodes]);
  const { data: allThumbnails, isFetching: isFetchingThumbnails } =
    useGetAllThumbnails(workflowId, imageIds);

  const {
    data: datasourceMetadataResponse,
    isFetching: isFetchingDatasourceMetadata,
  } = useGetDatasourceForWorkflow(workflowId);

  const {
    data: datasourceTableData,
    isFetching: isFetchingDatasourceTableData,
  } = useFetchDatasourceTable(
    datasourceMetadata?.datasourceId,
    Boolean(datasourceMetadata) && tableData === null,
  );

  const { mutateAsync: uploadFiles } = useUploadFiles();

  const onUploadFile = useCallback(
    async (file: File) => {
      if (!workflowId) {
        throw new Error('Workflow ID is not defined');
      }
      const fileIds = await uploadFiles({ files: [file], workflowId });
      return { fileId: fileIds[0] };
    },
    [uploadFiles, workflowId],
  );

  const [selectedVersion, setSelectedVersion] = useState<string>('local');

  const switchToVersionStore = useCallback(
    (show: boolean) => {
      if (show) {
        EditorStore.persist.setOptions({
          name: 'version-history',
          skipHydration: true,
        });
      } else {
        setSelectedVersion('local');
        resetWorkflow();
        EditorStore.persist.setOptions({
          name: workflowId,
          skipHydration: false,
        });
        void EditorStore.persist.rehydrate();
      }
    },
    [resetWorkflow, workflowId, setSelectedVersion],
  );

  const { mutateAsync: transformApiReq, status: transformApiReqStatus } =
    useTransformApiReq(variablesMap);

  const { mutateAsync: sendMessage, status: sendMessageStatus } =
    useSendSlackMessageWithFile();
  const { data: workflowData, isLoading: isFetchingWorkflowData } =
    useGetWorkflowData(workflowId, hasPersistedData);
  const { mutateAsync: cloneWorkflowImages } = useCloneWorkflowImages();

  const workflowVersionId: string | undefined = useMemo(() => {
    if (['local', 'cloud'].includes(selectedVersion)) {
      return undefined;
    }
    return selectedVersion;
  }, [selectedVersion]);

  const {
    data: workflowVersionData,
    isFetching: isFetchingWorkflowVersionData,
    refetch: refetchVersion,
  } = useGetWorkflowData(workflowId, false, true, workflowVersionId);
  const { mutateAsync: updateWorkflow, status: updateWorkflowStatus } =
    useUpdateWorkflow(noop, noop);

  const { data: nodeViewData, isLoading: isFetchingNodeData } = useGetRefData(
    workflowId,
    hasPersistedData,
    undefined,
    'admin-editor-key-ii',
  );

  const { data: versionNodeData, refetch: refetchRefVersionData } =
    useGetRefData(workflowId, false, workflowVersionId, 'admin-editor-key-i');

  const { workflowId: editorWorkflowId } = EditorStore();

  const nodeIds = useMemo(() => {
    return nodes.reduce((acc: string[], node) => {
      if (node.type === NodeTypesEnum.Image) {
        acc.push(node.id);
      }
      return acc;
    }, []);
  }, [nodes]);

  const onLoadVersion = (version: string) => {
    setSelectedVersion(version);
    if (version !== 'local') {
      setTimeout(() => {
        void refetchVersion();
        void refetchRefVersionData();
      }, 200);
    } else {
      setNodes(localWorkflowData.nodes);
      setEdges(localWorkflowData.edges);
    }
  };

  const onRestore = () => {
    const isLocal = selectedVersion === 'local';
    const editorData = isLocal ? workflowData : workflowVersionData;
    const actionManagerData = isLocal ? nodeViewData : versionNodeData;
    if (!editorData) {
      throw new Error(
        `No editor data to restore. Version: ${JSON.stringify(selectedVersion)}`,
      );
    }
    if (!actionManagerData) {
      throw new Error(
        `No action manager data to restore. Version: ${JSON.stringify(selectedVersion)}`,
      );
    }
    EditorStore.persist.clearStorage();
    EditorStore.persist.setOptions({
      name: workflowId,
      skipHydration: false,
    });
    setNodes(editorData.nodes);
    setEdges(editorData.edges);
    setTargets(actionManagerData.targetData);
    setVariables(actionManagerData.variableData);
    notify({
      message: 'Workflow version restored successfully!',
      variant: AlertVariant.SUCCESS,
    });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const uploadLocalVersionToCloud = () => {
    const newVariables = extractGlobalVariablesFromTemplates(
      variablesMap,
      globalVariablesMap as VariableMap,
      nodes,
    );

    void updateWorkflow({
      workflowId,
      editorState: {
        nodes,
        edges,
        variables: newVariables,
        // globalVariables: globalVariablesMap,
        targets,
        datasourceMetadata,
        tableData,
      },
      bypassVersionCheck: true,
    });
  };

  const downloadCloudVersion = useCallback(() => {
    localStorage.removeItem(workflowId);
    window.location.reload();
  }, [workflowId]);

  const onImport = async (replaceNodeId?: string) => {
    const extensionData = await getExtensionData();
    if (!extensionData) {
      return false;
    }
    const {
      workflowData: importedWorkflowData,
      variableStore,
      targetStore,
      config,
    } = await importSubWorkflow({
      workflowId,
      extensionData,
    });

    let newNodes = [...nodes, ...importedWorkflowData.nodes];
    let newEdges = [...edges, ...importedWorkflowData.edges];

    const replacementNodeId = config?.replaceNodeId ?? replaceNodeId;
    if (replacementNodeId) {
      ({ newNodes, newEdges } = replaceNodeWithSection(
        { nodes, edges },
        importedWorkflowData,
        replacementNodeId,
      ));
    }

    setEdges(newEdges);
    addTargets(targetStore);
    addVariables(variableStore);

    if (config ?? replacementNodeId) {
      autoFormat(newNodes, newEdges, setNodes);
    } else {
      setNodes(newNodes);
    }
    return true;
  };

  const createVariableMap = (variables: GlobalVariable[]) => {
    return variables.reduce<Record<string, typeof GlobalVariable._type>>(
      (map, variable) => {
        map[variable.id] = variable;
        return map;
      },
      {},
    );
  };

  const closeSelectedImageNodePanel = useCallback(() => {
    setSelectedNode(null);
  }, [setSelectedNode]);

  const userIdForAuthorization = workflowMetadata?.userId;
  const { data: globalVariablesRaw } = useGetGlobalVariables(
    userIdForAuthorization,
  );

  // FIXME: This causes an infinite recursion if we don't memoize the globalVariablesRaw within the hook
  useEffect(() => {
    if (!globalVariablesRaw?.length) return;

    const varResult = globalVariablesRaw
      .map((gVar) => {
        const parsed = GlobalVariable.safeParse({
          id: gVar.id,
          name: gVar.name,
          type: VariableTypeEnum.Global,
          data: [gVar.value],
        });

        if (parsed.success) {
          return parsed.data;
        }
        // eslint-disable-next-line
        console.error(parsed.error);
        return undefined;
      })
      .filter((v): v is GlobalVariable => Boolean(v));

    setGlobalVariables(createVariableMap(varResult));
  }, [globalVariablesRaw, setGlobalVariables]);

  useEffect(() => {
    if (
      workflowVersionData &&
      EditorStore.persist.getOptions().name === 'version-history' &&
      selectedVersion !== 'local'
    ) {
      setNodes(workflowVersionData.nodes);
      setEdges(workflowVersionData.edges);
    }
  }, [setEdges, setNodes, workflowVersionData, selectedVersion]);

  useEffect(() => {
    const unsubscribe = versionHistoryEventBus.on('open', switchToVersionStore);

    return () => {
      unsubscribe();
    };
  }, [switchToVersionStore]);

  useEffect(() => {
    if (!currentVersionId && currentCloudVersionId && nodes.length > 0) {
      setCurrentVersionId(currentCloudVersionId);
    }
  }, [
    currentVersionId,
    setCurrentVersionId,
    currentCloudVersionId,
    nodes.length,
  ]);

  useEffect(() => {
    if (
      localWorkflowId === workflowId &&
      currentVersionId &&
      currentCloudVersionId &&
      currentVersionId !== currentCloudVersionId
    ) {
      // eslint-disable-next-line
      console.log(
        'Version is different',
        currentVersionId,
        currentCloudVersionId,
      );
      showVersionDiffDialog(downloadCloudVersion);
    }
  }, [
    currentCloudVersionId,
    currentVersionId,
    downloadCloudVersion,
    workflowId,
    closeSelectedImageNodePanel,
    localWorkflowId,
  ]);

  useEffect(() => {
    return () => {
      void queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'workflowData' ||
          query.queryKey[0] === 'nodeData',
      });
    };
  }, [queryClient]);

  /*
   Note: We save the local data of the editor before the user selects
   cloud/previous workflow version because when they do the local state of
   zustand is overwritten by cloud/version data, so we want to make sure we
   have a copy of the local editor state user was on, when the user selects
   local version from version history panel, we set the saved local state
   into the zustand store.
   */

  useEffect(() => {
    if (selectedVersion === 'local') {
      setLocalWorkflowData((oldData) => {
        const updateLocalState =
          isEqual(oldData.nodes, nodes) && isEqual(oldData.edges, edges);
        if (updateLocalState) {
          return oldData;
        }
        return {
          nodes,
          edges,
        };
      });
    }
  }, [nodes, edges, selectedVersion]);

  const sourceVariable = useMemo(() => {
    return Object.values(variablesMap).find(
      (variable): variable is SourceVariable =>
        SourceVariable.safeParse(variable).success,
    );
  }, [variablesMap]);

  const sourceType = useMemo(
    () => sourceVariable?.data.sourceType ?? SourceTypeEnum.API,
    [sourceVariable?.data.sourceType],
  );
  const setSourceType = useCallback(
    (newSourceType: SourceTypeEnum) => {
      if (!sourceVariable) return;

      updateVariableData(sourceVariable.id, {
        data: { sourceType: newSourceType },
      });

      // mark the node as checked if the source type is changed to trigger
      if (newSourceType !== SourceTypeEnum.EmailTrigger) return;

      createEmailTriggerVariables(sourceVariable.id, variablesMap).forEach(
        addVariable,
      );
    },
    [sourceVariable, updateVariableData, variablesMap, addVariable],
  );

  return (
    <EditorCore
      hasSuggestions={false}
      ActionView={
        <ActionViewCore
          ActionsManager={ActionsManager}
          SelectedImageNodeContent={SelectedImageNodeContent}
          edges={edges}
          nodes={nodes}
          selectedNodeId={selectedNode}
          setSelectedNode={setSelectedNode}
          workflowStatus={workflowMetadata?.status}
          annotatedPDFData={annotatedPDFData}
          setAnnotatedPDFData={setAnnotatedPDFData}
        />
      }
      Toolbar={
        <Toolbar
          nodeIds={nodeIds}
          onImport={onImport}
          selectNode={setSelectedNode}
          setSourceType={setSourceType}
          workflowId={workflowId}
          workflowMetadata={workflowMetadata}
          saving={updateWorkflowStatus === 'pending'}
        />
      }
      onUploadFile={onUploadFile}
      addEdges={addEdges}
      addNodes={addNodes}
      addTargets={addTargets}
      addVariable={addVariable}
      addVariables={addVariables}
      allThumbnails={allThumbnails}
      allowNodesMerging
      cloneWorkflowImages={cloneWorkflowImages}
      continueRecordingBlockEnabled
      currentViewport={currentViewport}
      datasourceMetadata={datasourceMetadata}
      datasourceMetadataResponse={datasourceMetadataResponse}
      datasourceTableData={datasourceTableData}
      downloadCloudVersion={downloadCloudVersion}
      edgeTypes={edgeTypes}
      edges={edges}
      editorWorkflowId={editorWorkflowId}
      endingStatusBlockFeatureEnabled
      enabledFeatureFlags={[]}
      isFetchingDatasourceMetadata={isFetchingDatasourceMetadata}
      isFetchingDatasourceTableData={isFetchingDatasourceTableData}
      isFetchingNodeData={isFetchingNodeData}
      isFetchingThumbnails={isFetchingThumbnails}
      isFetchingWorkflowData={isFetchingWorkflowData}
      nodeTypes={nodeTypes}
      nodeViewData={nodeViewData}
      nodes={nodes}
      onConnect={onConnect}
      onEdgesChange={onEdgesChange}
      onImport={onImport}
      onLoadVersion={onLoadVersion}
      onNodesChange={onNodesChange}
      onRestore={onRestore}
      selectedNode={selectedNode}
      selectedVersion={selectedVersion}
      sendMessage={sendMessage}
      sendMessageStatus={sendMessageStatus}
      setCurrentViewport={setCurrentViewport}
      setDatasourceMetadata={setDatasourceMetadata}
      setDatasourceTable={setDatasourceTable}
      setEdges={setEdges}
      setNodes={setNodes}
      setSelectedNode={setSelectedNode}
      setSourceType={setSourceType}
      setTargets={setTargets}
      setThumbnails={setThumbnails}
      setVariables={setVariables}
      showSelectAllButton
      sourceType={sourceType}
      tableData={tableData}
      targets={targets}
      transformApiReq={transformApiReq}
      transformApiReqStatus={transformApiReqStatus}
      updateNode={updateNode}
      updateVariable={updateVariable}
      uploadLocalVersionToCloud={uploadLocalVersionToCloud}
      variables={variablesMap}
      globalVariables={globalVariablesMap as Record<string, GlobalVariable>}
      versionLoading={isFetchingWorkflowVersionData}
      versions={versions}
      workflowData={workflowData}
      workflowMetadata={workflowMetadata}
      workflowVersionData={
        selectedVersion === 'local' ? localWorkflowData : workflowVersionData
      }
      setSelectedMode={setSelectedMode}
      selectedMode={selectedMode}
    />
  );
}
