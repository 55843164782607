"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addNewRecordingUrlSchema = exports.dashboardSyncSchema = exports.idPingSchema = void 0;
var types_shared_1 = require("types-shared");
exports.idPingSchema = {
    request: types_shared_1.z.object({
        sender: types_shared_1.z.object({
            tab: types_shared_1.z.object({
                windowId: types_shared_1.z.number(),
            }),
        }),
    }),
    response: types_shared_1.ChromeTab,
};
exports.dashboardSyncSchema = {
    request: types_shared_1.z.object({
        body: types_shared_1.z.object({
            userData: types_shared_1.z
                .object({
                id: types_shared_1.z.string(),
                name: types_shared_1.z.string().optional(),
                email: types_shared_1.z.string().optional(),
            })
                .optional(),
            logout: types_shared_1.z.boolean().optional(),
        }),
    }),
    response: types_shared_1.z.boolean(),
};
exports.addNewRecordingUrlSchema = {
    request: types_shared_1.z.object({
        url: types_shared_1.z.string(),
    }),
    response: types_shared_1.z.boolean(),
};
