import { type ReactNode } from 'react';

export default function SectionLabel({
  title,
  children,
}: {
  title: string;
  children?: ReactNode;
}) {
  return (
    <div className="mt-4 flex items-center space-x-4">
      <span className="text-cyan-900 text-sm font-medium leading-normal">
        {title}
      </span>
      {children}
    </div>
  );
}
