import { CloseIcon, Button, FormControlLabel, Radio, RadioGroup } from 'ui-kit';
import type { WorkflowStopNode } from 'types-shared';
import { NodeStatusEnum, StopNodeStatusEnum } from 'types-shared';
import React from 'react';
import { NodeCheck } from '../NodeCheck';

interface Props {
  node: WorkflowStopNode;
  updateNodeDataStatus: (status: string) => void;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  onCancel: () => void;
}

export function StopBlock({
  node,
  onCancel,
  updateNodeDataStatus,
  updateNodeStatus,
}: Props) {
  return (
    <div className="zoom-adjusted-container node-block absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] p-8 flex flex-col justify-between space-y-5">
      <div className="overflow-visible">
        <div className="flex justify-between items-center">
          <span className="text-sm text-primary-blue font-medium">
            Stop step
          </span>
          <Button
            className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
            color="secondary"
            onClick={onCancel}
            variant="outlined"
          >
            <CloseIcon className="text-info" />
          </Button>
        </div>
        <div className="my-6">
          <h2 className="text-lg font-medium">Stop step</h2>
          <p className="text-sm font-normal mt-2 text-info-dark">
            Stop the workflow at this point.
          </p>
        </div>

        <RadioGroup
          name="node-types-group-2 stop-block"
          onChange={(e, v) => {
            updateNodeDataStatus(v);
          }}
          value={node.data.status}
        >
          <FormControlLabel
            classes={{
              root: '!items-start',
            }}
            control={<Radio color="secondary" />}
            label={
              <div className="flex flex-col mt-2">
                <div className="leading-normal">
                  Stop and mark as successful
                </div>
                <div className="text-zinc-500 text-smeading-tight">
                  When the workflow reaches this step, halt execution and mark
                  as a successful execution.
                </div>
              </div>
            }
            value={StopNodeStatusEnum.Success}
          />
          <div className="mt-4" />
          <FormControlLabel
            classes={{
              root: '!items-start',
            }}
            control={<Radio color="secondary" />}
            label={
              <div className="flex flex-col mt-2">
                <div className="leading-normal">Stop and mark as failure</div>
                <div className="text-zinc-500 text-smeading-tight">
                  When the workflow reaches this step, halt execution and mark
                  as a failed execution.
                </div>
              </div>
            }
            value={StopNodeStatusEnum.Failure}
          />
        </RadioGroup>
      </div>
      <div className="flex flex-col space-y-7">
        {!node.hideFromUser ? (
          <NodeCheck
            isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
            updateNodeStatus={updateNodeStatus}
          />
        ) : null}
        <Button
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          Back to flow view
        </Button>
      </div>
    </div>
  );
}
