"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.processWorkflowSchema = exports.WorkflowConversionFormatEnum = void 0;
var types_shared_1 = require("types-shared");
var WorkflowConversionFormatEnum;
(function (WorkflowConversionFormatEnum) {
    WorkflowConversionFormatEnum["PuppeteerUserflow"] = "PUPPETEER_USERFLOW";
    WorkflowConversionFormatEnum["LegacyWorkflow"] = "LEGACY_WORKFLOW";
    WorkflowConversionFormatEnum["LegacyWorkflowNode"] = "LEGACY_WORKFLOW_NODE";
    WorkflowConversionFormatEnum["LegacyRecording"] = "LEGACY_RECORDING";
    WorkflowConversionFormatEnum["DesktopPlan"] = "DESKTOP_PLAN";
})(WorkflowConversionFormatEnum || (exports.WorkflowConversionFormatEnum = WorkflowConversionFormatEnum = {}));
exports.processWorkflowSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
        }),
        body: types_shared_1.z.object({
            inputFormat: types_shared_1.z.nativeEnum(WorkflowConversionFormatEnum),
            outputFormat: types_shared_1.z.nativeEnum(WorkflowConversionFormatEnum),
        }),
    }),
    response: types_shared_1.z.object({
        processingId: types_shared_1.z.string(),
    }),
};
