import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  type EdgeProps,
} from 'types-shared/reactflow';
import { EditorStore } from '../../store/EditorState';
import { useEditingNodeId } from '../../hooks/useEditingNodeId';
import { useMemo } from 'react';
import {
  NodeStatusEnum,
  NodeTypesEnum,
  type WorkflowNode,
  type WorkflowEdge,
} from 'types-shared/workflowTypes';
import { v4 as uuid } from 'uuid';
import { clsx } from 'clsx';
import { isAdmin } from '../../../../utils/env';
import { Tooltip, Add } from 'ui-kit';
import { CustomMarker } from './CustomMarker';
import { autoFormat } from '../../utils/autoformat';
import { insertNodeAfter } from '../../utils/helper';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  selected,
  source,
  target,
}: EdgeProps) {
  const { nodes, edges, setEdges, setNodes } = EditorStore();
  const { setEditingNodeId } = useEditingNodeId();

  const [edgePath, labelX, labelY] = useMemo(
    () =>
      getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      }),
    [sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition],
  );
  const currentEdge: WorkflowEdge | undefined = useMemo(
    () => edges.find((edge) => edge.id === id),
    [edges, id],
  );
  const label = currentEdge?.label as string | undefined;

  const insertNode = () => {
    if (isAdmin) {
      const sourceNode = nodes.find((n) => n.id === source);

      if (sourceNode) {
        const nodeId = uuid();
        const newNode: WorkflowNode = {
          id: nodeId,
          position: {
            ...sourceNode.position,
          },
          type: NodeTypesEnum.New,
          data: {
            nodeStatus: NodeStatusEnum.NotViewed,
          },
        };

        const _oldEdge = edges.find((e) => e.id === id);
        const oldEdges = edges.filter((edge) => edge.id !== id);

        if (_oldEdge) {
          const newEdges = [
            ...oldEdges,
            {
              ..._oldEdge,
              target: nodeId,
            },
            {
              id: uuid(),
              source: nodeId,
              target,
            },
          ];
          setEdges(newEdges);
          autoFormat([...nodes, newNode], newEdges, setNodes);
        }
      }
    } else {
      // Customer
      const edge = edges.find((e) => e.id === id);
      if (!edge) {
        throw new Error('edge not found');
      }
      const { nodeId } = insertNodeAfter(edge, nodes, edges, {
        setNodes,
        setEdges,
      });
      setEditingNodeId(nodeId);
    }
  };

  return (
    <>
      <CustomMarker />
      <BaseEdge
        markerEnd="url(#triangle)"
        path={edgePath}
        style={{
          strokeWidth: selected ? '4px' : '2px',
          stroke: selected ? '#246BFA' : '#000',
        }}
      />
      <EdgeLabelRenderer>
        <div
          className="nodrag nopan"
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX.toString()}px,${labelY.toString()}px)`,
            fontSize: 12,
            pointerEvents: 'all',
          }}
        >
          {label ? (
            <Tooltip arrow hidden={label.length <= 20} title={label}>
              <p
                className={clsx(
                  'edge-label p-1 rounded text-center truncate max-w-[20ch] absolute -top-8 left-1/2 -translate-x-1/2 -translate-y-1/2',
                  {
                    'bg-primary-blue text-white': selected,
                    ' bg-white': !selected,
                  },
                )}
                style={currentEdge?.labelStyle}
              >
                {label}
              </p>
            </Tooltip>
          ) : null}
          <button
            className="bg-flow-view add-btn"
            onClick={insertNode}
            type="button"
          >
            <Add />
          </button>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
