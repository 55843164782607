import React, { useMemo } from 'react';
import {
  type Variable,
  type GlobalVariable,
  type VariableMap,
} from 'types-shared';
import values from 'lodash/values';

interface Props {
  name: string;
  editingVariableId?: string;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  className?: string;
  children?: React.ReactNode;
}

export function SameVariableNameError({
  name,
  editingVariableId,
  variablesMap,
  globalVariablesMap,
  className,
  children,
}: Props) {
  const sameNameVariable = useMemo(() => {
    const variables = [...values(variablesMap), ...values(globalVariablesMap)];
    return variables.find((v) => v.name?.trim() === name.trim());
  }, [name, variablesMap, globalVariablesMap]);

  if (!sameNameVariable) return null;
  if (sameNameVariable.id === editingVariableId) return null;

  return (
    <div className={className}>
      <span className="-mt-2 mb-3 text-red-500">
        {children ? children : 'Variable with this name already exists!'}
      </span>
    </div>
  );
}
